import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export function Title(props: { children: React.ReactNode }) {
    return (
        <Typography variant="h5" gutterBottom>
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};
