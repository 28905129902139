import { gql, useQuery } from "@apollo/client";
import { Check, Tune } from "@mui/icons-material";
import {
    Badge,
    Box,
    Button,
    ClickAwayListener,
    Drawer,
    Fade,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Select,
    Typography,
} from "@mui/material";
import clsx from "clsx";
import equal from "fast-deep-equal";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { SearchConfig } from "../generated/consumer-graph-types";
import { ROUTE_EXPLORE } from "../portal/routes";
import { URL_PARAM_SEARCH } from "../portal/url-params";
import { getCurrentLng } from "../providers/ui-language-provider";
import { teasers } from "../shared-queries";
import { ContentsFilterProps } from "../shared-queries/do-not-parse";
import { GraphLoader } from "./graph-loader";
import { InfoCubeAutocomplete } from "./info-cube-autocomplete";
import { useInfoCubeMediaQuery } from "./info-cube-media-query";
import { useSearchFieldStyles } from "./search-field-style";
import { getSearchMetadataURLParams } from "./search-utils";
import { ApplicationScope } from "../enums";

interface SearchFilterQueryData {
    searchConfig: SearchConfig;
}

export interface MetadataSearchField {
    title: string;
    id: string;
}

interface SearchFilterQueryData {
    searchConfig: SearchConfig;
}

export const SEARCH_CONFIG_QUERY = (languages: string[]) => {
    return gql`
        query searchConfig($id: ID!){         
            searchConfig(id:$id) {
                entries {
                    type
                    fieldType
                    referencedId
                    referencedId
                    ${teasers(languages)}
                }
            }
        }
    `;
};

export type SearchFieldClasses = {
    autocomplete: string;
};

export type SearchFieldProps = {
    color: "primary" | "secondary";
    variant?: "filled" | "outlined" | "standard";
    searchAction?: "Button" | "Icon";
    metadata?: boolean;
    classes?: SearchFieldClasses;
    label?: string;
    placeholder?: string;
    onBlur?: (searchValue: string) => void;
    inputRef?: any;
    onSelectFacetValue?: (rootId: string, referencedId: string) => void;
    contentFilterProps?: ContentsFilterProps;
    clearTriggersSearch?: boolean;
    autoFocus?: boolean;
};

export const SearchField: FC<SearchFieldProps> = ({
    color,
    variant,
    metadata,
    classes,
    label,
    placeholder,
    onBlur,
    inputRef,
    onSelectFacetValue,
    contentFilterProps,
    clearTriggersSearch = true,
    autoFocus,
}) => {
    const SELECTED_METADATA_SEARCH_KEY = "SelectedSearchMetadata";
    const METADATA_FIELD_ALL = "all";
    const isMobile = useInfoCubeMediaQuery();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const searchClasses = useSearchFieldStyles();
    const currentLanguage = getCurrentLng();
    const [searchValue, setSearchValue] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>("");
    const [selectedSearchFieldValue, setSelectedSearchFieldValue] = useState<string>("");
    const [availableMetadataSearchFields, setAvailableMetadataSearchFields] = useState<
        MetadataSearchField[] | undefined
    >();
    const [searchMetaFields, setSearchMetaFields] = useState<string[]>([]);
    const [isSettingsPopperOpen, setIsSettingsPopperOpen] = useState<boolean>(false);
    const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>();

    const updateActiveSearchField = (searchFieldValue: string) => {
        let searchFields: string[] = [];
        if (searchFieldValue && searchFieldValue !== METADATA_FIELD_ALL) searchFields = [searchFieldValue];

        if (!equal(searchMetaFields, searchFields)) setSearchMetaFields(searchFields);

        if (searchFieldValue !== selectedSearchFieldValue) setSelectedSearchFieldValue(searchFieldValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    //handles deeplinking for selected search meta field and search value
    useEffect(() => {
        let metaFields: string[] = [];

        if (location.search) {
            //set search values by url params
            //search value
            const urlParams = new URLSearchParams(location.search);
            const searchParam = urlParams.get(URL_PARAM_SEARCH) ? (urlParams.get(URL_PARAM_SEARCH) as string) : "";
            if (searchParam !== inputValue) {
                setSearchValue(searchParam);
                setInputValue(searchParam);
            }

            //search meta fields
            const metaDatafieldsFromUrl: string[] = [];
            urlParams.forEach((value, key) => {
                if (value === "1" && key !== URL_PARAM_SEARCH) {
                    metaDatafieldsFromUrl.push(key);
                }
            });

            if (metaDatafieldsFromUrl.length) metaFields = metaDatafieldsFromUrl;

            localStorage.setItem(SELECTED_METADATA_SEARCH_KEY, JSON.stringify(metaFields));
        } else if (searchValue) {
            setSearchValue("");
            setInputValue("");
        }

        const newSearchFieldValue = metaFields.length ? metaFields[0] : METADATA_FIELD_ALL;
        updateActiveSearchField(newSearchFieldValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    useEffect(() => {
        if (availableMetadataSearchFields) {
            if (!metadata || !searchMetaFields.length) {
                updateActiveSearchField(METADATA_FIELD_ALL);
                return;
            }

            //clean not available metadata
            const filteredMetaDatafields = searchMetaFields.filter((x) => {
                return availableMetadataSearchFields?.some((y) => {
                    return y.id === x;
                });
            });

            //currently its`s only possible to select one search field or all
            if (filteredMetaDatafields.length === 1) {
                updateActiveSearchField(filteredMetaDatafields[0]);
                return;
            }

            if (filteredMetaDatafields.length === availableMetadataSearchFields?.length) {
                if (
                    JSON.stringify([...filteredMetaDatafields].sort()) ===
                    JSON.stringify(availableMetadataSearchFields.map((x) => x.id).sort())
                ) {
                    updateActiveSearchField(METADATA_FIELD_ALL);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableMetadataSearchFields, searchMetaFields]);

    const { loading, error } = useQuery<SearchFilterQueryData>(SEARCH_CONFIG_QUERY([currentLanguage]), {
        fetchPolicy: "cache-first",
        skip: !metadata,
        variables: {
            id: ApplicationScope.documentSelection,
        },
        onCompleted: (data) => {
            const searchFieldMetaData: MetadataSearchField[] =
                data?.searchConfig?.entries?.map((searchEntry) => {
                    return {
                        title: searchEntry?.teasers?.title || "",
                        id: searchEntry?.referencedId as string,
                    };
                }) || [];

            setAvailableMetadataSearchFields(searchFieldMetaData);
            //if only one search field meta data exists then use this value as default
            if (searchFieldMetaData.length === 1) setSelectedSearchFieldValue(searchFieldMetaData[0].id);
        },
    });

    const onChangeSelection = (value: string) => {
        updateActiveSearchField(value);
        let selected = value === METADATA_FIELD_ALL ? [] : [value];
        localStorage.setItem(SELECTED_METADATA_SEARCH_KEY, JSON.stringify(selected));
    };

    const onClear = () => {
        if (clearTriggersSearch) triggerSearch("");
        else setInputValue("");
    };

    const triggerSearch = useCallback(
        (search) => {
            const searchMetadataURLParams = getSearchMetadataURLParams(location, search, searchMetaFields);
            history.push(`${ROUTE_EXPLORE}?${searchMetadataURLParams.toString()}`);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location, searchMetaFields]
    );

    const openSearchSettings = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsSettingsPopperOpen(!isSettingsPopperOpen);
    };

    const handleClickAway = () => {
        setIsSettingsPopperOpen(false);
    };

    if (!metadata)
        return (
            <>
                <InfoCubeAutocomplete
                    color={color}
                    classes={{ root: classes?.autocomplete }}
                    label={label}
                    placeholder={placeholder}
                    value={searchValue}
                    onSearchValueChange={(val: string) => setSearchValue(val)}
                    onInputValueChange={(val: string) => setInputValue(val)}
                    onEnter={triggerSearch}
                    onBlur={onBlur}
                    inputRef={inputRef}
                    onSelectFacetValue={onSelectFacetValue}
                    onClear={onClear}
                    contentFilterProps={contentFilterProps}
                    autoFocus={autoFocus}
                />
            </>
        );

    const renderSearchBtnInputAdornment = () => {
        return (
            <>
                <InputAdornment position="end">
                    <Button
                        onClick={openSearchSettings}
                        className={searchClasses.settingsBtn}
                        variant="contained"
                        color={"primary"}
                        disableElevation={true}
                        aria-label="search-config"
                    >
                        <Badge
                            badgeContent={selectedSearchFieldValue === METADATA_FIELD_ALL ? 0 : 1}
                            variant="dot"
                            color="error"
                        >
                            <Tune data-icon={"search-settings"} />
                        </Badge>
                    </Button>
                </InputAdornment>
            </>
        );
    };

    const showSettingsPopper = Boolean(availableMetadataSearchFields?.length) && !isMobile;
    const showSettingsDrawer = Boolean(availableMetadataSearchFields?.length) && isMobile;

    const renderSettingsPopper = (availableMetadataSearchFields: MetadataSearchField[]) => {
        return (
            <Popper
                key={"search-settings"}
                data-popper={"search-settings"}
                open={isSettingsPopperOpen}
                placement={"bottom"}
                anchorEl={anchorEl}
                transition
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper elevation={3}>
                                <Box p={2}>
                                    <FormControl className={searchClasses.metadata} variant="outlined" size={"small"}>
                                        <InputLabel>{t("Metadata")}</InputLabel>
                                        <Select
                                            autoFocus={true}
                                            data-testid={"select-metadata"}
                                            native
                                            autoWidth={true}
                                            variant={"outlined"}
                                            value={selectedSearchFieldValue}
                                            label={t("Metadata")}
                                            MenuProps={{ classes: { list: searchClasses.select } }}
                                            onChange={(event) => onChangeSelection(event.target.value)}
                                        >
                                            {availableMetadataSearchFields.length > 1 && (
                                                <option value={METADATA_FIELD_ALL}>{t("All")}</option>
                                            )}
                                            {availableMetadataSearchFields.map((x) => {
                                                return (
                                                    <option key={x.id} value={x.id}>
                                                        {x.title}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        );
    };

    const renderSettingsDrawer = (availableMetadataSearchFields: MetadataSearchField[]) => {
        return (
            <>
                <IconButton onClick={() => setIsSettingsDrawerOpen(true)}>
                    <Tune data-icon={"search-settings"} color="primary" sx={{ fontSize: 32 }} />
                </IconButton>
                <Drawer
                    anchor={"bottom"}
                    open={isSettingsDrawerOpen}
                    onClose={() => setIsSettingsDrawerOpen(false)}
                    sx={{ zIndex: 1305 }}
                >
                    <Paper elevation={3}>
                        <Box m={3} mb={1} mt={2}>
                            <Typography variant="h6">{t("Metadata")}</Typography>
                        </Box>
                        <MenuList>
                            {availableMetadataSearchFields.length > 1 && (
                                <MenuItem
                                    onClick={() => {
                                        onChangeSelection(METADATA_FIELD_ALL);
                                        setIsSettingsDrawerOpen(false);
                                    }}
                                >
                                    <Box component={"span"} minWidth={32}>
                                        {selectedSearchFieldValue === METADATA_FIELD_ALL && (
                                            <Box display={"flex"}>
                                                <Check />
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        component={"span"}
                                        fontWeight={
                                            selectedSearchFieldValue === METADATA_FIELD_ALL
                                                ? "fontWeightBold"
                                                : "fontWeightRegular"
                                        }
                                    >
                                        {t("All")}
                                    </Box>
                                </MenuItem>
                            )}
                            {availableMetadataSearchFields.map((entry, index) => {
                                const isSelected = selectedSearchFieldValue === entry.title;

                                return (
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            onChangeSelection(entry.title);
                                            setIsSettingsDrawerOpen(false);
                                        }}
                                    >
                                        <Box component={"span"} minWidth={32}>
                                            {isSelected && (
                                                <Box display={"flex"}>
                                                    <Check />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            component={"span"}
                                            fontWeight={isSelected ? "fontWeightBold" : "fontWeightRegular"}
                                        >
                                            {entry.title}
                                        </Box>
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </Paper>
                </Drawer>
            </>
        );
    };

    const render = () => {
        return (
            <>
                <InfoCubeAutocomplete
                    color={color}
                    variant={variant}
                    classes={{
                        root: showSettingsPopper
                            ? clsx(classes?.autocomplete, searchClasses.endAdornment)
                            : classes?.autocomplete,
                    }}
                    label={label}
                    placeholder={placeholder}
                    value={searchValue}
                    onSearchValueChange={(val: string) => setSearchValue(val)}
                    onInputValueChange={(val: string) => setInputValue(val)}
                    onEnter={triggerSearch}
                    onBlur={onBlur}
                    inputRef={inputRef}
                    onSelectFacetValue={onSelectFacetValue}
                    onClear={onClear}
                    contentFilterProps={contentFilterProps}
                    endAdornment={showSettingsPopper ? renderSearchBtnInputAdornment() : null}
                    autoFocus={autoFocus}
                />
                {showSettingsPopper && renderSettingsPopper(availableMetadataSearchFields as MetadataSearchField[])}
                {showSettingsDrawer && renderSettingsDrawer(availableMetadataSearchFields as MetadataSearchField[])}
            </>
        );
    };

    return <GraphLoader loading={loading} error={error} data={availableMetadataSearchFields} render={render} />;
};
