import { ApolloError, gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dictionary } from "ts-essentials";
import { consumerClient } from "../../apollo-clients/consumer-client";
import { ApplicationScope } from "../../enums";
import {
    DatafieldType,
    DataDisplayConfigEntry,
    DataDisplayConfigDocument,
    DataDisplayConfigQuery,
    FieldDefinitionTypes,
    TopicEdge,
    TopicQueryResult,
} from "../../generated/consumer-graph-types";
import { MultilingualString } from "../../generated/public-graph-types";
import { getCurrentLng } from "../../providers/ui-language-provider";
import { generateHitListQueryParts } from "../../shared-queries/do-not-parse";
import { DateTimeFormatter, isValidISODate } from "../../utils";

interface TopicData {
    topics: TopicQueryResult;
}

interface ContentDetailsDataResult {
    metaDataWithValues: Array<{ title: string; values: string[] }>;
    loading: boolean;
    error?: ApolloError;
}

export const useContentDetailsData = (contentId: string, skip: boolean): ContentDetailsDataResult => {
    const [metaDataWithValues, setMetaDataWithValues] = useState<Array<{ title: string; values: string[] }>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ApolloError>();
    const language = getCurrentLng();
    const { t } = useTranslation();

    const staticMetaData = [
        {
            id: "id",
            title: t("ID"),
        },
        {
            id: "uploadDate",
            title: t("Uploaded"),
        },
        {
            id: "modificationDate",
            title: t("Changed"),
        },
        {
            id: "creationDate",
            title: t("Created"),
        },
        {
            id: "locale",
            title: t("Language"),
        },
        {
            id: "mimeType",
            title: t("Format"),
        },
        {
            id: "version",
            title: t("Version"),
        },
    ];

    const buildTopicMetadataDetailsQuery = (hitInformationEntries: DataDisplayConfigEntry[]) => {
        const { topicFields, metadata } = generateHitListQueryParts(hitInformationEntries);

        let staticMetaDatafields = "";
        for (const _staticMetaData of staticMetaData) {
            staticMetaDatafields += _staticMetaData.id + "\n";
        }

        return gql`
            query topicMetadataDetails($ids: [ID]) {
                topics(filter: { ids: $ids }) {
                    topics {
                        node {
                            id
                            ${staticMetaDatafields}                            
                            ${topicFields}
                            ${metadata}
                        }
                    }
                } 
            }
        `;
    };

    const setMetadataTitles = (metaDataDefinitions: DataDisplayConfigEntry[], titles: Dictionary<string>) => {
        for (const metaDataDefinition of metaDataDefinitions) {
            if (!metaDataDefinition) continue;
            //@ts-ignore
            titles[metaDataDefinition?.referencedId] =
                metaDataDefinition?.teasers?.title || metaDataDefinition?.referencedId;
        }
    };

    const setTaxonomiesAndEnumValuesTitles = (
        metaDataDefinitions: DataDisplayConfigEntry[],
        titles: Dictionary<string>
    ) => {
        const taxonomyAndEnumMetadata = metaDataDefinitions.filter((datafieldDefinition) => {
            return (
                datafieldDefinition?.fieldType === FieldDefinitionTypes.taxonomy ||
                datafieldDefinition?.fieldType === FieldDefinitionTypes.enum
            );
        });

        for (const taxonomyOrEnumMetadataNode of taxonomyAndEnumMetadata) {
            if (!taxonomyOrEnumMetadataNode?.referencedId) continue;
            //@ts-ignore
            if (!taxonomyOrEnumMetadataNode?.datafieldDefinition?.values) continue;
            //@ts-ignore
            for (const taxonomyOrEnumValue of taxonomyOrEnumMetadataNode?.datafieldDefinition?.values) {
                if (!taxonomyOrEnumValue?.key) continue;
                //@ts-ignore
                titles[`${taxonomyOrEnumMetadataNode?.referencedId}.${taxonomyOrEnumValue?.key}`] =
                    taxonomyOrEnumValue.teasers.title || "";
            }
        }
    };

    function isString(x: any) {
        return Object.prototype.toString.call(x) === "[object String]";
    }

    function setMetaData(
        metaDataKeys: string[],
        topic: TopicEdge,
        translatedTitlesAndValues: Dictionary<string>,
        _metaDataWithValues: Array<{ title: string; values: string[] }>
    ) {
        for (const metaDataKey of metaDataKeys) {
            //@ts-ignore
            const metaDataValues = topic?.node[metaDataKey];
            if (!metaDataValues?.length) {
                continue;
            }

            const translatedTitleOfMetaDataValues = metaDataValues
                .map((value: string | MultilingualString) => {
                    let dateValue;
                    //@ts-ignore
                    if (isString(value) && isValidISODate(value)) {
                        //@ts-ignore
                        dateValue = DateTimeFormatter(value);
                    }

                    return dateValue || translatedTitlesAndValues[`${metaDataKey}.${value}`] || value;
                })
                .filter((x: any) => !!x);

            const translatedTitleOfMetaDataKey = translatedTitlesAndValues[metaDataKey];

            if ((translatedTitleOfMetaDataKey || metaDataKey) && Object.keys(translatedTitleOfMetaDataValues).length)
                _metaDataWithValues.push({
                    title: translatedTitleOfMetaDataKey || metaDataKey,
                    values: translatedTitleOfMetaDataValues,
                });
        }
    }

    function setStaticMetaDataValues(
        topic: TopicEdge,
        _metaDataWithValues: Array<{ title: string; values: string[] }>
    ) {
        for (const value of staticMetaData) {
            let _value = (topic?.node as any)[value.id];

            if (_value) {
                if (isString(_value) && isValidISODate(_value)) {
                    _value = DateTimeFormatter(_value);
                }
                _metaDataWithValues.push({ title: value.title, values: Array.isArray(_value) ? _value : [_value] });
            }
        }
        return _metaDataWithValues;
    }

    function setHitListData(
        hitListDataKeys: string[],
        topic: TopicEdge,
        translatedTitlesAndValues: Dictionary<string>,
        _metaDataWithValues: Array<{ title: string; values: string[] }>
    ) {
        for (const metaDataKey of hitListDataKeys) {
            //@ts-ignore
            let metaDataValues = topic?.node[metaDataKey];
            if (!metaDataValues) {
                continue;
            }

            if (isValidISODate(metaDataValues)) {
                metaDataValues = DateTimeFormatter(metaDataValues);
            }

            _metaDataWithValues.push({
                title: translatedTitlesAndValues[metaDataKey] || metaDataKey,
                values: [metaDataValues],
            });
        }
    }

    const setTextMetaData = (
        topic: TopicEdge,
        _metaDataWithValues: Array<{ title: string; values: string[] }>,
        translatedTitlesAndValues: Dictionary<string>
    ) => {
        if (topic?.node.teasers)
            for (const [key, value] of Object.entries(topic?.node.teasers)) {
                if (key === "__typename") continue;
                if (value && Array.isArray(value) && value.length > 0 && value[0]) {
                    _metaDataWithValues.push({ title: translatedTitlesAndValues[key] || key, values: value });
                }
            }
    };

    const getContentDetails = async () => {
        //1. query dataDisplayConfig
        const { data: dataDisplayConfigData, error: hitListError } =
            await consumerClient().query<DataDisplayConfigQuery>({
                query: DataDisplayConfigDocument,
                fetchPolicy: "cache-first",
                variables: { id: ApplicationScope.documentSelection, acceptedLanguages: [language] },
            });

        if (hitListError) {
            setError(hitListError);
            return;
        }

        //remove fields which are already in staticMetaDatafields
        const filteredHitListConfig =
            dataDisplayConfigData.dataDisplayConfig?.entries?.filter(
                (val) =>
                    !staticMetaData.some((x) => {
                        return x.id === val.referencedId;
                    })
            ) || [];

        const hitListFields = filteredHitListConfig.filter((val) => val.type === DatafieldType.field) || [];
        const hitListMetadata = filteredHitListConfig.filter((val) => val.type === DatafieldType.metadata) || [];

        const TOPIC_META_DATA_DETAILS_QUERY = buildTopicMetadataDetailsQuery(filteredHitListConfig as any);
        //2. query hitlistconfig values from topic
        const { data: topicMetaDataDetailsData, error: topicMetaDataDetailsError } =
            await consumerClient().query<TopicData>({
                query: TOPIC_META_DATA_DETAILS_QUERY,
                variables: { ids: [contentId] },
                fetchPolicy: "cache-first",
            });

        if (topicMetaDataDetailsError) {
            setError(topicMetaDataDetailsError);
            return;
        }

        const translatedTitlesAndValues: Dictionary<string> = {};

        setTaxonomiesAndEnumValuesTitles(filteredHitListConfig as DataDisplayConfigEntry[], translatedTitlesAndValues);
        setMetadataTitles(filteredHitListConfig as DataDisplayConfigEntry[], translatedTitlesAndValues);

        const topics = topicMetaDataDetailsData?.topics?.topics;

        if (!topics?.length) return;

        const topic = topics[0];

        const metaDataKeys = hitListMetadata.map((x) => {
            return x.referencedId;
        });

        const hitListDataKeys = hitListFields.map((x) => {
            return x.referencedId;
        });

        const _metaDataWithValues: Array<{ title: string; values: string[] }> = [];
        setStaticMetaDataValues(topic as TopicEdge, _metaDataWithValues);
        setMetaData(metaDataKeys, topic as TopicEdge, translatedTitlesAndValues, _metaDataWithValues);
        setHitListData(hitListDataKeys, topic as TopicEdge, translatedTitlesAndValues, _metaDataWithValues);
        setTextMetaData(topic as TopicEdge, _metaDataWithValues, translatedTitlesAndValues);

        setMetaDataWithValues(_metaDataWithValues);
    };

    useEffect(() => {
        if (skip) return;

        setLoading(true);

        getContentDetails()
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentId, skip]);

    return { error, loading, metaDataWithValues };
};
