import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useInfoCubeTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        windowScrollPaper: {
            display: "flex",
            flexGrow: 1,
            height: "fit-content",
        },
        virtualizedPaper: {
            display: "flex",
            flexGrow: 1,
        },
        tableHeaderRow: {
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid",
            color: theme.palette.grey["200"],
            "& div": {
                outline: "none",
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        tableCell: {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            width: "100% !important",
            paddingLeft: 0,
        },
        tooltipTableCell: {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            width: "100% !important",
            paddingLeft: 0,
            "& span": {
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
        tableRow: {
            display: "flex",
            flex: 1,
            width: "100% !important",
            "&:focus": {
                outline: "none",
            },
        },
        tableRowClickable: {
            cursor: "pointer",
            outline: "none",
            "&:hover": {
                backgroundColor: theme.palette.grey[100],
            },
        },
        tableRowSelected: {
            backgroundColor: theme.palette.grey[100],
        },
        tableHeader: {
            display: "flex",
            alignItems: "center",
        },
        tableHeaderSort: {
            cursor: "pointer",
        },
        tableGrid: {
            "&:focus": {
                outline: "none",
            },
        },
        moreDetailsSide: {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            paddingTop: 0,
            overflow: "auto",
            fontSize: 14,
        },
        moreDetailsBottom: {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            width: "100%",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        textEllipsis: {
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
    })
);
