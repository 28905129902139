import { gql } from "@apollo/client";
import { CommentOutlined, CreateNewFolderOutlined, InfoOutlined, Toc } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import "react-reflex/styles.css";
import { ErrorPage } from "../../components/error-page";
import { InfoCubeBreadcrumbs } from "../../components/info-cube-breadcrumbs";
import { useInfoCubeMediaQuery } from "../../components/info-cube-media-query";
import { MoreMenu } from "../../components/more-menu";
import { NotFoundPage } from "../../components/not-found-page";
import { Progress } from "../../components/progress";
import { RenderIfHasAccess } from "../../components/render-if-has-access";
import { register } from "../../customizing-service";
import { Action, Resource } from "../../enums";
import { ContentMap, ContentMapQueryResult, useContentMapsQuery } from "../../generated/consumer-graph-types";
import { getCurrentContent, setCurrentContent, setCurrentTopicId } from "../../providers/content-provider";
import { AddToCollectionDialog } from "../collections/add-to-collection-dialog";
import { NotesSideSheet } from "../notes/notes-side-sheet";
import { ContentDetailSideSheet } from "./content-detail-side-sheet";
import { ContentIframe } from "./content-iframe";
import { useContentStyle } from "./content-style";
import { ContentToc } from "./content-toc";
import { ContentTocDrawer } from "./content-toc-drawer";

const TOC_MENU_KEY = "tocMenu";
export interface ContentProps {
    contentMapId: string;
    topicId?: string;
    notificationsOpen?: boolean;
    setNotificationsOpen?: Dispatch<SetStateAction<boolean>>;
}

gql`
    query contentMaps($contentMapId: [ID]) {
        contentMaps(filter: { contentIds: $contentMapId }) {
            contentMaps {
                node {
                    teasers {
                        title(length: 255)
                    }
                    tocMap {
                        path
                        node {
                            ... on Topic {
                                id
                                contentId
                                mimeType
                                url
                                teasers {
                                    title(length: 255)
                                }
                            }
                        }
                    }
                    id
                    contentId
                    creationDate
                    locale
                }
            }
        }
    }
`;

export interface TocNode {
    id: string;
    uniqueId: string;
    url: string;
    children: TocNode[];
    title: string;
}

const findTopicById = (topicId: string, tocNodes: TocNode[]): TocNode | undefined => {
    for (const t of tocNodes) {
        if (t.id === topicId) return t;
        if (t.children.length) {
            const topic = findTopicById(topicId, t.children);
            if (topic) return topic;
        }
    }
};

export const Content: FC<ContentProps> = ({ contentMapId, topicId, notificationsOpen, setNotificationsOpen }) => {
    const isMobile = useInfoCubeMediaQuery();
    const classes = useContentStyle();
    const { t } = useTranslation();
    useContentMapsQuery({
        variables: { contentMapId },
        onCompleted: (data) => {
            if (data.contentMaps) {
                handleContentMapQueryResult(data.contentMaps as ContentMapQueryResult);
            }
            setLoading(false);
        },
    });
    const [anchorEl, setAnchorEl] = useState<any>();
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const [contentURL, setContentURL] = useState<string>("");
    const [contentMap, setContentMap] = useState<ContentMap>();
    const [topicTitle, setTopicTitle] = useState<string>("");
    //Unique identifier of the content in its use context
    const [uniqueTopicId, setUniqueTopicId] = useState<string>("");
    const [tocNodes, setTocNodes] = useState<TocNode[]>([]);
    const [isNotesSheetOpen, setIsNotesSheetOpen] = useState<boolean>(false);
    const [isDetailSheetOpen, setIsDetailSheetOpen] = useState<boolean>(false);
    const [isTocSheetOpen, setIsTocSheetOpen] = useState<boolean>(false);
    const [isTocMenuOpen, setIsTocMenuOpen] = useState<boolean>(false);
    const [isAddToCollectionOpen, setIsAddToCollectionOpen] = useState<boolean>(false);
    const [contendIdForMoreMenu, setContendIdForMoreMenu] = useState<string>("");
    const [titleForMoreMenu, setTitleForMoreMenu] = useState<string>("");

    const handleClickAway = (event?: any) => {
        setIsMoreMenuOpen(false);
        setAnchorEl(null);
    };

    const handleContentMapQueryResult = (data: ContentMapQueryResult) => {
        setCurrentContent(contentMapId, data.contentMaps[0]?.node);
        const { tocNodeTree } = getCurrentContent();
        if (!tocNodeTree) {
            setError(t("Content-Map: {{id}} not found.", { id: contentMapId }));
            setTocNodes([]);
            return;
        }

        //open topic if given by param
        if (topicId) {
            const topic = findTopicById(topicId, tocNodeTree);
            if (!topic) {
                setError(
                    t("Topic: {{topicId}} not found in Content-Map: {{contentMapId}}.", {
                        topicId: topicId,
                        contentMapId: contentMapId,
                    })
                );
                setTocNodes(tocNodeTree);
            } else {
                setTocNodes(tocNodeTree);
                setTopicTitle(topic.title);
                setContentURL(topic.url);
                setCurrentTopicId(topicId);
                setContentMap(data.contentMaps[0]?.node);
                setUniqueTopicId(topic.uniqueId);
            }
        }
        //open first topic if no topic is provided
        else if (tocNodeTree[0] && tocNodeTree[0].id) {
            setTopicTitle(tocNodeTree[0].title);
            setContentURL(tocNodeTree[0].url);
            setTocNodes(tocNodeTree);
            setCurrentTopicId(tocNodeTree[0].id);
            setContentMap(data.contentMaps[0]?.node);
            setUniqueTopicId(tocNodeTree[0].uniqueId);
        }
    };

    useEffect(() => {
        const tocMenu = localStorage.getItem(TOC_MENU_KEY);
        setIsTocMenuOpen(tocMenu !== "closed");
    }, []);

    useEffect(() => {
        let tocNodeByTopicId;
        if (topicId) tocNodeByTopicId = findTopicById(topicId, tocNodes);
        else if (tocNodes[0]) tocNodeByTopicId = findTopicById(tocNodes[0].id, tocNodes);

        if (tocNodeByTopicId) {
            setTopicTitle(tocNodeByTopicId.title);
            setContentURL(tocNodeByTopicId.url);
            setCurrentTopicId(topicId ?? "");
            setUniqueTopicId(tocNodeByTopicId.uniqueId);
        } else {
            setTopicTitle("");
            setCurrentTopicId(topicId ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topicId]);

    useEffect(() => {
        if (notificationsOpen) {
            setIsDetailSheetOpen(false);
            setIsNotesSheetOpen(false);
        }
    }, [notificationsOpen]);

    if (loading) return <Progress />;

    if (error) return <ErrorPage errorMessage={error} />;

    const renderBreadcrumbs = () => {
        if (!tocNodes && !uniqueTopicId) return null;

        return <InfoCubeBreadcrumbs contentMapId={contentMapId} tocNodes={tocNodes} uniqueId={uniqueTopicId} />;
    };

    if (!contentMap) {
        return <NotFoundPage errorMessage={t("The requested page could not be found.")} />;
    }

    //@ts-ignore
    const isPDF = contentMap?.tocMap?.length === 1 && contentMap.tocMap[0]?.node?.mimeType?.endsWith("/pdf");
    const showToc = !isPDF && tocNodes?.length > 0 && isTocMenuOpen;

    const renderTopBarActionsMobile = () => {
        return (
            <Paper
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1200,
                }}
                elevation={3}
            >
                <BottomNavigation>
                    {!isPDF && tocNodes.length > 0 && (
                        <BottomNavigationAction
                            onClick={() => setIsTocSheetOpen(true)}
                            icon={<Toc fontSize={"large"} color="primary" />}
                        />
                    )}
                    <RenderIfHasAccess action={Action.write} resource={Resource.collections} ignoreConditions={true}>
                        <BottomNavigationAction
                            onClick={async (event: any) => {
                                setIsMoreMenuOpen(true);
                                setAnchorEl(event.currentTarget);
                            }}
                            icon={<CreateNewFolderOutlined fontSize={"large"} color="primary" />}
                        />
                    </RenderIfHasAccess>
                    <RenderIfHasAccess action={Action.query} resource={Resource.notes_comments}>
                        <BottomNavigationAction
                            onClick={() => {
                                setIsDetailSheetOpen(false);
                                if (setNotificationsOpen) setNotificationsOpen(false);
                                setIsNotesSheetOpen(!isNotesSheetOpen);
                            }}
                            icon={<CommentOutlined fontSize={"large"} color="primary" />}
                        />
                    </RenderIfHasAccess>
                    <BottomNavigationAction
                        onClick={() => {
                            setIsNotesSheetOpen(false);
                            if (setNotificationsOpen) setNotificationsOpen(false);
                            setIsDetailSheetOpen(!isDetailSheetOpen);
                        }}
                        icon={<InfoOutlined fontSize={"large"} color="primary" />}
                    />
                </BottomNavigation>
            </Paper>
        );
    };

    const renderTopBarActionsDesktop = () => {
        return (
            <>
                {!isPDF && tocNodes.length > 0 && (
                    <Tooltip arrow title={<>{t("Table Of Contents")}</>}>
                        <IconButton
                            onClick={() => {
                                localStorage.setItem(TOC_MENU_KEY, !isTocMenuOpen ? "open" : "closed");
                                setIsTocMenuOpen(!isTocMenuOpen);
                            }}
                            size="large"
                        >
                            <Toc />
                        </IconButton>
                    </Tooltip>
                )}
                <RenderIfHasAccess action={Action.write} resource={Resource.collections} ignoreConditions={true}>
                    <Tooltip arrow title={<>{t("Collection")}</>}>
                        <IconButton
                            onClick={async (event: any) => {
                                setIsMoreMenuOpen(true);
                                setAnchorEl(event.currentTarget);
                            }}
                            size="large"
                        >
                            <CreateNewFolderOutlined />
                        </IconButton>
                    </Tooltip>
                </RenderIfHasAccess>
                <RenderIfHasAccess action={Action.query} resource={Resource.notes_comments}>
                    <Tooltip arrow title={<>{t("Comments")}</>}>
                        <IconButton
                            onClick={() => {
                                setIsDetailSheetOpen(false);
                                if (setNotificationsOpen) setNotificationsOpen(false);
                                setIsNotesSheetOpen(!isNotesSheetOpen);
                            }}
                            size="large"
                        >
                            <CommentOutlined />
                        </IconButton>
                    </Tooltip>
                </RenderIfHasAccess>
                <Tooltip arrow title={<>{t("Information")}</>}>
                    <IconButton
                        onClick={() => {
                            setIsNotesSheetOpen(false);
                            if (setNotificationsOpen) setNotificationsOpen(false);
                            setIsDetailSheetOpen(!isDetailSheetOpen);
                        }}
                        size="large"
                    >
                        <InfoOutlined />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const renderContainerDesktop = () => {
        return (
            <ReflexContainer orientation="vertical">
                {showToc && (
                    <ReflexElement flex={0.15} minSize={250}>
                        <Box mb={2}>
                            <Typography variant="h6">{t("Table Of Contents")}</Typography>
                        </Box>
                        <ContentToc uniqueId={uniqueTopicId} />
                    </ReflexElement>
                )}
                {showToc && <ReflexSplitter />}
                <ReflexElement minSize={250} className={classes.rightPanel}>
                    <Box ml={1} height="100%">
                        {renderBreadcrumbs()}
                        <Box display="flex" flexDirection="row">
                            <Box sx={{ width: "50%", fontWeight: 600, fontSize: "h5.fontSize" }}>{topicTitle}</Box>
                            <Box width="60%" display="flex" justifyContent="flex-end">
                                {renderTopBarActionsDesktop()}
                            </Box>
                        </Box>
                        <Box className={classes.iframeContainer}>
                            <ContentIframe contentURL={contentURL} contentMap={contentMap} />
                        </Box>
                    </Box>
                </ReflexElement>
            </ReflexContainer>
        );
    };

    const renderContainerMobile = () => {
        return (
            <>
                <ContentTocDrawer
                    open={isTocSheetOpen}
                    onClose={() => {
                        setIsTocSheetOpen(false);
                    }}
                    contentUniqueId={uniqueTopicId}
                />
                <Box className={classes.contentContainerMobile}>
                    <Box m={1}>
                        <Box display="flex" flexDirection="row">
                            <Box sx={{ fontWeight: 600, fontSize: "h5.fontSize" }}>{topicTitle}</Box>
                        </Box>
                    </Box>
                    {renderTopBarActionsMobile()}
                    <Box className={classes.iframeContainerMobile}>
                        <ContentIframe contentURL={contentURL} contentMap={contentMap} />
                    </Box>
                </Box>
            </>
        );
    };

    const moreMenuItems = [
        {
            label: t("Add topic to collection"),
            onClick: () => {
                setIsAddToCollectionOpen(true);
                setContendIdForMoreMenu(uniqueTopicId ?? "");
                setTitleForMoreMenu(topicTitle);
                handleClickAway();
            },
        },
        {
            label: t("Add document to collection"),
            onClick: () => {
                setIsAddToCollectionOpen(true);
                setContendIdForMoreMenu(contentMap?.id ?? "");
                setTitleForMoreMenu(contentMap?.teasers?.title ?? "");
                handleClickAway();
            },
        },
    ];

    return (
        <Box className={classes.container}>
            <Box display={"flex"} flexGrow={1} flexDirection={"column"}>
                <Box display={"flex"}></Box>
                {isMobile ? renderContainerMobile() : renderContainerDesktop()}
            </Box>
            <RenderIfHasAccess action={Action.query} resource={Resource.notes_comments}>
                <NotesSideSheet
                    uniqueId={uniqueTopicId}
                    onClose={() => setIsNotesSheetOpen(false)}
                    open={isNotesSheetOpen}
                />
            </RenderIfHasAccess>
            {uniqueTopicId && (
                <ContentDetailSideSheet
                    topicId={uniqueTopicId || ""}
                    onClose={() => setIsDetailSheetOpen(false)}
                    open={isDetailSheetOpen}
                />
            )}
            <MoreMenu
                handleClickAway={handleClickAway}
                isMoreMenuOpen={Boolean(anchorEl && isMoreMenuOpen)}
                anchorElement={anchorEl}
                moreMenuItems={moreMenuItems}
            />
            <AddToCollectionDialog
                onClose={async () => setIsAddToCollectionOpen(false)}
                open={isAddToCollectionOpen}
                contentId={contendIdForMoreMenu}
                contentTitle={titleForMoreMenu}
            />
        </Box>
    );
};

register("Content", Content);
