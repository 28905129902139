import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useSideSheetPortalStyle = makeStyles((theme: Theme) => ({
    drawerOpen: {
        width: theme.spacing(29),
    },
    drawerCollapsed: {
        width: theme.spacing(8),
    },
    progress: {
        height: 700,
    },
}));
