import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useFacetPopoverStyles = makeStyles((theme: Theme) =>
    createStyles({
        popover: {
            "& .MuiPopover-paper": {
                maxHeight: 350,
            },
        },
        clearIcon: {
            fontSize: 12,
            cursor: "pointer",
        },
        menuItem: {
            maxWidth: 300,
            minWidth: 100,
        },
        allCheckbox: {
            paddingLeft: theme.spacing(2),
        },
        formControlLabel: {
            whiteSpace: "normal",
        },
        progress: {
            margin: theme.spacing(2),
            overflow: "hidden",
            "& .MuiCircularProgress-root": {
                height: "40px !important",
                width: "40px !important",
            },
        },
        modalContent: {
            height: "60vh",
        },
    })
);
