import { ArrowDropDown, Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import equal from "fast-deep-equal";
import React, { FC, useEffect, useState } from "react";
import { Dictionary } from "ts-essentials";
import { consumerClient } from "../../apollo-clients/consumer-client";
import { FacetsNavigation } from "../../components/facets-navigation";
import { GraphLoader } from "../../components/graph-loader";
import { Facet } from "../../generated/consumer-graph-types";
import {
    generateDynamicSearchContentsOnlyAggregationsQuery,
    getContentsAggregationsQueryVariables,
} from "../../shared-queries/do-not-parse";

type ExploreFacetNavigationProps = {
    fieldDefinitions: any;
    selectedNavigationFacets: Dictionary<string[]>;
    facetsCollection: Facet[];
    contentsData: any;
    contentsQueryPropsGetter: () => any;
    onSelect: (selectedFacets: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
};

export const ExploreFacetNavigationMobile: FC<ExploreFacetNavigationProps> = ({
    fieldDefinitions,
    selectedNavigationFacets,
    facetsCollection,
    contentsData,
    contentsQueryPropsGetter,
    onSelect,
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isDlgOpen, setIsDlgOpen] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [aggregations, setAggregations] = useState<any>();

    useEffect(() => {
        if (fieldDefinitions?.length) {
            const enrichedMetaDataDefinitions = fieldDefinitions.map((m: any) => {
                return { type: "metadata", ...m };
            });

            const queryAggregationsProps = Object.assign({}, contentsQueryPropsGetter(), {
                ignoreNavigationFacets: true,
            });
            const aggregationsQuery = generateDynamicSearchContentsOnlyAggregationsQuery(enrichedMetaDataDefinitions);

            consumerClient()
                .query({
                    query: aggregationsQuery,
                    variables: getContentsAggregationsQueryVariables(queryAggregationsProps),
                    fetchPolicy: "no-cache",
                })
                .then((result) => {
                    if (result.error) {
                        setError(error);
                        return;
                    }
                    const newAggregations: Dictionary<number> = {};
                    if (result.data.contents?.aggregations?.useContext?.composite) {
                        for (const key in result.data.contents?.aggregations?.useContext.composite) {
                            if (key === "__typename") continue;
                         
                            const value: any = result.data.contents?.aggregations?.useContext.composite[key];

                            if (Array.isArray(value)) {
                                for (const entry of value) {
                                    newAggregations[entry.value] = entry.count;
                                }
                            }
                        }
                    }
                    setAggregations(newAggregations);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldDefinitions, contentsData]);

    const render = (data: any) => {
        const facetIsSelected = !equal(selectedNavigationFacets, {});

        return (
            <>
                <Box margin={0.5} ml={0}>
                    <Button
                        sx={{ textTransform: "none", justifyContent: "space-between" }}
                        fullWidth
                        color={facetIsSelected ? "primary" : "inherit"}
                        variant={facetIsSelected ? "contained" : "outlined"}
                        endIcon={<ArrowDropDown />}
                        onClick={() => setIsDlgOpen(true)}
                    >
                        {fieldDefinitions[0].teasers.title}
                    </Button>
                </Box>
                <Box mt={2} mb={2}>
                    <Divider variant="fullWidth" />
                </Box>
                <Dialog open={isDlgOpen} fullScreen fullWidth>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} pr={2}>
                        <DialogTitle> {fieldDefinitions[0].teasers.title}</DialogTitle>
                        <IconButton aria-label="close" onClick={() => setIsDlgOpen(false)} size={"small"}>
                            <Close data-icon={"close"} />
                        </IconButton>
                    </Box>
                    <DialogContent dividers>
                        <FacetsNavigation
                            aggregations={data}
                            fieldDefinitions={fieldDefinitions}
                            onSelect={onSelect}
                            selectedFacets={selectedNavigationFacets}
                            facetsCollection={facetsCollection}
                            hideRootLabel={true}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    };

    return <GraphLoader loading={loading} error={error} data={aggregations} render={render} />;
};
