import TreeItem, { useTreeItem } from "@mui/lab/TreeItem";
import { Typography } from "@mui/material";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { FC, forwardRef } from "react";

const CustomContent = forwardRef(function CustomContent(props: any, ref: any) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        onLabelClick,
        onIconClick,
    } = props;

    const { disabled, expanded, selected, focused, handleExpansion } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleExpansionClick = (event: any) => {
        if (onIconClick) {
            if (!disabled) onIconClick(event);
            event.preventDefault();
        } else {
            handleExpansion(event);
        }
    };

    const handleSelectionClick = (event: any) => {
        if (onLabelClick) {
            if (!disabled) onLabelClick(event);
            event.preventDefault();
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            ref={ref}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
                {label}
            </Typography>
        </div>
    );
});

CustomContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * className applied to the root element.
     */
    className: PropTypes.string,
    /**
     * The icon to display next to the tree node's label. Either a parent or end icon.
     */
    displayIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label. Either an expansion or collapse icon.
     */
    expansionIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label.
     */
    icon: PropTypes.node,
    /**
     * The tree node label.
     */
    label: PropTypes.node,
    /**
     * The id of the node.
     */
    nodeId: PropTypes.string.isRequired,
    /**
     * On label click handler
     */
    onLabelClick: PropTypes.func,
    /**
     * On icon click handler
     */
    onIconClick: PropTypes.func,
};

export const InfoCubeTreeItem: FC<any> = (props: any) => {
    const contentProps = { onLabelClick: props.onLabelClick, onIconClick: props.onIconClick };
    let copyProps = { ...props };
    delete copyProps["onLabelClick"];
    delete copyProps["onIconClick"];
    return <TreeItem ContentComponent={CustomContent} ContentProps={contentProps} {...copyProps} />;
};
