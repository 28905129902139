import { palette } from "../palette";

export const MuiFormControl = {
    styleOverrides: {
        root: {
            marginTop: "12px",
            marginBottom: "12px",
            width: "100%",
            "& .MuiOutlinedInput-root": {
                backgroundColor: palette.background.paper,
            },
        },
    },
};
