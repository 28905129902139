import { gql } from "@apollo/client";
import { Add, MoreVert } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RowMouseEventHandlerParams } from "react-virtualized";
import { FloatingAction } from "../../components/floating-action";
import { GraphLoader } from "../../components/graph-loader";
import { useInfoCubeMediaQuery } from "../../components/info-cube-media-query";
import { ColumnData, InfoCubeTable } from "../../components/info-cube-table";
import { MoreMenu } from "../../components/more-menu";
import { RenderIfHasAccess } from "../../components/render-if-has-access";
import { Title } from "../../components/title";
import {
    CollectionSortFields,
    CollectionsQuery,
    CountRelation,
    SortOrder,
    useCollectionsQuery,
    useDeleteCollectionMutation,
} from "../../generated/consumer-graph-types";
import { useCollectionStyle } from "./collection-style";
import { CollectionVisibilityType } from "./collection-visibility-type";
import { CreateCollectionDialog } from "./create-collection-dialog";
import { DeleteCollectionDialog } from "./delete-collection-dialog";
import { EditCollectionDialog } from "./edit-collection-dialog";

gql`
    mutation deleteCollection($id: ID!) {
        deleteCollection(id: $id)
    }
`;

gql`
    query collections($first: NonNegativeInt, $after: String, $sortId: CollectionSortFields!, $sortOrder: SortOrder) {
        collections {
            total {
                count
                countRelation
            }
            collections(after: $after, first: $first, sort: { field: $sortId, order: $sortOrder }) {
                cursor
                node {
                    access
                    user {
                        id
                    }
                    id
                    description
                    displayName
                    visibility
                }
            }
        }
    }
`;

export const Collections: FC<any> = () => {
    const isMobile = useInfoCubeMediaQuery();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useCollectionStyle();
    const rowsPerPage = 20;
    const [sortByField, setSortByField] = useState<CollectionSortFields>(CollectionSortFields.displayName);
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.asc);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<any>();
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);
    const [selectedCollection, setSelectedCollection] = useState<any>();
    const [isEditCollectionDialogOpen, setIsEditCollectionDialogOpen] = useState<boolean>(false);
    const [isCreateCollectionDialogOpen, setIsCreateCollectionDialogOpen] = useState<boolean>(false);
    const [isDeleteCollectionDialogOpen, setIsDeleteCollectionDialogOpen] = useState<boolean>(false);

    const handleClickAway = (event?: any) => {
        if (!isMoreMenuOpen || event?.target?.nodeName === "path" || event?.target?.nodeName === "svg") return;
        setIsMoreMenuOpen(false);
        setAnchorEl(null);
    };

    const { data, loading, error, refetch, fetchMore } = useCollectionsQuery({
        variables: {
            first: rowsPerPage,
            sortId: sortByField,
            sortOrder: sortOrder,
        },
        fetchPolicy: "network-only",
    });
    const [deleteCollectionMutation] = useDeleteCollectionMutation();

    const deleteCollection = async (id: string) => {
        const { data, errors } = await deleteCollectionMutation({
            variables: {
                id,
            },
            refetchQueries: ["collections"],
            awaitRefetchQueries: true,
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(
                t("Could not delete collection {{id}}.", {
                    id,
                }),
                {
                    key: id,
                    variant: "error",
                    autoHideDuration: null,
                }
            );
        }

        if (data) {
            enqueueSnackbar(t("Collection deleted."), {
                variant: "success",
            });
        }
    };

    const columnsDesktop: ColumnData[] = [
        {
            dataKey: "visibility",
            label: t("Visibility"),
            render: (data: any) => {
                return <CollectionVisibilityType visibilityType={data.node.visibility} />;
            },
            widthRatio: 0.1,
        },
        {
            dataKey: "displayName",
            dataKeyPath: "node.displayName",
            label: t("Title"),
            widthRatio: 0.4,
        },
        {
            dataKey: "description",
            dataKeyPath: "node.description",
            label: t("Description"),
            widthRatio: 0.4,
            disableSort: true,
        },
        {
            dataKey: "more-menu",
            label: "",
            align: "right",
            render: (data: any) => {
                return (
                    <RenderIfHasAccess action={"write"} resource={"collections"} context={data.node}>
                        <Box display={"flex"} justifyContent={"flex-end"} flexGrow={1}>
                            <IconButton
                                data-testid={`more-menu-${data.node.id}`}
                                aria-label="more-menu"
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    setAnchorEl(event.target);
                                    setIsMoreMenuOpen(true);
                                    setSelectedCollection(data.node);
                                }}
                                size="large"
                            >
                                <MoreVert />
                            </IconButton>
                        </Box>
                    </RenderIfHasAccess>
                );
            },
            widthRatio: 0.1,
            disableSort: true,
        },
    ];

    const columnsMobile: ColumnData[] = [
        {
            dataKey: "visibility",
            label: t("Visibility"),
            render: (data: any) => {
                return <CollectionVisibilityType visibilityType={data.node.visibility} />;
            },
            widthRatio: 0.2,
        },
        {
            dataKey: "displayName",
            dataKeyPath: "node.displayName",
            label: t("Title"),
            widthRatio: 0.6,
        },
        {
            dataKey: "more-menu",
            label: "",
            align: "right",
            render: (data: any) => {
                return (
                    <RenderIfHasAccess action={"write"} resource={"collections"} context={data.node}>
                        <Box display={"flex"} justifyContent={"flex-end"} flexGrow={1}>
                            <IconButton
                                data-testid={`more-menu-${data.node.id}`}
                                aria-label="more-menu"
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    setAnchorEl(event.target);
                                    setIsMoreMenuOpen(true);
                                    setSelectedCollection(data.node);
                                }}
                                size="large"
                            >
                                <MoreVert />
                            </IconButton>
                        </Box>
                    </RenderIfHasAccess>
                );
            },
            widthRatio: 0.2,
            disableSort: true,
        },
    ];
    const onRowClick = (params: RowMouseEventHandlerParams) => {
        const id = params.rowData.data.node?.id;
        history.push(`/collections/${id}`);
    };

    const sort = async ({ sortBy, sortOrder }: { sortBy: string; sortOrder: SortOrder }) => {
        setSortByField(sortBy as CollectionSortFields);
        setSortOrder(sortOrder);

        await refetch({
            first: rowsPerPage,
            sortId: sortBy as CollectionSortFields,
            sortOrder: sortOrder,
        });
    };

    const loadMore = async ({ startIndex }: { startIndex: number }) => {
        if (
            data?.collections?.total.countRelation === CountRelation.eq &&
            data?.collections?.total.count <= startIndex
        ) {
            return;
        }

        let after = "";
        if (data?.collections?.collections) {
            let editableCollection = data.collections?.collections[data.collections?.collections.length - 1];
            if (editableCollection) after = editableCollection.cursor;
        }

        await fetchMore({
            variables: {
                first: rowsPerPage,
                after: after,
                sortId: sortByField,
                sortOrder: sortOrder,
            },
        });
    };

    const onClickDeleteBtn = async () => {
        await deleteCollection(selectedCollection.id);
        setIsDeleteCollectionDialogOpen(false);
    };

    const onScroll = () => {
        if (isMoreMenuOpen) handleClickAway();
    };

    const renderCreateButton = () => {
        if (isMobile) {
            return (
                <FloatingAction
                    icon={<Add />}
                    title={t("Create Collection")}
                    onClick={() => setIsCreateCollectionDialogOpen(true)}
                />
            );
        }

        return (
            <Box className={classes.createCollectionBox}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => setIsCreateCollectionDialogOpen(true)}
                >
                    {t("Create Collection")}
                </Button>
            </Box>
        );
    };

    const render = (data: CollectionsQuery) => {
        const displayName = selectedCollection?.displayName;

        return (
            <Box display="flex" flexGrow={1} flexDirection="column">
                <Box className={classes.titleContainer}>
                    <Title> {t("Collections")}</Title>
                    {renderCreateButton()}
                </Box>
                <Box sx={{ pb: { lg: 2 } }}>
                    <InfoCubeTable
                        windowScroll={true}
                        rowHeight={60}
                        data={data.collections?.collections}
                        loadMore={loadMore}
                        sort={sort}
                        sortBy={sortByField}
                        sortOrder={sortOrder}
                        onRowClick={onRowClick}
                        columns={isMobile ? columnsMobile : columnsDesktop}
                        onScroll={onScroll}
                    />
                </Box>
                <MoreMenu
                    handleClickAway={handleClickAway}
                    isMoreMenuOpen={isMoreMenuOpen}
                    anchorElement={anchorEl}
                    moreMenuItems={[
                        {
                            label: t("Edit"),
                            onClick: () => {
                                handleClickAway();
                                setIsEditCollectionDialogOpen(true);
                            },
                        },
                        {
                            label: t("Delete"),
                            onClick: () => {
                                handleClickAway();
                                setIsDeleteCollectionDialogOpen(true);
                            },
                        },
                    ]}
                />
                {selectedCollection && (
                    <EditCollectionDialog
                        isOpen={isEditCollectionDialogOpen}
                        onClose={() => setIsEditCollectionDialogOpen(false)}
                        id={selectedCollection.id}
                    />
                )}
                <CreateCollectionDialog
                    isOpen={isCreateCollectionDialogOpen}
                    onClose={() => setIsCreateCollectionDialogOpen(false)}
                />
                <DeleteCollectionDialog
                    title={t("Delete Collection")}
                    content={
                        <Box textAlign="center" component="p">
                            {
                                <Trans>
                                    Do you really want to delete collection <strong>{{ displayName }}</strong>?
                                </Trans>
                            }
                        </Box>
                    }
                    onDelete={onClickDeleteBtn}
                    onCancel={() => setIsDeleteCollectionDialogOpen(false)}
                    open={isDeleteCollectionDialogOpen}
                />
            </Box>
        );
    };

    return <GraphLoader render={render} data={data} loading={loading} error={error} />;
};
