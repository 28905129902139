import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { isAuthenticated } from "../providers/authentication-provider";
import { ApmRoute } from "@elastic/apm-rum-react";
interface AuthenticatedRouteProps {
    path: string;
    component?: React.ComponentType<any>;
    render?: (props: RouteComponentProps<any>) => React.ReactNode;
}

export const AuthenticatedRoute: FC<AuthenticatedRouteProps> = ({ path, component, render }) => {
    if (!isAuthenticated()) return <ApmRoute path={path} component={undefined} />;

    return render ? <ApmRoute path={path} render={render} /> : <ApmRoute path={path} component={component} />;
};
