/* istanbul ignore file */
/* eslint-disable */

module.exports = // Generated by Peggy 1.2.0.
    //
    // https://peggyjs.org/
    (function () {
        "use strict";

        function peg$subclass(child, parent) {
            function C() {
                this.constructor = child;
            }
            C.prototype = parent.prototype;
            child.prototype = new C();
        }

        function peg$SyntaxError(message, expected, found, location) {
            var self = Error.call(this, message);
            if (Object.setPrototypeOf) {
                Object.setPrototypeOf(self, peg$SyntaxError.prototype);
            }
            self.expected = expected;
            self.found = found;
            self.location = location;
            self.name = "SyntaxError";
            return self;
        }

        peg$subclass(peg$SyntaxError, Error);

        function peg$padEnd(str, targetLength, padString) {
            padString = padString || " ";
            if (str.length > targetLength) {
                return str;
            }
            targetLength -= str.length;
            padString += padString.repeat(targetLength);
            return str + padString.slice(0, targetLength);
        }

        peg$SyntaxError.prototype.format = function (sources) {
            var str = "Error: " + this.message;
            if (this.location) {
                var src = null;
                var k;
                for (k = 0; k < sources.length; k++) {
                    if (sources[k].source === this.location.source) {
                        src = sources[k].text.split(/\r\n|\n|\r/g);
                        break;
                    }
                }
                var s = this.location.start;
                var loc = this.location.source + ":" + s.line + ":" + s.column;
                if (src) {
                    var e = this.location.end;
                    var filler = peg$padEnd("", s.line.toString().length, " ");
                    var line = src[s.line - 1];
                    var last = s.line === e.line ? e.column : line.length + 1;
                    str +=
                        "\n --> " +
                        loc +
                        "\n" +
                        filler +
                        " |\n" +
                        s.line +
                        " | " +
                        line +
                        "\n" +
                        filler +
                        " | " +
                        peg$padEnd("", s.column - 1, " ") +
                        peg$padEnd("", last - s.column, "^");
                } else {
                    str += "\n at " + loc;
                }
            }
            return str;
        };

        peg$SyntaxError.buildMessage = function (expected, found) {
            var DESCRIBE_EXPECTATION_FNS = {
                literal: function (expectation) {
                    return '"' + literalEscape(expectation.text) + '"';
                },

                class: function (expectation) {
                    var escapedParts = expectation.parts.map(function (part) {
                        return Array.isArray(part)
                            ? classEscape(part[0]) + "-" + classEscape(part[1])
                            : classEscape(part);
                    });

                    return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
                },

                any: function () {
                    return "any character";
                },

                end: function () {
                    return "end of input";
                },

                other: function (expectation) {
                    return expectation.description;
                },
            };

            function hex(ch) {
                return ch.charCodeAt(0).toString(16).toUpperCase();
            }

            function literalEscape(s) {
                return s
                    .replace(/\\/g, "\\\\")
                    .replace(/"/g, '\\"')
                    .replace(/\0/g, "\\0")
                    .replace(/\t/g, "\\t")
                    .replace(/\n/g, "\\n")
                    .replace(/\r/g, "\\r")
                    .replace(/[\x00-\x0F]/g, function (ch) {
                        return "\\x0" + hex(ch);
                    })
                    .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
                        return "\\x" + hex(ch);
                    });
            }

            function classEscape(s) {
                return s
                    .replace(/\\/g, "\\\\")
                    .replace(/\]/g, "\\]")
                    .replace(/\^/g, "\\^")
                    .replace(/-/g, "\\-")
                    .replace(/\0/g, "\\0")
                    .replace(/\t/g, "\\t")
                    .replace(/\n/g, "\\n")
                    .replace(/\r/g, "\\r")
                    .replace(/[\x00-\x0F]/g, function (ch) {
                        return "\\x0" + hex(ch);
                    })
                    .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
                        return "\\x" + hex(ch);
                    });
            }

            function describeExpectation(expectation) {
                return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
            }

            function describeExpected(expected) {
                var descriptions = expected.map(describeExpectation);
                var i, j;

                descriptions.sort();

                if (descriptions.length > 0) {
                    for (i = 1, j = 1; i < descriptions.length; i++) {
                        if (descriptions[i - 1] !== descriptions[i]) {
                            descriptions[j] = descriptions[i];
                            j++;
                        }
                    }
                    descriptions.length = j;
                }

                switch (descriptions.length) {
                    case 1:
                        return descriptions[0];

                    case 2:
                        return descriptions[0] + " or " + descriptions[1];

                    default:
                        return descriptions.slice(0, -1).join(", ") + ", or " + descriptions[descriptions.length - 1];
                }
            }

            function describeFound(found) {
                return found ? '"' + literalEscape(found) + '"' : "end of input";
            }

            return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
        };

        function peg$parse(input, options) {
            options = options !== undefined ? options : {};

            var peg$FAILED = {};
            var peg$source = options.grammarSource;

            var peg$startRuleFunctions = { start: peg$parsestart };
            var peg$startRuleFunction = peg$parsestart;

            var peg$c0 = "(";
            var peg$c1 = ")";
            var peg$c2 = "\\";
            var peg$c3 = ".";
            var peg$c4 = '"';
            var peg$c5 = "/";
            var peg$c6 = "+";
            var peg$c7 = "-";
            var peg$c8 = "!";
            var peg$c9 = "{";
            var peg$c10 = "}";
            var peg$c11 = "[";
            var peg$c12 = "]";
            var peg$c13 = "^";
            var peg$c14 = "?";
            var peg$c15 = ":";
            var peg$c16 = "&";
            var peg$c17 = "|";
            var peg$c18 = "'";
            var peg$c19 = "~";
            var peg$c20 = "*";
            var peg$c21 = " ";
            var peg$c22 = "0.";
            var peg$c23 = "TO";
            var peg$c24 = "OR NOT";
            var peg$c25 = "AND NOT";
            var peg$c26 = "OR";
            var peg$c27 = "AND";
            var peg$c28 = "NOT";
            var peg$c29 = "||";
            var peg$c30 = "&&";

            var peg$r0 = /^[:]/;
            var peg$r1 = /^[^ \t\r\n\f{}()"\/\^~[\]]/;
            var peg$r2 = /^[^: \t\r\n\f{}()"\/\^~[\]]/;
            var peg$r3 = /^[0-9]/;
            var peg$r4 = /^[ \t\r\n\f]/;

            var peg$e0 = peg$literalExpectation("(", false);
            var peg$e1 = peg$literalExpectation(")", false);
            var peg$e2 = peg$classExpectation([":"], false, false);
            var peg$e3 = peg$literalExpectation("\\", false);
            var peg$e4 = peg$literalExpectation(".", false);
            var peg$e5 = peg$classExpectation(
                [" ", "\t", "\r", "\n", "\f", "{", "}", "(", ")", '"', "/", "^", "~", "[", "]"],
                true,
                false
            );
            var peg$e6 = peg$classExpectation(
                [":", " ", "\t", "\r", "\n", "\f", "{", "}", "(", ")", '"', "/", "^", "~", "[", "]"],
                true,
                false
            );
            var peg$e7 = peg$literalExpectation('"', false);
            var peg$e8 = peg$literalExpectation("/", false);
            var peg$e9 = peg$anyExpectation();
            var peg$e10 = peg$literalExpectation("+", false);
            var peg$e11 = peg$literalExpectation("-", false);
            var peg$e12 = peg$literalExpectation("!", false);
            var peg$e13 = peg$literalExpectation("{", false);
            var peg$e14 = peg$literalExpectation("}", false);
            var peg$e15 = peg$literalExpectation("[", false);
            var peg$e16 = peg$literalExpectation("]", false);
            var peg$e17 = peg$literalExpectation("^", false);
            var peg$e18 = peg$literalExpectation("?", false);
            var peg$e19 = peg$literalExpectation(":", false);
            var peg$e20 = peg$literalExpectation("&", false);
            var peg$e21 = peg$literalExpectation("|", false);
            var peg$e22 = peg$literalExpectation("'", false);
            var peg$e23 = peg$literalExpectation("~", false);
            var peg$e24 = peg$literalExpectation("*", false);
            var peg$e25 = peg$literalExpectation(" ", false);
            var peg$e26 = peg$literalExpectation("0.", false);
            var peg$e27 = peg$classExpectation([["0", "9"]], false, false);
            var peg$e28 = peg$literalExpectation("TO", false);
            var peg$e29 = peg$literalExpectation("OR NOT", false);
            var peg$e30 = peg$literalExpectation("AND NOT", false);
            var peg$e31 = peg$literalExpectation("OR", false);
            var peg$e32 = peg$literalExpectation("AND", false);
            var peg$e33 = peg$literalExpectation("NOT", false);
            var peg$e34 = peg$literalExpectation("||", false);
            var peg$e35 = peg$literalExpectation("&&", false);
            var peg$e36 = peg$otherExpectation("whitespace");
            var peg$e37 = peg$classExpectation([" ", "\t", "\r", "\n", "\f"], false, false);

            var peg$f0 = function (node) {
                return node[0];
            };
            var peg$f1 = function () {
                return {};
            };
            var peg$f2 = function (operator) {
                return {
                    operator: operator,
                };
            };
            var peg$f3 = function (start, left, operator, right) {
                var node = {
                    start: start,
                    left: left,
                };

                var right = right.length == 0 ? null : right[0]["right"] == null ? right[0]["left"] : right[0];

                if (right != null) {
                    node["operator"] = operator == "" ? "<implicit>" : operator[0];
                    node["right"] = right;
                }

                return node;
            };
            var peg$f4 = function (operator, right) {
                return right;
            };
            var peg$f5 = function (left, operator, right) {
                var node = {
                    left: left,
                };

                var right = right.length == 0 ? null : right[0]["right"] == null ? right[0]["left"] : right[0];

                if (right != null) {
                    node["operator"] = operator == "" ? "<implicit>" : operator[0];
                    node["right"] = right;
                }

                return node;
            };
            var peg$f6 = function (field_exp) {
                return field_exp;
            };
            var peg$f7 = function (node) {
                node[0]["parenthesized"] = true;
                return node[0];
            };
            var peg$f8 = function (fieldname, range) {
                range["field"] = fieldname == null || fieldname.label == "" ? "<implicit>" : fieldname.label;
                range["fieldLocation"] = fieldname == null || fieldname.label == "" ? null : fieldname.location;

                return range;
            };
            var peg$f9 = function (fieldname, node) {
                node["field"] = fieldname.label;
                node["fieldLocation"] = fieldname.location;
                return node;
            };
            var peg$f10 = function (fieldname, term) {
                var fieldexp = {
                    field: fieldname == null || fieldname.label == "" ? "<implicit>" : fieldname.label,
                    fieldLocation: fieldname == null || fieldname.label == "" ? null : fieldname.location,
                };

                for (var key in term) fieldexp[key] = term[key];

                return fieldexp;
            };
            var peg$f11 = function (fieldname) {
                return {
                    label: fieldname.label,
                    location: fieldname.location,
                };
            };
            var peg$f12 = function (op, term, proximity, boost) {
                var result = {
                    term: term,
                    quoted: true,
                    regex: false,
                    termLocation: location(),
                };

                if ("" != proximity) {
                    result["proximity"] = proximity;
                }
                if ("" != boost) {
                    result["boost"] = boost;
                }
                if ("" != op) {
                    result["prefix"] = op;
                }

                return result;
            };
            var peg$f13 = function (op, term) {
                var result = {
                    term: term,
                    quoted: false,
                    regex: true,
                    termLocation: location(),
                };

                return result;
            };
            var peg$f14 = function (op, term, similarity, boost) {
                var result = {
                    term: term.label,
                    quoted: false,
                    regex: false,
                    termLocation: location(),
                };
                if ("" != similarity) {
                    result["similarity"] = similarity;
                }
                if ("" != boost) {
                    result["boost"] = boost;
                }
                if ("" != op) {
                    result["prefix"] = op;
                }
                return result;
            };
            var peg$f15 = function (sequence) {
                return "\\" + sequence;
            };
            var peg$f16 = function (term) {
                return term.join("");
            };
            var peg$f17 = function (term) {
                return {
                    label: term.join(""),
                    location: location(),
                };
            };
            var peg$f18 = function (chars) {
                return chars.join("");
            };
            var peg$f19 = function (chars) {
                return chars.join("");
            };
            var peg$f20 = function (char) {
                return char;
            };
            var peg$f21 = function (proximity) {
                return proximity;
            };
            var peg$f22 = function (boost) {
                return boost;
            };
            var peg$f23 = function (fuzziness) {
                return fuzziness == "" || fuzziness == null ? 0.5 : fuzziness;
            };
            var peg$f24 = function (val) {
                return parseFloat("0." + val.join(""));
            };
            var peg$f25 = function (val) {
                return parseInt(val.join(""));
            };
            var peg$f26 = function (term_min, term_max) {
                return {
                    term_min: term_min,
                    term_max: term_max,
                    inclusive: "both",
                };
            };
            var peg$f27 = function (term_min, term_max) {
                return {
                    term_min: term_min,
                    term_max: term_max,
                    inclusive: "none",
                };
            };
            var peg$f28 = function (term_min, term_max) {
                return {
                    term_min: term_min,
                    term_max: term_max,
                    inclusive: "left",
                };
            };
            var peg$f29 = function (term_min, term_max) {
                return {
                    term_min: term_min,
                    term_max: term_max,
                    inclusive: "right",
                };
            };
            var peg$f30 = function (operator) {
                return operator;
            };

            var peg$currPos = 0;
            var peg$savedPos = 0;
            var peg$posDetailsCache = [{ line: 1, column: 1 }];
            var peg$maxFailPos = 0;
            var peg$maxFailExpected = [];
            var peg$silentFails = 0;

            var peg$result;

            if ("startRule" in options) {
                if (!(options.startRule in peg$startRuleFunctions)) {
                    throw new Error("Can't start parsing from rule \"" + options.startRule + '".');
                }

                peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
            }

            function text() {
                return input.substring(peg$savedPos, peg$currPos);
            }

            function offset() {
                return peg$savedPos;
            }

            function range() {
                return {
                    source: peg$source,
                    start: peg$savedPos,
                    end: peg$currPos,
                };
            }

            function location() {
                return peg$computeLocation(peg$savedPos, peg$currPos);
            }

            function expected(description, location) {
                location = location !== undefined ? location : peg$computeLocation(peg$savedPos, peg$currPos);

                throw peg$buildStructuredError(
                    [peg$otherExpectation(description)],
                    input.substring(peg$savedPos, peg$currPos),
                    location
                );
            }

            function error(message, location) {
                location = location !== undefined ? location : peg$computeLocation(peg$savedPos, peg$currPos);

                throw peg$buildSimpleError(message, location);
            }

            function peg$literalExpectation(text, ignoreCase) {
                return { type: "literal", text: text, ignoreCase: ignoreCase };
            }

            function peg$classExpectation(parts, inverted, ignoreCase) {
                return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
            }

            function peg$anyExpectation() {
                return { type: "any" };
            }

            function peg$endExpectation() {
                return { type: "end" };
            }

            function peg$otherExpectation(description) {
                return { type: "other", description: description };
            }

            function peg$computePosDetails(pos) {
                var details = peg$posDetailsCache[pos];
                var p;

                if (details) {
                    return details;
                } else {
                    p = pos - 1;
                    while (!peg$posDetailsCache[p]) {
                        p--;
                    }

                    details = peg$posDetailsCache[p];
                    details = {
                        line: details.line,
                        column: details.column,
                    };

                    while (p < pos) {
                        if (input.charCodeAt(p) === 10) {
                            details.line++;
                            details.column = 1;
                        } else {
                            details.column++;
                        }

                        p++;
                    }

                    peg$posDetailsCache[pos] = details;

                    return details;
                }
            }

            function peg$computeLocation(startPos, endPos) {
                var startPosDetails = peg$computePosDetails(startPos);
                var endPosDetails = peg$computePosDetails(endPos);

                return {
                    source: peg$source,
                    start: {
                        offset: startPos,
                        line: startPosDetails.line,
                        column: startPosDetails.column,
                    },
                    end: {
                        offset: endPos,
                        line: endPosDetails.line,
                        column: endPosDetails.column,
                    },
                };
            }

            function peg$fail(expected) {
                if (peg$currPos < peg$maxFailPos) {
                    return;
                }

                if (peg$currPos > peg$maxFailPos) {
                    peg$maxFailPos = peg$currPos;
                    peg$maxFailExpected = [];
                }

                peg$maxFailExpected.push(expected);
            }

            function peg$buildSimpleError(message, location) {
                return new peg$SyntaxError(message, null, null, location);
            }

            function peg$buildStructuredError(expected, found, location) {
                return new peg$SyntaxError(peg$SyntaxError.buildMessage(expected, found), expected, found, location);
            }

            function peg$parsestart() {
                var s0, s1, s2, s3;

                s0 = peg$currPos;
                s1 = [];
                s2 = peg$parse_();
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    s2 = peg$parse_();
                }
                s2 = [];
                s3 = peg$parsenode();
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$parsenode();
                    }
                } else {
                    s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f0(s2);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = [];
                    s2 = peg$parse_();
                    while (s2 !== peg$FAILED) {
                        s1.push(s2);
                        s2 = peg$parse_();
                    }
                    peg$savedPos = s0;
                    s1 = peg$f1();
                    s0 = s1;
                }

                return s0;
            }

            function peg$parsenode() {
                var s0, s1, s2, s3, s4, s5;

                s0 = peg$currPos;
                s1 = peg$parseoperator_exp();
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseEOF();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f2(s1);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parseoperator_exp();
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parsegroup_exp();
                        if (s2 !== peg$FAILED) {
                            s3 = [];
                            s4 = peg$parseoperator_exp();
                            while (s4 !== peg$FAILED) {
                                s3.push(s4);
                                s4 = peg$parseoperator_exp();
                            }
                            s4 = [];
                            s5 = peg$parsenode();
                            while (s5 !== peg$FAILED) {
                                s4.push(s5);
                                s5 = peg$parsenode();
                            }
                            peg$savedPos = s0;
                            s0 = peg$f3(s1, s2, s3, s4);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parseoperator_exp();
                        if (s1 !== peg$FAILED) {
                            s2 = peg$parsenode();
                            if (s2 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s0 = peg$f4(s1, s2);
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            s1 = peg$parsegroup_exp();
                            if (s1 !== peg$FAILED) {
                                s2 = [];
                                s3 = peg$parseoperator_exp();
                                while (s3 !== peg$FAILED) {
                                    s2.push(s3);
                                    s3 = peg$parseoperator_exp();
                                }
                                s3 = [];
                                s4 = peg$parsenode();
                                while (s4 !== peg$FAILED) {
                                    s3.push(s4);
                                    s4 = peg$parsenode();
                                }
                                peg$savedPos = s0;
                                s0 = peg$f5(s1, s2, s3);
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        }
                    }
                }

                return s0;
            }

            function peg$parsegroup_exp() {
                var s0, s1, s2, s3;

                s0 = peg$currPos;
                s1 = peg$parsefield_exp();
                if (s1 !== peg$FAILED) {
                    s2 = [];
                    s3 = peg$parse_();
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$parse_();
                    }
                    peg$savedPos = s0;
                    s0 = peg$f6(s1);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$parseparen_exp();
                }

                return s0;
            }

            function peg$parseparen_exp() {
                var s0, s1, s2, s3, s4, s5, s6;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 40) {
                    s1 = peg$c0;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e0);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = [];
                    s3 = peg$parse_();
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$parse_();
                    }
                    s3 = [];
                    s4 = peg$parsenode();
                    if (s4 !== peg$FAILED) {
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parsenode();
                        }
                    } else {
                        s3 = peg$FAILED;
                    }
                    if (s3 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 41) {
                            s4 = peg$c1;
                            peg$currPos++;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e1);
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = [];
                            s6 = peg$parse_();
                            while (s6 !== peg$FAILED) {
                                s5.push(s6);
                                s6 = peg$parse_();
                            }
                            peg$savedPos = s0;
                            s0 = peg$f7(s3);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parsefield_exp() {
                var s0, s1, s2;

                s0 = peg$currPos;
                s1 = peg$parsefieldname();
                if (s1 === peg$FAILED) {
                    s1 = null;
                }
                s2 = peg$parserange_operator_exp();
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f8(s1, s2);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parsefieldname();
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseparen_exp();
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s0 = peg$f9(s1, s2);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parsefieldname();
                        if (s1 === peg$FAILED) {
                            s1 = null;
                        }
                        s2 = peg$parseterm();
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s0 = peg$f10(s1, s2);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    }
                }

                return s0;
            }

            function peg$parsefieldname() {
                var s0, s1, s2, s3, s4;

                s0 = peg$currPos;
                s1 = peg$parseunquoted_term();
                if (s1 !== peg$FAILED) {
                    if (peg$r0.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e2);
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        s3 = [];
                        s4 = peg$parse_();
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parse_();
                        }
                        peg$savedPos = s0;
                        s0 = peg$f11(s1);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parseterm() {
                var s0, s1, s2, s3, s4, s5, s6;

                s0 = peg$currPos;
                s1 = peg$parseprefix_operator_exp();
                if (s1 === peg$FAILED) {
                    s1 = null;
                }
                s2 = peg$parsequoted_term();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parseproximity_modifier();
                    if (s3 === peg$FAILED) {
                        s3 = null;
                    }
                    s4 = peg$parseboost_modifier();
                    if (s4 === peg$FAILED) {
                        s4 = null;
                    }
                    s5 = [];
                    s6 = peg$parse_();
                    while (s6 !== peg$FAILED) {
                        s5.push(s6);
                        s6 = peg$parse_();
                    }
                    peg$savedPos = s0;
                    s0 = peg$f12(s1, s2, s3, s4);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parseprefix_operator_exp();
                    if (s1 === peg$FAILED) {
                        s1 = null;
                    }
                    s2 = peg$parseregex_term();
                    if (s2 !== peg$FAILED) {
                        s3 = [];
                        s4 = peg$parse_();
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parse_();
                        }
                        peg$savedPos = s0;
                        s0 = peg$f13(s1, s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parseprefix_operator_exp();
                        if (s1 === peg$FAILED) {
                            s1 = null;
                        }
                        s2 = peg$parseunquoted_term();
                        if (s2 !== peg$FAILED) {
                            s3 = peg$parsefuzzy_modifier();
                            if (s3 === peg$FAILED) {
                                s3 = null;
                            }
                            s4 = peg$parseboost_modifier();
                            if (s4 === peg$FAILED) {
                                s4 = null;
                            }
                            s5 = [];
                            s6 = peg$parse_();
                            while (s6 !== peg$FAILED) {
                                s5.push(s6);
                                s6 = peg$parse_();
                            }
                            peg$savedPos = s0;
                            s0 = peg$f14(s1, s2, s3, s4);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    }
                }

                return s0;
            }

            function peg$parserterm_char() {
                var s0, s1, s2;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 92) {
                    s1 = peg$c2;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e3);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseEscapeSequence();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f15(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 46) {
                        s0 = peg$c3;
                        peg$currPos++;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e4);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (peg$r1.test(input.charAt(peg$currPos))) {
                            s0 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e5);
                            }
                        }
                    }
                }

                return s0;
            }

            function peg$parseranged_term() {
                var s0, s1, s2;

                s0 = peg$currPos;
                s1 = [];
                s2 = peg$parserterm_char();
                if (s2 !== peg$FAILED) {
                    while (s2 !== peg$FAILED) {
                        s1.push(s2);
                        s2 = peg$parserterm_char();
                    }
                } else {
                    s1 = peg$FAILED;
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$f16(s1);
                }
                s0 = s1;

                return s0;
            }

            function peg$parseunquoted_term() {
                var s0, s1, s2;

                s0 = peg$currPos;
                s1 = [];
                s2 = peg$parseterm_char();
                if (s2 !== peg$FAILED) {
                    while (s2 !== peg$FAILED) {
                        s1.push(s2);
                        s2 = peg$parseterm_char();
                    }
                } else {
                    s1 = peg$FAILED;
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$f17(s1);
                }
                s0 = s1;

                return s0;
            }

            function peg$parseterm_char() {
                var s0, s1, s2;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 92) {
                    s1 = peg$c2;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e3);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseEscapeSequence();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f15(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 46) {
                        s0 = peg$c3;
                        peg$currPos++;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e4);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (peg$r2.test(input.charAt(peg$currPos))) {
                            s0 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e6);
                            }
                        }
                    }
                }

                return s0;
            }

            function peg$parsequoted_term() {
                var s0, s1, s2, s3;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 34) {
                    s1 = peg$c4;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e7);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = [];
                    s3 = peg$parseDoubleStringCharacter();
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$parseDoubleStringCharacter();
                    }
                    if (input.charCodeAt(peg$currPos) === 34) {
                        s3 = peg$c4;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e7);
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f18(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parseregex_term() {
                var s0, s1, s2, s3;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 47) {
                    s1 = peg$c5;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e8);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = [];
                    s3 = peg$parseRegexCharacter();
                    if (s3 !== peg$FAILED) {
                        while (s3 !== peg$FAILED) {
                            s2.push(s3);
                            s3 = peg$parseRegexCharacter();
                        }
                    } else {
                        s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 47) {
                            s3 = peg$c5;
                            peg$currPos++;
                        } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e8);
                            }
                        }
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s0 = peg$f19(s2);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parseDoubleStringCharacter() {
                var s0, s1, s2;

                s0 = peg$currPos;
                s1 = peg$currPos;
                peg$silentFails++;
                if (input.charCodeAt(peg$currPos) === 34) {
                    s2 = peg$c4;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e7);
                    }
                }
                if (s2 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s2 = peg$c2;
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e3);
                        }
                    }
                }
                peg$silentFails--;
                if (s2 === peg$FAILED) {
                    s1 = undefined;
                } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                }
                if (s1 !== peg$FAILED) {
                    if (input.length > peg$currPos) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e9);
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f20(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s1 = peg$c2;
                        peg$currPos++;
                    } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e3);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseEscapeSequence();
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s0 = peg$f15(s2);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }

                return s0;
            }

            function peg$parseRegexCharacter() {
                var s0, s1, s2;

                s0 = peg$currPos;
                s1 = peg$currPos;
                peg$silentFails++;
                if (input.charCodeAt(peg$currPos) === 47) {
                    s2 = peg$c5;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e8);
                    }
                }
                if (s2 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s2 = peg$c2;
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e3);
                        }
                    }
                }
                peg$silentFails--;
                if (s2 === peg$FAILED) {
                    s1 = undefined;
                } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                }
                if (s1 !== peg$FAILED) {
                    if (input.length > peg$currPos) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e9);
                        }
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f20(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 92) {
                        s1 = peg$c2;
                        peg$currPos++;
                    } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e3);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseEscapeSequence();
                        if (s2 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s0 = peg$f15(s2);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }

                return s0;
            }

            function peg$parseEscapeSequence() {
                var s0;

                if (input.charCodeAt(peg$currPos) === 43) {
                    s0 = peg$c6;
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e10);
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 45) {
                        s0 = peg$c7;
                        peg$currPos++;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e11);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 33) {
                            s0 = peg$c8;
                            peg$currPos++;
                        } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e12);
                            }
                        }
                        if (s0 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 40) {
                                s0 = peg$c0;
                                peg$currPos++;
                            } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$e0);
                                }
                            }
                            if (s0 === peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 41) {
                                    s0 = peg$c1;
                                    peg$currPos++;
                                } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$e1);
                                    }
                                }
                                if (s0 === peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 123) {
                                        s0 = peg$c9;
                                        peg$currPos++;
                                    } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$e13);
                                        }
                                    }
                                    if (s0 === peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 125) {
                                            s0 = peg$c10;
                                            peg$currPos++;
                                        } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) {
                                                peg$fail(peg$e14);
                                            }
                                        }
                                        if (s0 === peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 91) {
                                                s0 = peg$c11;
                                                peg$currPos++;
                                            } else {
                                                s0 = peg$FAILED;
                                                if (peg$silentFails === 0) {
                                                    peg$fail(peg$e15);
                                                }
                                            }
                                            if (s0 === peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 93) {
                                                    s0 = peg$c12;
                                                    peg$currPos++;
                                                } else {
                                                    s0 = peg$FAILED;
                                                    if (peg$silentFails === 0) {
                                                        peg$fail(peg$e16);
                                                    }
                                                }
                                                if (s0 === peg$FAILED) {
                                                    if (input.charCodeAt(peg$currPos) === 94) {
                                                        s0 = peg$c13;
                                                        peg$currPos++;
                                                    } else {
                                                        s0 = peg$FAILED;
                                                        if (peg$silentFails === 0) {
                                                            peg$fail(peg$e17);
                                                        }
                                                    }
                                                    if (s0 === peg$FAILED) {
                                                        if (input.charCodeAt(peg$currPos) === 34) {
                                                            s0 = peg$c4;
                                                            peg$currPos++;
                                                        } else {
                                                            s0 = peg$FAILED;
                                                            if (peg$silentFails === 0) {
                                                                peg$fail(peg$e7);
                                                            }
                                                        }
                                                        if (s0 === peg$FAILED) {
                                                            if (input.charCodeAt(peg$currPos) === 63) {
                                                                s0 = peg$c14;
                                                                peg$currPos++;
                                                            } else {
                                                                s0 = peg$FAILED;
                                                                if (peg$silentFails === 0) {
                                                                    peg$fail(peg$e18);
                                                                }
                                                            }
                                                            if (s0 === peg$FAILED) {
                                                                if (input.charCodeAt(peg$currPos) === 58) {
                                                                    s0 = peg$c15;
                                                                    peg$currPos++;
                                                                } else {
                                                                    s0 = peg$FAILED;
                                                                    if (peg$silentFails === 0) {
                                                                        peg$fail(peg$e19);
                                                                    }
                                                                }
                                                                if (s0 === peg$FAILED) {
                                                                    if (input.charCodeAt(peg$currPos) === 92) {
                                                                        s0 = peg$c2;
                                                                        peg$currPos++;
                                                                    } else {
                                                                        s0 = peg$FAILED;
                                                                        if (peg$silentFails === 0) {
                                                                            peg$fail(peg$e3);
                                                                        }
                                                                    }
                                                                    if (s0 === peg$FAILED) {
                                                                        if (input.charCodeAt(peg$currPos) === 38) {
                                                                            s0 = peg$c16;
                                                                            peg$currPos++;
                                                                        } else {
                                                                            s0 = peg$FAILED;
                                                                            if (peg$silentFails === 0) {
                                                                                peg$fail(peg$e20);
                                                                            }
                                                                        }
                                                                        if (s0 === peg$FAILED) {
                                                                            if (input.charCodeAt(peg$currPos) === 124) {
                                                                                s0 = peg$c17;
                                                                                peg$currPos++;
                                                                            } else {
                                                                                s0 = peg$FAILED;
                                                                                if (peg$silentFails === 0) {
                                                                                    peg$fail(peg$e21);
                                                                                }
                                                                            }
                                                                            if (s0 === peg$FAILED) {
                                                                                if (
                                                                                    input.charCodeAt(peg$currPos) === 39
                                                                                ) {
                                                                                    s0 = peg$c18;
                                                                                    peg$currPos++;
                                                                                } else {
                                                                                    s0 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) {
                                                                                        peg$fail(peg$e22);
                                                                                    }
                                                                                }
                                                                                if (s0 === peg$FAILED) {
                                                                                    if (
                                                                                        input.charCodeAt(
                                                                                            peg$currPos
                                                                                        ) === 47
                                                                                    ) {
                                                                                        s0 = peg$c5;
                                                                                        peg$currPos++;
                                                                                    } else {
                                                                                        s0 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) {
                                                                                            peg$fail(peg$e8);
                                                                                        }
                                                                                    }
                                                                                    if (s0 === peg$FAILED) {
                                                                                        if (
                                                                                            input.charCodeAt(
                                                                                                peg$currPos
                                                                                            ) === 126
                                                                                        ) {
                                                                                            s0 = peg$c19;
                                                                                            peg$currPos++;
                                                                                        } else {
                                                                                            s0 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) {
                                                                                                peg$fail(peg$e23);
                                                                                            }
                                                                                        }
                                                                                        if (s0 === peg$FAILED) {
                                                                                            if (
                                                                                                input.charCodeAt(
                                                                                                    peg$currPos
                                                                                                ) === 42
                                                                                            ) {
                                                                                                s0 = peg$c20;
                                                                                                peg$currPos++;
                                                                                            } else {
                                                                                                s0 = peg$FAILED;
                                                                                                if (
                                                                                                    peg$silentFails ===
                                                                                                    0
                                                                                                ) {
                                                                                                    peg$fail(peg$e24);
                                                                                                }
                                                                                            }
                                                                                            if (s0 === peg$FAILED) {
                                                                                                if (
                                                                                                    input.charCodeAt(
                                                                                                        peg$currPos
                                                                                                    ) === 32
                                                                                                ) {
                                                                                                    s0 = peg$c21;
                                                                                                    peg$currPos++;
                                                                                                } else {
                                                                                                    s0 = peg$FAILED;
                                                                                                    if (
                                                                                                        peg$silentFails ===
                                                                                                        0
                                                                                                    ) {
                                                                                                        peg$fail(
                                                                                                            peg$e25
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                return s0;
            }

            function peg$parseproximity_modifier() {
                var s0, s1, s2;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 126) {
                    s1 = peg$c19;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e23);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseint_exp();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f21(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parseboost_modifier() {
                var s0, s1, s2;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 94) {
                    s1 = peg$c13;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e17);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsedecimal_or_int_exp();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f22(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parsefuzzy_modifier() {
                var s0, s1, s2;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 126) {
                    s1 = peg$c19;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e23);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsedecimal_exp();
                    if (s2 === peg$FAILED) {
                        s2 = null;
                    }
                    peg$savedPos = s0;
                    s0 = peg$f23(s2);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parsedecimal_or_int_exp() {
                var s0;

                s0 = peg$parsedecimal_exp();
                if (s0 === peg$FAILED) {
                    s0 = peg$parseint_exp();
                }

                return s0;
            }

            function peg$parsedecimal_exp() {
                var s0, s1, s2, s3;

                s0 = peg$currPos;
                if (input.substr(peg$currPos, 2) === peg$c22) {
                    s1 = peg$c22;
                    peg$currPos += 2;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e26);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = [];
                    if (peg$r3.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e27);
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        while (s3 !== peg$FAILED) {
                            s2.push(s3);
                            if (peg$r3.test(input.charAt(peg$currPos))) {
                                s3 = input.charAt(peg$currPos);
                                peg$currPos++;
                            } else {
                                s3 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$e27);
                                }
                            }
                        }
                    } else {
                        s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f24(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parseint_exp() {
                var s0, s1, s2;

                s0 = peg$currPos;
                s1 = [];
                if (peg$r3.test(input.charAt(peg$currPos))) {
                    s2 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e27);
                    }
                }
                if (s2 !== peg$FAILED) {
                    while (s2 !== peg$FAILED) {
                        s1.push(s2);
                        if (peg$r3.test(input.charAt(peg$currPos))) {
                            s2 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e27);
                            }
                        }
                    }
                } else {
                    s1 = peg$FAILED;
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$f25(s1);
                }
                s0 = s1;

                return s0;
            }

            function peg$parserange_operator_exp() {
                var s0, s1, s2, s3, s4, s5, s6, s7;

                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 91) {
                    s1 = peg$c11;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e15);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseranged_term();
                    if (s2 !== peg$FAILED) {
                        s3 = [];
                        s4 = peg$parse_();
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parse_();
                        }
                        if (input.substr(peg$currPos, 2) === peg$c23) {
                            s4 = peg$c23;
                            peg$currPos += 2;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e28);
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = [];
                            s6 = peg$parse_();
                            if (s6 !== peg$FAILED) {
                                while (s6 !== peg$FAILED) {
                                    s5.push(s6);
                                    s6 = peg$parse_();
                                }
                            } else {
                                s5 = peg$FAILED;
                            }
                            if (s5 !== peg$FAILED) {
                                s6 = peg$parseranged_term();
                                if (s6 !== peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 93) {
                                        s7 = peg$c12;
                                        peg$currPos++;
                                    } else {
                                        s7 = peg$FAILED;
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$e16);
                                        }
                                    }
                                    if (s7 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s0 = peg$f26(s2, s6);
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 123) {
                        s1 = peg$c9;
                        peg$currPos++;
                    } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e13);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parseranged_term();
                        if (s2 !== peg$FAILED) {
                            s3 = [];
                            s4 = peg$parse_();
                            while (s4 !== peg$FAILED) {
                                s3.push(s4);
                                s4 = peg$parse_();
                            }
                            if (input.substr(peg$currPos, 2) === peg$c23) {
                                s4 = peg$c23;
                                peg$currPos += 2;
                            } else {
                                s4 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$e28);
                                }
                            }
                            if (s4 !== peg$FAILED) {
                                s5 = [];
                                s6 = peg$parse_();
                                if (s6 !== peg$FAILED) {
                                    while (s6 !== peg$FAILED) {
                                        s5.push(s6);
                                        s6 = peg$parse_();
                                    }
                                } else {
                                    s5 = peg$FAILED;
                                }
                                if (s5 !== peg$FAILED) {
                                    s6 = peg$parseranged_term();
                                    if (s6 !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 125) {
                                            s7 = peg$c10;
                                            peg$currPos++;
                                        } else {
                                            s7 = peg$FAILED;
                                            if (peg$silentFails === 0) {
                                                peg$fail(peg$e14);
                                            }
                                        }
                                        if (s7 !== peg$FAILED) {
                                            peg$savedPos = s0;
                                            s0 = peg$f27(s2, s6);
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 91) {
                            s1 = peg$c11;
                            peg$currPos++;
                        } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e15);
                            }
                        }
                        if (s1 !== peg$FAILED) {
                            s2 = peg$parseranged_term();
                            if (s2 !== peg$FAILED) {
                                s3 = [];
                                s4 = peg$parse_();
                                while (s4 !== peg$FAILED) {
                                    s3.push(s4);
                                    s4 = peg$parse_();
                                }
                                if (input.substr(peg$currPos, 2) === peg$c23) {
                                    s4 = peg$c23;
                                    peg$currPos += 2;
                                } else {
                                    s4 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$e28);
                                    }
                                }
                                if (s4 !== peg$FAILED) {
                                    s5 = [];
                                    s6 = peg$parse_();
                                    if (s6 !== peg$FAILED) {
                                        while (s6 !== peg$FAILED) {
                                            s5.push(s6);
                                            s6 = peg$parse_();
                                        }
                                    } else {
                                        s5 = peg$FAILED;
                                    }
                                    if (s5 !== peg$FAILED) {
                                        s6 = peg$parseranged_term();
                                        if (s6 !== peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 125) {
                                                s7 = peg$c10;
                                                peg$currPos++;
                                            } else {
                                                s7 = peg$FAILED;
                                                if (peg$silentFails === 0) {
                                                    peg$fail(peg$e14);
                                                }
                                            }
                                            if (s7 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s0 = peg$f28(s2, s6);
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.charCodeAt(peg$currPos) === 123) {
                                s1 = peg$c9;
                                peg$currPos++;
                            } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$e13);
                                }
                            }
                            if (s1 !== peg$FAILED) {
                                s2 = peg$parseranged_term();
                                if (s2 !== peg$FAILED) {
                                    s3 = [];
                                    s4 = peg$parse_();
                                    while (s4 !== peg$FAILED) {
                                        s3.push(s4);
                                        s4 = peg$parse_();
                                    }
                                    if (input.substr(peg$currPos, 2) === peg$c23) {
                                        s4 = peg$c23;
                                        peg$currPos += 2;
                                    } else {
                                        s4 = peg$FAILED;
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$e28);
                                        }
                                    }
                                    if (s4 !== peg$FAILED) {
                                        s5 = [];
                                        s6 = peg$parse_();
                                        if (s6 !== peg$FAILED) {
                                            while (s6 !== peg$FAILED) {
                                                s5.push(s6);
                                                s6 = peg$parse_();
                                            }
                                        } else {
                                            s5 = peg$FAILED;
                                        }
                                        if (s5 !== peg$FAILED) {
                                            s6 = peg$parseranged_term();
                                            if (s6 !== peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 93) {
                                                    s7 = peg$c12;
                                                    peg$currPos++;
                                                } else {
                                                    s7 = peg$FAILED;
                                                    if (peg$silentFails === 0) {
                                                        peg$fail(peg$e16);
                                                    }
                                                }
                                                if (s7 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s0 = peg$f29(s2, s6);
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                        }
                    }
                }

                return s0;
            }

            function peg$parseoperator_exp() {
                var s0, s1, s2, s3, s4;

                s0 = peg$currPos;
                s1 = [];
                s2 = peg$parse_();
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    s2 = peg$parse_();
                }
                s2 = peg$parseoperator();
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$parse_();
                    if (s4 !== peg$FAILED) {
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            s4 = peg$parse_();
                        }
                    } else {
                        s3 = peg$FAILED;
                    }
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f30(s2);
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = [];
                    s2 = peg$parse_();
                    while (s2 !== peg$FAILED) {
                        s1.push(s2);
                        s2 = peg$parse_();
                    }
                    s2 = peg$parseoperator();
                    if (s2 !== peg$FAILED) {
                        s3 = peg$parseEOF();
                        if (s3 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s0 = peg$f30(s2);
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }

                return s0;
            }

            function peg$parseoperator() {
                var s0;

                if (input.substr(peg$currPos, 6) === peg$c24) {
                    s0 = peg$c24;
                    peg$currPos += 6;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e29);
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c25) {
                        s0 = peg$c25;
                        peg$currPos += 7;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e30);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c26) {
                            s0 = peg$c26;
                            peg$currPos += 2;
                        } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e31);
                            }
                        }
                        if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c27) {
                                s0 = peg$c27;
                                peg$currPos += 3;
                            } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$e32);
                                }
                            }
                            if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 3) === peg$c28) {
                                    s0 = peg$c28;
                                    peg$currPos += 3;
                                } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$e33);
                                    }
                                }
                                if (s0 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 2) === peg$c29) {
                                        s0 = peg$c29;
                                        peg$currPos += 2;
                                    } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$e34);
                                        }
                                    }
                                    if (s0 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 2) === peg$c30) {
                                            s0 = peg$c30;
                                            peg$currPos += 2;
                                        } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) {
                                                peg$fail(peg$e35);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                return s0;
            }

            function peg$parseprefix_operator_exp() {
                var s0, s1, s2;

                s0 = peg$currPos;
                s1 = [];
                s2 = peg$parse_();
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    s2 = peg$parse_();
                }
                s2 = peg$parseprefix_operator();
                if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f30(s2);
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            function peg$parseprefix_operator() {
                var s0;

                if (input.charCodeAt(peg$currPos) === 43) {
                    s0 = peg$c6;
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e10);
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 45) {
                        s0 = peg$c7;
                        peg$currPos++;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e11);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 33) {
                            s0 = peg$c8;
                            peg$currPos++;
                        } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e12);
                            }
                        }
                    }
                }

                return s0;
            }

            function peg$parse_() {
                var s0, s1;

                peg$silentFails++;
                s0 = [];
                if (peg$r4.test(input.charAt(peg$currPos))) {
                    s1 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e37);
                    }
                }
                if (s1 !== peg$FAILED) {
                    while (s1 !== peg$FAILED) {
                        s0.push(s1);
                        if (peg$r4.test(input.charAt(peg$currPos))) {
                            s1 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e37);
                            }
                        }
                    }
                } else {
                    s0 = peg$FAILED;
                }
                peg$silentFails--;
                if (s0 === peg$FAILED) {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e36);
                    }
                }

                return s0;
            }

            function peg$parseEOF() {
                var s0, s1;

                s0 = peg$currPos;
                peg$silentFails++;
                if (input.length > peg$currPos) {
                    s1 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e9);
                    }
                }
                peg$silentFails--;
                if (s1 === peg$FAILED) {
                    s0 = undefined;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }

                return s0;
            }

            peg$result = peg$startRuleFunction();

            if (peg$result !== peg$FAILED && peg$currPos === input.length) {
                return peg$result;
            } else {
                if (peg$result !== peg$FAILED && peg$currPos < input.length) {
                    peg$fail(peg$endExpectation());
                }

                throw peg$buildStructuredError(
                    peg$maxFailExpected,
                    peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
                    peg$maxFailPos < input.length
                        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
                        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
                );
            }
        }

        return {
            SyntaxError: peg$SyntaxError,
            parse: peg$parse,
        };
    })();
