import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useCollectionStyle = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            [theme.breakpoints.up("md")]: {
                height: 600,
            },
        },
        paper: {
            height: "75%",
        },
        title: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "80vw",
        },
        createCollectionBox: {
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: theme.spacing(1),
            "& a:hover": {
                textDecoration: "none",
            },
            "& a": {
                color: theme.palette.common.white,
            },
            [theme.breakpoints.down("md")]: {
                justifyContent: "flex-start",
            },
        },
        titleContainer: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(1),
                paddingLeft: theme.spacing(1),
            },
        },
    })
);
