import React, { Fragment, FC, ReactNode, useEffect, useRef, useState } from "react";
import { Box, IconButton, Paper, TableCell, Tooltip } from "@mui/material";
import {
    AutoSizer,
    Column,
    defaultTableRowRenderer,
    IndexRange,
    InfiniteLoader,
    RowMouseEventHandlerParams,
    ScrollEventData,
    SortDirection,
    SortDirectionType,
    SortIndicator,
    Table,
    TableCellProps,
    TableCellRenderer,
    TableHeaderProps,
    TableRowProps,
    WindowScroller,
} from "react-virtualized";
import { useInfoCubeTableStyles } from "./info-cube-table-style";
import clsx from "clsx";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import get from "lodash.get";
import { SortOrder } from "../generated/consumer-graph-types";

export interface ColumnData {
    dataKey: string;
    label: string;
    widthRatio: number;
    dataKeyPath?: string;
    disableSort?: boolean;
    render?: (data: any, rowIndex: number) => ReactNode;
    onClick?: (rowData: any) => void;
    align?: "left" | "right";
    withToolTip?: boolean;
}

export interface RowData {
    data: any;
    index: number;
}

export interface InfoCubeTableClasses {
    paper?: string;
    table?: string;
}

type InfoCubeTableProps = {
    data: any[] | undefined;
    loadMore: (params: IndexRange) => Promise<any>;
    columns: ColumnData[];
    classes?: InfoCubeTableClasses;
    rowHeight?: number;
    headerHeight?: number;
    sort?: (info: { sortBy: string; sortOrder: SortOrder }) => void;
    sortBy?: string;
    sortOrder?: SortOrder;
    cellRenderer?: (props: TableCellProps) => ReactNode;
    cellDataDetailRenderer?: (rowData: RowData) => ReactNode;
    headerRenderer?: (props: TableHeaderProps) => ReactNode;
    detailsBoxWidth?: number;
    onRowClick?: (params: RowMouseEventHandlerParams) => void;
    selected?: any[];
    rowDataIdentifier?: string;
    onScroll?: (info: ScrollEventData) => void;
    windowScroll?: boolean;
};

export const InfoCubeTable: FC<InfoCubeTableProps> = (props) => {
    const { cellDataDetailRenderer } = props;
    const tableClasses = useInfoCubeTableStyles();
    const { t } = useTranslation();
    const defaultHeight = 56,
        defaultDetailsBoxBottomHeight = 180;
    let isFullWidthRef = useRef(window.outerWidth > 1280);
    const [moreDetailSelection, setMoreDetailSelection] = useState<{ index: number; rowData: any } | undefined>();
    const [isFullWidth, setIsFullWidth] = useState<boolean>(isFullWidthRef.current as boolean);
    let tableRef = useRef<any>();

    const getIdValue = (val: any) => {
        return get(val, props.rowDataIdentifier ?? "node.id");
    };

    useEffect(() => {
        const handleResize = () => {
            const isLarge = window.outerWidth > 1280;

            if (isLarge !== isFullWidthRef.current) {
                isFullWidthRef.current = isLarge;
                setIsFullWidth(isLarge);
            }
        };

        if (props.cellDataDetailRenderer) {
            window.addEventListener("resize", handleResize);
        }

        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!moreDetailSelection) {
            return;
        }
        let dataIndex = props.data?.findIndex((val: any) => {
            return getIdValue(val) === getIdValue(moreDetailSelection.rowData.data);
        });

        if (dataIndex === undefined || dataIndex === -1) {
            setMoreDetailSelection(undefined);
            return;
        }

        if (props.data)
            setMoreDetailSelection({
                index: dataIndex,
                rowData: { data: props.data[dataIndex], index: dataIndex },
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.recomputeRowHeights();
            tableRef.current.forceUpdateGrid();
            tableRef.current.forceUpdate();
        }
    }, [moreDetailSelection, isFullWidth, tableRef]);

    const getRowClassName = ({ index }: { index: number }) => {
        if (index === -1) return tableClasses.tableHeaderRow;

        return clsx(tableClasses.tableRow, {
            [tableClasses.tableRowClickable]: index !== -1 && (props.cellDataDetailRenderer || props.onRowClick),
            [tableClasses.tableRowSelected]:
                (moreDetailSelection && moreDetailSelection.index === index) ||
                isItemSelected(props.data ? getIdValue(props.data[index]) : undefined),
        });
    };

    const headerRenderer = ({
        label,
        dataKey,
        sortDirection,
        disableSort,
    }: TableHeaderProps & { columnIndex: number }) => {
        if (!label) return null;

        return (
            <TableCell
                component="div"
                variant="head"
                className={clsx(tableClasses.tableHeader, !disableSort && tableClasses.tableHeaderSort)}
            >
                <span>{t(label as string)}</span>
                {!disableSort && props.sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
            </TableCell>
        );
    };

    const defaultCellDataGetter = ({
        columnData,
        dataKey,
        rowData,
    }: {
        columnData?: any;
        dataKey: string;
        rowData: any;
    }) => {
        if (columnData?.render) {
            return columnData.render(rowData.data, rowData.index);
        }
        if (dataKey === "index") {
            return rowData.index + 1;
        }
        if (typeof rowData.data === "string") return rowData.data;

        return get(rowData.data, columnData?.dataKeyPath || dataKey);
    };

    const defaultCellRenderer: TableCellRenderer = (tableCellProps) => {
        return tableCellProps.columnData.withToolTip ? (
            <Tooltip followCursor title={tableCellProps.cellData} placement="top-start">
                <TableCell
                    component="div"
                    className={tableClasses.tooltipTableCell}
                    variant="body"
                    style={{ height: defaultHeight }}
                    align={tableCellProps.columnData.align}
                >
                    {props.cellRenderer ? (
                        props.cellRenderer(tableCellProps)
                    ) : (
                        <Box className={tableClasses.textEllipsis} pl={2}>
                            {tableCellProps.cellData}
                        </Box>
                    )}
                </TableCell>
            </Tooltip>
        ) : (
            <TableCell
                component="div"
                className={tableClasses.tableCell}
                variant="body"
                style={{ height: defaultHeight }}
                align={tableCellProps.columnData.align}
            >
                {props.cellRenderer ? props.cellRenderer(tableCellProps) : <Box pl={2}>{tableCellProps.cellData}</Box>}
            </TableCell>
        );
    };

    const defaultOnRowClick = ({ event, index, rowData }: RowMouseEventHandlerParams) => {
        if (!moreDetailSelection || moreDetailSelection.index !== index) {
            setMoreDetailSelection({ index, rowData });
        }
    };

    const onRowClickHandler = (params: RowMouseEventHandlerParams) => {
        if (
            //check if the click has been executed in a cell with checkbox
            //@ts-ignore
            params.event.target.type === "checkbox" ||
            //@ts-ignore
            params.event.target.dataset.type === "checkbox" ||
            //@ts-ignore
            params.event.target.parentNode.dataset.type === "checkbox" ||
            //@ts-ignore
            params.event.target.firstElementChild?.dataset.type === "checkbox"
        ) {
            const dataKey =
                //@ts-ignore
                params.event.target.dataset.datakey ||
                //@ts-ignore
                params.event.target.parentNode.dataset.datakey ||
                //@ts-ignore
                params.event.target.firstElementChild?.dataset.datakey;

            const foundColumn = props.columns.find((val) => val.dataKey === dataKey);
            if (foundColumn?.onClick) foundColumn.onClick(params.rowData.data);
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.cellDataDetailRenderer ? defaultOnRowClick(params) : props.onRowClick?.(params);
    };

    const closeDetailsBox = () => {
        setMoreDetailSelection(undefined);
    };

    const rowRenderer = (props: TableRowProps) => {
        const { index, style, className, key, rowData } = props;
        if (!isFullWidth && moreDetailSelection && moreDetailSelection.index === index) {
            return (
                <div style={{ ...style, display: "flex", flexDirection: "column" }} className={className} key={key}>
                    {defaultTableRowRenderer({
                        ...props,
                        style: { height: defaultHeight },
                    })}
                    <div className={tableClasses.moreDetailsBottom} style={{ height: defaultDetailsBoxBottomHeight }}>
                        {cellDataDetailRenderer && cellDataDetailRenderer(rowData)}
                    </div>
                </div>
            );
        }
        return defaultTableRowRenderer(props);
    };
    const getRowHeight = ({ index }: { index: number }): number => {
        if (!isFullWidth && moreDetailSelection && moreDetailSelection.index === index) {
            return defaultHeight + defaultDetailsBoxBottomHeight;
        }

        return defaultHeight;
    };

    const showDetailsSide = () => {
        return !!(isFullWidth && moreDetailSelection);
    };

    const sort = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
        if (!props.sort) return;

        props.sort({
            sortBy: info.sortBy,
            sortOrder: info.sortDirection === SortDirection.DESC ? SortOrder.desc : SortOrder.asc,
        });
    };

    const isItemSelected = (itemId: string) => {
        if (!itemId || !props.selected) return false;

        return props.selected.some((value, index, obj) => {
            return getIdValue(value) === itemId;
        });
    };

    const windowScrollTable = (
        <>
            <Paper className={clsx(tableClasses.windowScrollPaper, props.classes?.paper)}>
                <Box display="flex" flexGrow={1} height="100%" className={"infoCubeTableContainer"}>
                    <InfiniteLoader
                        isRowLoaded={({ index }) => (props.data ? !!props.data[index] : true)}
                        loadMoreRows={props.loadMore}
                        rowCount={1000000}
                    >
                        {({ onRowsRendered, registerChild }) => (
                            <WindowScroller>
                                {({ height, isScrolling, scrollTop }) => (
                                    <AutoSizer disableHeight>
                                        {({ width }) => (
                                            <Table
                                                autoHeight
                                                ref={registerChild}
                                                onRowsRendered={onRowsRendered}
                                                rowClassName={getRowClassName}
                                                height={height || 100}
                                                width={width || 100}
                                                gridClassName={tableClasses.tableGrid}
                                                rowHeight={getRowHeight}
                                                headerHeight={defaultHeight}
                                                rowCount={props.data ? props.data.length : 0}
                                                rowGetter={({ index }) => {
                                                    return {
                                                        data: props.data ? props.data[index] : undefined,
                                                        index,
                                                    };
                                                }}
                                                sort={sort}
                                                sortBy={props.sortBy}
                                                sortDirection={
                                                    props.sortOrder === SortOrder.asc
                                                        ? SortDirection.ASC
                                                        : SortDirection.DESC
                                                }
                                                onRowClick={onRowClickHandler}
                                                rowRenderer={rowRenderer}
                                                onScroll={props.onScroll}
                                                isScrolling={isScrolling}
                                                scrollTop={scrollTop}
                                            >
                                                {props.columns.map(
                                                    (
                                                        {
                                                            dataKey,
                                                            widthRatio,
                                                            dataKeyPath,
                                                            render,
                                                            disableSort,
                                                            align,
                                                            withToolTip,
                                                            ...other
                                                        },
                                                        index
                                                    ) => {
                                                        return (
                                                            <Column
                                                                key={dataKey}
                                                                headerRenderer={(headerProps) =>
                                                                    (props.headerRenderer &&
                                                                        props.headerRenderer(headerProps)) ||
                                                                    headerRenderer({
                                                                        ...headerProps,
                                                                        columnIndex: index,
                                                                    })
                                                                }
                                                                disableSort={disableSort}
                                                                width={width * widthRatio}
                                                                className={tableClasses.tableRow}
                                                                cellRenderer={defaultCellRenderer}
                                                                cellDataGetter={defaultCellDataGetter}
                                                                dataKey={dataKey}
                                                                columnData={{ render, dataKeyPath, align, withToolTip }}
                                                                {...other}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </Table>
                                        )}
                                    </AutoSizer>
                                )}
                            </WindowScroller>
                        )}
                    </InfiniteLoader>
                </Box>
                {showDetailsSide() && props.cellDataDetailRenderer && moreDetailSelection && (
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        className={tableClasses.moreDetailsSide}
                        width={props.detailsBoxWidth || 300}
                        data-more-details
                    >
                        <Box display={"flex"} justifyContent={"flex-end"}>
                            <IconButton aria-label="close" onClick={closeDetailsBox} size={"small"}>
                                <Close />
                            </IconButton>
                        </Box>
                        {props.cellDataDetailRenderer(moreDetailSelection.rowData)}
                    </Box>
                )}
            </Paper>
        </>
    );

    const virtualizedTable = (
        <>
            <Paper className={clsx(tableClasses.virtualizedPaper, props.classes?.paper)}>
                <Box display="flex" flexGrow={1} height="100%" className={"infoCubeTableContainer"}>
                    <InfiniteLoader
                        isRowLoaded={({ index }) => (props.data ? !!props.data[index] : true)}
                        loadMoreRows={props.loadMore}
                        rowCount={1000000}
                    >
                        {({ onRowsRendered, registerChild }) => (
                            <AutoSizer>
                                {({ height, width }) => (
                                    <Table
                                        ref={(ref) => (tableRef.current = ref)}
                                        onRowsRendered={onRowsRendered}
                                        rowClassName={getRowClassName}
                                        height={height || 100}
                                        width={width || 100}
                                        gridClassName={tableClasses.tableGrid}
                                        rowHeight={getRowHeight}
                                        headerHeight={defaultHeight}
                                        rowCount={props.data ? props.data.length : 0}
                                        rowGetter={({ index }) => {
                                            return {
                                                data: props.data ? props.data[index] : undefined,
                                                index,
                                            };
                                        }}
                                        sort={sort}
                                        sortBy={props.sortBy}
                                        sortDirection={
                                            props.sortOrder === SortOrder.asc ? SortDirection.ASC : SortDirection.DESC
                                        }
                                        onRowClick={onRowClickHandler}
                                        rowRenderer={rowRenderer}
                                        onScroll={props.onScroll}
                                    >
                                        {props.columns.map(
                                            (
                                                {
                                                    dataKey,
                                                    widthRatio,
                                                    dataKeyPath,
                                                    render,
                                                    disableSort,
                                                    withToolTip,
                                                    ...other
                                                },
                                                index
                                            ) => {
                                                return (
                                                    <Column
                                                        key={dataKey}
                                                        headerRenderer={(headerProps) =>
                                                            (props.headerRenderer &&
                                                                props.headerRenderer(headerProps)) ||
                                                            headerRenderer({
                                                                ...headerProps,
                                                                columnIndex: index,
                                                            })
                                                        }
                                                        disableSort={disableSort}
                                                        width={width * widthRatio}
                                                        className={tableClasses.tableRow}
                                                        cellRenderer={defaultCellRenderer}
                                                        cellDataGetter={defaultCellDataGetter}
                                                        dataKey={dataKey}
                                                        columnData={{ render, dataKeyPath, withToolTip }}
                                                        {...other}
                                                    />
                                                );
                                            }
                                        )}
                                    </Table>
                                )}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                </Box>
                {showDetailsSide() && props.cellDataDetailRenderer && moreDetailSelection && (
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        className={tableClasses.moreDetailsSide}
                        width={props.detailsBoxWidth || 300}
                        data-more-details
                    >
                        <Box display={"flex"} justifyContent={"flex-end"}>
                            <IconButton aria-label="close" onClick={closeDetailsBox} size={"small"}>
                                <Close />
                            </IconButton>
                        </Box>
                        {props.cellDataDetailRenderer(moreDetailSelection.rowData)}
                    </Box>
                )}
            </Paper>
        </>
    );

    if (props.windowScroll === true) return windowScrollTable;

    return virtualizedTable;
};

export * from "./detail-box-entry";
