import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useProgressStyles = makeStyles((theme: Theme) =>
    createStyles({
        progress: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "16px",
        },
    })
);
