import { useQuery } from "@apollo/client";
import { Person } from "@mui/icons-material";
import {
    Avatar,
    Box,
    ClickAwayListener,
    Divider,
    Fade,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IncognitoCircle } from "../img/incognito-circle.svg";
import {
    getAuthenticators,
    getAuthHeader,
    isMixedMode,
    isAuthenticated,
    redirectToLoginPage,
} from "../providers/authentication-provider";
import { getLanguageConfig, getDisplayName } from "../providers/tenant-provider";
import { getCurrentLng } from "../providers/ui-language-provider";
import { UserData, USER_QUERY } from "../shared-queries";
import { LanguagePopover } from "./language-popover";
import { useUserAvatarStyle } from "./user-avatar-style";
import { LoginModal } from "./login-modal";
import { Authenticator } from "../generated/public-graph-types";

interface UserAvatarProps {
    title?: string;
}

export const UserAvatar: FC<UserAvatarProps> = ({ title }) => {
    const languageConfig = getLanguageConfig();
    const currentLng = getCurrentLng();
    const classes = useUserAvatarStyle();
    const { t } = useTranslation();
    const [selectedLng, setSelectedLng] = useState<string>(currentLng);
    const [openUserInfo, setOpenUserInfo] = useState<boolean>(false);
    const [openLoginDlg, setOpenLoginDlg] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>();
    const [authenticators] = useState<Authenticator[]>(getAuthenticators());
    const { data, loading } = useQuery<UserData>(USER_QUERY);
    const mixedMode = isMixedMode();

    const isAnonymous = () => {
        return data?.me?.id === "anonymous";
    };

    useEffect(() => {
        if (!loading) {
            const showLoginDlg = Boolean(!isAuthenticated() && !isAnonymous());
            setOpenLoginDlg(showLoginDlg);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    function changeLanguageHandler(language: any) {
        if (language === selectedLng) return;
        localStorage.setItem("i18nextLng", language);
        setSelectedLng(language);
        window.location.reload();
    }

    const toggleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
        setOpenUserInfo(!openUserInfo);
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = () => {
        setOpenUserInfo(false);
    };

    const onLoginClick = () => {
        if (authenticators.length === 1) redirectToLoginPage(authenticators[0].url);
        else {
            setOpenUserInfo(false);
            setOpenLoginDlg(true);
        }
    };

    const renderAvatar = () => {
        return (
            <Avatar sx={{ bgcolor: "#546e7a" }}>{data?.me?.displayName?.toUpperCase().substring(0, 1) || ""}</Avatar>
        );
    };

    const renderUserSummary = () => {
        return (
            <Box p={1} display={"flex"} alignItems={"center"} textAlign={"center"} flexDirection={"column"}>
                {!isAnonymous() ? renderAvatar() : <IncognitoCircle />}
                <Box className={classes.longText} marginBottom={1} maxWidth={480}>
                    <Typography variant={"subtitle1"} className={classes.longText} sx={{ marginBottom: 0.5 }}>
                        {data?.me?.displayName}
                    </Typography>
                    <Typography color={"textSecondary"} variant="caption">
                        {data?.me?.email}
                    </Typography>
                </Box>
            </Box>
        );
    };

    const logout = async () => {       
        try {
            await fetch("/auth/logout", {
                method: "POST",
                headers: {
                    Authorization: getAuthHeader(),
                },
            });
        } catch (ignore) {
        } finally {           
            window.location.href = data?.me.endSessionUrl ? data?.me.endSessionUrl: window.location.origin;
        }
    };

    const renderUserIsLoggedIn = () => {
        return (
            <MenuList>
                <LanguagePopover
                    languages={languageConfig?.available as string[]}
                    selected={selectedLng}
                    onChange={changeLanguageHandler}
                />
                {!isAnonymous() && (
                    <MenuItem className={classes.item} onClick={logout}>
                        {t("Logout")}
                    </MenuItem>
                )}
                {isAnonymous() && mixedMode && (
                    <MenuItem className={classes.item} onClick={onLoginClick}>
                        {t("Login")}
                    </MenuItem>
                )}
            </MenuList>
        );
    };

    const renderUserIsNotLoggedIn = () => {
        return (
            <MenuList>
                <MenuItem className={classes.item} onClick={onLoginClick}>
                    {t("Login")}
                </MenuItem>
            </MenuList>
        );
    };

    const renderPopperContent = () => {
        return (
            <Paper elevation={3}>
                {data && renderUserSummary()}
                <Divider />
                {data ? renderUserIsLoggedIn() : renderUserIsNotLoggedIn()}
            </Paper>
        );
    };

    return (
        <>
            {data ? (
                <Tooltip arrow title={<>{t("User Profile")}</>}>
                    <IconButton aria-label="user" onClick={toggleUserInfo}>
                        {isAnonymous() ? <IncognitoCircle /> : renderAvatar()}
                    </IconButton>
                </Tooltip>
            ) : (
                <IconButton
                    aria-label="login"
                    color="inherit"
                    style={{ padding: 0 }}
                    size="large"
                    onClick={toggleUserInfo}
                >
                    <Person />
                </IconButton>
            )}
            <Popper
                open={openUserInfo}
                placement={"bottom-end"}
                anchorEl={anchorEl}
                transition
                className={classes.popper}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            {renderPopperContent()}
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
            <LoginModal
                isDlgOpen={openLoginDlg}
                authenticators={authenticators}
                title={title ? title : getDisplayName()}
                onClose={
                    isAnonymous()
                        ? () => {
                              setOpenLoginDlg(false);
                          }
                        : undefined
                }
            />
        </>
    );
};
