import { isEqual } from "lodash";
import React, { FC, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ExploreData, useExploreData } from "../../components/explore-data";
import { GraphLoader } from "../../components/graph-loader";
import { ROUTE_EXPLORE } from "../routes";
import { Explore } from "./explore";
import { useExploreState } from "./use-explore-state";

export const ExploreLoader: FC = () => {
    const location = useLocation();
    const history = useHistory();
    const { loading, error, data } = useExploreData();
    const [exploreState] = useExploreState();

    useEffect(() => {
        //use stored selected facets if there aren't any facets url params
        if (!location.search) {
            const selected = Object.assign(
                {},
                exploreState.selectedFacetsByNavigation,
                exploreState.selectedFacetsByFilter
            );

            if (isEqual(selected, {})) return;

            const urlParams = new URLSearchParams();

            for (const rootId in selected) {
                if (selected[rootId].length) urlParams.set(rootId, selected[rootId].join(","));
            }

            const route = `${ROUTE_EXPLORE}?${urlParams.toString()}`;
            if (`${location.pathname}${location.search}` !== route) history.push(route);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const render = (data: ExploreData) => {
        return (
            <Explore
                facetsFilter={data.facetsFilter}
                facetsNavigation={data.facetsNavigation}
                availableKeywordFields={data.availableKeywordFields}
                availableTextFields={data.availableTextFields}
                availableNumberFields={data.availableNumberFields}
                hitlistMetadataDefinitions={data.hitListMetadata}
                dataDisplayConfiguration={data.hitListConfiguration}
                navigationMetadataDefinitions={data.navigationMetadataDefinitions}
            />
        );
    };

    return <GraphLoader loading={loading} error={error} data={data} render={render} />;
};
