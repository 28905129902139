import React, { Dispatch, FC, SetStateAction } from "react";
import { Redirect, Switch } from "react-router-dom";
import { AccessRoute } from "../components/access-route";
import { AuthenticatedRoute } from "../components/authenticated-route";
import { Action, Resource } from "../enums";
import { Collections } from "./collections/collections";
import { ContentsOfCollectionTable } from "./collections/contents-of-collection-table";
import { CreateCollection } from "./collections/create-collection";
import { Content } from "./content/content";
import { ExploreLoader } from "./explore/explore-loader";
import { GoLoader } from "./go/go-loader";
import { StartPage } from "./start-page";
import { StaticPages } from "./static-pages/static-pages";

export const ROUTE_STATIC_PAGES = "/static-pages/:pageType";
export const ROUTE_CONTENT = "/content/:contentMapId";
export const ROUTE_CONTENT_WITH_TOPIC = "/content/:contentMapId/:topicId";
export const ROUTE_EXPLORE = "/explore";
export const ROUTE_HOME = "/home";
export const ROUTE_ADMIN = "/admin";
export const ROUTE_COLLECTIONS = "/collections";
export const ROUTE_COLLECTIONS_WITH_ID = "/collections/:collectionId";
export const ROUTE_COLLECTIONS_CREATE = "/collections/create";
export const ROUTE_GO = "/go";

export const Routes: FC<{ notificationsOpen: boolean; setNotificationsOpen: Dispatch<SetStateAction<boolean>> }> = ({
    notificationsOpen,
    setNotificationsOpen,
}) => {
    return (
        <Switch>
            <AuthenticatedRoute path={ROUTE_HOME} component={() => <StartPage />} />
            <AccessRoute
                path={ROUTE_CONTENT_WITH_TOPIC}
                component={(params) => {
                    return (
                        <Content
                            contentMapId={params.match.params.contentMapId}
                            topicId={params.match.params.topicId}
                            notificationsOpen={notificationsOpen}
                            setNotificationsOpen={setNotificationsOpen}
                        />
                    );
                }}
                action={Action.query}
                resource={Resource.contents}
            />
            <AccessRoute
                path={ROUTE_CONTENT}
                component={(params) => {
                    return (
                        <Content
                            contentMapId={params.match.params.contentMapId}
                            notificationsOpen={notificationsOpen}
                            setNotificationsOpen={setNotificationsOpen}
                        />
                    );
                }}
                action={Action.query}
                resource={Resource.contents}
            />
            <AccessRoute
                path={ROUTE_STATIC_PAGES}
                component={(params) => {
                    return <StaticPages pageType={params.match.params.pageType} />;
                }}
                action={Action.query}
                resource={Resource.static_pages}
            />
            <AccessRoute
                path={ROUTE_EXPLORE}
                component={() => <ExploreLoader />}
                action={Action.query}
                resource={Resource.facet_collections}
            />
            <AccessRoute
                path={ROUTE_COLLECTIONS_CREATE}
                component={() => <CreateCollection />}
                action={Action.query}
                resource={Resource.contents}
            />
            <AccessRoute
                path={ROUTE_COLLECTIONS_WITH_ID}
                component={(params) => <ContentsOfCollectionTable collectionId={params.match.params.collectionId} />}
                action={Action.query}
                resource={Resource.contents}
            />
            <AccessRoute
                path={ROUTE_COLLECTIONS}
                component={() => <Collections />}
                action={Action.query}
                resource={Resource.collections}
            />
            <AccessRoute
                path={ROUTE_GO}
                component={() => <GoLoader />}
                action={Action.query}
                resource={Resource.facet_collections}
            />
            <Redirect from="/" to={ROUTE_HOME} />
        </Switch>
    );
};
