import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmationDialogProps extends DialogProps {
    title: string;
    content: ReactNode;
    dlgBtn: React.ReactNode;
    onCancel: () => void;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    title,
    content,
    dlgBtn,
    onCancel,
    maxWidth = "sm",
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });

    return (
        <Dialog fullWidth={!isDesktop} fullScreen={!isDesktop} maxWidth={isDesktop ? maxWidth : undefined} {...props}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <DialogTitle sx={{ paddingRight: 0 }}>{title}</DialogTitle>
                <Box pr={2}>
                    <IconButton aria-label="close" onClick={onCancel} size={"small"}>
                        <Close data-icon={"close"} />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent dividers>{content}</DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary" variant="outlined">
                    {t("Cancel")}
                </Button>
                {dlgBtn}
            </DialogActions>
        </Dialog>
    );
};
