import { Observable, ServerError } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Authenticator } from "../generated/admin-graph-types";
import { AuthenticatorEdge, AuthenticatorQueryResult, TenantAccessMode } from "../generated/public-graph-types";
import { TenantAuthConfig } from "./../generated/consumer-graph-types";
import { getCurrentLng } from "./ui-language-provider";

const FORBIDDEN = "forbidden";
const UNAUTHORIZED = "unauthorized";
const ANONYMOUS_STRATEGY = "AnonymousStrategy";

interface AuthToken {
    token: string;
    expiresInSeconds: number;
}

export const isAuthenticated = () => {
    return !!token;
};

let token: string;
let authUrl: string;
let authConfig: TenantAuthConfig;
let authenticators: Authenticator[] = [];
let anonymousAuthenticator: Authenticator;

export const loginLink = onError(({ networkError, operation, forward }) => {
    const statusCode = (networkError as ServerError)?.statusCode;

    if (statusCode === 401 && !!token) {
        return new Observable((observer) => {
            getTokenAndStartTokenAutoRefresh()
                .then(() => {
                    const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                })
                .catch((error: any) => {
                    if (error.message === UNAUTHORIZED) {
                        redirectToLoginPage(authUrl).catch(() => observer.error(error.message));
                        return;
                    }

                    observer.error(error);
                });
        });
    }

    if (statusCode === 403) {
        return new Observable((observer) => {
            observer.error("You don't have the rights to use the app. Please contact your administrator.");
        });
    }
});

export const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: getAuthHeader(),
            language: getCurrentLng(),
        },
    };
});

export const getAuthHeader = () => {
    return token ? `Bearer ${token}` : "";
};

export const initAuthentication = async (config: TenantAuthConfig, useDefault: boolean = false) => {
    const authenticatorQueryResult = config?.authenticators as AuthenticatorQueryResult;
    if (!authenticatorQueryResult.authenticators?.length) throw new Error("No authenticator defined.");
    authConfig = config;
    //currently only system admin uses default authenticator login behavior
    setAuthenticators(authenticatorQueryResult.authenticators as AuthenticatorEdge[], useDefault);

    try {
        const result = await getTokenAndStartTokenAutoRefresh();
        return result;
    } catch (e: any) {
        if (e.message === UNAUTHORIZED || e.message === FORBIDDEN) {
            if (anonymousAuthenticator) await redirectToLoginPage(anonymousAuthenticator.url);
            else if (useDefault || authenticators.length === 1) await redirectToLoginPage(authenticators[0].url);
        }

        return false;
    }
};

const getTokenAndStartTokenAutoRefresh = async () => {
    token = "";
    const expiresIn = await getAndSetJWT();

    if (expiresIn) {
        tokenAutoRefresh(expiresIn);
        return true;
    }

    return false;
};

const setAuthenticators = (authList: AuthenticatorEdge[], useDefault: boolean = false) => {
    //check if tenant is for e2e ui testing
    const testStrategyAuth = authList.find(
        (val: AuthenticatorEdge) => val?.node?.strategyName.toUpperCase() === "TestStrategy".toUpperCase()
    );
    if (testStrategyAuth) {
        authenticators = [testStrategyAuth.node as Authenticator];
        return;
    }

    //check if tenant has anonymous mode
    const anonymousAuth = authList.find((val: AuthenticatorEdge) => val?.node?.strategyName === ANONYMOUS_STRATEGY);
    if (anonymousAuth) {
        anonymousAuthenticator = anonymousAuth.node as Authenticator;
        if (useDefault && !isAuthenticatedMode()) {
            authenticators = [anonymousAuthenticator];
            return;
        }
    }

    if (useDefault) {
        const defaultAuthenticator = authenticators.find((authenticator) => {
            return authenticator.default;
        });
        authenticators = defaultAuthenticator ? [defaultAuthenticator] : [authList[0].node as Authenticator];
    } else {
        authenticators = authList
            .filter((val) => val.node?.strategyName !== ANONYMOUS_STRATEGY)
            .map((val) => val.node) as Authenticator[];
    }
};

export const fetchToken = async () => {
    try {
        const result = await getTokenAndStartTokenAutoRefresh();
        return result;
    } catch (e) {
        return false;
    }
};

export const redirectToLoginPage = async (authenticatorUrl: string) => {
    authUrl = authenticatorUrl;
    const loginDomain = `${window.location.protocol}//${window.location.host}`;
    const loginURL = new URL(authenticatorUrl);
    const callbackURL = new URL(window.location.href);

    loginURL.searchParams.append("loginDomain", loginDomain);
    loginURL.searchParams.append("callbackURL", callbackURL.href);

    window.location.href = loginURL.href;
};

const getTokenFromCookie: () => Promise<AuthToken> = async () => {
    const tokenResponse = await fetch("/auth/tokens", {
        credentials: "include",
    });

    if (tokenResponse.status === 401) throw new Error(UNAUTHORIZED);
    if (tokenResponse.status === 403) throw new Error(FORBIDDEN);
    if (!tokenResponse.ok) throw new Error(tokenResponse.statusText);

    const tokenObject: AuthToken = await tokenResponse.json();
    return tokenObject;
};

const getAndSetJWT = async () => {
    const jwt = await getTokenFromCookie();
    token = jwt.token;
    return jwt.expiresInSeconds;
};

const tokenAutoRefresh = (expiresInSeconds: number, authenticate?: boolean) => {
    const intervalInMs = expiresInSeconds * 1000 * 0.9;
    setTimeout(async () => {
        try {
            const _expiresInSeconds = await getAndSetJWT();
            if (_expiresInSeconds) tokenAutoRefresh(_expiresInSeconds, authenticate);
        } catch (e: any) {
            if (e.messages === UNAUTHORIZED) await redirectToLoginPage(authUrl);
        }
    }, intervalInMs);
};

export const getAccessMode = () => {
    return authConfig.accessMode;
};

export const isMixedMode = () => {
    return authConfig.accessMode === TenantAccessMode.mixed;
};

export const isAnonymousMode = () => {
    return authConfig.accessMode === TenantAccessMode.anonymous;
};

export const isAuthenticatedMode = () => {
    return authConfig.accessMode === TenantAccessMode.authenticated;
};

export const getAuthenticators = () => {
    return authenticators;
};

export const getAnonymousAuthenticator = () => {
    return anonymousAuthenticator;
};
