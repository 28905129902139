import { MoreVertOutlined } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Chip, IconButton } from "@mui/material";
import React, { FC, Fragment } from "react";
import { MimeTypeIcon } from "../../components/mime-type-icon";
import { NotesVisibilityIcon } from "../../components/notes-visibility-icon";
import { RenderIfHasAccess } from "../../components/render-if-has-access";
import { Action, Resource } from "../../enums";
import { Language, MultilingualString, Note, NoteVisibilityTypes } from "../../generated/consumer-graph-types";
import { DateFormatter } from "../../utils";
import { useNoteCardStyle } from "./note-card-style";
import { useTranslation } from "react-i18next";

type NoteCardProps = {
    note: Note;
    onMenuClick?: (event: React.MouseEvent<HTMLButtonElement>, note: Note) => void;
};

export const NoteCard: FC<NoteCardProps> = ({ note, onMenuClick }) => {
    const classes = useNoteCardStyle();
    const { t } = useTranslation();

    const getNoteTexts = (texts: MultilingualString) => {
        return (
            <Fragment>
                {Object.keys(Language).map((key) => {
                    // @ts-ignore
                    if (texts[key])
                        // @ts-ignore
                        return <Box key={key}>{texts[key]} </Box>;

                    return null;
                })}
            </Fragment>
        );
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                title={
                    <Box display={"flex"} alignItems={"center"} fontWeight={600} fontSize={12}>
                        <NotesVisibilityIcon visibility={note.visibility as NoteVisibilityTypes} />
                        <Box display={"flex"} flexDirection={"column"} marginLeft={0.5}>
                            <Box> {note.user ? note.user.displayName : t("Deleted User")}</Box>
                            <Box>{DateFormatter(note.creationDate)}</Box>
                        </Box>
                    </Box>
                }
                action={
                    onMenuClick && (
                        <RenderIfHasAccess action={Action.write} resource={Resource.notes_comments}>
                            <IconButton aria-label="more-menu" onClick={(e) => onMenuClick(e, note)} size="large">
                                <MoreVertOutlined />
                            </IconButton>
                        </RenderIfHasAccess>
                    )
                }
            />
            <CardContent className={classes.cardContent}>{getNoteTexts(note.text as MultilingualString)}</CardContent>
            {!!note.attachments?.length && (
                <Box className={classes.cardActions}>
                    {note.attachments?.map((val, index) => {
                        return (
                            <Chip
                                classes={{ root: classes.chip, label: classes.chipLabel }}
                                key={val?.blobId || index}
                                size={"medium"}
                                icon={
                                    <Box mt={0.5}>
                                        <MimeTypeIcon mimeType={val?.blob?.mimeType || ""} />
                                    </Box>
                                }
                                label={val?.path?.substring(1, val?.path?.length)}
                                clickable
                                component={"a"}
                                download
                                href={val?.blob?.url}
                            />
                        );
                    })}
                </Box>
            )}
        </Card>
    );
};
