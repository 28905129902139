import { Box, Typography, Button } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorImage } from "../img/error.svg";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { NotFoundPage } from "./not-found-page";

export type ErrorPageProps = {
    errorCode?: string;
    errorMessage?: string;
    requestId?: string;
    goBack?: () => void;
};

export const ErrorPage: FC<ErrorPageProps> = ({ errorCode, errorMessage, requestId, goBack }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const goToPreviousPage = () => {
        if (goBack) {
            goBack();
            return;
        }

        if (history) history.goBack();
        else window.location.reload();
    };

    if (errorCode === "404") return <NotFoundPage requestId={requestId} goBack={goToPreviousPage} />;

    return (
        <Box display="flex" textAlign="center" flexDirection="column" alignItems="center" sx={{ mt: { xs: 2, lg: 8 } }}>
            <Typography variant="h4">{t("Oh no!")}</Typography>
            <Typography variant="h5">{t("Something went wrong")}</Typography>
            <ErrorImage style={{ width: "400px", height: "350px" }} />
            {errorCode && <Typography variant="subtitle2">{t("Error Code: {{code}}", { code: errorCode })}</Typography>}
            {errorMessage && <Typography variant="subtitle1">{errorMessage}</Typography>}
            <Box display="flex" flexDirection="column" height="30vh" justifyContent="space-between">
                <Box />
                <Box>
                    <Button
                        variant="outlined"
                        onClick={goToPreviousPage}
                        startIcon={<ArrowBack />}
                        sx={{ borderColor: "#706A8F", color: "#706A8F" }}
                    >
                        {t("Back to previous page")}
                    </Button>
                </Box>
                {requestId ? (
                    <Typography variant="body2" color="textSecondary">
                        {t(
                            "If the problem persists, please contact the administrator or the support team and provide the query ID."
                        )}
                        <br />
                        <strong>{t("Query ID:{{id}}", { id: requestId })}</strong>
                    </Typography>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        {t("If the problem persists, please contact the administrator or the support team.")}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
