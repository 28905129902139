import React, { FC } from "react";
import { Dictionary } from "ts-essentials";
export const LanguageCode: FC<{ languageCode: string; asSelectOption?: boolean; testid?: string }> = ({
    languageCode,
    asSelectOption,
    testid,
}) => {
    const languageCodesMapped: Dictionary<string> = {
        ar: "العربية",
        da: "Dansk",
        de: "Deutsch",
        el: "Ελληνικά",
        en: "English",
        es: "Español",
        fi: "Suomi",
        fr: "Français",
        hu: "Magyar",
        it: "Italiano",
        ja: "日本語",
        nb: "Norsk Bokmål",
        nl: "Nederlands",
        pt: "Português",
        ro: "Română",
        ru: "Русский",
        sv: "Svenska",
        tr: "Türkçe",
    };

    if (asSelectOption)
        return <option value={languageCode}>{languageCodesMapped[languageCode] || languageCode}</option>;

    return <span data-testid={testid}>{languageCodesMapped[languageCode] || languageCode}</span>;
};
