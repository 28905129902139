import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useErrorPageStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            borderColor: "#706A8F",
            color: "#706A8F",
        },
        imageContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(2),
            [theme.breakpoints.down("xl")]: {
                "@media (orientation: landscape)": {
                    width: "12%",
                },
            },
        },
    })
);
