import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useSearchFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        metadata: {
            marginLeft: theme.spacing(1),
            minWidth: "200px",
        },
        select: {
            padding: "0 !important",
            minWidth: "200px",
        },
        searchBtn: {
            marginLeft: theme.spacing(1),
        },
        searchSettingsPopper: {
            padding: theme.spacing(2),
        },
        searchIcons: {
            fill: theme.palette.action.active,
        },
        settingsBtn: {
            height: theme.spacing(5.5),
            right: 0,
            top: 1,
            position: "absolute",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
        },
        endAdornment: {
            "& .MuiAutocomplete-endAdornment": {
                right: "72px !important",
            },
        },
    })
);
