import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithProgress } from "../../components/button-with-progress";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import { GraphLoader } from "../../components/graph-loader";
import { Collection, CollectionVisibilityTypes } from "../../generated/consumer-graph-types";
import { CollectionForm, CollectionFormData } from "./collection-form";

export const COLLECTION_QUERY = gql`
    query getCollectionById($id: ID!) {
        collection(id: $id) {
            id
            description
            displayName
            visibility
        }
    }
`;

export const UPDATE_COLLECTION_MUTATION = gql`
    mutation updateCollection($description: String, $displayName: String, $id: ID!) {
        updateCollection(diff: { displayName: $displayName, description: $description, id: $id }) {
            id
        }
    }
`;

interface EditCollectionDialogProps {
    id: string;
    isOpen: boolean;
    onClose: () => void;
}
interface CollectionData {
    collection: Collection;
}

export const EditCollectionDialog: FC<EditCollectionDialogProps> = ({ isOpen: open, id, onClose }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formData, setFormData] = useState<CollectionFormData>({ description: "", displayName: "", isPublic: false });

    const { data, loading, error } = useQuery<CollectionData>(COLLECTION_QUERY, {
        variables: {
            id,
        },
        skip: !isOpen,
    });

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const [updateCollectionMutation] = useMutation(UPDATE_COLLECTION_MUTATION);

    const updateCollection = async () => {
        const { data, errors } = await updateCollectionMutation({
            variables: {
                id,
                displayName: formData.displayName,
                description: formData.description,
            },
            refetchQueries: ["collections"],
            awaitRefetchQueries: true,
        });

        onClose();

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(
                t("Could not change collection {{id}}.", {
                    id,
                }),
                {
                    key: id,
                    variant: "error",
                    autoHideDuration: null,
                }
            );
        }

        if (data) {
            enqueueSnackbar(t("Collection changed."), {
                variant: "success",
            });
        }
    };

    const render = (data: CollectionData) => {
        const collectionFormData: CollectionFormData = {
            description: data.collection.description ?? "",
            displayName: data.collection.displayName ?? "",
            isPublic: data.collection.visibility === CollectionVisibilityTypes.public,
        };

        return <CollectionForm data={collectionFormData} onChangeData={setFormData} />;
    };

    return (
        <ConfirmationDialog
            fullWidth={true}
            maxWidth="md"
            open={isOpen}
            onCancel={onClose}
            title={t("Edit collection {{displayName}}", { displayName: data?.collection?.displayName })}
            dlgBtn={
                <Box ml={1}>
                    <ButtonWithProgress
                        disabled={!formData.displayName}
                        loading={isSaving}
                        aria-label={"save"}
                        variant="contained"
                        onClick={async () => {
                            setIsSaving(true);
                            await updateCollection();
                            setIsSaving(false);
                        }}
                        title={t("Save")}
                    />
                </Box>
            }
            content={<GraphLoader render={render} data={data} loading={loading} error={error} />}
        />
    );
};
