import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useFacetsNavigationStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiTreeItem-content": {
                backgroundColor: "inherit !important",
            },
            "& .MuiTypography-body1": {
                fontSize: "0.85rem",
            },
            "& .MuiCollapse-wrapperInner": {
                display: "flex",
                flexDirection: "column",
                marginBottom: theme.spacing(1),
            },
            "& .Mui-selected": {
                backgroundColor: "inherit !important",
            },
        },
        itemContent: {
            flexDirection: "row-reverse",
        },
        disabledLabel: {
            "& label": {
                cursor: "default",
                color: theme.palette.text.disabled,
            },
        },
        rootWithoutChildren: {
            "& .MuiCollapse-root": {
                height: 0,
            },
        },
    })
);
