import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useSideSheetCollapsableStyles = makeStyles((theme: Theme) => ({
    paper: {
        top: "64px",
        [theme.breakpoints.down("lg")]: {
            top: 0,
        },
    },
    drawerOpen: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerHidden: {
        width: 0,
    },
    list: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        "& span": {
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightMedium,
        },
        "& .active span": {
            color: theme.palette.primary.main + " !important",
            "& div": {
                color: theme.palette.primary.main + " !important",
            },
        },
    },
    boxIcons: {
        width: "100%",
    },
    iconButton: {
        color: theme.palette.text.secondary,
    },
    button: {
        margin: "3px",
        marginLeft: "5px",
        color: theme.palette.text.secondary,
        "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
        },
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));
