import { MuiButton } from "./mui-button";
import { MuiTableCell } from "./mui-table-cell";
import { MuiFormControl } from "./mui-form-control";
import { MuiTableRow } from "./mui-table-row";
import { MuiTooltip } from "./mui-tooltip";
import { MuiCssBaseline } from "./mui-baseline";
import { MuiTreeItem } from "./mui-tree-item";
import { MuiTextField } from "./mui-text-field";
import { MuiChip } from "../mui-chip";

export const overrides = {
    MuiTableCell,
    MuiFormControl,
    MuiTableRow,
    MuiTooltip,
    MuiCssBaseline,
    MuiTreeItem,
    MuiTextField,
    MuiButton,
    MuiChip,
};
