import { gql, useMutation } from "@apollo/client";
import { Box, Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithProgress } from "../../components/button-with-progress";
import { MimeTypeIcon } from "../../components/mime-type-icon";
import {
    CreateNoteInput,
    MultilingualStringInput,
    NoteVisibilityTypesInput,
} from "../../generated/consumer-graph-types";
import { getAuthHeader } from "../../providers/authentication-provider";
import { NoteDialog } from "./note-dialog";
import { NoteFormData } from "./note-form";
import { useNoteFormStyle } from "./notes-form-style";

export const CREATE_NOTE_QUERY = gql`
    mutation createNote($note: CreateNoteInput!) {
        createNote(noteSpec: $note)
    }
`;

type NoteCreateModalProps = {
    open: boolean;
    onClose: () => void;
    objectId: string;
    onCreate: () => void;
};

export const NoteCreateDialog: FC<NoteCreateModalProps> = ({ open, onClose, objectId, onCreate }) => {
    const classes = useNoteFormStyle();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [createNoteMutation] = useMutation(CREATE_NOTE_QUERY);
    const [attachments, setAttachments] = useState<File[]>([]);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [formData, setFormData] = useState<NoteFormData | undefined>();

    const onChangeAttachmentInput = (files: File[]) => {
        const addedAttachments: File[] = [];
        files.forEach((val: File) => {
            if (
                !attachments.find((x: File) => {
                    return x.name === val.name;
                })
            )
                addedAttachments.push(val);
        });

        if (addedAttachments.length) setAttachments([...attachments, ...addedAttachments]);
    };

    const removeAttachment = (index: any) => {
        let newAttachments = [...attachments];
        newAttachments.splice(index, 1);
        setAttachments(newAttachments);
    };

    const onSave = async (
        language: string,
        comment: string,
        visibilityType: NoteVisibilityTypesInput
    ): Promise<boolean> => {
        const text: MultilingualStringInput = {};
        // @ts-ignore
        text[language] = comment;
        const noteInput: CreateNoteInput = {
            objectId,
            text,
            visibility: visibilityType,
        };
        const { data, errors } = await createNoteMutation({
            variables: { note: noteInput },
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(t("Note couldn't be created"), {
                variant: "error",
                autoHideDuration: null,
            });
            onCreate();
            return false;
        }

        if (attachments.length) {
            try {
                await uploadAttachments(data.createNote, attachments);
            } catch (e) {
                enqueueSnackbar(t("Note created but attachment upload failed."), {
                    variant: "warning",
                    autoHideDuration: null,
                });
                onCreate();
                return false;
            }
        }

        enqueueSnackbar(t("Note successfully created"), {
            variant: "success",
        });
        setAttachments([]);
        onCreate();
        return true;
    };

    const uploadAttachments = (noteId: String, attachments: File[]) => {
        return new Promise((resolve, reject) => {
            const data = new FormData();
            attachments.forEach((val) => {
                data.append("files", val);
            });

            const uploadRequest = new XMLHttpRequest();
            uploadRequest.addEventListener("load", (event) => {
                //@ts-ignore
                if (event.target.status === 201) {
                    //@ts-ignore
                    resolve(event.target.response);
                    return;
                }
                reject(event);
            });
            uploadRequest.addEventListener("error", (event) => {
                reject(event);
            });

            uploadRequest.open("POST", `/api/upload/notes/${noteId}/attachments`, true);
            uploadRequest.setRequestHeader("Authorization", getAuthHeader());
            uploadRequest.send(data);
        });
    };

    const attachmentsRenderer = (
        <Box mt={1} mb={1}>
            {attachments.map((val: File, index: number) => {
                return (
                    <Chip
                        style={{ maxWidth: 270 }}
                        color={"default"}
                        key={val.name}
                        size={"small"}
                        label={val.name}
                        icon={
                            <Box className={classes.mimeTypeIconBox}>
                                <MimeTypeIcon mimeType={val.type} />
                            </Box>
                        }
                        onDelete={() => removeAttachment(index)}
                    />
                );
            })}
        </Box>
    );

    return (
        <NoteDialog
            open={open}
            title={t("Create Note")}
            onChange={setFormData}
            onCancel={() => {
                setAttachments([]);
                onClose();
            }}
            attachmentsRenderer={attachmentsRenderer}
            onChangeAttachmentInput={onChangeAttachmentInput}
            dlgBtn={
                <Box ml={1}>
                    <ButtonWithProgress
                        title={t("Save")}
                        loading={inProgress}
                        color={"primary"}
                        disabled={!formData?.comment}
                        variant={"contained"}
                        onClick={async () => {
                            setInProgress(true);
                            await onSave(
                                formData?.language as string,
                                formData?.comment as string,
                                formData?.visibilityType as NoteVisibilityTypesInput
                            );

                            setInProgress(false);
                        }}
                    />
                </Box>
            }
        />
    );
};
