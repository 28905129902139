import { Close } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Trash } from "../img/trash.svg";
import { ButtonWithProgress } from "./button-with-progress";
import { useDeleteConfirmationDialogStyles } from "./delete-confirmation-dialog-style";

interface DeleteConfirmationDialogProps extends DialogProps {
    title: string;
    content: ReactNode;
    onDelete: () => Promise<any>;
    onCancel: () => void;
    disabled?: boolean;
}

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
    title,
    content,
    onDelete,
    onCancel,
    disabled,
    maxWidth,
    ...props
}) => {
    const { t } = useTranslation();
    const classes = useDeleteConfirmationDialogStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    return (
        <Dialog fullScreen={!isDesktop} classes={{ paper: classes.paper }} {...props} fullWidth={!isDesktop}>
            <DialogTitle>
                {title}
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Box display="flex" alignItems="center" flexDirection="column">
                    <Avatar sx={{ width: 64, height: 64, backgroundColor: "error.main" }}>
                        <Trash className={classes.icon} />
                    </Avatar>
                    {content}
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    display="flex"
                    flexGrow={1}
                    m={1}
                    sx={{ justifyContent: ["flex-end", "flex-end", "flex-end", "center", "center"] }}
                >
                    <Button onClick={onCancel} color="inherit" variant="outlined" size="large" sx={{ marginRight: 2 }}>
                        {t("Cancel")}
                    </Button>
                    <ButtonWithProgress
                        disabled={disabled}
                        loading={isDeleting}
                        onClick={async () => {
                            setIsDeleting(true);
                            await onDelete();
                            setIsDeleting(false);
                        }}
                        variant="contained"
                        color="error"
                        title={t("Delete")}
                        size="large"
                    />
                </Box>
            </DialogActions>
        </Dialog>
    );
};
