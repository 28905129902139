import { ArrowDropDown, Clear, Search } from "@mui/icons-material";
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, OutlinedInput, Popover } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dictionary } from "ts-essentials";
import { consumerClient } from "../apollo-clients/consumer-client";
import { ApplicationScope } from "../enums";
import {
    AggregationEdge,
    Facet,
    LanguageWithWildcard,
    DatafieldDefinition,
    useFacetSelectionQuery,
} from "../generated/consumer-graph-types";
import { getCurrentLng } from "../providers/ui-language-provider";
import {
    ContentsAggregationsProps,
    generateDynamicSearchContentsOnlyAggregationsQuery,
    getContentsAggregationsQueryVariables,
} from "../shared-queries/do-not-parse";
import { deepCopy } from "../utils";
import { useFacetPopoverStyles } from "./facet-popover-style";
import { FacetSelector } from "./facet-selector";
import { GraphLoader } from "./graph-loader";
import { InfoCubeDialog } from "./info-cube-dialog";
import { useInfoCubeMediaQuery } from "./info-cube-media-query";

export interface FacetSelectionProps {
    facet: Facet;
    selected: Dictionary<string[]>;
    onSelect: (selected: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
    contentsAggregationsProps?: ContentsAggregationsProps;
}

export const FacetSelection: FC<FacetSelectionProps> = ({ facet, selected, onSelect, contentsAggregationsProps }) => {
    const MIN_VALUES_FOR_FILTER = 12;
    const isMobile = useInfoCubeMediaQuery();
    const { t } = useTranslation();
    const classes = useFacetPopoverStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [facetMetadata, setFacetMetadata] = useState<any>(undefined);
    const [aggregations, setAggregations] = useState<any>();
    const [filteredFacetValues, setFilteredFacetValues] = useState<any[]>([]);
    const [facetIsSelected, setFacetIsSelected] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [filterValue, setFilterValue] = useState<string>("");
    const [modalOpen, setModelOpen] = useState<boolean>(false);
    const currentLanguage = getCurrentLng();
    const isModal = facet.display.options?.modal || isMobile;

    const { error, refetch } = useFacetSelectionQuery({
        variables: {
            acceptedLanguages: [currentLanguage as LanguageWithWildcard, LanguageWithWildcard.en],
            id: ApplicationScope.documentSelection,
        },
        skip: true,
    });

    let contentAggregationsProps: ContentsAggregationsProps | undefined;
    if (contentsAggregationsProps) {
        contentAggregationsProps = deepCopy(contentsAggregationsProps);
        if (contentAggregationsProps?.selectedFacetsByAll[facet.referencedId])
            delete contentAggregationsProps.selectedFacetsByAll[facet.referencedId];
    }

    useEffect(() => {
        //refetch aggregations each time when popover or modal is opened
        if ((anchorEl || modalOpen) && facetMetadata) {
            const aggregationsQuery = generateDynamicSearchContentsOnlyAggregationsQuery([facetMetadata]);
            consumerClient()
                .query({
                    query: aggregationsQuery,
                    variables: getContentsAggregationsQueryVariables(
                        contentAggregationsProps as ContentsAggregationsProps
                    ),
                    fetchPolicy: "network-only",
                })
                .then((result) => {
                    let values: AggregationEdge[] = [];

                    for (const key in result.data.contents?.aggregations) {
                        if (key === "__typename" || key === "useContext") continue;
                        
                        const aggregationsValues = result.data.contents?.aggregations[key] as AggregationEdge[];
                        if (Array.isArray(aggregationsValues)) {
                            values = values.concat(aggregationsValues);
                        }
                    }

                    for (const key in result.data.contents?.aggregations?.useContext?.composite) {
                        if (key === "__typename") continue;
                     
                        const aggregationsValues: any = result.data.contents?.aggregations?.useContext.composite[
                            key
                        ] as AggregationEdge;

                        if (Array.isArray(aggregationsValues)) {
                            values = values.concat(aggregationsValues);
                        }
                    }
                    const newAggregations: Dictionary<number> = {};
                    for (const entry of values) {
                        newAggregations[entry.value] = entry.count;
                    }

                    setAggregations(newAggregations);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facetMetadata, anchorEl, modalOpen]);

    const popoverOpen = Boolean(anchorEl);

    useEffect(() => {
        setFacetIsSelected(!!selected[facet.referencedId]);
    }, [facet.referencedId, selected]);

    const handleClick = async (event: any) => {
        if (isModal) {
            setModelOpen(true);
        } else {
            setAnchorEl(event.currentTarget);
        }
        if (facetMetadata) return;

        const { data } = await refetch({
            referencedIds: [facet.referencedId as string],
            acceptedLanguages: [currentLanguage as LanguageWithWildcard, LanguageWithWildcard.en],
        });

        if (data?.facetsCollection?.facets?.length && data.facetsCollection.facets[0]?.definition) {
            const metadata = {
                id: data.facetsCollection.facets[0].referencedId,
                type: data.facetsCollection.facets[0].type,
                ...data.facetsCollection.facets[0].definition,
            };
            setFacetMetadata(metadata);
            // @ts-ignore
            const values = data.facetsCollection?.facets[0]?.definition?.values || [];
            setFilteredFacetValues(values);
        }
    };

    const filterValues = (event: any) => {
        setFilterValue(event.target.value);
        if (!event.target.value) {
            //@ts-ignore
            setFilteredFacetValues(facetMetadata?.values || []);
            return;
        }
        //@ts-ignore
        const newValues = facetMetadata?.values.filter((val) =>
            val.teasers?.title?.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setFilteredFacetValues(newValues);
    };

    const clearFilter = () => {
        setFilterValue("");
        //@ts-ignore
        setFilteredFacetValues(facetMetadata?.values || []);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setFacetIsSelected(!!selected[facet.referencedId]);
    };

    const selectAll = (values: any[]) => {
        const newSelected = deepCopy(selected);
        if (!newSelected[facet.referencedId]) {
            newSelected[facet.referencedId] = [];
        }

        values
            .filter((val: any) => {
                return aggregations === undefined || aggregations[val.key] > 0;
            })
            .forEach((val: any) => {
                if (!newSelected[facet.referencedId].includes(val.key)) {
                    newSelected[facet.referencedId].push(val.key);
                }
            });

        if (newSelected[facet.referencedId].length) {
            onSelect(newSelected, []);
        }
    };

    const deselectAll = () => {
        const newSelected = deepCopy(selected);
        delete newSelected[facet.referencedId];
        onSelect(newSelected, [facet.referencedId]);
    };

    const toggleSelectAll = (selectAllChecked: boolean) => {
        if (selectAllChecked) deselectAll();
        //@ts-ignore
        else selectAll(facetMetadata?.values || []);
    };

    const render = (data: DatafieldDefinition) => {
        // @ts-ignore
        const values = data.values || [];
        return (
            <Box minWidth={120}>
                {values?.length > MIN_VALUES_FOR_FILTER && (
                    <Box margin={1} display={"flex"} alignItems={"center"}>
                        <FormControl variant="outlined" size="small">
                            <OutlinedInput
                                placeholder={t("Search")}
                                value={filterValue}
                                onChange={filterValues}
                                endAdornment={
                                    filterValue.length > 0 && (
                                        <InputAdornment position="end">
                                            <IconButton color={"default"} onClick={clearFilter} size={"small"}>
                                                <Clear />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                )}
                <FacetSelector
                    filteredFacetValues={filteredFacetValues}
                    toggleSelectAll={toggleSelectAll}
                    filterValue={filterValue}
                    metaDataDefinition={data}
                    aggregations={aggregations}
                    facet={facet}
                    selected={selected}
                    onSelect={onSelect}
                />
            </Box>
        );
    };

    const getLoader = () => {
        return (
            <GraphLoader
                classes={{ progress: classes.progress }}
                loading={loading}
                error={error}
                data={facetMetadata}
                render={render}
            />
        );
    };

    return (
        <Box margin={0.5} ml={0}>
            <Button
                sx={{ textTransform: "none", justifyContent: isMobile ? "space-between" : "center" }}
                color={facetIsSelected ? "primary" : "inherit"}
                variant={facetIsSelected ? "contained" : "outlined"}
                size={isMobile ? "large" : "small"}
                endIcon={<ArrowDropDown />}
                aria-label={facet.teasers?.title || ""}
                onClick={handleClick}
                disableElevation={true}
                fullWidth={isMobile}
            >
                {facet.teasers?.title}
            </Button>
            {isMobile && (
                <Box mt={2} mb={1}>
                    <Divider variant="fullWidth" />
                </Box>
            )}
            {!isModal && (
                <Popover
                    className={classes.popover}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {getLoader()}
                </Popover>
            )}
            {isModal && (
                <InfoCubeDialog
                    title={facet.teasers?.title || ""}
                    onClose={() => setModelOpen(false)}
                    open={modalOpen}
                    contentClasses={{ root: classes.modalContent }}
                >
                    {getLoader()}
                </InfoCubeDialog>
            )}
        </Box>
    );
};
