import { ClickAwayListener, Fade, IconButton, Link, MenuItem, Paper, Popper, Tooltip } from "@mui/material";
import { Help } from "@mui/icons-material";
import React, { FC, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { LanguageWithWildcard, useStaticPageMenuQuery } from "../../generated/consumer-graph-types";
import { getCurrentLng } from "../../providers/ui-language-provider";
import { useHelpTopBarStyle } from "./help-top-bar-styles";
import { useTranslation } from "react-i18next";

export const HelpTopBar: FC<any> = () => {
    const { t } = useTranslation();
    const classes = useHelpTopBarStyle();
    const currentLanguage = getCurrentLng();
    const [anchorEl, setAnchorEl] = useState<any>();
    const [isHelpMenuOpen, setIsHelpMenuOpen] = useState<boolean>(false);
    const { data, error } = useStaticPageMenuQuery({
        variables: {
            languages: [currentLanguage as LanguageWithWildcard, LanguageWithWildcard.en],
        },
    });

    const render = (data: any) => {
        return (
            <>
                <Tooltip arrow title={<>{t("Information")}</>}>
                    <IconButton
                        aria-label="help"
                        color="inherit"
                        onClick={(event: any) => {
                            setAnchorEl(event.target);
                            setIsHelpMenuOpen(true);
                        }}
                        size="large"
                    >
                        <Help data-icon="help" />
                    </IconButton>
                </Tooltip>
                <Popper
                    open={isHelpMenuOpen}
                    placement={"bottom"}
                    anchorEl={anchorEl}
                    transition
                    className={classes.popper}
                >
                    {({ TransitionProps }) => (
                        <ClickAwayListener
                            onClickAway={() => {
                                setIsHelpMenuOpen(false);
                            }}
                        >
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper elevation={3}>
                                    {data.staticPageMenu?.pages?.map((page: any, index: number) => {
                                        return (
                                            <MenuItem key={page?.id || index}>
                                                <Link
                                                    component={RouterLink}
                                                    to={`/static-pages/${page?.pageType}`}
                                                    color="inherit"
                                                    underline="none"
                                                    onClick={() => {
                                                        setIsHelpMenuOpen(false);
                                                    }}
                                                >
                                                    {page?.teasers?.title}
                                                </Link>
                                            </MenuItem>
                                        );
                                    })}
                                </Paper>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            </>
        );
    };

    if (data?.staticPageMenu?.pages?.length) {
        return render(data);
    }

    if (error) console.error(error);

    return null;
};
