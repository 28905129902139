import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useContentStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexGrow: 1,
            height: "100%",
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(1),
            },
        },
        split: {
            display: "flex",
            flexDirection: "row",
            "& .gutter": {
                backgroundColor: "#eee",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50%",
            },
            "& .gutter.gutter-horizontal": {
                backgroundImage:
                    "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')",
                cursor: "col-resize",
            },
        },
        toc: {
            maxWidth: 400,
            marginRight: theme.spacing(2),
        },
        popper: {
            zIndex: 100000,
            minWidth: 180,
        },
        item: {
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 16,
        },
        rightPanel: {
            overflow: "hidden !important",
        },
        tocDrawer: {
            width: 350,
            [theme.breakpoints.up("lg")]: {
                marginTop: 64,
                height: "calc(100% - 64px)",
            },
        },
        contentContainerMobile: {
            height: "calc(100% - 58px)",
            [theme.breakpoints.down("md")]: {
                height: "calc(100% - 37px)",
            },
        },
        iframeContainer: {
            height: "calc(100% - 72px)",
        },
        iframeContainerMobile: {
            height: "calc(100% - 56px)",
        },
    })
);
