import { ContentMap, TocMapEntry } from "../generated/consumer-graph-types";
import { TocNode } from "../portal/content/content";

let contentMap: ContentMap | undefined = undefined;
let contentId: string | undefined = "";
let topicId: string | undefined = "";
let tocNodeTree: TocNode[] = [];

const createNodeFromPaths = (paths: string[], uniqueId: string, title: string, url: string, tree: TocNode[]): void => {
    const id = paths.shift() || "";
    const idx = tree.findIndex((e: TocNode) => {
        return e.id === id;
    });

    if (idx < 0) {
        tree.push({
            id,
            uniqueId,
            children: [],
            url: url,
            title,
        });

        if (paths.length !== 0) {
            createNodeFromPaths(paths, uniqueId, title, url, tree[tree.length - 1].children);
        }
    } else {
        createNodeFromPaths(paths, uniqueId, title, url, tree[idx].children);
    }
};

const transformToTreeNode = (tocMapEntries: TocMapEntry[]): TocNode[] => {
    const tree: TocNode[] = [];
    for (let tocMapEntry of tocMapEntries) {
        let path: string = tocMapEntry.path;
        const contentId = tocMapEntry.node?.contentId || "";
        if (path === "~") path = contentId;
        else path = `${tocMapEntry.path.substr(2)}/${contentId}`;

        const split: string[] = path.split("/");
        const title = tocMapEntry.node?.teasers?.title || contentId;

        // @ts-ignore
        const url = tocMapEntry?.node?.url;
        const uniqueId = tocMapEntry?.node?.id || "";
        createNodeFromPaths(split, uniqueId, title, url, tree);
    }
    return tree;
};

export const getTocNodePath = (node: TocNode, uniqueId: string, path: TocNode[]) => {
    if (node.uniqueId === uniqueId) {
        path.push(node);
    } else if (node.children.length) {
        path.push(node);
        node.children.forEach((val) => {
            getTocNodePath(val, uniqueId, path);
        });
        if (path[path.length - 1].uniqueId === node.uniqueId) path.pop();
    }
};

export const setCurrentContent = (
    id: string,
    newContentMap: ContentMap | undefined = undefined,
    newTopicId: string | undefined = undefined
) => {
    contentId = id;
    contentMap = newContentMap;
    topicId = newTopicId;
    const tocMapEntries = newContentMap?.tocMap as TocMapEntry[];
    if (tocMapEntries) tocNodeTree = transformToTreeNode(tocMapEntries);
};

export const setCurrentTopicId = (newTopicId: string) => {
    topicId = newTopicId;
};

export const getCurrentContent = () => {
    return {
        contentId,
        contentMap,
        topicId,
        tocNodeTree,
    };
};
