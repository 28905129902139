import { Box } from "@mui/material";
import equal from "fast-deep-equal";
import React, { FC, useEffect, useRef, useState } from "react";
import { Dictionary } from "ts-essentials";
import { consumerClient } from "../../apollo-clients/consumer-client";
import { FacetsNavigation } from "../../components/facets-navigation";
import { GraphLoader } from "../../components/graph-loader";
import { Facet } from "../../generated/consumer-graph-types";
import {
    generateDynamicSearchContentsOnlyAggregationsQuery,
    getContentsAggregationsQueryVariables,
} from "../../shared-queries/do-not-parse";

type ExploreFacetNavigationProps = {
    fieldDefinitions: any;
    selectedNavigationFacets: Dictionary<string[]>;
    facetsCollection: Facet[];
    contentsData: any;
    contentsQueryPropsGetter: () => any;
    onSelect: (selectedFacets: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
    onWidthChange: (width: number) => void;
    top: number;
};

export const ExploreFacetNavigationDesktop: FC<ExploreFacetNavigationProps> = ({
    fieldDefinitions,
    selectedNavigationFacets,
    facetsCollection,
    contentsData,
    contentsQueryPropsGetter,
    onSelect,
    onWidthChange,
    top,
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>();
    const [aggregations, setAggregations] = useState<any>();
    const elWidth = useRef();
    const elRef = useRef();

    useEffect(() => {
        if (fieldDefinitions?.length) {
            const enrichedMetaDataDefinitions = fieldDefinitions.map((m: any) => {
                return { type: "metadata", ...m };
            });

            const queryAggregationsProps = Object.assign({}, contentsQueryPropsGetter(), {
                ignoreNavigationFacets: true,
            });
            const aggregationsQuery = generateDynamicSearchContentsOnlyAggregationsQuery(enrichedMetaDataDefinitions);

            consumerClient()
                .query({
                    query: aggregationsQuery,
                    variables: getContentsAggregationsQueryVariables(queryAggregationsProps),
                    fetchPolicy: "no-cache",
                })
                .then((result) => {
                    if (result.error) {
                        setError(error);
                        return;
                    }
                    const newAggregations: Dictionary<number> = {};
                    if (result.data.contents?.aggregations?.useContext?.composite) {
                        for (const key in result.data.contents?.aggregations?.useContext.composite) {
                            if (key === "__typename") continue;

                            const value: any = result.data.contents?.aggregations?.useContext.composite[key];

                            if (Array.isArray(value)) {
                                for (const entry of value) {
                                    newAggregations[entry.value] = entry.count;
                                }
                            }
                        }
                    }

                    if (!equal(aggregations, newAggregations)) {
                        setAggregations(newAggregations);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldDefinitions, contentsData]);

    const render = (data: any) => {
        return (
            <Box
                sx={{
                    backgroundColor: "background.default",
                    height: "fit-content",
                    paddingRight: 2,
                    minWidth: "320px",
                    maxWidth: "320px",
                    position: "absolute",
                    top: top,
                    marginTop: "-5px",
                }}
                ref={(el: any) => {
                    if (!el) return;

                    elRef.current = el;
                    //polling detects element width
                    setInterval(() => {
                        if (elRef.current && !elWidth.current) {
                            //@ts-ignore
                            const width = elRef.current?.getBoundingClientRect().width;
                            elWidth.current = width;
                            onWidthChange(width);
                        }
                    }, 100);
                }}
            >
                <FacetsNavigation
                    aggregations={data}
                    fieldDefinitions={fieldDefinitions}
                    onSelect={onSelect}
                    selectedFacets={selectedNavigationFacets}
                    facetsCollection={facetsCollection}
                />
            </Box>
        );
    };

    return <GraphLoader loading={loading} error={error} data={aggregations} render={render} />;
};
