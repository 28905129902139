import { gql } from "@apollo/client";
import { Add, AddCircleOutlined, DescriptionOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableCellProps } from "react-virtualized";
import { GraphLoader } from "../../components/graph-loader";
import { InfoCubeDialog } from "../../components/info-cube-dialog";
import { ColumnData, InfoCubeTable } from "../../components/info-cube-table";
import {
    CollectionQueryResult,
    CollectionSortFields,
    CountRelation,
    SortOrder,
    useAddContentsToCollectionMutation,
    useEditableCollectionsQuery,
} from "../../generated/consumer-graph-types";
import { AddToNewCollectionDialog } from "./add-to-new-collection-dialog";
import { useCollectionStyle } from "./collection-style";
import { CollectionVisibilityType } from "./collection-visibility-type";
import { useInfoCubeMediaQuery } from "../../components/info-cube-media-query";

type AddToCollectionDialogProps = {
    open: boolean;
    onClose: () => {};
    contentId: string;
    contentTitle: string;
    currentCollectionId?: string;
};

interface EditableCollectionsData {
    editableCollections: CollectionQueryResult;
}

gql`
    query editableCollections(
        $first: NonNegativeInt
        $after: String
        $sortId: CollectionSortFields!
        $sortOrder: SortOrder
    ) {
        editableCollections {
            total {
                count
                countRelation
            }
            collections(after: $after, first: $first, sort: { field: $sortId, order: $sortOrder }) {
                cursor
                node {
                    id
                    displayName
                    description
                    visibility
                }
            }
        }
    }
`;

gql`
    mutation addContentsToCollection($collectionId: ID!, $idsOfContents: [ID!]!) {
        addContentsToCollection(collectionId: $collectionId, idsOfContents: $idsOfContents)
    }
`;

export const AddToCollectionDialog: FC<AddToCollectionDialogProps> = ({
    open,
    contentId,
    onClose,
    contentTitle,
    currentCollectionId,
}) => {
    const isMobile = useInfoCubeMediaQuery();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isNewCollectionDialogOpen, setIsNewCollectionDialogOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useCollectionStyle();
    const rowsPerPage = 20;
    const [sortByField, setSortByField] = useState<CollectionSortFields>(CollectionSortFields.displayName);
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.asc);

    const { data, fetchMore, refetch, loading, error } = useEditableCollectionsQuery({
        variables: {
            first: rowsPerPage,
            sortId: sortByField,
            sortOrder: sortOrder,
        },
        fetchPolicy: "network-only",
        skip: !isOpen,
    });
    const [addContentsToCollectionMutation] = useAddContentsToCollectionMutation();

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const loadMore = async ({ startIndex }: { startIndex: number }) => {
        if (
            data?.editableCollections?.total.countRelation === CountRelation.eq &&
            data?.editableCollections?.total.count <= startIndex
        ) {
            return;
        }

        let after = "";
        if (data?.editableCollections?.collections) {
            let editableCollection =
                data.editableCollections?.collections[data.editableCollections?.collections.length - 1];
            if (editableCollection) after = editableCollection.cursor;
        }

        await fetchMore({
            variables: {
                first: rowsPerPage,
                after: after,
                sortId: sortByField,
                sortOrder: sortOrder,
            },
        });
    };

    const cellRenderer = ({ dataKey, cellData }: TableCellProps) => {
        if (dataKey === "add")
            return (
                <Box display="flex" flexGrow={1} justifyContent="flex-end" data-testid={`editable-${dataKey}`}>
                    {cellData}
                </Box>
            );

        return (
            <Box component="span" data-testid={`editable-${dataKey}`} pl={2}>
                {cellData}
            </Box>
        );
    };

    const sort = async ({ sortBy, sortOrder }: { sortBy: string; sortOrder: SortOrder }) => {
        setSortByField(sortBy as CollectionSortFields);
        setSortOrder(sortOrder);

        await refetch({
            first: rowsPerPage,
            sortId: sortBy as CollectionSortFields,
            sortOrder: sortOrder,
        });
    };

    const columnsDesktop: ColumnData[] = [
        {
            label: "",
            dataKey: "visibility",
            render: (data: any) => {
                return <CollectionVisibilityType visibilityType={data.node.visibility} />;
            },
            widthRatio: 0.1,
        },
        {
            label: t("Title"),
            dataKey: "displayName",
            dataKeyPath: "node.displayName",
            widthRatio: 0.4,
        },
        {
            label: t("Description"),
            dataKey: "description",
            dataKeyPath: "node.description",
            widthRatio: 0.4,
            disableSort: true,
        },
        {
            label: t("Add"),
            dataKey: "add",
            render: (data: any) => {
                return (
                    <IconButton
                        data-testid={`add-to-collection-${data.node.id}`}
                        aria-label="add"
                        onClick={async () => {
                            await addContentToCollection(data.node.id);
                            onClose();
                        }}
                        size="large"
                    >
                        <AddCircleOutlined color="primary" />
                    </IconButton>
                );
            },
            widthRatio: 0.1,
            disableSort: true,
        },
    ];

    const columnsMobile: ColumnData[] = [
        {
            label: "",
            dataKey: "visibility",
            render: (data: any) => {
                return <CollectionVisibilityType visibilityType={data.node.visibility} />;
            },
            widthRatio: 0.2,
        },
        {
            label: t("Title"),
            dataKey: "displayName",
            dataKeyPath: "node.displayName",
            widthRatio: 0.6,
        },
        {
            label: t("Add"),
            dataKey: "add",
            render: (data: any) => {
                return (
                    <IconButton
                        data-testid={`add-to-collection-${data.node.id}`}
                        aria-label="add"
                        onClick={async () => {
                            await addContentToCollection(data.node.id);
                            onClose();
                        }}
                        size="large"
                    >
                        <AddCircleOutlined color="primary" />
                    </IconButton>
                );
            },
            widthRatio: 0.2,
            disableSort: true,
        },
    ];

    const addContentToCollection = async (collectionId: string) => {
        const { errors } = await addContentsToCollectionMutation({
            variables: { collectionId, idsOfContents: [contentId] },
            refetchQueries: ["collections"],
            awaitRefetchQueries: true,
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(t("Content couldn't be added to collection"), {
                variant: "error",
                autoHideDuration: null,
            });
        }

        enqueueSnackbar(t("Content successfully added to collection"), {
            variant: "success",
        });
    };

    const addAfterCreate = async (collectionId: string) => {
        await addContentToCollection(collectionId);
        setIsNewCollectionDialogOpen(false);
        onClose();
    };

    const renderMobile = (data: EditableCollectionsData) => {
        let collections = data.editableCollections?.collections || [];
        if (currentCollectionId) collections = collections.filter((val) => val?.node?.id !== currentCollectionId);

        return (
            <>
                <Box display="flex" alignItems="center" mb={2}>
                    <DescriptionOutlined color="disabled" />
                    <Typography
                        variant="caption"
                        sx={{ color: "palette.text.disabled", marginLeft: 1, marginTop: 0.5 }}
                    >
                        {contentTitle}
                    </Typography>
                </Box>
                <Box display="flex" flexGrow={1} height="92%">
                    <InfoCubeTable
                        sort={sort}
                        sortBy={sortByField}
                        sortOrder={sortOrder}
                        data={collections}
                        loadMore={loadMore}
                        columns={isMobile ? columnsMobile : columnsDesktop}
                        cellRenderer={cellRenderer}
                    />
                </Box>
            </>
        );
    };

    const renderDesktop = (data: EditableCollectionsData) => {
        let collections = data.editableCollections?.collections || [];
        if (currentCollectionId) collections = collections.filter((val) => val?.node?.id !== currentCollectionId);

        return (
            <>
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Button startIcon={<Add />} onClick={() => setIsNewCollectionDialogOpen(true)} variant="contained">
                        {t("New Collection")}
                    </Button>
                </Box>
                <InfoCubeTable
                    classes={{ paper: classes.paper }}
                    sort={sort}
                    sortBy={sortByField}
                    sortOrder={sortOrder}
                    data={collections}
                    loadMore={loadMore}
                    columns={columnsDesktop}
                    cellRenderer={cellRenderer}
                />
                <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button variant="outlined" onClick={onClose}>
                        {t("Close")}
                    </Button>
                </Box>
                <AddToNewCollectionDialog
                    open={isNewCollectionDialogOpen}
                    afterCreate={addAfterCreate}
                    title={t("Add to new collection")}
                    onClose={() => setIsNewCollectionDialogOpen(false)}
                />
            </>
        );
    };

    return (
        <InfoCubeDialog
            maxWidth="lg"
            open={isOpen}
            title={t("Add to collection")}
            onClose={onClose}
            classes={{ paper: classes.dialogPaper }}
        >
            <GraphLoader render={isMobile ? renderMobile : renderDesktop} data={data} loading={loading} error={error} />
        </InfoCubeDialog>
    );
};
