import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useButtonWithProgressStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: "relative",
        },
        progress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
    })
);
