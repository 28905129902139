import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: any;
  EmailAddress: any;
  HexColorCode: any;
  JSON: any;
  JSONObject: any;
  Long: any;
  NegativeFloat: any;
  NegativeInt: any;
  NoDynamicValueDefined: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  PhoneNumber: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  URL: any;
  UnsignedFloat: any;
  UnsignedInt: any;
};






export type Authenticator = {
  __typename?: 'Authenticator';
  /** Readable name for the authenticator */
  displayName: Scalars['String'];
  /** The description for the authenticator */
  description?: Maybe<Scalars['String']>;
  /** Image url for display purpose */
  imageUrl?: Maybe<Scalars['String']>;
  /** Indicates the default authenticator */
  default?: Maybe<Scalars['Boolean']>;
  /** The strategy used for the authenticator */
  strategyName: Scalars['String'];
  /** The url of the authenticator */
  url: Scalars['String'];
};

export type AuthenticatorEdge = {
  __typename?: 'AuthenticatorEdge';
  /** The result's authenticator */
  node?: Maybe<Authenticator>;
};

export type AuthenticatorQueryResult = {
  __typename?: 'AuthenticatorQueryResult';
  /**
   * The authenticators of the tenant.
   *
   * Currently there's no functionality to filter them nor using pagination.
   *
   * But for later compatibility and the ability to add paging or filtering, they are
   * returned in the appropriate format.
   *
   * The field will always contain any authenticator results which exist on the tenant.
   *
   * This might change in the future.
   */
  authenticators?: Maybe<Array<Maybe<AuthenticatorEdge>>>;
  /** Returns the total count of results. */
  total: TotalCount;
};


/** Reference to a binary blob */
export type BlobRef = {
  __typename?: 'BlobRef';
  /** The referenced blob's unique, content hashed ID. */
  blobId: Scalars['ID'];
  /** The referenced blob's mime type. May be empty if no mime type coud be detected. */
  mimeType?: Maybe<Scalars['String']>;
  /** The path and file name under which the blob is referenced in this context. */
  path?: Maybe<Scalars['String']>;
  /** Download URL for the blob's binary data */
  url?: Maybe<Scalars['URL']>;
};

/** Spec for a reference to a binary blob */
export type BlobRefInput = {
  /** The referenced blob's unique, content hashed ID. */
  blobId: Scalars['ID'];
  /** The referenced blob's mime type. */
  mimeType?: Maybe<Scalars['String']>;
  /** The path and file name under which the blob is referenced in this context. */
  path: Scalars['String'];
};

export type ComponentInfo = {
  __typename?: 'ComponentInfo';
  /** The component's name */
  name: Scalars['String'];
  /** The component's version */
  version: VersionInfo;
  /** Information about the component's sub components */
  components?: Maybe<Array<ComponentInfo>>;
};

export enum CountRelation {
  /** The count is accurate and equals the actual count of hits */
  eq = 'eq',
  /** The result is inaccurate and only a lower bound of the actual possible hits */
  gte = 'gte',
  /** The result is inaccurate and only a upper bound of the actual possible hits */
  lte = 'lte'
}






export enum Language {
  ar = 'ar',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  hu = 'hu',
  it = 'it',
  ja = 'ja',
  nb = 'nb',
  nl = 'nl',
  pt = 'pt',
  ro = 'ro',
  ru = 'ru',
  sv = 'sv',
  tr = 'tr',
  zh = 'zh'
}

export enum LanguageWithWildcard {
  ar = 'ar',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  hu = 'hu',
  it = 'it',
  ja = 'ja',
  nb = 'nb',
  nl = 'nl',
  pt = 'pt',
  ro = 'ro',
  ru = 'ru',
  sv = 'sv',
  tr = 'tr',
  zh = 'zh',
  any = 'any'
}

export type LanguagesQueryResult = {
  __typename?: 'LanguagesQueryResult';
  /** The tenant´s default language */
  default?: Maybe<Scalars['String']>;
  /** Available languages for the tenant */
  available?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A string in a single or multiple of the available languages */
export type MultilingualString = {
  __typename?: 'MultilingualString';
  ar?: Maybe<Scalars['String']>;
  da?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  el?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fi?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  nb?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
  ro?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
  tr?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
};

/** A string in a single or multiple of the available languages */
export type MultilingualStringInput = {
  ar?: Maybe<Scalars['String']>;
  da?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  el?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fi?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  nb?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
  ro?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
  tr?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Connection test. Responds with the provided `tagLine` */
  ping?: Maybe<Scalars['String']>;
};


export type MutationPingArgs = {
  tagLine?: Maybe<Scalars['String']>;
};












export type Query = {
  __typename?: 'Query';
  _blobRefsByBlobIds?: Maybe<Array<BlobRef>>;
  /**
   * Internal for graph composition.
   * Returns the graph's full SDL including information not available via introspection.
   */
  _sdl?: Maybe<Scalars['String']>;
  /** Returns details about the application providing this API */
  api?: Maybe<ComponentInfo>;
  /** Returns the current tenant. */
  tenant: Tenant;
  /**
   * Queries the theme configuration.
   * If there's no custom theme defined, it will return the default theme.
   */
  theme?: Maybe<Theme>;
  /**
   * Returns the server version.
   * Deprecated. Use query `api { version }` instead
   * @deprecated Field no longer supported
   */
  version?: Maybe<VersionInfo>;
};


export type Query_BlobRefsByBlobIdsArgs = {
  blobIds: Array<Scalars['ID']>;
};



/** A single tenant in Exegol */
export type Tenant = {
  __typename?: 'Tenant';
  /** The tenant's unique identifier */
  id: Scalars['ID'];
  /** The tenant's provider specific identifier */
  displayName: Scalars['String'];
  /** The domain name under which the tenant's content delivery services are provided */
  domain: Scalars['String'];
  /** The tenant authentication config */
  auth?: Maybe<TenantAuthConfig>;
  /** The tenant's language configuration */
  languages?: Maybe<LanguagesQueryResult>;
};

export enum TenantAccessMode {
  /** Only anonymous users may access the api */
  anonymous = 'anonymous',
  /** Both anonymous and users authenticated via one of the configured authenticators may access the api */
  mixed = 'mixed',
  /** Only users authenticated via one of the configured authenticators can access the api */
  authenticated = 'authenticated'
}

export type TenantAuthConfig = {
  __typename?: 'TenantAuthConfig';
  /** The access mode for the tenant, which indicates which types of users should be able to authenticate */
  accessMode: TenantAccessMode;
  /** The available authenticators */
  authenticators?: Maybe<AuthenticatorQueryResult>;
};

export type Theme = {
  __typename?: 'Theme';
  /** The title of the portal */
  title: MultilingualString;
  /** Indicates if the title should be visible */
  showTitle: Scalars['Boolean'];
  /** Theming JSONObject */
  theming?: Maybe<Scalars['JSONObject']>;
  /** The logo of the theme */
  logo?: Maybe<BlobRef>;
  /** The startpage logo of the theme */
  startPageLogo?: Maybe<BlobRef>;
  /** Length limited text from the theme for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<ThemeTeasers>;
};

export type ThemeTeasers = {
  __typename?: 'ThemeTeasers';
  /**
   * The theme's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   * If no value is found for any of the accepted languages, `title` uses the English (`en`) value
   */
  title?: Maybe<Scalars['String']>;
};


export type ThemeTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<Maybe<LanguageWithWildcard>>>;
};

export type TotalCount = {
  __typename?: 'TotalCount';
  /**
   * The total count of results.
   *
   * See `CountRelation` for further information on how to interpret the count.
   */
  count: Scalars['NonNegativeInt'];
  /**
   * Indicator on how the count should be interpreted.
   *
   * The count may not be accurate so the relation gives information whether the real
   * actual count deviates from the provided count or is equal to.
   *
   * See `CountRelation` for further information about the possible deviations.
   */
  countRelation?: Maybe<CountRelation>;
};




export type UploadConfig = {
  __typename?: 'UploadConfig';
  /** The limits for the files to upload */
  limits: UploadLimits;
  /** The allowed mime types */
  allowedMimeTypes: Array<Maybe<Scalars['String']>>;
  /** Are all mime types allowed */
  allowAllMimeTypes?: Maybe<Scalars['Boolean']>;
  /** The allowed file extensions */
  allowedFileExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadLimits = {
  __typename?: 'UploadLimits';
  /** The maximal allowed size in bytes per file */
  maxFileSize?: Maybe<Scalars['NonNegativeInt']>;
};

export type UploadMultipleConfig = {
  __typename?: 'UploadMultipleConfig';
  /** The limits for the files to upload */
  limits: UploadMultipleLimits;
  /** The allowed mime types */
  allowedMimeTypes: Array<Maybe<Scalars['String']>>;
  /** Are all mime types allowed */
  allowAllMimeTypes?: Maybe<Scalars['Boolean']>;
  /** The allowed file extensions */
  allowedFileExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadMultipleLimits = {
  __typename?: 'UploadMultipleLimits';
  /** The maximal allowed size in bytes per file */
  maxFileSize?: Maybe<Scalars['NonNegativeInt']>;
  /** The maximal number of files per upload request */
  maxFilesPerUpload?: Maybe<Scalars['NonNegativeInt']>;
};

export type VersionInfo = {
  __typename?: 'VersionInfo';
  /** Full version info as a semver string. E.g '1.15.8' */
  versionString?: Maybe<Scalars['String']>;
  /** Major version */
  major?: Maybe<Scalars['Int']>;
  /** Minor version */
  minor?: Maybe<Scalars['Int']>;
  /** Patch level */
  patch?: Maybe<Scalars['Int']>;
};
