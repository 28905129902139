import { createTheme } from "@mui/material/styles";
import { publicClient } from "../apollo-clients/public-client";
import { Theme } from "../generated/consumer-graph-types";
import { getCurrentLng } from "../providers/ui-language-provider";
import { THEME_QUERY } from "../shared-queries";

export const getServerTheme = async () => {
    const currentLanguage = getCurrentLng();
    const { data } = await publicClient.query({
        query: THEME_QUERY([currentLanguage]),
    });
    return data.theme;
};

export const applyTheme = async (
    setTheme: (value: ((prevState: any) => any) | any) => void,
    setThemeHasLoaded: (value: ((prevState: boolean) => boolean) | boolean) => void,
    setLogoId: (value: ((prevState: string) => string) | string) => void,
    setTitle: (value: ((prevState: string) => string) | string) => void,
    setShowTitle: (value: ((prevState: boolean) => boolean) | boolean) => void,
    displayName: string
) => {
    const theme = await getServerTheme();
    const initTheme: Theme = JSON.parse(JSON.stringify(theme));
    initTheme.theming = createTheme(theme.theming);
    setTheme(initTheme);
    setLogoId(initTheme?.logo?.blobId || "");
    setShowTitle(initTheme?.showTitle);
    const title = initTheme?.teasers?.title || displayName;
    setTitle(title);
    document.title = title;
    setThemeHasLoaded(true);
};
