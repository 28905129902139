import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCurrentContent, getTocNodePath } from "../../providers/content-provider";
import { Toc } from "../toc";
import { TocNode } from "./content";

type ContentTocProps = {
    uniqueId: string;
};

export const ContentToc: FC<ContentTocProps> = ({ uniqueId }) => {
    const history = useHistory();
    const [contentMapId, setContentMapId] = useState<string>("");
    const [tocNode, setTocNode] = useState<TocNode>();
    const [selected, setSelected] = useState<string>("");
    const [expanded, setExpanded] = useState<string[]>([]);

    useEffect(() => {
        const { contentId } = getCurrentContent();

        if (contentId) {
            setContentMapId(contentId);
            const { tocNodeTree } = getCurrentContent();

            let path: TocNode[] = [tocNodeTree[0]];
            tocNodeTree[0].children.forEach((val) => getTocNodePath(val, uniqueId, path));

            setTocNode(tocNodeTree[0]);

            const ids = path.map((val) => val.id);
            if (ids.length) {
                setExpanded(ids.length === 1 ? ids : ids.slice(0, ids.length - 1));
                setSelected(ids[ids.length - 1]);
            } else {
                setExpanded([tocNodeTree[0].id]);
                setSelected(tocNodeTree[0].id);
            }
        }
    }, [uniqueId]);

    const navigate = (id: string) => {
        if (contentMapId !== id) history.push(`/content/${contentMapId}/${id}`);
    };

    if (!contentMapId || !tocNode) return null;

    return <Toc selected={selected} expanded={expanded} tocNode={tocNode} navigate={navigate} />;
};
