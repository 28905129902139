import { Replay } from "@mui/icons-material";
import { Box, Checkbox, Divider, FormControlLabel, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dictionary } from "ts-essentials";
import { Facet, TotalCount } from "../generated/consumer-graph-types";
import { deepCopy, TotalCountFormatter } from "../utils";
import { useFacetPopoverStyles } from "./facet-popover-style";

type FacetMultiSelectProps = {
    facet: Facet;
    facetValues: any[];
    onSelect: (selected: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
    selected: Dictionary<string[]>;
    filterValue: string;
    toggleSelectAll: (selectAllChecked: boolean) => void;
    contentsTotal?: TotalCount;
    aggregations?: Dictionary<number>;
};

export const FacetMultiSelect: FC<FacetMultiSelectProps> = ({
    facet,
    facetValues,
    onSelect,
    selected,
    filterValue,
    toggleSelectAll,
    contentsTotal,
    aggregations,
}) => {
    const classes = useFacetPopoverStyles();
    const { t } = useTranslation();
    const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
    const [showReset, setShowReset] = useState<boolean>(false);

    useEffect(() => {
        const filterSelectedCount = selected[facet.referencedId]?.length ?? 0;
        let isAllChecked = facetValues.length > 0 && facetValues.length === filterSelectedCount;
        if (aggregations) {
            isAllChecked =
                filterSelectedCount ===
                Object.keys(aggregations).filter((val) => {
                    return aggregations[val] > 0;
                }).length;
        }
        setSelectAllChecked(isAllChecked);

        const resetVisible = !isAllChecked && filterSelectedCount > 0;
        setShowReset(resetVisible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facetValues, selected, aggregations]);

    const isSelected = (val: any) => {
        return !!(selected[facet.referencedId] && selected[facet.referencedId].includes(val.key));
    };

    const toggleFacetValue = (facet: Facet, value: any) => {
        let newSelected = deepCopy(selected);
        const isSelected = newSelected[facet.referencedId] && newSelected[facet.referencedId].includes(value.key);
        if (isSelected) {
            //deselect facet
            if (newSelected[facet.referencedId].length === 1) {
                delete newSelected[facet.referencedId];
            } else {
                newSelected[facet.referencedId] = newSelected[facet.referencedId].filter(
                    (x: string) => x !== value.key
                );
            }
        } else {
            if (newSelected[facet.referencedId]) newSelected[facet.referencedId].push(value.key);
            else newSelected[facet.referencedId] = [value.key];
        }

        onSelect(newSelected, isSelected ? [facet.referencedId] : []);
        const filterSelected = newSelected[facet.referencedId]?.length ?? 0;
        const isAllChecked = facetValues.length === filterSelected;
        setSelectAllChecked(isAllChecked);
        const resetVisible = !isAllChecked && filterSelected > 0;
        setShowReset(resetVisible);
    };

    const getLabel = (val: any) => {
        let label = val.teasers?.title || "";
        if (aggregations) {
            label = `${label} (${aggregations[val.key] ?? 0})`;
        }

        return label;
    };

    return (
        <>
            {!filterValue.length && (!aggregations || Object.keys(aggregations).length > 0) && (
                <>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Checkbox
                                title={selectAllChecked ? t("Deselect all") : t("Select all")}
                                className={classes.allCheckbox}
                                color="primary"
                                checked={selectAllChecked}
                                onChange={() => {
                                    toggleSelectAll(selectAllChecked);
                                    setSelectAllChecked(!selectAllChecked);
                                    setShowReset(false);
                                }}
                            />
                            <span>{t("All")}</span>
                        </Box>
                        {showReset && (
                            <Box ml={2} mr={1}>
                                <IconButton
                                    size={"small"}
                                    onClick={() => {
                                        toggleSelectAll(true);
                                        setShowReset(false);
                                    }}
                                >
                                    <Tooltip arrow title={<>{t("Reset")}</>}>
                                        <Replay />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                </>
            )}
            <Divider />
            <Box overflow={"auto"}>
                {facetValues
                    .filter((val: any) => {
                        const isDisabled = aggregations
                            ? !!(aggregations[val.key] === undefined || aggregations[val.key] === 0)
                            : false;
                        return !isDisabled;
                    })
                    .map((val: any, index: number) => {
                        return (
                            <MenuItem key={val.key} className={classes.menuItem}>
                                <FormControlLabel
                                    classes={{ root: classes.formControlLabel }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={isSelected(val)}
                                            onChange={() => {
                                                toggleFacetValue(facet, val);
                                            }}
                                        />
                                    }
                                    label={getLabel(val)}
                                    labelPlacement="end"
                                />
                            </MenuItem>
                        );
                    })}
            </Box>
            {contentsTotal && (
                <>
                    <Divider />
                    <Box m={1}>
                        <Typography variant={"caption"}>
                            {TotalCountFormatter(contentsTotal, t("{{count}} Results", { count: contentsTotal.count }))}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    );
};
