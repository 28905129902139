import { URL_PARAM_SEARCH } from "../portal/url-params";

export const getSearchMetadataURLParams = (location: any, searchValue: string, searchMeta?: string[]) => {
    const urlParams = new URLSearchParams(location.search);
    //remove all = 1 params
    const paramsToRemove: string[] = [];
    urlParams.forEach((value, key) => {
        if (value === "1" && key !== URL_PARAM_SEARCH) {
            paramsToRemove.push(key);
        }
    });
    for (const paramToRemove of paramsToRemove) {
        urlParams.delete(paramToRemove);
    }

    if (searchValue) {
        urlParams.set(URL_PARAM_SEARCH, searchValue);
    } else {
        urlParams.delete(URL_PARAM_SEARCH);
    }

    if (searchMeta) {
        for (const field of searchMeta) {
            urlParams.set(field, "1");
        }
    }

    return urlParams;
};
