import { Box, Button, Drawer, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import { Action, Resource } from "../enums";
import { RenderIfHasAccess } from "./render-if-has-access";
import { useSideSheetCollapsableStyles } from "./side-sheet-collapsable-styles";

export type SideSheetCollapsableItem = {
    id: string;
    startIcon: ReactNode;
    contentTitle: string;
    onClick: () => void;
    disabled?: boolean;
    endIcon?: ReactNode;
    access?: {
        action: Action;
        resource: Resource;
    };
};

export type sideSheetClasses = {
    drawerOpen: string;
    drawerCollapsed: string;
};

export type SideSheetCollapsableProps = {
    items: SideSheetCollapsableItem[];
    open?: boolean;
    collapsed?: boolean;
    classes: sideSheetClasses;
    onClose: () => void;
    anchor?: "left" | "right";
};

export const SideSheetCollapsable: FC<SideSheetCollapsableProps> = ({
    items,
    open,
    collapsed,
    classes,
    onClose,
    anchor,
}) => {
    const sideSheetClasses = useSideSheetCollapsableStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });

    const root = !open
        ? sideSheetClasses.drawerHidden
        : !isDesktop || !collapsed
        ? classes.drawerOpen
        : classes.drawerCollapsed;

    const getButton = (item: SideSheetCollapsableItem) => {
        return collapsed && isDesktop ? (
            <Tooltip
                arrow
                title={<Box style={{ textTransform: "uppercase" }}>{item.contentTitle}</Box>}
                placement="right"
            >
                <span>
                    <IconButton
                        name={item.id}
                        className={sideSheetClasses.iconButton}
                        onClick={() => {
                            item.onClick();
                        }}
                        disabled={item.disabled}
                        size="large"
                    >
                        {item.startIcon}
                    </IconButton>
                </span>
            </Tooltip>
        ) : (
            <Button
                name={item.id}
                size={"large"}
                className={sideSheetClasses.button}
                onClick={item.onClick}
                disabled={item.disabled}
                startIcon={item.startIcon}
                endIcon={item.endIcon}
            >
                {(!collapsed || !isDesktop) && item.contentTitle}
            </Button>
        );
    };

    return (
        <Drawer
            variant={isDesktop ? "persistent" : "temporary"}
            anchor={anchor ?? "left"}
            onClose={onClose}
            open={open}
            classes={{
                root: clsx(root, open ? sideSheetClasses.drawerOpen : sideSheetClasses.drawerClose),
                paper: sideSheetClasses.paper,
            }}
        >
            <Box display={"flex"} flexGrow={1}>
                <Box paddingTop={1} className={clsx(sideSheetClasses.boxIcons, collapsed ? "expanded" : "")}>
                    {items.map((item: SideSheetCollapsableItem) => {
                        return (
                            <Box margin={1} key={item.id} height={48} onClick={onClose}>
                                {item.access ? (
                                    <RenderIfHasAccess
                                        resource={item.access.resource}
                                        action={item.access.action}
                                        ignoreConditions={true}
                                    >
                                        {getButton(item)}
                                    </RenderIfHasAccess>
                                ) : (
                                    getButton(item)
                                )}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Drawer>
    );
};
