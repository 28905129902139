import { Box, Typography, Button } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as NotFoundImage } from "../img/404.svg";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { ErrorPageProps } from "./error-page";
import { useErrorPageStyles } from "./errror-page-style";

export const NotFoundPage: FC<ErrorPageProps> = ({ requestId, goBack }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useErrorPageStyles();

    const goToPreviousPage = () => {
        if (goBack) {
            goBack();
            return;
        }

        if (history) history.goBack();
        else window.location.reload();
    };

    return (
        <Box display="flex" textAlign="center" flexDirection="column" alignItems="center" sx={{ mt: { xs: 2, lg: 7 } }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                Lost in Space!
            </Typography>
            <Typography variant="h5">{t("The requested page could not be found.")}</Typography>
            <Box className={classes.imageContainer} sx={{ width: { xs: "35%", sm: "25%", lg: "15%" } }}>
                <NotFoundImage />
            </Box>
            <Box display="flex" flexDirection="column" height="20vh" justifyContent="space-between">
                <Box mt={6} mb={2}>
                    <Button
                        variant="outlined"
                        onClick={goToPreviousPage}
                        startIcon={<ArrowBack />}
                        classes={{ outlined: classes.btn }}
                    >
                        {t("Back to previous page")}
                    </Button>
                </Box>
                {requestId ? (
                    <Typography variant="body2" color="textSecondary">
                        {t(
                            "If the problem persists, please contact the administrator or the support team and provide the query ID."
                        )}
                        <br />
                        <strong>{t("Query ID:{{id}}", { id: requestId })}</strong>
                    </Typography>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        {t("If the problem persists, please contact the administrator or the support team.")}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
