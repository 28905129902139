import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ROUTE_COLLECTIONS } from "../routes";

export const CreateCollection: FC<any> = () => {
    return (
        <Box>
            <Box display={"flex"} alignItems={"center"} mb={2}>
                <IconButton aria-label="back" component={Link} to={ROUTE_COLLECTIONS} size="large">
                    <ArrowBack />
                </IconButton>
            </Box>
        </Box>
    );
};
