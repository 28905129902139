import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useSubTitleStyles = makeStyles((theme: Theme) =>
    createStyles({
        primary: {
            color: theme.palette.text.secondary,
        },
        secondary: {
            color: theme.palette.secondary.main,
        },
    })
);
