import { useQuery } from "@apollo/client";
import equal from "fast-deep-equal";
import React, { FC, useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Dictionary } from "ts-essentials";
import { ErrorPage } from "../../components/error-page";
import { ExploreData } from "../../components/explore-data";
import { GraphLoader } from "../../components/graph-loader";
import { ContentSort, ContentSortFields, DatafieldDefinition, SortOrder } from "../../generated/consumer-graph-types";
import { ContentsData, CONTENTS_QUERY, getContentsAggregationsQueryVariables } from "../../shared-queries/do-not-parse";
import { getContentPath } from "../../utils/index";
import { URL_PARAM_SEARCH } from "../url-params";
import { useExploreState, ExploreState } from "../explore/use-explore-state";

type SearchState = {
    selectedFacetsByAll: Dictionary<string[]>;
    searchPhrase: string;
    searchFields: string[];
    hitsSort: ContentSort;
};

export const Go: FC<{ data: ExploreData }> = ({ data: exploreData }) => {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>();
    const [contentsData, setContentsData] = useState<ContentsData | undefined>();
    const contents = useRef<ContentsData | undefined>();
    const [searchState, setSearchState] = useState<SearchState | undefined>();
    const DEFAULT_HITS_SORT = { field: ContentSortFields.score, order: SortOrder.desc };
    const [, setExploreState] = useExploreState();

    const { refetch: refetchContentsData } = useQuery<ContentsData>(CONTENTS_QUERY([]), {
        skip: true,
        fetchPolicy: "no-cache",
    });

    //listen if search value changes
    useEffect(() => {
        handleUrlParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    useEffect(() => {
        if (searchState) {
            refetchContents(exploreData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchState]);

    const refetchContents = (data: ExploreData) => {
        setLoading(true);
        const contentsQueryProps = {
            rowsPerPage: 1,
            searchPhrase: searchState?.searchPhrase as string,
            selectedFacetsByAll: searchState?.selectedFacetsByAll ?? {},
            selectedSearchFields: searchState?.searchFields ?? [],
            sort: DEFAULT_HITS_SORT,
            navigationMetadataDefinitions: data.navigationMetadataDefinitions as DatafieldDefinition[],
            availableTextFields: data.availableTextFields,
            availableKeywordFields: data.availableKeywordFields,
            availableNumberFields: data.availableNumberFields,
            allFacetFilters: data.facetsFilter.concat(data.facetsNavigation),
        };
        let queryProps = Object.assign({}, contentsQueryProps, {
            ignoreNavigationFacets: false,
        });
        refetchContentsData(getContentsAggregationsQueryVariables(queryProps)).then((result) => {
            if (result.error) {
                setError(error);
            } else {
                contents.current = result.data;
                setContentsData(result.data);
            }
            setLoading(false);
        });
    };

    function handleUrlParams() {
        //check url params for search value and search fields
        const urlParams = new URLSearchParams(location.search);
        const searchParam = urlParams.has(URL_PARAM_SEARCH) ? urlParams.get(URL_PARAM_SEARCH) || "" : "";
        const fields: string[] = [];
        const filterParams: Dictionary<string[]> = {};
        const navigationParams: Dictionary<string[]> = {};

        urlParams.forEach((value, key) => {
            if (key === URL_PARAM_SEARCH) return;

            let keyCopy = key;
            if (keyCopy === "metadata_f1") {
                keyCopy = "custom_f1";
            }

            if (keyCopy === "swversion") {
                keyCopy = "custom_swversion";
            }

            if (value === "1") {
                //TODO handle if facet value is 1
                //search fields
                fields.push(keyCopy);
            } else if (value !== "1") {
                //add available facet filter
                if (exploreData.facetsNavigation.some((val) => val.referencedId === keyCopy))
                    navigationParams[keyCopy] = value.split(",");
                if (exploreData.facetsFilter.some((val) => val.referencedId === keyCopy))
                    filterParams[keyCopy] = value.split(",");
            }
        });

        setExploreState((val: ExploreState) =>
            Object.assign({}, val, {
                selectedFacetsByNavigation: navigationParams,
                selectedFacetsByFilter: filterParams,
            })
        );

        const allFilterParams = Object.assign({}, navigationParams, filterParams);

        let isChanged =
            !equal(searchParam, searchState?.searchPhrase) ||
            !equal(fields, searchState?.searchFields) ||
            !equal(allFilterParams, searchState?.selectedFacetsByAll);

        if (isChanged)
            setSearchState((prevState: SearchState | undefined) => {
                let newState: SearchState | undefined;

                if (prevState)
                    newState = {
                        ...prevState,
                        searchPhrase: searchParam,
                        searchFields: fields,
                        selectedFacetsByAll: allFilterParams,
                    };
                else
                    newState = {
                        searchPhrase: searchParam,
                        searchFields: fields,
                        selectedFacetsByAll: allFilterParams,
                        hitsSort: DEFAULT_HITS_SORT,
                    };

                return newState;
            });
    }

    const render = (data: ContentsData) => {
        if (!data.contents.contents.length) return <ErrorPage errorCode="404" />;
        const content = data.contents.contents[0];
        if (content?.node) {
            const contentPath = getContentPath(content.node);
            if (contentPath) return <Redirect to={contentPath} />;
        }
        return <ErrorPage errorCode="404" />;
    };

    return <GraphLoader loading={loading} error={error} data={contentsData} render={render} />;
};
