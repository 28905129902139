import { ApolloClient, createHttpLink, NormalizedCacheObject } from "@apollo/client";
import { authLink, loginLink } from "../providers/authentication-provider";
import { getCurrentLng } from "../providers/ui-language-provider";
import { clientCache } from "./client-cache";
import { onError } from "@apollo/client/link/error";

export type GraphError = {
    requestId?: string;
    message?: string;
    errorCode?: string;
};

let client: ApolloClient<NormalizedCacheObject>;

export const consumerClient = (setError?: React.Dispatch<React.SetStateAction<GraphError | undefined>>) => {
    if (client) return client;

    const httpLink = createHttpLink({
        uri: "/graphql",
        headers: {
            language: getCurrentLng(),
        },
    });

    const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
        if (setError) {
            let errorCode: string | undefined = undefined;

            if (graphQLErrors) {
                console.error(graphQLErrors);
                if (
                    graphQLErrors[0]?.extensions?.exception?.status === "INTERNAL_SERVER_ERROR" ||
                    graphQLErrors[0]?.extensions?.exception?.status === 400 ||
                    graphQLErrors[0]?.extensions?.code === "400"
                )
                    return;

                errorCode = graphQLErrors[0]?.extensions?.exception?.status?.toString() ?? "";
            } else if (networkError) {
                if (
                    //@ts-ignore
                    networkError.result?.statusCode !== 401 &&
                    //@ts-ignore
                    networkError.result?.statusCode !== 413 &&
                    networkError.message
                ) {
                    setError({ message: networkError.message });
                }

                return;
            }

            const context = operation.getContext();
            const id = context.response.headers.get("x-rid");
            setError({ requestId: id, errorCode: errorCode });
        }
    });

    client = new ApolloClient({
        link: loginLink.concat(errorLink).concat(authLink).concat(httpLink),
        cache: clientCache,
        //Keep for MVP
        connectToDevTools: true,
        defaultOptions: {
            mutate: {
                errorPolicy: "all",
            },
        },
    });

    return client;
};
