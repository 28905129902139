import { Language } from "@mui/icons-material";
import { Box, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../../components/language-selector";
import { MultilingualString, Note, NoteVisibilityTypesInput } from "../../generated/consumer-graph-types";
import { getCurrentLng } from "../../providers/ui-language-provider";
import { useNoteFormStyle } from "./notes-form-style";

const MAX_NOTE_LENGTH = 2000;

export type NoteFormData = {
    language: string;
    text: MultilingualString | null | undefined;
    comment: string;
    visibilityType: NoteVisibilityTypesInput;
};

interface NoteFormProps {
    onChange: (data: NoteFormData) => void;
    attachmentsRenderer: ReactNode;
    onChangeAttachmentInput: (files: File[]) => void;
    note?: Note;
    allowedMimeTypes?: string[];
    maxFileSize?: number;
    maxFilesPerUpload?: number;
}

export const NoteForm: FC<NoteFormProps> = ({
    onChange,
    attachmentsRenderer,
    onChangeAttachmentInput,
    note,
    allowedMimeTypes,
    maxFileSize,
    maxFilesPerUpload,
}) => {
    const classes = useNoteFormStyle();
    const defaultLng = getCurrentLng();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [language, setLanguage] = useState<string>(defaultLng);
    const [text, setText] = useState<MultilingualString | null>();
    const [comment, setComment] = useState<string>("");
    const [visibilityType, setVisibilityType] = useState<NoteVisibilityTypesInput>(NoteVisibilityTypesInput.public);

    useEffect(() => {
        setText(note?.text);
        setLanguage(defaultLng);
        // @ts-ignore
        const comment = note?.text[defaultLng];
        setComment(comment);
        setVisibilityType(
            note?.visibility
                ? ((note.visibility as unknown) as NoteVisibilityTypesInput)
                : NoteVisibilityTypesInput.public
        );
    }, [note, defaultLng]);

    useEffect(() => {
        const data: NoteFormData = {
            language,
            text,
            comment,
            visibilityType,
        };
        onChange(data);
    }, [onChange, language, text, comment, visibilityType]);

    const onChangeFileInput = (files: File[]) => {
        if (maxFilesPerUpload && files.length > maxFilesPerUpload) {
            enqueueSnackbar(
                t("Too many files selected. Max {{count}} files allowed.", {
                    count: maxFilesPerUpload,
                }),
                { variant: "warning" }
            );
            return;
        }

        if (maxFileSize && files.some((a) => a.size > maxFileSize)) {
            enqueueSnackbar(t("Max {{maxSize}} file size allowed.", { maxSize: maxFileSize }), { variant: "warning" });
            return;
        }

        const newFiles = Array.from(files);
        onChangeAttachmentInput(newFiles);
    };

    const switchLanguage = (lng: string) => {
        setLanguage(lng);
        if (!note) return;
        // @ts-ignore
        setComment(text ? text[lng] || "" : "");
    };

    const toggleVisibility = () => {
        if (visibilityType === NoteVisibilityTypesInput.public) setVisibilityType(NoteVisibilityTypesInput.private);
        else setVisibilityType(NoteVisibilityTypesInput.public);
    };

    return (
        <Box display={"flex"} flexDirection={"column"} className={classes.root}>
            <TextField
                name="comment-field"
                multiline
                rows={4}
                variant="outlined"
                value={comment || ""}
                inputProps={{ maxLength: MAX_NOTE_LENGTH }}
                onChange={(event) => {
                    setComment(event.target.value);
                }}
                autoFocus={true}
                sx={{ margin: 0 }}
            />
            <Box display={"flex"} justifyContent={"flex-end"}>
                <Typography variant="caption">
                    {comment?.length || 0}/{MAX_NOTE_LENGTH}
                </Typography>
            </Box>
            {/*Note attachments upload only allowed if upload config is given*/}
            {!!allowedMimeTypes?.length && maxFileSize && maxFilesPerUpload && (
                <Fragment>
                    {attachmentsRenderer}
                    <Dropzone
                        onDrop={(acceptedFiles: File[]) => onChangeFileInput(acceptedFiles)}
                        multiple={true}
                        accept={allowedMimeTypes}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: classes.dropzone })}>
                                    <input {...getInputProps()} />
                                    <p>{t("Drag and drop note attachments or click to upload")}</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Fragment>
            )}
            <Box marginTop={3} display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={visibilityType === NoteVisibilityTypesInput.public}
                                onChange={toggleVisibility}
                            />
                        }
                        label={t("Public")}
                        labelPlacement="end"
                    />
                    <LanguageSelector
                        startAdornment={<Language />}
                        selected={language}
                        onChange={(e) => switchLanguage(e.target.value as string)}
                        size={"small"}
                    />
                </Box>
            </Box>
        </Box>
    );
};
