import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { isAuthenticated } from "../providers/authentication-provider";
import { RenderIfHasAccess } from "./render-if-has-access";
import { ApmRoute } from "@elastic/apm-rum-react";

interface RoleRouteProps {
    path: string;
    component?: React.ComponentType<any>;
    render?: (props: RouteComponentProps<any>) => React.ReactNode;
    action: string;
    resource: string;
    context?: any;
    featureID?: string;
}
export const AccessRoute: FC<RoleRouteProps> = ({ path, component, render, action, resource, context, featureID }) => {
    if (!isAuthenticated()) return <ApmRoute path={path} component={undefined} />;

    return (
        <RenderIfHasAccess
            action={action}
            resource={resource}
            renderForbiddenMessage={true}
            ignoreConditions={true}
            context={context}
            featureID={featureID}
        >
            {render ? <ApmRoute path={path} render={render} /> : <ApmRoute path={path} component={component} />}
        </RenderIfHasAccess>
    );
};
