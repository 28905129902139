import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Link } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TocNode } from "../portal/content/content";
import { ROUTE_CONTENT_WITH_TOPIC } from "../portal/routes";
import { getTocNodePath } from "../providers/content-provider";
import { useInfoCubeBreadcrumbsStyles } from "./info-cube-breadcrumbs-style";

type InfoCubeBreadcrumbsProps = {
    tocNodes: TocNode[];
    uniqueId: string;
    contentMapId: string;
};

type BreadCrumb = {
    route: string;
    title: string;
};

export const InfoCubeBreadcrumbs: FC<InfoCubeBreadcrumbsProps> = ({ tocNodes, uniqueId, contentMapId }) => {
    const classes = useInfoCubeBreadcrumbsStyles();
    const history = useHistory();
    const [items, setItems] = useState<BreadCrumb[]>([]);

    useEffect(() => {
        //set breadcrumbs items only if more than one topic exists
        if (tocNodes[0] && tocNodes[0].children.length > 0) {
            let path: TocNode[] = [tocNodes[0]];
            tocNodes[0].children.forEach((val) => getTocNodePath(val, uniqueId, path));
            let newItems: BreadCrumb[] = [];
            path.forEach((val) => {
                let url = ROUTE_CONTENT_WITH_TOPIC.replace(":contentMapId", contentMapId).replace(":topicId", val.id);
                newItems.push({ route: url, title: val.title });
            });
            if (newItems.length > 4) {
                newItems = [...newItems.slice(0, 3), { route: "", title: "" }, newItems[newItems.length - 1]];
            }
            setItems(newItems);
        } else {
            setItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tocNodes, uniqueId]);

    const goToRoute = (route: string) => {
        history.push(route);
    };

    return (
        <Breadcrumbs
            classes={{ ol: classes.ol, li: classes.item, separator: classes.separator }}
            separator={<NavigateNext fontSize="small" />}
        >
            {items.map((val, index) => {
                if (!val.title) return <span key={val.title}>...</span>;
                if (index === items.length - 1)
                    return (
                        <Box key={val.title} color={"text.primary"}>
                            {val.title}
                        </Box>
                    );
                return (
                    <Link
                        color={"textSecondary"}
                        key={val.route}
                        href={val.route}
                        onClick={(e: React.SyntheticEvent) => {
                            e.preventDefault();
                            goToRoute(val.route);
                        }}
                    >
                        {val.title}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};
