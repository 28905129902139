import React, { FC, Fragment } from "react";
import { StartPagePortal } from "./start-page-portal";
import { getComponent } from "../customizing-service";
import { StaticPagesFooter } from "./static-pages/static-pages-footer";
import { Box } from "@mui/material";

export const StartPage: FC = () => {
    const component = getComponent("StartPage");
    if (component) return <Fragment>{component()}</Fragment>;
    return (
        <Box display={"flex"} flexDirection={"column"} height={"100%"} alignItems="center">
            <StartPagePortal />
            <StaticPagesFooter />
        </Box>
    );
};
