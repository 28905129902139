import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useNotesSideSheetStyle = makeStyles((theme: Theme) => ({
    drawerPaper: {
        width: 350,
        [theme.breakpoints.up("lg")]: {
            marginTop: 64,
            height: "calc(100% - 64px)",
        },
        [theme.breakpoints.down("md")]: {
            width: "85%",
        },
    },
    noteForm: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    menuList: {
        padding: 0,
    },
    noteList: {
        display: "flex",
        flexGrow: 1,
    },
    progress: {
        height: 700,
    },
}));
