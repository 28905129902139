import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CollectionVisibilityTypes } from "../../generated/consumer-graph-types";
import { PublicOutlined, Person } from "@mui/icons-material";

type CollectionVisibilityTypeProps = {
    visibilityType: CollectionVisibilityTypes;
};

export const CollectionVisibilityType: FC<CollectionVisibilityTypeProps> = ({ visibilityType }) => {
    const { t } = useTranslation();

    switch (visibilityType) {
        case CollectionVisibilityTypes.personal:
            return <Person titleAccess={t("Private")} />;
        case CollectionVisibilityTypes.public:
            return <PublicOutlined titleAccess={t("Public")} />;
    }

    return null;
};
