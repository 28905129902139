import React, { FunctionComponent } from "react";
import { CircularProgress } from "@mui/material";
import { useProgressStyles } from "./progress-style";
import clsx from "clsx";

export interface ProgressClasses {
    root: string | undefined;
}
export interface ProgressProps {
    classes?: ProgressClasses;
    size?: number;
}

export const Progress: FunctionComponent<ProgressProps> = ({ classes, size }: ProgressProps) => {
    const classesProgresses = useProgressStyles();
    return (
        <div className={clsx(classesProgresses.progress, classes?.root)}>
            <CircularProgress size={size || 80} />
        </div>
    );
};
