import { ApolloClient, createHttpLink } from "@apollo/client";
import { getCurrentLng } from "../providers/ui-language-provider";
import { clientCache } from "./client-cache";

const httpLink = createHttpLink({
    uri: "/graphql/public",
    headers: {
        language: getCurrentLng(),
    },
});

export const publicClient = new ApolloClient({
    link: httpLink,
    connectToDevTools: false,
    cache: clientCache,
});
