import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useNoteCardStyle = makeStyles((theme: Theme) => ({
    card: {
        width: "95%",
        marginTop: theme.spacing(1),
        margin: theme.spacing(1),
        "& .MuiCardContent-root:last-child": {
            paddingBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("md")]: {
            width: "97%",
        },
    },
    cardContent: {
        wordBreak: "break-all",
    },
    cardActions: {
        flexDirection: "column",
        alignItems: "normal",
        padding: theme.spacing(2),
    },
    chip: {
        maxWidth: 280,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        alignItems: "center",
    },
    chipLabel: {
        paddingLeft: theme.spacing(1),
    },
}));
