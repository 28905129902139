import React, { FC, Fragment } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { GraphLoader } from "../../components/graph-loader";
import { SideSheet } from "../../components/side-sheet";
import { useNotificationsSideSheetStyle } from "./notifications-side-sheet-style";
import { Box, FormHelperText, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close, DoneAll } from "@mui/icons-material";
import {
    CountRelation,
    GetContentByNoteIdDocument,
    GetContentByNoteIdQuery,
    LanguageWithWildcard,
    Notification,
    useNotificationsQuery,
} from "../../generated/consumer-graph-types";
import { useSnackbar } from "notistack";
import { InfoCubeList } from "../../components/info-cube-list";
import { ListRowProps } from "react-virtualized";
import { NotificationCard } from "./notification-card";
import { USER_QUERY, UserData } from "../../shared-queries";
import { useHistory } from "react-router-dom";
import { consumerClient } from "../../apollo-clients/consumer-client";
import { getCurrentLng } from "../../providers/ui-language-provider";
import { getContentPath } from "../../utils/index";

export const MARK_NOTIFICATIONS_AS_READ_MUTATION = gql`
    mutation markNotificationsAsRead($ids: [ID]!) {
        markNotificationsAsRead(ids: $ids)
    }
`;

gql`
    query notifications(
        $first: NonNegativeInt
        $after: String
        $userId: ID
        $acceptedLanguages: [LanguageWithWildcard!]
    ) {
        notifications(filter: { andGroup: [{ equals: { userId: $userId } }, { not: { exists: readDate } }] }) {
            total {
                count
                countRelation
            }
            notifications(first: $first, sort: { field: creationDate, order: desc }, after: $after) {
                cursor
                node {
                    id
                    teasers {
                        text(acceptedLanguages: $acceptedLanguages)
                    }
                    objectId
                    objectType
                    notificationType
                    creationDate
                    users {
                        id
                        email
                    }
                }
            }
        }
    }
`;

gql`
    query getContentByNoteId($id: ID!) {
        note(id: $id) {
            content {
                id
                contentId
            }
        }
    }
`;

export type NotificationURLBuilder = {
    [key: string]: (notification: Notification) => Promise<string>;
};

type NotificationsSideSheetProps = {
    onClose: () => void;
    open: boolean;
};

export const NotificationsSideSheet: FC<NotificationsSideSheetProps> = ({ onClose, open }) => {
    const rowsPerPage = 10;
    const classes = useNotificationsSideSheetStyle();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const currentLng = getCurrentLng();

    const { data: userData } = useQuery<UserData>(USER_QUERY);

    const { data: notificationsData, error, loading, fetchMore } = useNotificationsQuery({
        skip: !userData?.me?.id || !open,
        fetchPolicy: "network-only",
        variables: {
            first: rowsPerPage,
            userId: userData?.me?.id,
            acceptedLanguages: [currentLng as LanguageWithWildcard, LanguageWithWildcard.en],
        },
    });

    const [markNotificationsAsReadMutation] = useMutation(MARK_NOTIFICATIONS_AS_READ_MUTATION);

    const urlBuilder = {
        newPublicNote: async (notification: Notification) => {
            const objectId = notification.objectId as string;
            const { data, error } = await consumerClient().query<GetContentByNoteIdQuery>({
                query: GetContentByNoteIdDocument,
                variables: { id: objectId },
            });
            const content = data.note?.content;

            if (error) {
                enqueueSnackbar(error?.message, { variant: "error", autoHideDuration: null });
                return "";
            }

            if (!content) {
                enqueueSnackbar(t("Content not available"), { variant: "error", autoHideDuration: null });
                return "";
            }

            return getContentPath(content);
        },
    };

    const loadMore = async ({ startIndex }: { startIndex: number }) => {
        if (
            notificationsData?.notifications?.total.countRelation === CountRelation.eq &&
            notificationsData?.notifications?.total.count <= startIndex
        ) {
            return;
        }

        let after = "";
        if (notificationsData?.notifications?.notifications) {
            let notification =
                notificationsData.notifications.notifications[notificationsData.notifications.notifications.length - 1];
            if (notification) after = notification.cursor;
        }

        await fetchMore({
            variables: {
                first: rowsPerPage,
                after,
                userId: userData?.me.id,
            },
        });
    };

    const markAsRead = async (notificationIds: string[], showSuccess: boolean) => {
        const { errors } = await markNotificationsAsReadMutation({
            variables: { ids: notificationIds },
            refetchQueries: ["notifications"],
            awaitRefetchQueries: true,
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(
                t("{{count}} notification(s) couldn't be marked as read", { count: notificationIds.length }),
                {
                    variant: "error",
                    autoHideDuration: null,
                }
            );
        }

        if (showSuccess) {
            enqueueSnackbar(t("{{count}} notification(s) marked as read", { count: notificationIds.length }), {
                variant: "success",
            });
        }
    };

    const onMarkAsRead = async (notification: Notification) => {
        return markAsRead([notification.id], true);
    };

    const onGotoObject = async (notification: Notification) => {
        if (!notification.readDate) await markAsRead([notification.id], false);
        //@ts-ignore
        const url = await urlBuilder[notification.notificationType || ""](notification);
        if (!url) return;
        onClose();
        history.push(url);
    };

    const onMarkAllAsRead = () => {
        if (!notificationsData) return;

        const notificationIds = notificationsData?.notifications?.notifications.reduce(
            (result: string[], notification) => {
                if (notification?.node.id) {
                    result.push(notification.node.id);
                }
                return result;
            },
            []
        );
        if (notificationIds?.length) markAsRead(notificationIds, true);
    };

    const rowRender = (props: ListRowProps) => {
        const { index, key, style } = props;
        const notification = notificationsData?.notifications?.notifications[index]?.node;
        if (!notification) return;
        //@ts-ignore
        const hideGotoButton = urlBuilder[notification.notificationType || ""] === undefined;

        return (
            <Box style={style} key={key}>
                <NotificationCard
                    notification={notification as Notification}
                    onMarkAsRead={(notification as Notification).readDate ? undefined : onMarkAsRead}
                    onGotoObject={hideGotoButton ? undefined : onGotoObject}
                />
            </Box>
        );
    };

    const render = (data: any) => {
        const notifications = data.notifications.notifications;
        return (
            <Fragment>
                {notifications.length === 0 && (
                    <Box margin={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <FormHelperText>{t("There are no new notifications at the moment.")}</FormHelperText>
                    </Box>
                )}
                <InfoCubeList
                    classes={{ list: classes.notificationList }}
                    data={notifications}
                    rowRenderer={rowRender}
                    loadMore={loadMore}
                />
                <Box bottom={0} margin={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    {notifications.length > 0 ? (
                        <IconButton aria-label="mark-all-as-read" onClick={(e) => onMarkAllAsRead()} size="large">
                            <DoneAll />
                        </IconButton>
                    ) : (
                        <Fragment></Fragment>
                    )}
                </Box>
            </Fragment>
        );
    };

    return (
        <SideSheet onClose={onClose} open={open} anchor={"right"} classes={{ drawerPaper: classes.drawerPaper }}>
            {open && (
                <>
                    <Box margin={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant="h6">{t("Notifications")}</Typography>
                        <IconButton aria-label="close" onClick={onClose} size={"small"}>
                            <Close />
                        </IconButton>
                    </Box>
                    <GraphLoader loading={loading} error={error} data={notificationsData} render={render}></GraphLoader>
                </>
            )}
        </SideSheet>
    );
};
