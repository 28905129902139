import { Drawer, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React, { FC, Fragment } from "react";
import { useSideSheetStyles } from "./side-sheet-styles";
import { useTheme } from "@mui/styles";

type SideSheetProps = {
    open?: boolean;
    classes: any;
    onClose: () => void;
    anchor?: "left" | "right";
};

export const SideSheet: FC<SideSheetProps> = ({ open, classes, onClose, anchor, children }) => {
    const sideSheetClasses = useSideSheetStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });

    return (
        <Fragment>
            <Drawer
                variant={isDesktop ? "persistent" : "temporary"}
                anchor={anchor ?? "left"}
                onClose={onClose}
                open={open}
                classes={{
                    root: open ? sideSheetClasses.open : sideSheetClasses.close,
                    paper: clsx(sideSheetClasses.paper, classes.drawerPaper),
                }}
            >
                {children}
            </Drawer>
        </Fragment>
    );
};
