import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Authenticator } from "../generated/public-graph-types";
import { redirectToLoginPage } from "../providers/authentication-provider";

type LoginModalProps = {
    isDlgOpen: boolean;
    authenticators: Authenticator[];
    title?: string;
    onClose?: () => void;
};

export const LoginModal: FC<LoginModalProps> = ({ isDlgOpen, authenticators, title, onClose }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={isDlgOpen} maxWidth={"xl"} fullScreen={true}>
            <DialogTitle sx={{ backgroundColor: "primary.main", minHeight: 56, color: "primary.contrastText" }}>
                {title}
                {onClose && (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: "primary.contrastText",
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                    >
                        <Close />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent dividers>
                <Box display="flex" flexDirection="column" height="100%" alignItems="center" justifyContent="center">
                    <Box
                        sx={{ backgroundColor: "background.default" }}
                        display="flex"
                        flexDirection="column"
                        textAlign="center"
                        p={4}
                        maxWidth="60%"
                    >
                        <Typography variant="h3" mb={4}>
                            {t("Login")}
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            {authenticators.map((val: Authenticator) => {
                                return (
                                    <Box key={val.url} mb={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ width: "100%" }}
                                            onClick={() => {
                                                redirectToLoginPage(val.url);
                                            }}
                                        >
                                            {val.displayName}
                                        </Button>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
