import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useMoreMenuStyle = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: 100000,
            maxWidth: 290,
            minWidth: 100,
        },
        item: {
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 16,
        },
    })
);
