import React from "react";
import ReactDOM from "react-dom";
import get from "lodash.get";

const components: any = {};
const nodeModules = { React, ReactDOM };

export const getComponent = (name: string) => {
    return components[name];
};

export const register = (name: string, component: any) => {
    components[name] = component;
};

export const getNodeModule = (name: string) => {
    const nodeModule = get(nodeModules, name);
    if (nodeModule) return nodeModule;

    throw new Error(`Node module ${name} is not shared by infocube.`);
};

// @ts-ignore
window["infocube"] = { getNodeModule, get: getComponent, register };

// @ts-ignore keep for old tenants
window["exegol"] = window["infocube"];
