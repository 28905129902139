import { Typography, Box } from "@mui/material";
import React, { FC } from "react";
import { useSubTitleStyles } from "./sub-title-style";
interface SubTitleProps {
    color?: "primary" | "secondary";
}

export const SubTitle: FC<SubTitleProps> = ({ color, children }) => {
    const classes = useSubTitleStyles();

    return (
        <Typography variant="subtitle2" className={color === "primary" ? classes.primary : classes.secondary}>
            <Box fontWeight="fontWeightBold">{children} </Box>
        </Typography>
    );
};
