import { palette } from "../palette";

export const MuiTreeItem = {
    styleOverrides: {
        root: {
            "& .MuiTreeItem-content": {
                "&.Mui-focused": {
                    backgroundColor: palette.background.paper,
                },
                "&:hover": {
                    backgroundColor: palette.background.paper,
                },
            },
        },
    },
};
