import { gql } from "@apollo/client";
import { AccessControlSettings, Feature, User } from "../generated/consumer-graph-types";
import { Tenant } from "../generated/public-graph-types";

export interface AvailableFeaturesData {
    availableFeatures: Feature[];
}

export const AVAILABLE_FEATURES = gql`
    query availableFeatures {
        availableFeatures {
            id
        }
    }
`;

gql`
    query metadataDefinitionById($id: DatafieldDefinitionID!, $acceptedLanguages: [LanguageWithWildcard!]) {
        datafieldDefinition(id: $id) {
            id
            fieldType
            teasers {
                title(acceptedLanguages: $acceptedLanguages)
            }
            ... on EnumDatafieldDefinition {
                id
                mappedNames
                fieldType
                values {
                    key
                    teasers {
                        title(acceptedLanguages: $acceptedLanguages)
                    }
                }
            }
            ... on TaxonomyDatafieldDefinition {
                id
                mappedNames
                fieldType
                values {
                    key
                    pathKeys
                    teasers {
                        title(acceptedLanguages: $acceptedLanguages)
                    }
                }
            }
        }
    }
`;

gql`
    query facetSelection($referencedIds: [String!], $acceptedLanguages: [LanguageWithWildcard!], $id: ID!) {
        facetsCollection(id: $id) {
            facets(referencedIds: $referencedIds) {
                id
                referencedId
                type
                teasers {
                    title(acceptedLanguages: $acceptedLanguages)
                }
                definition {
                    fieldType
                    ... on EnumFacetDefinition {
                        values {
                            key
                            teasers {
                                title(acceptedLanguages: $acceptedLanguages)
                            }
                        }
                    }
                    ... on TaxonomyFacetDefinition {
                        values {
                            key
                            pathKeys
                            teasers {
                                title(acceptedLanguages: $acceptedLanguages)
                            }
                        }
                    }
                }
            }
        }
    }
`;

gql`
    query fieldDefinitions($ids: [ID], $acceptedLanguages: [LanguageWithWildcard!]) {
        fieldDefinitions(
            filter: { ids: $ids, orGroup: [{ equals: { fieldType: taxonomy } }, { equals: { fieldType: enum } }] }
        ) {
            fieldDefinitions(first: 1000) {
                cursor
                node {
                    id
                    fieldType
                    teasers {
                        title(acceptedLanguages: $acceptedLanguages)
                    }
                    ... on TaxonomyDatafieldDefinition {
                        values {
                            key
                            teasers {
                                title(acceptedLanguages: $acceptedLanguages)
                            }
                            pathKeys
                        }
                    }
                    ... on EnumDatafieldDefinition {
                        values {
                            key
                            teasers {
                                title(acceptedLanguages: $acceptedLanguages)
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const teasers = (acceptedLanguages: string[]) => {
    return `teasers {
                title(length: 50, acceptedLanguages:${acceptedLanguages.length ? acceptedLanguages.join(",") : "de"})
            }`;
};

export const USER_QUERY = gql`
    query me {
        me {
            id
            email
            roles
            displayName
            effectiveRoles
            endSessionUrl
        }
    }
`;

export interface UserData {
    me: User;
}

export const ACCESS_CONTROL_SETTINGS_QUERY = gql`
    query accessControlSettings {
        accessControlSettings {
            id
            rolesConfig
        }
    }
`;

export interface AccessControlData {
    accessControlSettings: AccessControlSettings;
}

export const THEME_QUERY = (languages: string[]) => {
    return gql`
    query themeQuery {
        theme {
            theming
            logo {
                blobId
                url
                path
            }
            startPageLogo{
                blobId
                url
                path
            }
            ${teasers(languages)}
            showTitle
        }
    }
`;
};

export interface TenantQueryData {
    tenant: Tenant;
}

export const GET_UPLOAD_CONFIG_QUERY = gql`
    query getUploadConfig {
        tenant {
            languages {
                available
                default
            }
            uploadConfig {
                notes {
                    attachments {
                        allowedMimeTypes
                        limits {
                            maxFileSize
                            maxFilesPerUpload
                        }
                    }
                }
            }
        }
    }
`;

export const TITLE = `title{
        de
        en
        es
        fr
        el
        ja
    }`;

gql`
    mutation deleteNoteAttachments($id: ID!, $attachmentIds: [ID]!) {
        deleteNoteAttachments(noteId: $id, attachmentIds: $attachmentIds)
    }
`;

gql`
    query explore($id: ID!, $acceptedLanguages: [LanguageWithWildcard!]) {
        facetsCollection(id: $id) {
            facets {
                kind
                display {
                    type
                    options
                }
                referencedId
                teasers {
                    title(acceptedLanguages: $acceptedLanguages)
                }
                definition {
                    fieldType
                }
            }
        }
        searchConfig(id: $id) {
            entries {
                type
                fieldType
                referencedId
                teasers {
                    title(length: 50, acceptedLanguages: $acceptedLanguages)
                }
            }
        }
        dataDisplayConfig(id: $id) {
            separator
            entries {
                type
                fieldType
                referencedId
                showTitlePrefix
                teasers {
                    title(length: 50, acceptedLanguages: $acceptedLanguages)
                }
                ... on DataDisplayConfigFieldEntry {
                    nodeTypes
                }
            }
        }
    }
`;

gql`
    query dataDisplayConfig($id: ID!, $acceptedLanguages: [LanguageWithWildcard!]) {
        dataDisplayConfig(id: $id) {
            separator
            entries {
                type
                fieldType
                referencedId
                showTitlePrefix
                ... on DataDisplayConfigDatafieldEntry {
                    datafieldDefinition {
                        ... on TaxonomyDatafieldDefinition {
                            id
                            values {
                                key
                                teasers {
                                    title(length: 50, acceptedLanguages: $acceptedLanguages)
                                }
                            }
                        }
                    }
                }
                teasers {
                    title(length: 50, acceptedLanguages: $acceptedLanguages)
                }
            }
        }
    }
`;
gql`
    query adminContentMaps(
        $first: NonNegativeInt
        $after: String
        $sortId: ContentMapSortFields!
        $sortOrder: SortOrder
        $filter: ContentMapFilter
        $trackLimit: NonNegativeInt
    ) {
        contentMaps(filter: $filter) {
            total(trackLimit: $trackLimit) {
                count
                countRelation
            }
            contentMaps(sort: { field: $sortId, order: $sortOrder }, first: $first, after: $after) {
                cursor
                node {
                    id
                    contentId
                    locale
                    modificationDate
                    teasers {
                        title
                    }
                }
            }
        }
    }
`;

gql`
    query staticPageMenu($languages: [LanguageWithWildcard!]) {
        staticPageMenu {
            id
            pages {
                teasers {
                    title(acceptedLanguages: $languages)
                }
                staticPages {
                    id
                }
                pageType
            }
        }
    }
`;

gql`
    mutation createCollection($description: String, $displayName: String, $visibility: CollectionVisibilityTypes!) {
        createCollection(spec: { visibility: $visibility, displayName: $displayName, description: $description })
    }
`;
