import { ArticleOutlined, MoreVert } from "@mui/icons-material";
import { Box, Chip, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React, { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IndexRange, ListRowProps } from "react-virtualized";
import { InfoCubeList } from "../../components/info-cube-list";
import { MimeTypeIcon } from "../../components/mime-type-icon";
import { MoreMenu } from "../../components/more-menu";
import { Progress } from "../../components/progress";
import { RenderIfHasAccess } from "../../components/render-if-has-access";
import { Action, Resource } from "../../enums";
import { Content, Facet, DatafieldType, FieldDefinitionTypes } from "../../generated/consumer-graph-types";
import { DateTimeFormatter, isValidISODate } from "../../utils";
import { getContentPath } from "../../utils/index";
import { AddToCollectionDialog } from "../collections/add-to-collection-dialog";
import { useHitListStyles } from "./hit-list-style";
import { Dictionary } from "ts-essentials";
import { DataDisplayConfigEntry } from "../../generated/consumer-graph-types";

type HitListEntry = { title: string; values: any[] };

type HitListProps = {
    data: any;
    loading: boolean;
    loadMore: (params: IndexRange) => Promise<any>;
    fieldDefinitions?: Dictionary<any>;
    facets?: Facet[];
    metadataSeparator?: string;
    dataDisplayConfigEntries?: DataDisplayConfigEntry[];
    onSelectFacet?: (rootId: string, referencedId: string) => void;
};

export const HitList: FC<HitListProps> = ({
    data,
    loading,
    loadMore,
    fieldDefinitions,
    facets: facetsFilter,
    metadataSeparator,
    dataDisplayConfigEntries: hitInformationEntries,
    onSelectFacet,
}) => {
    const { t } = useTranslation();
    const classes = useHitListStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<any>();
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);
    const [isAddToCollectionOpen, setIsAddToCollectionOpen] = useState<boolean>(false);
    const [contendIdForMoreMenu, setContendIdForMoreMenu] = useState<string>("");
    const [titleForMoreMenu, setTitleForMoreMenu] = useState<string>("");

    const handleClickAway = () => {
        if (!isMoreMenuOpen) return;
        setIsMoreMenuOpen(false);
        setAnchorEl(null);
    };

    const goToContent = (content: Content) => {
        const contentPath = getContentPath(content);
        if (contentPath) history.push(contentPath);
    };

    const onScroll = () => {
        if (isMoreMenuOpen) handleClickAway();
    };

    const getIcon = (mimeType: string | undefined, typename: string) => {
        if (typename === "ContentMap")
            return (
                <Tooltip arrow title={<>{t("Document")}</>}>
                    <ArticleOutlined />
                </Tooltip>
            );
        else if (typename && mimeType) {
            return (
                <Tooltip arrow title={mimeType}>
                    <Box>
                        <MimeTypeIcon mimeType={mimeType} />
                    </Box>
                </Tooltip>
            );
        }
    };

    const onClickFacet = (val: any) => {
        if (onSelectFacet) onSelectFacet(val.rootId, val.referencedId);
    };

    const renderFacetValue = (val: any) => {
        //only facets of type metadata and which are present in the filter are selectable
        if (val.rootId && facetsFilter && facetsFilter.some((facet) => facet.referencedId === val.rootId)) {
            return (
                <Chip
                    size="small"
                    label={val.title}
                    onClick={(e: any) => {
                        onClickFacet(val);
                        e.stopPropagation();
                    }}
                    data-selectable-metadata={val.referencedId}
                    className={classes.facetValue}
                    sx={{ ".MuiChip-label": { color: "primary.main" } }}
                />
            );
        }

        return <Box sx={{ fontWeight: "fontWeightBold" }}>{val.title}</Box>;
    };

    const getHitListEntryByContentType = (content: any, entry: DataDisplayConfigEntry) => {
        let id, title;

        if (content.__typename === "Topic") {
            id = "topic";
            title = t("Topic");
        }
        if (content.__typename === "ContentMap") {
            id = "contentMap";
            title = t("Document");
        }

        return {
            rootId: entry.referencedId,
            rootTitle: entry.teasers?.title,
            referencedId: id,
            title,
        };
    };

    const getHitListEntryForFieldType = (content: any, entry: DataDisplayConfigEntry) => {
        //workaround to handle fields as selectable filter
        if (entry.referencedId === "type") {
            const entryByContentType = getHitListEntryByContentType(content, entry);
            return entryByContentType;
        } else if (entry.referencedId === "language") {
            return {
                rootId: entry.referencedId,
                rootTitle: entry.teasers?.title,
                referencedId: content[entry.referencedId],
                title: t(content[entry.referencedId]),
            };
        }
        //--------------
        else if (content[entry.referencedId]) {
            return {
                title: isValidISODate(content[entry.referencedId])
                    ? DateTimeFormatter(content[entry.referencedId])
                    : content[entry.referencedId],
            };
        }
    };

    const getHitListValuesForMetadataType = (content: any, entry: DataDisplayConfigEntry): any[] => {
        let values: any[] = [];

        if (entry.fieldType === FieldDefinitionTypes.text) {
            const metadataEntries = content?.teasers[entry.referencedId];
            if (metadataEntries?.length) {
                for (const metadataEntry of metadataEntries) {
                    values.push({ title: metadataEntry });
                }
            }
        } else if (entry.fieldType === FieldDefinitionTypes.keyword && content[entry.referencedId]) {
            const metadataEntries = content[entry.referencedId];
            if (metadataEntries?.length) {
                for (const metadataEntry of metadataEntries) {
                    values.push({ title: metadataEntry });
                }
            }
        } else if (entry.fieldType === FieldDefinitionTypes.dateTime && content[entry.referencedId]) {
            const metadataEntries = content[entry.referencedId];
            if (metadataEntries?.length) {
                for (const metadataEntry of metadataEntries) {
                    values.push({
                        title: isValidISODate(metadataEntry) ? DateTimeFormatter(metadataEntry) : metadataEntry,
                    });
                }
            }
        } else if (content[entry.referencedId] && fieldDefinitions && fieldDefinitions[entry.referencedId]) {
            const fieldDefinition = fieldDefinitions[entry.referencedId];

            let metaValues = content[entry.referencedId]?.map((val: string) => {
                return {
                    rootId: fieldDefinition.id,
                    rootTitle: fieldDefinition.title,
                    referencedId: val,
                    title: fieldDefinition[val]?.teasers?.title,
                };
            });
            values = values.concat(metaValues);
        }

        return values;
    };

    const getHitListEntries = (content: any): HitListEntry[] => {
        let hitListEntries: HitListEntry[] = [];

        hitInformationEntries?.forEach((entry) => {
            let values: any[] = [];
            let title: string = "";

            if (entry && entry.showTitlePrefix && entry.teasers?.title) {
                title = entry.teasers.title;
            }

            switch (entry.type) {
                case DatafieldType.field:
                    const entryTypeField = getHitListEntryForFieldType(content, entry);
                    if (entryTypeField) values.push(entryTypeField);
                    break;
                case DatafieldType.metadata:
                    if (content) {
                        const metadataValues = getHitListValuesForMetadataType(content, entry);
                        values = values.concat(metadataValues);
                    }
                    break;
            }

            if (values.length) hitListEntries.push({ title, values });
        });

        return hitListEntries;
    };

    const renderHitListByConfig = (content: any) => {
        let hitListEntries = getHitListEntries(content);
        if (!hitListEntries.length) return null;

        return (
            <Box className={classes.searchResultMeta} sx={{ typography: "body2", lineHeight: 2 }}>
                {hitListEntries.map((entry, entryIndex) => {
                    return (
                        <Fragment key={entryIndex}>
                            {entry.title && <Box>{`${entry.title}:`}&nbsp;</Box>}
                            {entry.values.map((val, valIndex) => {
                                return <Fragment key={valIndex}>{renderFacetValue(val)}</Fragment>;
                            })}
                            {entryIndex < hitListEntries.length - 1 && (
                                <Box ml={0.5} mr={0.5}>
                                    {metadataSeparator ? metadataSeparator : <>&middot;</>}
                                </Box>
                            )}
                        </Fragment>
                    );
                })}
            </Box>
        );
    };

    const rowRenderer = (params: ListRowProps) => {
        const highlightTitle = data?.contents.contents[params.index]?.highlights?.title;
        const highlightText = data?.contents.contents[params.index]?.highlights?.text;
        const highlightTitleString = highlightTitle && highlightTitle.length ? (highlightTitle[0] as string) : "";
        const highlightTextString = highlightText && highlightText.length ? (highlightText[0] as string) : "";
        const content = data?.contents.contents[params.index]?.node;

        return (
            <Box style={params.style} key={params.key}>
                <Box
                    display={"flex"}
                    className={classes.searchResultRow}
                    onClick={() => {
                        if (data?.contents.contents[params.index].node)
                            goToContent(data.contents.contents[params.index].node);
                    }}
                >
                    <Box className={classes.icon}>
                        {getIcon(content.contents ? content.contents[0].mimeType : undefined, content.__typename)}
                    </Box>
                    <Box display={"flex"} flexDirection="column">
                        <Box className={classes.title} sx={{ typography: "subtitle1", fontWeight: "fontWeightBold" }}>
                            {highlightTitleString ? (
                                <Box
                                    dangerouslySetInnerHTML={{
                                        __html: highlightTitleString,
                                    }}
                                />
                            ) : (
                                content?.teasers?.title
                            )}
                        </Box>
                        {content?.__typename === "Topic" && content?.useContext?.rootContentMap?.teasers?.title && (
                            <Box mb={0.5}>
                                <Typography variant="body2">
                                    {t("in")}
                                    <Box component="span" fontStyle="italic">
                                        {` ${content?.useContext?.rootContentMap?.teasers?.title}`}
                                    </Box>
                                </Typography>
                            </Box>
                        )}
                        {hitInformationEntries && renderHitListByConfig(content)}
                        {highlightTextString && (
                            <Box
                                className={classes.highlightText}
                                dangerouslySetInnerHTML={{
                                    __html: highlightTextString,
                                }}
                            />
                        )}
                    </Box>
                    <RenderIfHasAccess action={Action.write} resource={Resource.collections} ignoreConditions={true}>
                        <Box display="flex" alignItems="center" flexGrow={1} justifyContent="flex-end">
                            <IconButton
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    setAnchorEl(event.currentTarget);
                                    setIsMoreMenuOpen(true);
                                    setContendIdForMoreMenu(content?.id);
                                    setTitleForMoreMenu(content?.teasers?.title);
                                }}
                                size="medium"
                            >
                                <MoreVert data-icon={"more-menu"} />
                            </IconButton>
                        </Box>
                    </RenderIfHasAccess>
                </Box>
            </Box>
        );
    };

    return (
        <Paper elevation={0} className={classes.searchPaper}>
            {loading ? (
                <Box display="flex" justifyContent={"center"} flexGrow={1}>
                    <Progress />
                </Box>
            ) : (
                <InfoCubeList
                    windowScroll={true}
                    classes={{ list: classes.searchList }}
                    data={data?.contents.contents ?? []}
                    rowRenderer={rowRenderer}
                    loadMore={loadMore}
                    onScroll={onScroll}
                />
            )}
            <RenderIfHasAccess action={Action.write} resource={Resource.collections} ignoreConditions={true}>
                <MoreMenu
                    handleClickAway={handleClickAway}
                    isMoreMenuOpen={isMoreMenuOpen}
                    anchorElement={anchorEl}
                    moreMenuItems={[
                        {
                            label: t("Add to collection"),
                            onClick: () => {
                                handleClickAway();
                                setIsAddToCollectionOpen(true);
                            },
                        },
                    ]}
                />
                <AddToCollectionDialog
                    onClose={async () => setIsAddToCollectionOpen(false)}
                    open={isAddToCollectionOpen}
                    contentId={contendIdForMoreMenu}
                    contentTitle={titleForMoreMenu}
                />
            </RenderIfHasAccess>
        </Paper>
    );
};
