export const palette = {
    background: {
        dark: "#f3f5f8",
        default: "#f3f5f8",
        paper: "#fff",
    },
    primary: {
        main: "#00417d",
    },
    secondary: {
        main: "#06B67F",
    },
    text: {
        primary: "#000000",
        secondary: "#C0C0C0",
    },
    icon: "#C0C0C0",
    divider: "#eeeeee",
};
