import { gql } from "@apollo/client";
import { Box } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { useSnackbar } from "notistack";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { PageTitle } from "../../components/page-title";
import { Progress } from "../../components/progress";
import { LanguageWithWildcard, useGetStaticPageQuery } from "../../generated/consumer-graph-types";
import { getCurrentLng } from "../../providers/ui-language-provider";
import { getContent } from "../../utils/get";

export interface StaticPagesProps {
    pageType: string;
}

export const GET_STATIC_PAGE_QUERY = gql`
    query getStaticPage($pageType: String!, $languages: [LanguageWithWildcard!]) {
        staticPage(pageType: $pageType, acceptedLanguages: $languages) {
            id
            title
            contents {
                url
            }
        }
    }
`;

export const StaticPages: FC<StaticPagesProps> = ({ pageType }) => {
    const [contentLoading, setContentLoading] = useState(true);
    const [content, setContent] = useState("");
    const [contentError, setContentError] = useState(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const currentLanguage = getCurrentLng();

    const { loading, error, data } = useGetStaticPageQuery({
        variables: {
            pageType,
            languages: [currentLanguage as LanguageWithWildcard, LanguageWithWildcard.en],
        },
    });

    useEffect(() => {
        if (!data) return;
        const staticPage = data.staticPage;
        if (staticPage) {
            const contentUrl = staticPage.contents?.length ? staticPage.contents[0]?.url : "";
            if (contentUrl)
                getContent(contentUrl)
                    .then(setContent)
                    .catch((e) => setContentError(e))
                    .finally(() => setContentLoading(false));
            else {
                setContentError(t("Static Page has no URL defined"));
                setContentLoading(false);
            }
        } else {
            setContentError(t("No static page for language defined"));
            setContentLoading(false);
        }
    }, [data, t]);

    if (error || contentError) {
        enqueueSnackbar(error?.message || contentError, { variant: "error", autoHideDuration: null });
        return <Redirect to={"/home"} />;
    }

    if (contentLoading || loading) return <Progress />;

    return (
        <>
            <PageTitle title={data?.staticPage?.title ?? ""} />
            <Box mt={2}>
                <Markdown>{content}</Markdown>
            </Box>
        </>
    );
};
