import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  /**
   * An unique identifier for datafield definitions
   * It must adhere to the following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  DatafieldDefinitionID: any;
  DateTime: any;
  EmailAddress: any;
  /**
   * Reserved characters
   * The regular expression engine supports all Unicode characters. However, the following characters are reserved as operators:
   * . ? + * | { } [ ] ( ) " \ # @ & < >  ~
   * To use one of these characters literally, escape it with a preceding backslash or surround it with double quotes. For example:
   *
   * \@                  # renders as a literal '@'
   * \\                  # renders as a literal '\'
   * "john@smith.com"    # renders as 'john@smith.com'
   *
   *
   * The regular expression engine does not use the Perl Compatible Regular Expressions (PCRE) library, but it does support the following operators:
   *
   * .
   * Matches any character. For example:
   *
   * ab.     # matches 'aba', 'abb', 'abz', etc.
   * ?
   * Repeat the preceding character zero or one times. Often used to make the preceding character optional. For example:
   *
   * abc?     # matches 'ab' and 'abc'
   * +
   * Repeat the preceding character one or more times. For example:
   *
   * ab+     # matches 'ab', 'abb', 'abbb', etc.
   * *
   * Repeat the preceding character zero or more times. For example:
   *
   * ab*     # matches 'a', 'ab', 'abb', 'abbb', etc.
   *
   *
   * {}
   * Minimum and maximum number of times the preceding character can repeat. For example:
   *
   * a{2}    # matches 'aa'
   * a{2,4}  # matches 'aa', 'aaa', and 'aaaa'
   * a{2,}   # matches 'a` repeated two or more times
   *
   *
   * |
   * OR operator. The match will succeed if the longest pattern on either the left side OR the right side matches. For example:
   *
   * abc|xyz  # matches 'abc' and 'xyz'
   *
   *
   * ( … )
   * Forms a group. You can use a group to treat part of the expression as a single character. For example:
   *
   * abc(def)?  # matches 'abc' and 'abcdef' but not 'abcd'
   *
   *
   * [ … ]
   * Match one of the characters in the brackets. For example:
   *
   * [abc]   # matches 'a', 'b', 'c'
   *
   * Inside the brackets, - indicates a range unless - is the first character or escaped. For example:
   *
   * [a-c]   # matches 'a', 'b', or 'c'
   * [-abc]  # '-' is first character. Matches '-', 'a', 'b', or 'c'
   * [abc\-] # Escapes '-'. Matches 'a', 'b', 'c', or '-'
   *
   * A ^ before a character in the brackets negates the character or range. For example:
   *
   * [^abc]      # matches any character except 'a', 'b', or 'c'
   * [^a-c]      # matches any character except 'a', 'b', or 'c'
   * [^-abc]     # matches any character except '-', 'a', 'b', or 'c'
   * [^abc\-]    # matches any character except 'a', 'b', 'c', or '-'
   *
   *
   * COMPLEMENT
   * You can use ~ to negate the shortest following pattern. For example:
   * a~bc   # matches 'adc' and 'aec' but not 'abc'
   *
   *
   * INTERVAL
   * You can use <> to match a numeric range. For example:
   *
   * foo<1-100>      # matches 'foo1', 'foo2' ... 'foo99', 'foo100'
   * foo<01-100>     # matches 'foo01', 'foo02' ... 'foo99', 'foo100'
   *
   *
   * INTERSECTION
   * The match will succeed if patterns on both the left side AND the right side matches. For example:
   *
   * aaa.+&.+bbb  # matches 'aaabbb'
   *
   *
   * ANYSTRING
   * You can use @ to match any entire string.
   * You can combine the @ operator with & and ~ operators to create an "everything except" logic. For example:
   *
   * @&~(abc.+)  # matches everything except terms beginning with 'abc'
   */
  FilterRegex: any;
  /** Full text query type which is length limited */
  FullTextSearchQuery: any;
  HexColorCode: any;
  JSON: any;
  JSONObject: any;
  Long: any;
  NegativeFloat: any;
  NegativeInt: any;
  NoDynamicValueDefined: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  PhoneNumber: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  URL: any;
  UnsignedFloat: any;
  UnsignedInt: any;
};







export type AccessControlSettings = {
  __typename?: 'AccessControlSettings';
  /** The unique id */
  id: Scalars['ID'];
  /**
   * The role configuration for the access control
   *
   * The result contains a dictionary of roles.
   * Each of the roles has a grants array which contains the specific resource,
   * action and allowed attributes.
   */
  rolesConfig?: Maybe<Scalars['JSON']>;
};

export type AggregationEdge = {
  __typename?: 'AggregationEdge';
  value: Scalars['String'];
  cursor: Scalars['String'];
  count: Scalars['NonNegativeInt'];
};

export type Aggregations = {
  __typename?: 'Aggregations';
  /** Provides aggregations for the type filed */
  type?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the contentId field */
  contentId?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the documentId field */
  documentId?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the language field */
  language?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the locale field */
  locale?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the mimeType field */
  mimeType?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the rendition field */
  rendition?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the version field */
  version?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the versionLabel field */
  versionLabel?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the source field */
  source?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the sourceId field */
  sourceId?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the content's use context */
  useContext?: Maybe<UseContextAggregations>;
};


export type AggregationsTypeArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsContentIdArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsDocumentIdArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsLanguageArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsLocaleArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsMimeTypeArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsRenditionArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsVersionArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsVersionLabelArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsSourceArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type AggregationsSourceIdArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  /** The api key's unique identifier */
  id: Scalars['ID'];
  /** Readable name for the api key */
  displayName: Scalars['String'];
  /** Date until which the api key is valid */
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type Authenticator = {
  __typename?: 'Authenticator';
  /** Readable name for the authenticator */
  displayName: Scalars['String'];
  /** The description for the authenticator */
  description?: Maybe<Scalars['String']>;
  /** Image url for display purpose */
  imageUrl?: Maybe<Scalars['String']>;
  /** Indicates the default authenticator */
  default?: Maybe<Scalars['Boolean']>;
  /** The strategy used for the authenticator */
  strategyName: Scalars['String'];
  /** The url of the authenticator */
  url: Scalars['String'];
};

export type AuthenticatorEdge = {
  __typename?: 'AuthenticatorEdge';
  /** The result's authenticator */
  node?: Maybe<Authenticator>;
};

export type AuthenticatorQueryResult = {
  __typename?: 'AuthenticatorQueryResult';
  /**
   * The authenticators of the tenant.
   *
   * Currently there's no functionality to filter them nor using pagination.
   *
   * But for later compatibility and the ability to add paging or filtering, they are
   * returned in the appropriate format.
   *
   * The field will always contain any authenticator results which exist on the tenant.
   *
   * This might change in the future.
   */
  authenticators?: Maybe<Array<Maybe<AuthenticatorEdge>>>;
  /** Returns the total count of results. */
  total: TotalCount;
};


/** Typed binary data of `Topic`s. E.g. images, HTML and media files, style sheets, etc. */
export type Blob = {
  __typename?: 'Blob';
  /** The blob's unique, content hashed ID. */
  id: Scalars['ID'];
  /** The blob's mime type. May be empty if no mime type coud be detected. */
  mimeType?: Maybe<Scalars['String']>;
  /** The blob's uncompressed size in bytes. */
  size?: Maybe<Scalars['Int']>;
  /** Type of compression used for the blob or empty if the blob is not compressed. */
  compressionType?: Maybe<CompressionType>;
  /** Download URL for the blob's binary data */
  url?: Maybe<Scalars['URL']>;
  /**
   * The blob's protection class.
   * See `BlobProtectionClass` for further information.
   */
  protectionClass?: Maybe<BlobProtectionClass>;
};

export type BlobEdge = {
  __typename?: 'BlobEdge';
  /** The result's blob object */
  node: Blob;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

export type BlobEqualsValueFilter = {
  /** Returns only blobs with the specified protection class */
  protectionClass?: Maybe<BlobProtectionClass>;
  /** Returns only blobs with the specified mime type */
  mimeType?: Maybe<Scalars['String']>;
  /** Returns only blobs with the specified file size */
  size?: Maybe<Scalars['Long']>;
};

export type BlobFilter = {
  /** Filter by ids */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Returns blobs that contain all of the exact values specified */
  equals?: Maybe<BlobEqualsValueFilter>;
  /** Returns blobs with values within all of the ranges specified */
  range?: Maybe<BlobRangeFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: Maybe<Array<Maybe<BlobFilter>>>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: Maybe<Array<Maybe<BlobFilter>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: Maybe<BlobFilter>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
};

/** Blob protection classes which control who can access the specified blob */
export enum BlobProtectionClass {
  /** Can be accessed by everyone (including unauthenticated users). */
  public = 'public',
  /** Can be accessed by every authenticated user. */
  authenticated = 'authenticated',
  /** Can only be accessed by users who have access to at least one content which uses this blob. */
  contentAuthorized = 'contentAuthorized'
}

export type BlobQueryResult = {
  __typename?: 'BlobQueryResult';
  /**
   * The query's matching blobs.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  blobs: Array<Maybe<BlobEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


export type BlobQueryResultBlobsArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<BlobSort>>>;
};


export type BlobQueryResultTotalArgs = {
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
};

export type BlobRangeFilter = {
  /** Returns only blob whose size is in the specified range */
  size?: Maybe<LongRange>;
};

/** Reference to a binary blob */
export type BlobRef = {
  __typename?: 'BlobRef';
  /** The referenced `Blob` */
  blob?: Maybe<Blob>;
  /** The referenced blob's unique, content hashed ID. */
  blobId: Scalars['ID'];
  /** The referenced blob's mime type. May be empty if no mime type coud be detected. */
  mimeType?: Maybe<Scalars['String']>;
  /** The path and file name under which the blob is referenced in this context. */
  path?: Maybe<Scalars['String']>;
  /** Download URL for the blob's binary data */
  url?: Maybe<Scalars['URL']>;
};

/** Spec for a reference to a binary blob */
export type BlobRefInput = {
  /** The referenced blob's unique, content hashed ID. */
  blobId: Scalars['ID'];
  /** The referenced blob's mime type. */
  mimeType?: Maybe<Scalars['String']>;
  /** The path and file name under which the blob is referenced in this context. */
  path: Scalars['String'];
};

export type BlobSort = {
  /** Sort field */
  field: BlobSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum BlobSortFields {
  /** Sort by relevance score */
  score = 'score',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by file size */
  size = 'size',
  /** Sort by mime type */
  mimeType = 'mimeType',
  /** Sort by protection class */
  protectionClass = 'protectionClass'
}

export type Collection = {
  __typename?: 'Collection';
  /** The unique id */
  id: Scalars['ID'];
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The detailed description */
  description?: Maybe<Scalars['String']>;
  /** The visibility of the collection */
  visibility?: Maybe<CollectionVisibilityTypes>;
  /** The access kind to this collection */
  access?: Maybe<CollectionAccessTypes>;
  /** The user who owns the collection */
  user?: Maybe<User>;
  /** The collection's contents */
  contents: ContentQueryResult;
};


export type CollectionContentsArgs = {
  filter?: Maybe<ContentFilter>;
};

export enum CollectionAccessTypes {
  /** The collection is a personal collection owned by the requesting user */
  personal = 'personal',
  /** The collection is public */
  public = 'public',
  /** The collection is a personal collection of another user */
  shared = 'shared'
}

export type CollectionEdge = {
  __typename?: 'CollectionEdge';
  /** The result's collection object. */
  node?: Maybe<Collection>;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

export type CollectionEqualsValueFilter = {
  /** Returns only collections with the specified display name */
  displayName?: Maybe<Scalars['String']>;
  /** Returns only collections with the specified visibility */
  visibility?: Maybe<CollectionVisibilityTypes>;
};

export type CollectionFilter = {
  /** Filter by ids */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Full text search in collections */
  fullText?: Maybe<CollectionFullTextFilter>;
  /** Returns collections that contain all of the exact values specified */
  equals?: Maybe<CollectionEqualsValueFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: Maybe<Array<Maybe<CollectionFilter>>>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: Maybe<Array<Maybe<CollectionFilter>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: Maybe<CollectionFilter>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
};

export type CollectionFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: Maybe<Scalars['Float']>;
  /** Match the query against the display name and rank the results with the specified boost */
  displayName?: Maybe<Scalars['Float']>;
};

export type CollectionFullTextFilter = {
  /** Text to search for */
  query: Scalars['String'];
  /** Specifies if either all or any query words need to match */
  operator?: Maybe<FullTextOperator>;
  /** Fields to search in with their respective score weights */
  fields?: Maybe<CollectionFullTextFields>;
  /** Type of fulltext search to perform */
  searchType?: Maybe<FullTextSearchType>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: Maybe<Scalars['Boolean']>;
};

export type CollectionQueryResult = {
  __typename?: 'CollectionQueryResult';
  /**
   * The query's matching collections.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  collections: Array<Maybe<CollectionEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


export type CollectionQueryResultCollectionsArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<CollectionSort>>>;
};


export type CollectionQueryResultTotalArgs = {
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
};

export type CollectionSort = {
  /** Sort field */
  field: CollectionSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum CollectionSortFields {
  /** Sort by relevance score */
  score = 'score',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by display name */
  displayName = 'displayName',
  /** Sort by visibility */
  visibility = 'visibility'
}

export enum CollectionVisibilityTypes {
  /**
   * Only listed for the collection's creator but accessible for any user via
   * the collection's id
   */
  personal = 'personal',
  /** Listed for everyone */
  public = 'public'
}

export type ComponentInfo = {
  __typename?: 'ComponentInfo';
  /** The component's name */
  name: Scalars['String'];
  /** The component's version */
  version: VersionInfo;
  /** Information about the component's sub components */
  components?: Maybe<Array<ComponentInfo>>;
};

/** Type of content compression */
export enum CompressionType {
  gzip = 'gzip'
}

/** Common interface for contents as e.g. `ContentMap` or `Topic` */
export type Content = {
  /** Unique identifier of the content itself */
  contentId: Scalars['ID'];
  /** Date and time when the content was created */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Unique identifier of the content in its use context */
  id: Scalars['ID'];
  /** Language of the content */
  language?: Maybe<Language>;
  /** Locale of the content */
  locale?: Maybe<Scalars['String']>;
  /** Date and time of the last modification */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /**
   * Relevant notes for the content.
   *
   * This field only shows private, pendingApproval notes created by the requesting user and any public notes
   * that reference the content.
   *
   * Even with administrative privileges this field only shows the above mentioned notes and therefore doesn't include any notes
   * which don't belong to the requesting user except the public ones. To view any note and filter them, refer to the
   * filter query for the notes.
   */
  notes?: Maybe<NoteQueryResult>;
  /** The source this content was generated from */
  source?: Maybe<Scalars['String']>;
  /** The unique id of the source */
  sourceId?: Maybe<Scalars['String']>;
  /** Length limited text from the content for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<ContentTeasers>;
  /** Title of the content */
  title?: Maybe<MultilingualString>;
  /** Most recent date and time when the content was uploaded */
  uploadDate?: Maybe<Scalars['DateTime']>;
  /** The use context in which this content is used */
  useContext?: Maybe<UseContext>;
  /** Technical version of the content */
  version?: Maybe<Scalars['Long']>;
};


/** Common interface for contents as e.g. `ContentMap` or `Topic` */
export type ContentNotesArgs = {
  filter?: Maybe<NoteFilter>;
};

export type ContentAutocompleteHits = {
  __typename?: 'ContentAutocompleteHits';
  /** The found suggestion */
  suggest: Scalars['String'];
  /** The count of docs found containing the suggestion */
  docCount: Scalars['NonNegativeInt'];
};

export type ContentAutocompleteResult = {
  __typename?: 'ContentAutocompleteResult';
  /** The query used for this result */
  query: Scalars['String'];
  /** The hits found for the query */
  hits?: Maybe<Array<ContentAutocompleteHits>>;
};

export type ContentEdge = {
  __typename?: 'ContentEdge';
  /** The result's content object. */
  node?: Maybe<Content>;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
  /** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
  highlights?: Maybe<ContentHighlights>;
};

export type ContentEqualsValueFilter = {
  /** Returns only contents with the specified locale */
  locale?: Maybe<Scalars['String']>;
  /** Returns only contents of the specified type */
  type?: Maybe<ContentType>;
  /** Returns only contents with the specified language */
  language?: Maybe<Language>;
  /** Returns only contents with the specified version */
  version?: Maybe<Scalars['Long']>;
  /** Returns only topics with the specified mime type */
  mimeType?: Maybe<Scalars['String']>;
  /** Returns only contents with the specified source */
  source?: Maybe<Scalars['String']>;
  /** Returns only contents with the specified source id */
  sourceId?: Maybe<Scalars['String']>;
  /** Returns only contents used by the specified root content id */
  rootContentId?: Maybe<Scalars['ID']>;
  /** Returns only contents with the specified creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only contents with the specified modification date */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only contents with the specified upload date */
  uploadDate?: Maybe<Scalars['DateTime']>;
};

export enum ContentExistsValueFields {
  /** Returns only contents which have a locale specified */
  locale = 'locale',
  /** Return only contents which have a version specified */
  version = 'version'
}

export type ContentFilter = {
  /** Returns contents with one of the specified unique identifiers */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Returns contents with one of the specified content identifiers */
  contentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Returns contents that contain all of the exact values specified */
  equals?: Maybe<ContentEqualsValueFilter>;
  /** Returns contents that match all of the specified full text searches */
  fullText?: Maybe<ContentFullTextFilter>;
  /** Returns contents with values within all of the ranges specified */
  range?: Maybe<ContentRangeFilter>;
  /** Returns contents that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: Maybe<ContentTaxonomyFilter>;
  /** Returns contents that have a value in all of the specified fields */
  exists?: Maybe<Array<Maybe<ContentExistsValueFields>>>;
  /** Returns contents that match all of the regular expressions */
  regex?: Maybe<ContentRegexFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: Maybe<Array<Maybe<ContentFilter>>>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: Maybe<Array<Maybe<ContentFilter>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: Maybe<ContentFilter>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
};

export type ContentFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: Maybe<Scalars['Float']>;
  /** Match the query against any title language and rank the results with the specified boost */
  title?: Maybe<Scalars['Float']>;
  /** Match the query against any text language and rank the results with the specified boost */
  text?: Maybe<Scalars['Float']>;
};

export type ContentFullTextFilter = {
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Specifies if either all or any query words need to match */
  operator?: Maybe<FullTextOperator>;
  /** Fields to search in with their respective score weights */
  fields?: Maybe<ContentFullTextFields>;
  /** Type of fulltext search to perform */
  searchType?: Maybe<FullTextSearchType>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: Maybe<Scalars['Boolean']>;
  /** Options to configure the synonym search */
  synonymOptions?: Maybe<SynonymOptions>;
};

/** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
export type ContentHighlights = {
  __typename?: 'ContentHighlights';
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
export type ContentHighlightsTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
};


/** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
export type ContentHighlightsTextArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
};

/** A content map aggregates other content maps or topics into a table of content maps structure. */
export type ContentMap = Content & {
  __typename?: 'ContentMap';
  /** Unique identifier of the content map itself */
  contentId: Scalars['ID'];
  /** Date and time when the content map was created */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Document identifier */
  documentId?: Maybe<Scalars['ID']>;
  /** Unique identifier of the content map in its use context */
  id: Scalars['ID'];
  /** Language of the content map */
  language?: Maybe<Language>;
  /** Locale of the content map */
  locale?: Maybe<Scalars['String']>;
  /** Date and time of the last modification */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /**
   * Relevant notes for the content map.
   *
   * This field only shows private, pendingApproval notes created by the requesting user and any public notes
   * that reference the content.
   *
   * Even with administrative privileges this field only shows the above mentioned notes and therefore doesn't include any notes
   * which don't belong to the requesting user except the public ones. To view any note and filter them, refer to the
   * filter query for the notes.
   */
  notes?: Maybe<NoteQueryResult>;
  /** Flag if the content map is published */
  published?: Maybe<Scalars['Boolean']>;
  /** The rendition */
  rendition?: Maybe<ContentMapRendition>;
  /** The source this content was generated from */
  source?: Maybe<Scalars['String']>;
  /** The unique id of the source */
  sourceId?: Maybe<Scalars['String']>;
  /** Length limited text from the content map for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<ContentMapTeasers>;
  /** Title of the content map */
  title?: Maybe<MultilingualString>;
  /**
   * The content map's table of contents.
   * To fetch specific paths of the toc, specify the `paths` argument.
   * The root path is represented by the '~' character.
   * To fetch children of a `TocEdge` use the edge's `childPath` in the `paths` argument.
   * For each specified path, the immediate child-entries of the specified path will be returned.
   * If no `paths` are provided, the root-entries will be returned.
   */
  toc?: Maybe<TocResult>;
  /**
   * The content map's table of contents
   * @deprecated Field no longer supported
   */
  tocMap?: Maybe<Array<Maybe<TocMapEntry>>>;
  /** The type of the node */
  type?: Maybe<Scalars['String']>;
  /** Most recent date and time when the content map was uploaded */
  uploadDate?: Maybe<Scalars['DateTime']>;
  /** The use context in which this content map is used */
  useContext?: Maybe<UseContext>;
  /** Technical version of the content map */
  version?: Maybe<Scalars['Long']>;
  /** Readable label for the version */
  versionLabel?: Maybe<Scalars['String']>;
};


/** A content map aggregates other content maps or topics into a table of content maps structure. */
export type ContentMapNotesArgs = {
  filter?: Maybe<NoteFilter>;
};


/** A content map aggregates other content maps or topics into a table of content maps structure. */
export type ContentMapTocArgs = {
  paths?: Maybe<Array<Scalars['String']>>;
};

export type ContentMapEdge = {
  __typename?: 'ContentMapEdge';
  /** The result's content map. */
  node: ContentMap;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
  /** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
  highlights?: Maybe<ContentHighlights>;
};

export type ContentMapEqualsValueFilter = {
  /** Returns only documents with the specified document id */
  documentId?: Maybe<Scalars['String']>;
  /** Returns only content maps with the specified locale */
  locale?: Maybe<Scalars['String']>;
  /** Returns only content maps with the published flag set */
  published?: Maybe<Scalars['Boolean']>;
  /** Return only content maps with the specified language */
  language?: Maybe<Language>;
  /** Returns only contents with the specified version */
  version?: Maybe<Scalars['Long']>;
  /** Returns only contents with the specified version label */
  versionLabel?: Maybe<Scalars['String']>;
  /** Returns only contents with the specified rendition */
  rendition?: Maybe<ContentMapRendition>;
  /** Returns only contents with the specified source */
  source?: Maybe<Scalars['String']>;
  /** Returns only contents with the specified source id */
  sourceId?: Maybe<Scalars['String']>;
  /** Returns only content maps used by the specified root content id */
  rootContentId?: Maybe<Scalars['ID']>;
  /** Returns only content maps with the specified creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only content maps with the specified modification date */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only content maps with the specified upload date */
  uploadDate?: Maybe<Scalars['DateTime']>;
};

export enum ContentMapExistsValueFields {
  /** Returns only content maps which have a locale specified */
  locale = 'locale',
  /** Returns only content maps which have a version specified */
  version = 'version',
  /** Returns only content maps which have a version label specified */
  versionLabel = 'versionLabel',
  /** Returns only content maps which have a source specified */
  source = 'source',
  /** Returns only content maps which have a source id specified */
  sourceId = 'sourceId',
  /** Returns only content maps which have a rendition specified */
  rendition = 'rendition'
}

/**
 * The `ContentMapFilter` is used to specify filter criteria for content maps.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 *
 * Important: Datafields in this filter is only accessible through the namespaced graphql id defined in the
 * definition of the corresponding datafield. For further information see `DatafieldDefinition`.
 */
export type ContentMapFilter = {
  /** Returns content maps with one of the specified unique identifiers */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Returns content maps with one of the specified content identifiers */
  contentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Returns content maps that contain all of the exact values specified */
  equals?: Maybe<ContentMapEqualsValueFilter>;
  /** Returns content maps that match all of the specified full text searches */
  fullText?: Maybe<ContentMapFullTextFilter>;
  /** Returns content maps with values within all of the ranges specified */
  range?: Maybe<ContentMapRangeFilter>;
  /** Returns contents that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: Maybe<ContentMapTaxonomyFilter>;
  /** Returns content maps that have a value in all of the specified fields */
  exists?: Maybe<Array<Maybe<ContentMapExistsValueFields>>>;
  /** Returns content maps that match all of the regular expressions */
  regex?: Maybe<ContentMapRegexFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: Maybe<Array<Maybe<ContentMapFilter>>>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: Maybe<Array<Maybe<ContentMapFilter>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: Maybe<ContentMapFilter>;
  /** Boosts the relevance score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
};

export type ContentMapFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: Maybe<Scalars['Float']>;
  /** Match the query against any tile language and rank the results with the specified boost */
  title?: Maybe<Scalars['Float']>;
};

export type ContentMapFullTextFilter = {
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Specifies if either all or any query words need to match */
  operator?: Maybe<FullTextOperator>;
  /** Fields to search in with their respective score weights */
  fields?: Maybe<ContentMapFullTextFields>;
  /** Type of fulltext search to perform */
  searchType?: Maybe<FullTextSearchType>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: Maybe<Scalars['Boolean']>;
  /** Options for synonyms */
  synonymOptions?: Maybe<SynonymOptions>;
};

export type ContentMapQueryResult = {
  __typename?: 'ContentMapQueryResult';
  /**
   * The query's matching content maps.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  contentMaps: Array<Maybe<ContentMapEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
  /**
   * Matched datafield values and number of matching content map uses per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: Aggregations;
};


export type ContentMapQueryResultContentMapsArgs = {
  sort?: Maybe<Array<Maybe<ContentMapSort>>>;
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};


export type ContentMapQueryResultTotalArgs = {
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
};

export type ContentMapRangeFilter = {
  /** Returns only content maps with a creation date in the specified range */
  creationDate?: Maybe<DateRange>;
  /** Returns only content maps with a modification date in the specified range */
  modificationDate?: Maybe<DateRange>;
  /** Returns only content maps with a upload date in the specified range */
  uploadDate?: Maybe<DateRange>;
  /** Returns only contents with a version within the specified range */
  version?: Maybe<LongRange>;
};

export type ContentMapRegexFilter = {
  /** No regex filterable indexed field definitions exist. As soon as the first matching indexed field definition is defined, it's automatically listed here. */
  not_applicable_as_no_regex_filterable_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};

export enum ContentMapRendition {
  unknown = 'unknown'
}

export type ContentMapSort = {
  /** Sort field */
  field: ContentMapSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum ContentMapSortFields {
  /** Sort by relevance score */
  score = 'score',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by content id */
  contentId = 'contentId',
  /** Sort by title */
  title = 'title',
  /** Sort by locale */
  locale = 'locale',
  /** Sort by language */
  language = 'language',
  /** Sort by published flag */
  published = 'published',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by upload date */
  uploadDate = 'uploadDate',
  /** Sort by source */
  source = 'source',
  /** Sort by source id */
  sourceId = 'sourceId',
  /** Sort by rendition */
  rendition = 'rendition',
  /** Sort by version */
  version = 'version',
  /** Sort by version label */
  versionLabel = 'versionLabel'
}

export type ContentMapTaxonomyFilter = {
  /** No taxonomy indexed field definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the content map for use e.g. in result lists, previews, etc. */
export type ContentMapTeasers = ContentTeasers & {
  __typename?: 'ContentMapTeasers';
  /** The content map's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the content map for use e.g. in result lists, previews, etc. */
export type ContentMapTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
};

export type ContentQueryResult = {
  __typename?: 'ContentQueryResult';
  /**
   * The query's matching contents.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  contents: Array<Maybe<ContentEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
  /**
   * Matched datafield values and number of matching contents per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: Aggregations;
};


export type ContentQueryResultContentsArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<ContentSort>>>;
};


export type ContentQueryResultTotalArgs = {
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
};

export type ContentRangeFilter = {
  /** Returns only contents with a creation date in the specified range */
  creationDate?: Maybe<DateRange>;
  /** Returns only contents with a modification date in the specified range */
  modificationDate?: Maybe<DateRange>;
  /** Returns only contents with a upload date in the specified range */
  uploadDate?: Maybe<DateRange>;
  /** Returns only contents with a version within the specified range */
  version?: Maybe<LongRange>;
};

export type ContentRegexFilter = {
  /** No regex filterable indexed field definitions exist. As soon as the first matching indexed field definition is defined, it's automatically listed here. */
  not_applicable_as_no_regex_filterable_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};

export type ContentSort = {
  /** Sort field */
  field: ContentSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum ContentSortFields {
  /** Sort by relevance score */
  score = 'score',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by content id */
  contentId = 'contentId',
  /** Sort by title */
  title = 'title',
  /** Sort by locale */
  locale = 'locale',
  /** Sort by language */
  language = 'language',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by upload date */
  uploadDate = 'uploadDate',
  /** Sort by technical version */
  version = 'version',
  /** Sort by source */
  source = 'source',
  /** Sort by source id */
  sourceId = 'sourceId'
}

export type ContentTaxonomyFilter = {
  /** No taxonomy indexed field definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the content for use e.g. in result lists, previews, etc. */
export type ContentTeasers = {
  /** The content's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the content for use e.g. in result lists, previews, etc. */
export type ContentTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
};

export enum ContentType {
  /** Specifies the content map */
  contentMap = 'contentMap',
  /** Specifies the topic */
  topic = 'topic'
}

export enum CountRelation {
  /** The count is accurate and equals the actual count of hits */
  eq = 'eq',
  /** The result is inaccurate and only a lower bound of the actual possible hits */
  gte = 'gte',
  /** The result is inaccurate and only a upper bound of the actual possible hits */
  lte = 'lte'
}

export type CreateApiKeyInput = {
  /** The unique identifier of the user for whom the api key will be created */
  userId: Scalars['ID'];
  /** The api key's readable name */
  displayName: Scalars['String'];
  /** Date until which the api key will be valid */
  validUntil: Scalars['DateTime'];
};

export type CreateApiKeyResponse = {
  __typename?: 'CreateApiKeyResponse';
  /** The api key's details */
  key?: Maybe<ApiKey>;
  /**
   * The access token for the api key.
   * Please note: The token is a credential which grants access to resources and should be treated like a password.
   * To provide the best security for the token, it isn't stored and can only be retrieved once during creation
   * of an api key.
   */
  token: Scalars['String'];
  /**
   * The api pass is a verifiable string which contains all authentication information needed to access the application.
   * It can be distributed to trusted applications to provide access to the application without the need to retrieve
   * any other information.
   *
   * The api pass consists of the payload and the checksum part separated by a colon (:).
   * The payload part is a JSON object containing the url and the access token encoded in base 64.
   * To verify the integrity of the payload use the checksum part which is a md5 hash.
   *
   * Please note: Handle the pass with same caution as described in `token`.
   */
  apiPass: Scalars['String'];
};

export type CreateCollectionInput = {
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The detailed description */
  description?: Maybe<Scalars['String']>;
  /**
   * The visibility of the new collection.
   * Can't be changed after creation
   */
  visibility: CollectionVisibilityTypes;
};

export type CreateConnectorKeyInput = {
  /** The unique identifier of the user for whom the connector key will be created */
  userId: Scalars['ID'];
};

export type CreateNoteCommentInput = {
  /** The text of the comment */
  text?: Maybe<Scalars['String']>;
};

export type CreateNoteInput = {
  /** The note text */
  text?: Maybe<MultilingualStringInput>;
  /** The visibility for the note */
  visibility: NoteVisibilityTypesInput;
  /** The id of the object the note is created for */
  objectId: Scalars['ID'];
};

export type CreateOrUpdateUserSettings = {
  /**
   * The settings as a string key value map.
   *
   * Be aware: Key and value must of type string. Otherwise
   * an error will be thrown.
   */
  settings: Scalars['JSONObject'];
};

export type CustomDatafield = Datafield & {
  __typename?: 'CustomDatafield';
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced indexed field definition id */
  referencedId: Scalars['ID'];
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldTeasers>;
  /** The indexed datafield definition id */
  datafieldId: Scalars['DatafieldDefinitionID'];
  /** The full indexed field definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
};

export type CustomDatafieldFacet = Facet & {
  __typename?: 'CustomDatafieldFacet';
  /** The unique id of the facet */
  id: Scalars['ID'];
  /** The type of the facet */
  type: DatafieldType;
  /** Details about how the facet should be displayed by a frontend component */
  display: FacetDisplay;
  /**
   * Kind of facet, e.g. is it a filter or navigation facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  kind: Scalars['String'];
  /** The id of the field, indexed field definition etc. referenced by this facet */
  referencedId: Scalars['ID'];
  /**
   * The title of the facet.
   * If the facet doesn't have a custom title specified
   * and has a valid datafield id specified, the title of the datafield
   * definition will be used as title.
   */
  title?: Maybe<MultilingualString>;
  /** Length limited text */
  teasers?: Maybe<FacetTeasers>;
  /** The facets definition */
  definition?: Maybe<FacetDefinition>;
  /** The full indexed field definition referenced by the facet */
  datafieldDefinition: DatafieldDefinition;
};

export type DataDisplayConfig = {
  __typename?: 'DataDisplayConfig';
  /** The unique id */
  id: Scalars['ID'];
  /** Defines the separator characters with whom the information will be separated */
  separator: Scalars['String'];
  /**
   * Entries of this data display config.
   * The Field is ordered and always returns the entries in the defined order.
   */
  entries?: Maybe<Array<DataDisplayConfigEntry>>;
};

export type DataDisplayConfigDatafieldEntry = DataDisplayConfigEntry & {
  __typename?: 'DataDisplayConfigDatafieldEntry';
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced indexed field definition id */
  referencedId: Scalars['ID'];
  /** Indicates whether the title should be shown as prefix */
  showTitlePrefix: Scalars['Boolean'];
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DataDisplayConfigEntryTeasers>;
  /** The indexed field definition id */
  datafieldId: Scalars['DatafieldDefinitionID'];
  /** The full indexed field definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /**
   * Details about how the entry should be displayed by a frontend component.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  displayOptions?: Maybe<Scalars['JSONObject']>;
};

export type DataDisplayConfigEntry = {
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The id of the field, indexed field definition, etc. referenced by this entry */
  referencedId: Scalars['ID'];
  /** Indicates whether the title should be shown as prefix */
  showTitlePrefix: Scalars['Boolean'];
  /** The title of the entry */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the entry for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DataDisplayConfigEntryTeasers>;
  /**
   * Details about how the entry should be displayed by a frontend component.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  displayOptions?: Maybe<Scalars['JSONObject']>;
};

/** Length limited text value for use e.g. in result lists, previews, etc. */
export type DataDisplayConfigEntryTeasers = {
  __typename?: 'DataDisplayConfigEntryTeasers';
  /**
   * The value's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text value for use e.g. in result lists, previews, etc. */
export type DataDisplayConfigEntryTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

export type DataDisplayConfigFieldEntry = DataDisplayConfigEntry & {
  __typename?: 'DataDisplayConfigFieldEntry';
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced field name */
  referencedId: Scalars['ID'];
  /** Indicates whether the title should be shown as prefix */
  showTitlePrefix: Scalars['Boolean'];
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DataDisplayConfigEntryTeasers>;
  /** Indicates to which data types the entry belongs to. */
  nodeTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Details about how the entry should be displayed by a frontend component.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  displayOptions?: Maybe<Scalars['JSONObject']>;
};

export type Datafield = {
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The id of the field, indexed field definition, etc. referenced by this entry */
  referencedId: Scalars['ID'];
  /** The title of the entry */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the entry for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldTeasers>;
};

export type DatafieldAutocompleteEdge = {
  __typename?: 'DatafieldAutocompleteEdge';
  /**
   * Id of the datafield definition in which the value was found
   * This value can be used as field name content query filters
   */
  datafieldDefinitionId: Scalars['DatafieldDefinitionID'];
  /**
   * Unique value of the datafield definition value
   * This value can be used as operand in content query filters
   */
  value: Scalars['String'];
  /** Display text for this autocomplete hit */
  displayName: Scalars['String'];
  /**
   * Language which this hit is based on
   * Is only returned if an exact language assignment can be made
   */
  language?: Maybe<Language>;
  /** The full datafield definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
};

export type DatafieldAutocompleteFilter = {
  /**
   * String to search for
   * The provided query is executed as a prefix search
   * Fulltext features like wildcards, fuzzy search, etc. aren't supported
   */
  query: Scalars['String'];
  /**
   * Indexed field definitions to search in
   * Only definitions of type `taxonomy` and `enum` are supported
   * The order of datafield definitions specifies their precedence
   */
  datafieldDefinitionIds: Array<Scalars['DatafieldDefinitionID']>;
  /**
   * Languages to search in (if applicable for the datafield definition).
   * The order of languages specifies their precedence.
   */
  languages: Array<Language>;
};

export type DatafieldAutocompleteResult = {
  __typename?: 'DatafieldAutocompleteResult';
  /**
   * The search string which produced this result
   * Can be used by clients to correlate queries and responses
   */
  query: Scalars['String'];
  /** The search results */
  hits: Array<DatafieldAutocompleteEdge>;
};

export type DatafieldDefinition = {
  /**
   * Unique identifier of this datafield definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Type of this datafield definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /** Human readable description of this datafield definition */
  title?: Maybe<MultilingualString>;
  /** Field names which shall be mapped to this datafield definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Length limited text from the datafield definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
};

export type DatafieldDefinitionEdge = {
  __typename?: 'DatafieldDefinitionEdge';
  /** The result's datafield definition */
  node: DatafieldDefinition;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

export type DatafieldDefinitionEqualsValueFilter = {
  /** Returns only datafield definitions with the specified type */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /** Returns only datafield definitions with the specified title */
  title?: Maybe<Scalars['String']>;
};

/** The `DatafieldDefinitionFilter` is used to specify filter criteria for datafield definitions. */
export type DatafieldDefinitionFilter = {
  /** Returns datafield definitions with one of the specified unique identifiers */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Returns datafield definitions that contain all of the exact values specified */
  equals?: Maybe<DatafieldDefinitionEqualsValueFilter>;
  /** Returns datafield definitions that match all of the regular expressions */
  regex?: Maybe<DatafieldDefinitionRegexFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: Maybe<Array<Maybe<DatafieldDefinitionFilter>>>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: Maybe<Array<Maybe<DatafieldDefinitionFilter>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: Maybe<DatafieldDefinitionFilter>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
};


export type DatafieldDefinitionQueryResult = {
  __typename?: 'DatafieldDefinitionQueryResult';
  /**
   * The query's datafield definitions.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  fieldDefinitions: Array<Maybe<DatafieldDefinitionEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


export type DatafieldDefinitionQueryResultFieldDefinitionsArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<DatafieldDefinitionSort>>>;
};


export type DatafieldDefinitionQueryResultTotalArgs = {
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
};

export type DatafieldDefinitionRegexFilter = {
  /** Returns only datafield definitions whose event matches the specified Regex */
  fieldType?: Maybe<Scalars['FilterRegex']>;
  /** Returns only datafield definitions whose title matches the specified Regex */
  title?: Maybe<Scalars['String']>;
};

export type DatafieldDefinitionSort = {
  /** Sort field */
  field: DatafieldDefinitionSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum DatafieldDefinitionSortFields {
  /** Sort by relevance score */
  score = 'score',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by title */
  title = 'title',
  /** Sort by event type */
  eventType = 'eventType'
}

/** Length limited text from the datafield definition for use e.g. in result lists, previews, etc. */
export type DatafieldDefinitionTeasers = {
  __typename?: 'DatafieldDefinitionTeasers';
  /**
   * The datafield definition's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the datafield definition for use e.g. in result lists, previews, etc. */
export type DatafieldDefinitionTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

/** Length limited text from the indexed field definition value for use e.g. in result lists, previews, etc. */
export type DatafieldTeasers = {
  __typename?: 'DatafieldTeasers';
  /**
   * The value's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the indexed field definition value for use e.g. in result lists, previews, etc. */
export type DatafieldTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

export enum DatafieldType {
  field = 'field',
  metadata = 'metadata'
}

/** Length limited text from a defined datafield value for use e.g. in result lists, previews, etc. */
export type DatafieldValueTeasers = {
  __typename?: 'DatafieldValueTeasers';
  /**
   * The value's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from a defined datafield value for use e.g. in result lists, previews, etc. */
export type DatafieldValueTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

export type Datafields = {
  __typename?: 'Datafields';
  contentMap?: Maybe<Array<Datafield>>;
  /** Ignore this field */
  dummy?: Maybe<Scalars['Boolean']>;
  topic?: Maybe<Array<Datafield>>;
};

export type DateInclusiveRange = {
  __typename?: 'DateInclusiveRange';
  /** Less than or equal to */
  lte?: Maybe<Scalars['DateTime']>;
  /** Greater than or equal to */
  gte?: Maybe<Scalars['DateTime']>;
};

export type DateInclusiveRangeSpec = {
  /** Less than or equal to */
  lte?: Maybe<DateSpec>;
  /** Greater than or equal to */
  gte?: Maybe<DateSpec>;
};

export type DateOffset = {
  /** Amount of `unit` to offset. May be positive or negative. */
  amount: Scalars['Int'];
  /** Unit to offset */
  unit: DateOffsetUnit;
};

export enum DateOffsetUnit {
  years = 'years',
  months = 'months',
  weeks = 'weeks',
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds'
}

export type DateRange = {
  /** Less than */
  lt?: Maybe<DateSpec>;
  /** Less than or equal to */
  lte?: Maybe<DateSpec>;
  /** Greater than or equal to */
  gte?: Maybe<DateSpec>;
  /** Greater than */
  gt?: Maybe<DateSpec>;
};

export type DateSpec = {
  /** Anchor date for the date spec. If no date is provided defaults to <now>. */
  date?: Maybe<Scalars['DateTime']>;
  /** Zero or more date offsets relative to the anchor date. */
  offset?: Maybe<Array<Maybe<DateOffset>>>;
  /** Specifies if the resulting date should be rounded down to the nearest day. */
  roundDownToNearestDay?: Maybe<Scalars['Boolean']>;
};


/** Defines a date time field */
export type DateTimeDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'DateTimeDatafieldDefinition';
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Field names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
};


/**
 * Defines a enum datafield.
 * An enum datafield is a string field restricted to a fixed set of values.
 */
export type EnumDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'EnumDatafieldDefinition';
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** List of valid values */
  values?: Maybe<Array<EnumValue>>;
};

export type EnumFacetDefinition = FacetDefinition & {
  __typename?: 'EnumFacetDefinition';
  /** The datafield type of the field */
  fieldType: FieldDefinitionTypes;
  /** The values the field represented by this facet may have */
  values?: Maybe<Array<EnumValue>>;
};

/** Valid value for an enum */
export type EnumValue = {
  __typename?: 'EnumValue';
  /**
   * Data value
   * Must be unique within the enum
   */
  key: Scalars['String'];
  /** Human readable name */
  title?: Maybe<MultilingualString>;
  /** Length limited text from the enum value for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldValueTeasers>;
};

export type Facet = {
  /** The unique id of the facet */
  id: Scalars['ID'];
  /** The type of the facet */
  type: DatafieldType;
  /** Details about how the facet should be displayed by a frontend component */
  display: FacetDisplay;
  /**
   * Kind of facet, e.g. is it a filter or navigation facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  kind: Scalars['String'];
  /** The id of the field, indexed field definition etc. referenced by this facet */
  referencedId: Scalars['ID'];
  /** The title of the facet. */
  title?: Maybe<MultilingualString>;
  /** Length limited text */
  teasers?: Maybe<FacetTeasers>;
  /** The facets definition */
  definition?: Maybe<FacetDefinition>;
};

export type FacetDefinition = {
  /** The datafield type of the field */
  fieldType: FieldDefinitionTypes;
};

export type FacetDisplay = {
  __typename?: 'FacetDisplay';
  /**
   * Display type for the facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  type: Scalars['String'];
  /**
   * Display options for the facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  options?: Maybe<Scalars['JSONObject']>;
};

/** Length limited text from the facet for use e.g. in result lists, previews, etc. */
export type FacetTeasers = {
  __typename?: 'FacetTeasers';
  /**
   * The facets title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   * For further information about the titel see `Facet`'s title attribute.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the facet for use e.g. in result lists, previews, etc. */
export type FacetTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

export type FacetsCollection = {
  __typename?: 'FacetsCollection';
  /** The unique id of the facets collection */
  id: Scalars['ID'];
  /**
   * Ordered facets of the collection.
   * The order is specified by the order of the array.
   */
  facets?: Maybe<Array<Maybe<Facet>>>;
};


export type FacetsCollectionFacetsArgs = {
  referencedIds?: Maybe<Array<Scalars['String']>>;
};

export type Feature = {
  __typename?: 'Feature';
  /** The unique id of the feature */
  id: Scalars['ID'];
};

export enum FieldDefinitionTypes {
  dateTime = 'dateTime',
  enum = 'enum',
  keyword = 'keyword',
  number = 'number',
  taxonomy = 'taxonomy',
  text = 'text'
}

export type FieldFacet = Facet & {
  __typename?: 'FieldFacet';
  /** The unique id of the facet */
  id: Scalars['ID'];
  /** The type of the facet */
  type: DatafieldType;
  /** Details about how the facet should be displayed by a frontend component */
  display: FacetDisplay;
  /**
   * Kind of facet, e.g. is it a filter or navigation facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  kind: Scalars['String'];
  /** The id of the field, indexed field definition etc. referenced by this facet */
  referencedId: Scalars['ID'];
  /** The title of the facet. */
  title?: Maybe<MultilingualString>;
  /** Length limited text */
  teasers?: Maybe<FacetTeasers>;
  /** The facets definition */
  definition?: Maybe<FacetDefinition>;
};


export type FloatRange = {
  /** Less than */
  lt?: Maybe<Scalars['Float']>;
  /** Less than or equal to */
  lte?: Maybe<Scalars['Float']>;
  /** Greater than or equal to */
  gte?: Maybe<Scalars['Float']>;
  /** Greater than */
  gt?: Maybe<Scalars['Float']>;
};

export enum FullTextOperator {
  /** Contents must contain all of the query's words */
  and = 'AND',
  /** Contents must contain only one of the query's words */
  or = 'OR'
}


export enum FullTextSearchType {
  /** Finds contents which match any field, but uses the score from the best field */
  bestFields = 'best_fields',
  /** Finds contents which match any field and combines the score from each field */
  mostFields = 'most_fields',
  /** Returns contents that contain the words of a provided text, in the same order as provided. */
  phrase = 'phrase',
  /** Returns contents that match all terms in the provided text except the last term. The last term is used as a prefix search */
  prefix = 'prefix'
}


export type InherentDatafield = Datafield & {
  __typename?: 'InherentDatafield';
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced field name */
  referencedId: Scalars['ID'];
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldTeasers>;
};



/**
 * Defines a keyword datafield.
 * A keyword datafield is a string field which can be used for filtering by exact values or regex matches.
 */
export type KeywordDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'KeywordDatafieldDefinition';
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
};

export type KeywordFacetDefinition = FacetDefinition & {
  __typename?: 'KeywordFacetDefinition';
  /** The datafield type of the field */
  fieldType: FieldDefinitionTypes;
};

export enum Language {
  ar = 'ar',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  hu = 'hu',
  it = 'it',
  ja = 'ja',
  nb = 'nb',
  nl = 'nl',
  pt = 'pt',
  ro = 'ro',
  ru = 'ru',
  sv = 'sv',
  tr = 'tr',
  zh = 'zh'
}

export enum LanguageWithWildcard {
  ar = 'ar',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  hu = 'hu',
  it = 'it',
  ja = 'ja',
  nb = 'nb',
  nl = 'nl',
  pt = 'pt',
  ro = 'ro',
  ru = 'ru',
  sv = 'sv',
  tr = 'tr',
  zh = 'zh',
  any = 'any'
}

export type LanguagesQueryResult = {
  __typename?: 'LanguagesQueryResult';
  /** The tenant´s default language */
  default?: Maybe<Scalars['String']>;
  /** Available languages for the tenant */
  available?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type LongInclusiveRange = {
  __typename?: 'LongInclusiveRange';
  /** Less than or equal to */
  lte?: Maybe<Scalars['Float']>;
  /** Greater than or equal to */
  gte?: Maybe<Scalars['Float']>;
};

export type LongInclusiveRangeSpec = {
  /** Less than or equal to */
  lte?: Maybe<Scalars['Float']>;
  /** Greater than or equal to */
  gte?: Maybe<Scalars['Float']>;
};

export type LongRange = {
  /** Less than */
  lt?: Maybe<Scalars['Float']>;
  /** Less than or equal to */
  lte?: Maybe<Scalars['Float']>;
  /** Greater than or equal to */
  gte?: Maybe<Scalars['Float']>;
  /** Greater than */
  gt?: Maybe<Scalars['Float']>;
};

/** A string in a single or multiple of the available languages */
export type MultilingualString = {
  __typename?: 'MultilingualString';
  ar?: Maybe<Scalars['String']>;
  da?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  el?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fi?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  nb?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
  ro?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
  tr?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
};

/** A string in a single or multiple of the available languages */
export type MultilingualStringInput = {
  ar?: Maybe<Scalars['String']>;
  da?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  el?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fi?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  nb?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
  ro?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
  tr?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Add contents to a collection.
   * `idsOfContents` takes a list of ids of content-like objects (e.g. `Topic` and `ContentMap`).
   * Please take care to use the objects' `id`s and not the objects' `contentId`s.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to add contents to a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  addContentsToCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Create an api key for the user
   *
   * Required permission\
   * Resource: **users.api-keys**\
   * Action: **write**
   */
  createApiKey: CreateApiKeyResponse;
  /**
   * Create a new collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to be able to create collections with the visibility set to 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  createCollection: Scalars['ID'];
  /**
   * Create a connector key for the user.
   * A connector key is a special kind of api key which is limited to uploading contents and
   * syncing notes.
   *
   * Required permission\
   * Resource: **users.api-keys**\
   * Action: **write**
   */
  createConnectorKey: CreateApiKeyResponse;
  /**
   * Create a new note.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **write**
   *
   * See below for additional permission required to be able to create notes with the visibility status set to 'public'.
   * If the invoking user doesn't have the permission below and creates a note with the visibility 'public', the note
   * will be on status 'pendingApproval' (see `VisibilityTypes`).
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **write.public**
   */
  createNote: Scalars['ID'];
  /**
   * Create new comment for a note.
   *
   * Required permission\
   * Resource: **notes.comments**\
   * Action: **write**
   */
  createNoteComment: Scalars['ID'];
  /**
   * Create or update user settings.
   *
   * Required permission\
   * Resource: **users-settings**\
   * Action: **write**
   */
  createOrUpdateUserSettings: UserSettings;
  /**
   * Delete an existing collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **delete**
   *
   * See below for additional permission required to be able to delete a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **delete.public**
   */
  deleteCollection: Scalars['ID'];
  /**
   * Delete an existing note.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **delete**
   */
  deleteNote: Scalars['ID'];
  /**
   * Delete multiple attachments from a note.
   *
   * Required permission\
   * Resource: **notes.attachments**\
   * Action: **delete**
   */
  deleteNoteAttachments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Delete an existing note comment.
   *
   * Required permission\
   * Resource: **notes.comments**\
   * Action: **delete**
   */
  deleteNoteComment: Scalars['ID'];
  /**
   * Delete entries in the user settings.
   *
   * Required permission\
   * Resource: **users-settings**\
   * Action: **write**
   */
  deleteUserSettingEntries: Scalars['Boolean'];
  /**
   * Delete the user settings.
   *
   * Required permission\
   * Resource: **users-settings**\
   * Action: **delete**
   */
  deleteUserSettings: Scalars['Boolean'];
  /**
   * Marks notification as read. Gets notification by id and sets read date.
   *
   * Required permission\
   * Resource: **notifications**\
   * Action: **mark-as-read**
   */
  markNotificationsAsRead: Array<Maybe<Scalars['ID']>>;
  /** Connection test. Responds with the provided `tagLine` */
  ping?: Maybe<Scalars['String']>;
  /**
   * Remove contents from a collection.
   * `idsOfContents` takes a list of ids of content-like objects (e.g. `Topic` and `ContentMap`).
   * Please take care to use the objects' `id`s and not the objects' `contentId`s.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to remove contents from a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  removeContentsFromCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Revoke an api key for the user by it's unique id
   *
   * Required permission\
   * Resource: **users.api-keys**\
   * Action: **delete**
   */
  revokeApiKey: Scalars['ID'];
  /**
   * Update an existing collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to be able to update a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  updateCollection: Collection;
  /**
   * Update an existing note.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **write**
   *
   * See below for additional permission required to be able to update the visibility status to 'public'. If the
   * invoking user doesn't have the permission below and creates a note with the visibility 'public', the note will be
   * on status 'pendingApproval' (see `VisibilityTypes`).
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **write.public**
   */
  updateNote: Note;
  /**
   * Update an existing note comment.
   *
   * Required permission\
   * Resource: **notes.comments**\
   * Action: **write**
   */
  updateNoteComment: NoteComment;
};


export type MutationAddContentsToCollectionArgs = {
  idsOfContents: Array<Scalars['ID']>;
  collectionId: Scalars['ID'];
};


export type MutationCreateApiKeyArgs = {
  apiKeySpec: CreateApiKeyInput;
};


export type MutationCreateCollectionArgs = {
  spec?: Maybe<CreateCollectionInput>;
};


export type MutationCreateConnectorKeyArgs = {
  connectorKeySpec: CreateConnectorKeyInput;
};


export type MutationCreateNoteArgs = {
  noteSpec: CreateNoteInput;
};


export type MutationCreateNoteCommentArgs = {
  noteId: Scalars['ID'];
  commentSpec: CreateNoteCommentInput;
};


export type MutationCreateOrUpdateUserSettingsArgs = {
  diff?: Maybe<CreateOrUpdateUserSettings>;
};


export type MutationDeleteCollectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNoteAttachmentsArgs = {
  noteId: Scalars['ID'];
  attachmentIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationDeleteNoteCommentArgs = {
  noteId: Scalars['ID'];
  commentId: Scalars['ID'];
};


export type MutationDeleteUserSettingEntriesArgs = {
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationMarkNotificationsAsReadArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type MutationPingArgs = {
  tagLine?: Maybe<Scalars['String']>;
};


export type MutationRemoveContentsFromCollectionArgs = {
  idsOfContents: Array<Scalars['ID']>;
  collectionId: Scalars['ID'];
};


export type MutationRevokeApiKeyArgs = {
  userId: Scalars['ID'];
  apiKeyId: Scalars['ID'];
};


export type MutationUpdateCollectionArgs = {
  diff?: Maybe<UpdateCollectionInput>;
};


export type MutationUpdateNoteArgs = {
  partialNote: UpdateNoteInput;
};


export type MutationUpdateNoteCommentArgs = {
  noteId: Scalars['ID'];
  partialComment: UpdateNoteCommentInput;
};








export type Note = {
  __typename?: 'Note';
  /** The unique id */
  id: Scalars['ID'];
  /** The user who owns the note */
  user?: Maybe<User>;
  /** The note text */
  text?: Maybe<MultilingualString>;
  /** Length limited text from the note for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<NoteTeasers>;
  /** Optional attachments for the note (e.g. images) */
  attachments?: Maybe<Array<Maybe<BlobRef>>>;
  /** Content the note was created for */
  content?: Maybe<Content>;
  /** Comments for the note */
  comments?: Maybe<Array<Maybe<NoteComment>>>;
  /** The visibility for the note */
  visibility?: Maybe<NoteVisibilityTypes>;
  /** ID of object referenced by the note */
  objectId?: Maybe<Scalars['ID']>;
  /** Type of object referenced by the note */
  objectType?: Maybe<Scalars['String']>;
  /**
   * User that reviewed the note and approved publication.
   *
   * If the note gets revoked, the reviewer will not be updated until the
   * note is again in review process and is reviewed by eventually another reviewer.
   */
  reviewer?: Maybe<User>;
  /**
   * The latest date the note got approved for publication.
   *
   * Due to the possibility that the note gets revoked, the field shows the latest approval and is no indicator
   * if the note is currently public.
   */
  approvalDate?: Maybe<Scalars['DateTime']>;
  /** The creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** The latest modification date */
  modificationDate?: Maybe<Scalars['DateTime']>;
};

export type NoteComment = {
  __typename?: 'NoteComment';
  /** The unique id of the note comment */
  id: Scalars['ID'];
  /** The text of the comment */
  text?: Maybe<Scalars['String']>;
  /** The user who created the comment */
  user?: Maybe<User>;
  /** The creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** The latest modification date */
  modificationDate?: Maybe<Scalars['DateTime']>;
};

export type NoteEdge = {
  __typename?: 'NoteEdge';
  /** The result's note object */
  node: Note;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

export type NoteEqualsValueFilter = {
  /** Returns only notes which reference the specified object id */
  objectId?: Maybe<Scalars['ID']>;
  /** Returns only notes with the specified visibility */
  visibility?: Maybe<NoteVisibilityTypes>;
  /** Returns only notes owned by an user with the specified id */
  userId?: Maybe<Scalars['ID']>;
  /** Returns only notes reviewed by an user with the specified id */
  reviewerId?: Maybe<Scalars['ID']>;
  /** Returns only notes with the specified creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only notes with the specified modification date */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only notes with the specified approval date */
  approvalDate?: Maybe<Scalars['DateTime']>;
};

export type NoteFilter = {
  /** Filter by ids */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Full text search in notes */
  fullText?: Maybe<NoteFullTextFilter>;
  /** Returns notes that contain all of the exact values specified */
  equals?: Maybe<NoteEqualsValueFilter>;
  /** Returns notes with values within all of the ranges specified */
  range?: Maybe<NoteRangeFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: Maybe<Array<Maybe<NoteFilter>>>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: Maybe<Array<Maybe<NoteFilter>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: Maybe<NoteFilter>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
};

export type NoteFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: Maybe<Scalars['Float']>;
  /** Match the query against the note text and rank the results with the specified boost */
  text?: Maybe<Scalars['Float']>;
};

export type NoteFullTextFilter = {
  /** Text to search for */
  query: Scalars['FullTextSearchQuery'];
  /** Specifies if either all or any query words need to match */
  operator?: Maybe<FullTextOperator>;
  /** Fields to search in with their respective score weights */
  fields?: Maybe<NoteFullTextFields>;
  /** Type of fulltext search to perform */
  searchType?: Maybe<FullTextSearchType>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: Maybe<Scalars['Boolean']>;
};

export type NoteQueryResult = {
  __typename?: 'NoteQueryResult';
  /**
   * The query's matching notes.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  notes: Array<Maybe<NoteEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


export type NoteQueryResultNotesArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<NoteSort>>>;
};


export type NoteQueryResultTotalArgs = {
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
};

export type NoteRangeFilter = {
  /** Returns only notes with an approval date in the specified range */
  approvalDate?: Maybe<DateRange>;
  /** Returns only notes with a creation date in the specified range */
  creationDate?: Maybe<DateRange>;
  /** Returns only notes with a modification date in the specified range */
  modificationDate?: Maybe<DateRange>;
};

export type NoteSort = {
  /** Sort field */
  field: NoteSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum NoteSortFields {
  /** Sort by relevance score */
  score = 'score',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by object id */
  objectId = 'objectId',
  /** Sort by visibility */
  visibility = 'visibility',
  /** Sort by user id */
  userId = 'userId',
  /** Sort by reviewer id */
  reviewerId = 'reviewerId',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by approval date */
  approvalDate = 'approvalDate'
}

/** Length limited text from the note for use e.g. in result lists, previews, etc. */
export type NoteTeasers = {
  __typename?: 'NoteTeasers';
  /**
   * The notes text limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  text?: Maybe<Scalars['String']>;
};


/** Length limited text from the note for use e.g. in result lists, previews, etc. */
export type NoteTeasersTextArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

export type NoteTextUploadConfig = {
  __typename?: 'NoteTextUploadConfig';
  /** The maximal allowed text length per language */
  maxLength: Scalars['NonNegativeInt'];
};

/** The different visibility types for notes */
export enum NoteVisibilityTypes {
  /** Only visible to users with administrative privileges and the creator itself */
  private = 'private',
  /** Visible to everyone */
  public = 'public',
  /**
   * Indicates that the note is marked for publication, but has not yet approved
   * by an user with administrative privileges.
   *
   * Visibility is equal to 'private'.
   */
  pendingApproval = 'pendingApproval'
}

export enum NoteVisibilityTypesInput {
  /** Visible to users with administrative privileges and the creator itself */
  private = 'private',
  /**
   * Visible to everyone with access to the referenced content.
   *
   * If the user doesn't have privileges to publish notes, the visibility state is changed to 'pendingApproval'
   * and needs to be reviewed by an user with administrative privileges. If the note gets approved the state is changed
   * to 'public', in case the note doesn't get approved the state is changed to 'private'
   */
  public = 'public'
}

export type NotesUploadConfig = {
  __typename?: 'NotesUploadConfig';
  /** Upload config for the text */
  text?: Maybe<NoteTextUploadConfig>;
  /** Upload config for the note attachments */
  attachments?: Maybe<UploadMultipleConfig>;
};

export type Notification = {
  __typename?: 'Notification';
  /** The unique id */
  id: Scalars['ID'];
  /** The notification text */
  text?: Maybe<MultilingualString>;
  /** ID of object referenced by the notification */
  objectId?: Maybe<Scalars['ID']>;
  /** ID of object referenced by the notification */
  objectType?: Maybe<Scalars['String']>;
  /** The type of the notification. */
  notificationType?: Maybe<Scalars['String']>;
  /** Users that should receive the notification */
  users?: Maybe<Array<Maybe<User>>>;
  /** The creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Date the user read / clicked on the notification */
  readDate?: Maybe<Scalars['DateTime']>;
  /** Length limited text from the notification for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<NotificationTeasers>;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** The result's notification object */
  node: Notification;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

export type NotificationEqualsValueFilter = {
  /** Returns only notifications with the specified object id */
  objectId?: Maybe<Scalars['ID']>;
  /** Returns only notifications with the specified object type */
  objectType?: Maybe<Scalars['String']>;
  /** Returns only notifications with the specified type */
  notificationType?: Maybe<Scalars['String']>;
  /** Returns only notifications with the specified user id */
  userId?: Maybe<Scalars['ID']>;
  /** Returns only notifications with the specified creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only notifications with the specified read date */
  readDate?: Maybe<Scalars['DateTime']>;
};

export enum NotificationExistsValueFields {
  /** Returns only notifications which have a read date specified */
  readDate = 'readDate'
}

export type NotificationFilter = {
  /** Allows to filter all notifications */
  all?: Maybe<Scalars['Boolean']>;
  /** Filter by ids */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Full text search in notifications */
  fullText?: Maybe<NotificationFullTextFilter>;
  /** Returns notifications that contain all of the exact values specified */
  equals?: Maybe<NotificationEqualsValueFilter>;
  /** Returns notifications that have a value in all of the specified fields */
  exists?: Maybe<Array<Maybe<NotificationExistsValueFields>>>;
  /** Returns notifications with values within all of the ranges specified */
  range?: Maybe<NotificationRangeFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: Maybe<Array<Maybe<NotificationFilter>>>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: Maybe<Array<Maybe<NotificationFilter>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: Maybe<NotificationFilter>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
};

export type NotificationFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: Maybe<Scalars['Float']>;
  /** Match the query against the notification message and rank the results with the specified boost */
  text?: Maybe<Scalars['Float']>;
};

export type NotificationFullTextFilter = {
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Specifies if either all or any query words need to match */
  operator?: Maybe<FullTextOperator>;
  /** Fields to search in with their respective score weights */
  fields?: Maybe<NotificationFullTextFields>;
  /** Type of fulltext search to perform */
  searchType?: Maybe<FullTextSearchType>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: Maybe<Scalars['Boolean']>;
};

export type NotificationQueryResult = {
  __typename?: 'NotificationQueryResult';
  /**
   * The query's matching notifications.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  notifications: Array<Maybe<NotificationEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


export type NotificationQueryResultNotificationsArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<NotificationSort>>>;
};


export type NotificationQueryResultTotalArgs = {
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
};

export type NotificationRangeFilter = {
  /** Returns only notifications with a creation date in the specified range */
  creationDate?: Maybe<DateRange>;
  /** Returns only notifications with a read date in the specified range */
  readDate?: Maybe<DateRange>;
};

export type NotificationSort = {
  /** Sort field */
  field: NotificationSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum NotificationSortFields {
  /** Sort by relevance score */
  score = 'score',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by creation date */
  creationDate = 'creationDate'
}

/** Length limited text from the notification for use e.g. in result lists, previews, etc. */
export type NotificationTeasers = {
  __typename?: 'NotificationTeasers';
  /**
   * The notifications title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  text?: Maybe<Scalars['String']>;
};


/** Length limited text from the notification for use e.g. in result lists, previews, etc. */
export type NotificationTeasersTextArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

/** Defines a numeric datafield. */
export type NumberDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'NumberDatafieldDefinition';
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Optional minimum value for this datafield */
  min?: Maybe<Scalars['Float']>;
  /** Optional maximum value for this datafield */
  max?: Maybe<Scalars['Float']>;
};





export type Query = {
  __typename?: 'Query';
  _blobRefsByBlobIds?: Maybe<Array<BlobRef>>;
  /**
   * Internal for graph composition.
   * Returns the graph's full SDL including information not available via introspection.
   */
  _sdl?: Maybe<Scalars['String']>;
  /** Internal query to extend users */
  _usersByIds?: Maybe<Array<User>>;
  /** Returns the active access control configuration */
  accessControlSettings: AccessControlSettings;
  /** Returns details about the application providing this API */
  api?: Maybe<ComponentInfo>;
  /**
   * Queries the available features for the tenant.
   *
   * Required permission\
   * Resource: **features**\
   * Action: **query**
   */
  availableFeatures?: Maybe<Array<Maybe<Feature>>>;
  /**
   * Fetch `Blob`s by filter criteria.
   *
   * Required permission\
   * Resource: **blobs**\
   * Action: **query**
   */
  blobs?: Maybe<BlobQueryResult>;
  /**
   * Get a single collection by its unique id.
   * This query allows access to public collections, personal collections of the requesting user as well as
   * personal collections of other users and thus can be used to get the details of a shared collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **query.by-id**
   */
  collection?: Maybe<Collection>;
  /**
   * Query `Collection`s. Filters can be specified via the `filter` argument. See `CollectionFilter` for further details.
   * The results can contain public collections as well as personal collections of the requesting user. Personal
   * collections of other users won't be returned.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **query**
   */
  collections: CollectionQueryResult;
  /**
   * Query word-based autocomplete suggestions for contents.
   * Suggestions are found within content titles and extracted texts
   * and must be at least 3 characters long. The result contains up to
   * 5 suggestions.
   *
   * Required permission\
   * Resource: **contents.autocomplete**\
   * Action: **query**
   */
  contentAutocomplete: ContentAutocompleteResult;
  /**
   * Query the uses of a specific content in an use context (e.g. in a content map).
   *
   * Returns all uses of the content in the specified context as TOC entries.
   */
  contentInContext?: Maybe<Array<TocEdge>>;
  /** Query available `ContentMap`s. Filters can be specified via the `filter` argument. See `ContentMapFilter` for further details. */
  contentMaps: ContentMapQueryResult;
  /** Query available `Content`s. Filters can be specified via the `filter` argument. See `ContentFilter` for further details. */
  contents: ContentQueryResult;
  /**
   * Query a data display config by its unique id.
   *
   * Required permission\
   * Resource: **data-display-configs**\
   * Action: **query**
   */
  dataDisplayConfig?: Maybe<DataDisplayConfig>;
  /**
   * Returns autocomplete suggestions from the values of the selected datafield definitions.
   * The query string is compared against the value's title and the first matching values are returned.
   *
   * Required permission\
   * Resource: **datafield-definitions.autocomplete**\
   * Action: **query**
   */
  datafieldAutocomplete: DatafieldAutocompleteResult;
  /**
   * Get a `DatafieldDefinition` by the definition's unique id
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **query**
   */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /**
   * Queries all available fields which may be
   * used as entry in different list configs.
   *
   * Required permission\
   * Resource: **datafield.type**\
   * Action: **query**
   */
  datafields?: Maybe<Datafields>;
  /**
   * Query `Collection`s which are editable by the current user.
   * Filters can be specified via the `filter` argument. See `CollectionFilter` for further details.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   */
  editableCollections: CollectionQueryResult;
  /**
   * Query a facets collection by id.
   *
   * Required permission\
   * Resource: **facet-collections**\
   * Action: **query**
   */
  facetsCollection?: Maybe<FacetsCollection>;
  /**
   * Query `DatafieldDefinition`s. Filters can be specified via the `filter` argument. See `DatafieldDefinitionFilter` for further details.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **query**
   */
  fieldDefinitions: DatafieldDefinitionQueryResult;
  /**
   * Get the requesting users user settings. The settings may be filtered
   * by setting the `keys` argument to only receive the specified entries.
   * To get all settings omit the keys parameter.
   *
   * Required permission\
   * Resource: **users-settings**\
   * Action: **query**
   */
  getUserSettings?: Maybe<UserSettings>;
  /**
   * Returns the current user's data.
   *
   * Required permission\
   * Resource: **users.me**\
   * Action: **query**
   */
  me: User;
  /**
   * Get a single note by its unique id.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **query**
   */
  note?: Maybe<Note>;
  /**
   * Query `Note`s. Filters con be specified via the `filter` argument. See `NoteFilter` for further details.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **query**
   */
  notes?: Maybe<NoteQueryResult>;
  /**
   * Query Notifications. Filters can be specified via the `filter` argument. See `NotificationFilter` for further details.
   *
   * Required permission\
   * Resource: **notifications**\
   * Action: **query**
   */
  notifications?: Maybe<NotificationQueryResult>;
  /**
   * Query a search config by id.
   *
   * Required permission\
   * Resource: **search-configs**\
   * Action: **query**
   */
  searchConfig?: Maybe<SearchConfig>;
  /**
   * Get an static page by page type.
   * The acceptedLanguages field can be used to tell the system which languages are accepted by the consumer.
   * Heads up: The order of the languages controls their priority in descending order, thus the foremost found language will be returned.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **query**
   */
  staticPage?: Maybe<StaticPage>;
  /**
   * Query the static page menu.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **query**
   */
  staticPageMenu?: Maybe<StaticPageMenu>;
  /** Returns the current tenant. */
  tenant: Tenant;
  /**
   * Queries the theme configuration.
   * If there's no custom theme defined, it will return the default theme.
   */
  theme?: Maybe<Theme>;
  /** Query available `Topic`s. Filters can be specified via the `filter` argument. See `TopicFilter` for further details. */
  topics: TopicQueryResult;
  /**
   * Returns the server version.
   * Deprecated. Use query `api { version }` instead
   * @deprecated Field no longer supported
   */
  version?: Maybe<VersionInfo>;
};


export type Query_BlobRefsByBlobIdsArgs = {
  blobIds: Array<Scalars['ID']>;
};


export type Query_UsersByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryBlobsArgs = {
  filter?: Maybe<BlobFilter>;
};


export type QueryCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryCollectionsArgs = {
  filter?: Maybe<CollectionFilter>;
};


export type QueryContentAutocompleteArgs = {
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<ContentFilter>;
};


export type QueryContentInContextArgs = {
  rootContentId?: Maybe<Scalars['ID']>;
  contentId?: Maybe<Scalars['ID']>;
};


export type QueryContentMapsArgs = {
  filter?: Maybe<ContentMapFilter>;
};


export type QueryContentsArgs = {
  filter?: Maybe<ContentFilter>;
};


export type QueryDataDisplayConfigArgs = {
  id: Scalars['ID'];
};


export type QueryDatafieldAutocompleteArgs = {
  filter: DatafieldAutocompleteFilter;
  first?: Maybe<Scalars['NonNegativeInt']>;
};


export type QueryDatafieldDefinitionArgs = {
  id: Scalars['DatafieldDefinitionID'];
};


export type QueryEditableCollectionsArgs = {
  filter?: Maybe<CollectionFilter>;
};


export type QueryFacetsCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryFieldDefinitionsArgs = {
  filter?: Maybe<DatafieldDefinitionFilter>;
};


export type QueryGetUserSettingsArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
};


export type QueryNoteArgs = {
  id: Scalars['ID'];
};


export type QueryNotesArgs = {
  filter?: Maybe<NoteFilter>;
};


export type QueryNotificationsArgs = {
  filter?: Maybe<NotificationFilter>;
};


export type QuerySearchConfigArgs = {
  id: Scalars['ID'];
};


export type QueryStaticPageArgs = {
  pageType: Scalars['String'];
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};


export type QueryTopicsArgs = {
  filter?: Maybe<TopicFilter>;
};



export type RawDatafield = {
  __typename?: 'RawDatafield';
  key: Scalars['String'];
  values: Array<RawDatafieldValue>;
};

export type RawDatafieldSpec = {
  key: Scalars['String'];
  values: Array<RawDatafieldValueSpec>;
};

export type RawDatafieldValue = {
  __typename?: 'RawDatafieldValue';
  value: Scalars['String'];
  metaMetadata?: Maybe<Scalars['JSONObject']>;
};

export type RawDatafieldValueSpec = {
  value: Scalars['String'];
  metaMetadata?: Maybe<Scalars['JSONObject']>;
};

export type SearchConfig = {
  __typename?: 'SearchConfig';
  /** The unique id */
  id: Scalars['ID'];
  /**
   * Entries of this search config
   * The Field is ordered and always returns the entries in the defined order.
   */
  entries?: Maybe<Array<SearchConfigEntry>>;
};

export type SearchConfigDatafieldEntry = SearchConfigEntry & {
  __typename?: 'SearchConfigDatafieldEntry';
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced indexed field definition id */
  referencedId: Scalars['ID'];
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<SearchConfigEntryTeasers>;
  /** The indexed field definition id */
  datafieldId: Scalars['DatafieldDefinitionID'];
  /** The full indexed field definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
};

export type SearchConfigEntry = {
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The id of the field, indexed field definition, etc. referenced by this entry */
  referencedId: Scalars['ID'];
  /** The title of the entry */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the entry for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<SearchConfigEntryTeasers>;
};

/** Length limited text from the indexed field definition value for use e.g. in result lists, previews, etc. */
export type SearchConfigEntryTeasers = {
  __typename?: 'SearchConfigEntryTeasers';
  /**
   * The value's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the indexed field definition value for use e.g. in result lists, previews, etc. */
export type SearchConfigEntryTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

export type SearchConfigFieldEntry = SearchConfigEntry & {
  __typename?: 'SearchConfigFieldEntry';
  /** The entries type */
  type: DatafieldType;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced field name */
  referencedId: Scalars['ID'];
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Length limited text for the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<SearchConfigEntryTeasers>;
};

export enum SortOrder {
  /** Ascending */
  asc = 'asc',
  /** Descending */
  desc = 'desc'
}

export type StaticPage = {
  __typename?: 'StaticPage';
  /** Unique identifier for the static page */
  id: Scalars['ID'];
  /** The title */
  title: Scalars['String'];
  /** The page type */
  pageType?: Maybe<Scalars['String']>;
  /** The content of the page */
  contents?: Maybe<Array<Maybe<BlobRef>>>;
  /** The language of the static page */
  locale?: Maybe<Scalars['String']>;
};

export type StaticPageMenu = {
  __typename?: 'StaticPageMenu';
  /** Unique id of the page menu */
  id: Scalars['ID'];
  /** The static pages referenced by this menu */
  pages?: Maybe<Array<Maybe<StaticPageMenuEntry>>>;
};

export type StaticPageMenuEntry = {
  __typename?: 'StaticPageMenuEntry';
  /** The page type of the referenced static pages */
  pageType: Scalars['String'];
  /** The combined titles of the differntly localized static pages */
  title?: Maybe<MultilingualString>;
  /** Length limited text from the combined titles for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<StaticPageMenuEntryTeasers>;
  /** The full static pages */
  staticPages?: Maybe<Array<Maybe<StaticPage>>>;
};

/** Length limited text from the note for use e.g. in result lists, previews, etc. */
export type StaticPageMenuEntryTeasers = {
  __typename?: 'StaticPageMenuEntryTeasers';
  /** The static page's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the note for use e.g. in result lists, previews, etc. */
export type StaticPageMenuEntryTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard>>;
};

export type SynonymOptions = {
  /** Indicates whether the synonyms are ignored in the search */
  ignore?: Maybe<Scalars['Boolean']>;
  /** The languages synonyms will be searched in */
  languages?: Maybe<Array<Maybe<Language>>>;
  /**
   * Indicates if a fuzzy search should be performed on synonyms matching.
   *
   * Be aware: This options doesn't enable fuzzy searching on documents. When enabled
   * the matching synonyms for the query will be retrieved using fuzzy search. To configure fuzzy search
   * for the content search itself please refer to fuzzy parameter in the full text search options.
   */
  fuzzy?: Maybe<Scalars['Boolean']>;
};

/** Defines a taxonomy datafield. */
export type TaxonomyDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'TaxonomyDatafieldDefinition';
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** The rule to be used when filtering using this taxonomy */
  evaluationType?: Maybe<TaxonomyEvaluationType>;
  /** The taxonomy's hierarchy of keys */
  keyHierarchy?: Maybe<Scalars['JSONObject']>;
  /** All of the taxonomy's values in a flattened list */
  values?: Maybe<Array<TaxonomyValue>>;
};

/** Available rules to be used when a filter uses a taxonomy */
export enum TaxonomyEvaluationType {
  /**
   * A filter will return all objects that either have the specified taxonomy value
   * itself or any of its descendants
   */
  descendantsOrSelf = 'DescendantsOrSelf',
  /**
   * A filter will return all objects that either have the specified taxonomy value
   * itself or any of its ancestors or any of its descendants
   */
  ancestorsOrDescendantsOrSelf = 'AncestorsOrDescendantsOrSelf',
  /** A filter will return all objects have the specified taxonomy value itself */
  self = 'Self',
  /**
   * A filter will return all objects that either have the specified taxonomy value
   * itself or any of its ancestors
   */
  ancestorsOrSelf = 'AncestorsOrSelf'
}

export type TaxonomyFacetDefinition = FacetDefinition & {
  __typename?: 'TaxonomyFacetDefinition';
  /** The datafield type of the field */
  fieldType: FieldDefinitionTypes;
  /** The taxonomy's hierarchy of keys */
  keyHierarchy?: Maybe<Scalars['JSONObject']>;
  /** All of the taxonomy's values in a flattened list */
  values?: Maybe<Array<TaxonomyValue>>;
};

/** A single value in the taxonomy */
export type TaxonomyValue = {
  __typename?: 'TaxonomyValue';
  /** Unique data value of this entry in this taxonomy */
  key: Scalars['String'];
  /** Human readable description of this taxonomy value */
  title?: Maybe<MultilingualString>;
  /** Length limited text from the taxonomy value for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldValueTeasers>;
  /**
   * The flattened path to this taxonomy value
   * The path contains all keys of ancestors and the value's own key
   */
  pathKeys?: Maybe<Array<Scalars['String']>>;
};

/** A single tenant in Exegol */
export type Tenant = {
  __typename?: 'Tenant';
  /** The tenant authentication config */
  auth?: Maybe<TenantAuthConfig>;
  /** Domain name for the tenant's app and api as an alternative to the default sub-domain */
  customDomain?: Maybe<Scalars['String']>;
  /** Active custom features */
  customFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The tenant's provider specific identifier */
  displayName: Scalars['String'];
  /** The domain name under which the tenant's content delivery services are provided */
  domain: Scalars['String'];
  /** Date when the tenant will expire */
  expires?: Maybe<Scalars['DateTime']>;
  /** The tenant's feature plan ID */
  featurePlan: Scalars['String'];
  /** The tenant's unique identifier */
  id: Scalars['ID'];
  /** The tenant's language configuration */
  languages?: Maybe<LanguagesQueryResult>;
  /** Upload configs */
  uploadConfig?: Maybe<TenantUploadConfig>;
};

export enum TenantAccessMode {
  /** Only anonymous users may access the api */
  anonymous = 'anonymous',
  /** Both anonymous and users authenticated via one of the configured authenticators may access the api */
  mixed = 'mixed',
  /** Only users authenticated via one of the configured authenticators can access the api */
  authenticated = 'authenticated'
}

export type TenantAuthConfig = {
  __typename?: 'TenantAuthConfig';
  /** The access mode for the tenant, which indicates which types of users should be able to authenticate */
  accessMode: TenantAccessMode;
  /** The available authenticators */
  authenticators?: Maybe<AuthenticatorQueryResult>;
};

export type TenantUploadConfig = {
  __typename?: 'TenantUploadConfig';
  /** Ignore this field */
  dummy?: Maybe<Scalars['Boolean']>;
  /** Upload config for notes */
  notes?: Maybe<NotesUploadConfig>;
};

/**
 * Defines a text datafield.
 * A text datafield is a string field which can be used for full text
 * filtering, fuzzy search, etc.
 */
export type TextDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'TextDatafieldDefinition';
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
};

export type Theme = {
  __typename?: 'Theme';
  /** The title of the portal */
  title: MultilingualString;
  /** Indicates if the title should be visible */
  showTitle: Scalars['Boolean'];
  /** Theming JSONObject */
  theming?: Maybe<Scalars['JSONObject']>;
  /** The logo of the theme */
  logo?: Maybe<BlobRef>;
  /** The startpage logo of the theme */
  startPageLogo?: Maybe<BlobRef>;
  /** Length limited text from the theme for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<ThemeTeasers>;
};

export type ThemeTeasers = {
  __typename?: 'ThemeTeasers';
  /**
   * The theme's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   * If no value is found for any of the accepted languages, `title` uses the English (`en`) value
   */
  title?: Maybe<Scalars['String']>;
};


export type ThemeTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<Maybe<LanguageWithWildcard>>>;
};

export type TocEdge = {
  __typename?: 'TocEdge';
  /** Identifier of the referenced `Topic` or `ContentMap` */
  id: Scalars['ID'];
  /** The Path in the TOC */
  path: Scalars['String'];
  /** The Path to fetch this TOC entry's children */
  childPath: Scalars['String'];
  /** Referenced `Topic` or `ContentMap` */
  node?: Maybe<Content>;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** True if this TOC element is a branch and has children, false if it's a leaf */
  hasChildren?: Maybe<Scalars['Boolean']>;
  /** Paths of all entries on the track from root to this entry */
  parentPaths?: Maybe<Array<Scalars['String']>>;
  /** The TOC element's children */
  children?: Maybe<TocResult>;
};

/** Item in the content map's table of contents (TOC) */
export type TocMapEntry = {
  __typename?: 'TocMapEntry';
  /** Identifier of the referenced `Topic` or `ContentMap` */
  id: Scalars['ID'];
  /** The Path in the TOC */
  path: Scalars['String'];
  /** Referenced `Topic` or `ContentMap` */
  node?: Maybe<Content>;
};

export type TocResult = {
  __typename?: 'TocResult';
  /**
   * The entries of a `ContentMap`'s TOC in the order they are defined.
   * To limit the number of results, set the `first` argument.
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  tocEntries?: Maybe<Array<TocEdge>>;
  /** Returns the total count of entries. */
  total: TotalCount;
};


export type TocResultTocEntriesArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};

/**
 * A topic is the atomar unit of information. It primarily consists of
 * - a title
 * - indexed fields (custom fields)
 * - the content - optionally represented in different mime types
 * - attachments which are required by the content (e.g. images, style sheets, etc.)
 */
export type Topic = Content & {
  __typename?: 'Topic';
  /** Attachments which are required by the content (e.g. images, style sheets, etc.) */
  attachments?: Maybe<Array<Maybe<BlobRef>>>;
  /** Unique identifier of the topic itself */
  contentId: Scalars['ID'];
  /** The topic's content - optionally represented in different mime types */
  contents?: Maybe<Array<Maybe<BlobRef>>>;
  /** Date and time when the content map was created */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Unique identifier of the topic in its use context */
  id: Scalars['ID'];
  /** Language of the topic */
  language?: Maybe<Language>;
  /** Locale of the topic */
  locale?: Maybe<Scalars['String']>;
  /** The file type of the asset this topic refers to */
  mimeType?: Maybe<Scalars['String']>;
  /** Date and time of the last modification */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /**
   * Relevant notes for the topic.
   *
   * This field only shows private, pendingApproval notes created by the requesting user and any public notes
   * that reference the content.
   *
   * Even with administrative privileges this field only shows the above mentioned notes and therefore doesn't include any notes
   * which don't belong to the requesting user except the public ones. To view any note and filter them, refer to the
   * filter query for the notes.
   */
  notes?: Maybe<NoteQueryResult>;
  /** Flag that specifies if the topic is recommended */
  recommended?: Maybe<Scalars['Boolean']>;
  /** The source this content was generated from */
  source?: Maybe<Scalars['String']>;
  /** The unique id of the source */
  sourceId?: Maybe<Scalars['String']>;
  /** Length limited text from the topic for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<TopicTeasers>;
  /** Extracted textual content of the topic */
  text?: Maybe<MultilingualString>;
  /** Title of the topic */
  title?: Maybe<MultilingualString>;
  /** The type of the node */
  type?: Maybe<Scalars['String']>;
  /** Most recent date and time when the content map was uploaded */
  uploadDate?: Maybe<Scalars['DateTime']>;
  /** Download URL to display the topic */
  url?: Maybe<Scalars['URL']>;
  /** The use context in which this topic is used */
  useContext?: Maybe<UseContext>;
  /** The technical version of the topic */
  version?: Maybe<Scalars['Long']>;
};


/**
 * A topic is the atomar unit of information. It primarily consists of
 * - a title
 * - indexed fields (custom fields)
 * - the content - optionally represented in different mime types
 * - attachments which are required by the content (e.g. images, style sheets, etc.)
 */
export type TopicNotesArgs = {
  filter?: Maybe<NoteFilter>;
};

export type TopicEdge = {
  __typename?: 'TopicEdge';
  /** The result's topic. */
  node: Topic;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
  /** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
  highlights?: Maybe<ContentHighlights>;
};

export type TopicEqualsValueFilter = {
  /** Returns only topics with the specified locale */
  locale?: Maybe<Scalars['String']>;
  /** Returns only topics with the recommended flag set */
  recommended?: Maybe<Scalars['Boolean']>;
  /** Returns only topics with the specified language */
  language?: Maybe<Language>;
  /** Returns only topics with the specified mime type */
  mimeType?: Maybe<Scalars['String']>;
  /** Returns only contents with the specified version */
  version?: Maybe<Scalars['Long']>;
  /** Returns only contents with the specified source */
  source?: Maybe<Scalars['String']>;
  /** Returns only contents with the specified source id */
  sourceId?: Maybe<Scalars['String']>;
  /** Returns only topics used by the specified root content id */
  rootContentId?: Maybe<Scalars['ID']>;
  /** Returns only topics with the specified creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only topics with the specified modification date */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /** Returns only topics with the specified upload date */
  uploadDate?: Maybe<Scalars['DateTime']>;
};

export enum TopicExistsValueFields {
  /** Returns only topics which have a locale specified */
  locale = 'locale',
  /** Returns only topics which have a version specified */
  version = 'version',
  /** Returns only topics which habe a mime type specified */
  mimeType = 'mimeType',
  /** Returns only topics which have a source specified */
  source = 'source',
  /** Returns only topics which have a source id specified */
  sourceId = 'sourceId'
}

/**
 * The `TopicFilter` is used to specify filter criteria for topics.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type TopicFilter = {
  /** Returns topics with one of the specified unique identifiers */
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Returns topics with one of the specified content identifiers */
  contentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Returns topics that contain all of the exact values specified */
  equals?: Maybe<TopicEqualsValueFilter>;
  /** Returns topics that match all of the specified full text searches */
  fullText?: Maybe<TopicFullTextFilter>;
  /** Returns topics with values within all of the ranges specified */
  range?: Maybe<TopicRangeFilter>;
  /** Returns topics that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: Maybe<TopicTaxonomyFilter>;
  /** Returns topics that have a value in all of the specified fields */
  exists?: Maybe<Array<Maybe<TopicExistsValueFields>>>;
  /** Returns topics that match all of the regular expressions */
  regex?: Maybe<TopicRegexFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: Maybe<Array<Maybe<TopicFilter>>>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: Maybe<Array<Maybe<TopicFilter>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: Maybe<TopicFilter>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
};

export type TopicFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: Maybe<Scalars['Float']>;
  /** Match the query against any tile language and rank the results with the specified boost */
  title?: Maybe<Scalars['Float']>;
  /** Match the query against any tile language and rank the results with the specified boost */
  text?: Maybe<Scalars['Float']>;
};

export type TopicFullTextFilter = {
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Specifies if either all or any query words need to match */
  operator?: Maybe<FullTextOperator>;
  /** Fields to search in with their respective score weights */
  fields?: Maybe<TopicFullTextFields>;
  /** Type of fulltext search to perform */
  searchType?: Maybe<FullTextSearchType>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: Maybe<Scalars['Boolean']>;
  /** Options to configure synonyms search */
  synonymOptions?: Maybe<SynonymOptions>;
};

export type TopicQueryResult = {
  __typename?: 'TopicQueryResult';
  /**
   * The query's matching topics.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  topics: Array<Maybe<TopicEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
  /**
   * Matched datafield values and number of matching topics per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: Aggregations;
};


export type TopicQueryResultTopicsArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<TopicSort>>>;
};


export type TopicQueryResultTotalArgs = {
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
};

export type TopicRangeFilter = {
  /** Returns only topics with a creation date in the specified range */
  creationDate?: Maybe<DateRange>;
  /** Returns only topics with a modification date in the specified range */
  modificationDate?: Maybe<DateRange>;
  /** Returns only topics with a upload date in the specified range */
  uploadDate?: Maybe<DateRange>;
  /** Returns only contents with a version within the specified range */
  version?: Maybe<LongRange>;
};

export type TopicRegexFilter = {
  /** No regex filterable indexed field definitions exist. As soon as the first matching indexed field definition is defined, it's automatically listed here. */
  not_applicable_as_no_regex_filterable_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};

export type TopicSort = {
  /** Sort field */
  field: TopicSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum TopicSortFields {
  /** Sort by relevance score */
  score = 'score',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by content id */
  contentId = 'contentId',
  /** Sort by title */
  title = 'title',
  /** Sort by locale */
  locale = 'locale',
  /** Sort by language */
  language = 'language',
  /** Sort by recommended flag */
  recommended = 'recommended',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by upload date */
  uploadDate = 'uploadDate',
  /** Sort by version */
  version = 'version',
  /** Sort by source */
  source = 'source',
  /** Sort by source id */
  sourceId = 'sourceId'
}

export type TopicTaxonomyFilter = {
  /** No taxonomy indexed field definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the topic for use e.g. in result lists, previews, etc. */
export type TopicTeasers = ContentTeasers & {
  __typename?: 'TopicTeasers';
  /** The topic's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
  /** The topic's text limited to `length` characters */
  text?: Maybe<Scalars['String']>;
};


/** Length limited text from the topic for use e.g. in result lists, previews, etc. */
export type TopicTeasersTitleArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
};


/** Length limited text from the topic for use e.g. in result lists, previews, etc. */
export type TopicTeasersTextArgs = {
  length?: Maybe<Scalars['NonNegativeInt']>;
};

export type TotalCount = {
  __typename?: 'TotalCount';
  /**
   * The total count of results.
   *
   * See `CountRelation` for further information on how to interpret the count.
   */
  count: Scalars['NonNegativeInt'];
  /**
   * Indicator on how the count should be interpreted.
   *
   * The count may not be accurate so the relation gives information whether the real
   * actual count deviates from the provided count or is equal to.
   *
   * See `CountRelation` for further information about the possible deviations.
   */
  countRelation?: Maybe<CountRelation>;
};




export type UpdateCollectionInput = {
  /** The unique id */
  id: Scalars['ID'];
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The detailed description */
  description?: Maybe<Scalars['String']>;
};

export type UpdateNoteCommentInput = {
  /** Unique id of the comment */
  id: Scalars['ID'];
  /** The text of the comment */
  text?: Maybe<Scalars['String']>;
};

export type UpdateNoteInput = {
  /** The unique id */
  id: Scalars['ID'];
  /** The note text */
  text?: Maybe<MultilingualStringInput>;
  /** The visibility for the note. See `VisibilityTypes` */
  visibility?: Maybe<NoteVisibilityTypesInput>;
};

export type UploadConfig = {
  __typename?: 'UploadConfig';
  /** The limits for the files to upload */
  limits: UploadLimits;
  /** The allowed mime types */
  allowedMimeTypes: Array<Maybe<Scalars['String']>>;
  /** Are all mime types allowed */
  allowAllMimeTypes?: Maybe<Scalars['Boolean']>;
  /** The allowed file extensions */
  allowedFileExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadLimits = {
  __typename?: 'UploadLimits';
  /** The maximal allowed size in bytes per file */
  maxFileSize?: Maybe<Scalars['NonNegativeInt']>;
};

export type UploadMultipleConfig = {
  __typename?: 'UploadMultipleConfig';
  /** The limits for the files to upload */
  limits: UploadMultipleLimits;
  /** The allowed mime types */
  allowedMimeTypes: Array<Maybe<Scalars['String']>>;
  /** Are all mime types allowed */
  allowAllMimeTypes?: Maybe<Scalars['Boolean']>;
  /** The allowed file extensions */
  allowedFileExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadMultipleLimits = {
  __typename?: 'UploadMultipleLimits';
  /** The maximal allowed size in bytes per file */
  maxFileSize?: Maybe<Scalars['NonNegativeInt']>;
  /** The maximal number of files per upload request */
  maxFilesPerUpload?: Maybe<Scalars['NonNegativeInt']>;
};

export type UseContext = {
  __typename?: 'UseContext';
  /** ID of the content map in which this context roots */
  rootContentId: Scalars['ID'];
  /** Content map in which this context roots */
  rootContentMap?: Maybe<ContentMap>;
};

export type UseContextAggregations = {
  __typename?: 'UseContextAggregations';
  /**
   * Provides terms aggregations for the use context.
   * Supports prefix search and the results are ordered by count.
   */
  terms?: Maybe<UseContextTermsAggregation>;
  /** Provides composite aggregations with pagination support */
  composite?: Maybe<UseContextCompositeAggregation>;
};

export type UseContextCompositeAggregation = {
  __typename?: 'UseContextCompositeAggregation';
  /** Provides aggregations for the rootContentId field */
  rootContentId?: Maybe<Array<AggregationEdge>>;
};


export type UseContextCompositeAggregationRootContentIdArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
};

export type UseContextSort = {
  /** Sort field */
  field: UseContextSortFields;
  /** Sort order */
  order?: Maybe<SortOrder>;
};

export enum UseContextSortFields {
  /** Sort by unique ID */
  rootContentId = 'rootContentId'
}

export type UseContextTermsAggregation = {
  __typename?: 'UseContextTermsAggregation';
  /** Provides aggregations for the rootContentId field */
  rootContentId?: Maybe<Array<AggregationEdge>>;
};


export type UseContextTermsAggregationRootContentIdArgs = {
  first?: Maybe<Scalars['NonNegativeInt']>;
  prefix?: Maybe<Scalars['String']>;
};

/** A single user in Exegol */
export type User = {
  __typename?: 'User';
  /** Indicates if the user is the anonymous user */
  anonymous: Scalars['Boolean'];
  /** The user's API keys */
  apiKeys?: Maybe<Array<ApiKey>>;
  /** The user's claims */
  claims?: Maybe<UserClaims>;
  /** Readable name for the user */
  displayName?: Maybe<Scalars['String']>;
  /** The user's assigned and inherited roles */
  effectiveRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The user's email address */
  email?: Maybe<Scalars['EmailAddress']>;
  /** The logout Url based on the last authenticator used */
  endSessionUrl?: Maybe<Scalars['String']>;
  /** The user's unique identifier */
  id: Scalars['ID'];
  /** The users notes */
  notes?: Maybe<NoteQueryResult>;
  /** All of the user's claims */
  rawClaims?: Maybe<Scalars['JSONObject']>;
  /** The user's assigned roles */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The provider which served the user information */
  sourceProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The user's provider specific identifier */
  sourceUserIdentifier: Scalars['String'];
};


/** A single user in Exegol */
export type UserNotesArgs = {
  filter?: Maybe<NoteFilter>;
};

/** A user's claims */
export type UserClaims = {
  __typename?: 'UserClaims';
  /** No claims are currently defined. As soon as the first claims are defined, they are automatically listed here. */
  not_applicable_as_no_claims_defined?: Maybe<Scalars['NoDynamicValueDefined']>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  /**
   * The user settings as a string key value map.
   *
   * Be aware: The entries are not interpreted by the backend.
   */
  settings?: Maybe<Scalars['JSONObject']>;
};

export type VersionInfo = {
  __typename?: 'VersionInfo';
  /** Full version info as a semver string. E.g '1.15.8' */
  versionString?: Maybe<Scalars['String']>;
  /** Major version */
  major?: Maybe<Scalars['Int']>;
  /** Minor version */
  minor?: Maybe<Scalars['Int']>;
  /** Patch level */
  patch?: Maybe<Scalars['Int']>;
};

export type EditableCollectionsQueryVariables = Exact<{
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sortId: CollectionSortFields;
  sortOrder?: Maybe<SortOrder>;
}>;


export type EditableCollectionsQuery = (
  { __typename?: 'Query' }
  & { editableCollections: (
    { __typename?: 'CollectionQueryResult' }
    & { total: (
      { __typename?: 'TotalCount' }
      & Pick<TotalCount, 'count' | 'countRelation'>
    ), collections: Array<Maybe<(
      { __typename?: 'CollectionEdge' }
      & Pick<CollectionEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'id' | 'displayName' | 'description' | 'visibility'>
      )> }
    )>> }
  ) }
);

export type AddContentsToCollectionMutationVariables = Exact<{
  collectionId: Scalars['ID'];
  idsOfContents: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AddContentsToCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addContentsToCollection'>
);

export type DeleteCollectionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCollection'>
);

export type CollectionsQueryVariables = Exact<{
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sortId: CollectionSortFields;
  sortOrder?: Maybe<SortOrder>;
}>;


export type CollectionsQuery = (
  { __typename?: 'Query' }
  & { collections: (
    { __typename?: 'CollectionQueryResult' }
    & { total: (
      { __typename?: 'TotalCount' }
      & Pick<TotalCount, 'count' | 'countRelation'>
    ), collections: Array<Maybe<(
      { __typename?: 'CollectionEdge' }
      & Pick<CollectionEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Collection' }
        & Pick<Collection, 'access' | 'id' | 'description' | 'displayName' | 'visibility'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )> }
      )> }
    )>> }
  ) }
);

export type RemoveContentsFromCollectionMutationVariables = Exact<{
  collectionId: Scalars['ID'];
  idsOfContents: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RemoveContentsFromCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeContentsFromCollection'>
);

export type GetContentsOfCollectionQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sortId: ContentSortFields;
  sortOrder?: Maybe<SortOrder>;
}>;


export type GetContentsOfCollectionQuery = (
  { __typename?: 'Query' }
  & { collection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'displayName' | 'visibility'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, contents: (
      { __typename?: 'ContentQueryResult' }
      & { total: (
        { __typename?: 'TotalCount' }
        & Pick<TotalCount, 'count' | 'countRelation'>
      ), contents: Array<Maybe<(
        { __typename?: 'ContentEdge' }
        & Pick<ContentEdge, 'cursor'>
        & { node?: Maybe<(
          { __typename?: 'ContentMap' }
          & Pick<ContentMap, 'id' | 'contentId'>
          & { teasers?: Maybe<(
            { __typename?: 'ContentMapTeasers' }
            & Pick<ContentMapTeasers, 'title'>
          )> }
        ) | (
          { __typename?: 'Topic' }
          & Pick<Topic, 'id' | 'contentId'>
          & { teasers?: Maybe<(
            { __typename?: 'TopicTeasers' }
            & Pick<TopicTeasers, 'title'>
          )> }
        )> }
      )>> }
    ) }
  )> }
);

export type GetCollectionByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCollectionByIdQuery = (
  { __typename?: 'Query' }
  & { collection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'description' | 'displayName' | 'visibility'>
  )> }
);

export type UpdateCollectionMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}>;


export type UpdateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { updateCollection: (
    { __typename?: 'Collection' }
    & Pick<Collection, 'id'>
  ) }
);

export type TopicMetadataDetailsQueryVariables = Exact<{
  ids?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type TopicMetadataDetailsQuery = (
  { __typename?: 'Query' }
  & { topics: (
    { __typename?: 'TopicQueryResult' }
    & { topics: Array<Maybe<(
      { __typename?: 'TopicEdge' }
      & { node: (
        { __typename?: 'Topic' }
        & Pick<Topic, 'id'>
      ) }
    )>> }
  ) }
);

export type RawClaimsQueryVariables = Exact<{ [key: string]: never; }>;


export type RawClaimsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'rawClaims'>
  ) }
);

export type ContentMapsQueryVariables = Exact<{
  contentMapId?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ContentMapsQuery = (
  { __typename?: 'Query' }
  & { contentMaps: (
    { __typename?: 'ContentMapQueryResult' }
    & { contentMaps: Array<Maybe<(
      { __typename?: 'ContentMapEdge' }
      & { node: (
        { __typename?: 'ContentMap' }
        & Pick<ContentMap, 'id' | 'contentId' | 'creationDate' | 'locale'>
        & { teasers?: Maybe<(
          { __typename?: 'ContentMapTeasers' }
          & Pick<ContentMapTeasers, 'title'>
        )>, tocMap?: Maybe<Array<Maybe<(
          { __typename?: 'TocMapEntry' }
          & Pick<TocMapEntry, 'path'>
          & { node?: Maybe<{ __typename?: 'ContentMap' } | (
            { __typename?: 'Topic' }
            & Pick<Topic, 'id' | 'contentId' | 'mimeType' | 'url'>
            & { teasers?: Maybe<(
              { __typename?: 'TopicTeasers' }
              & Pick<TopicTeasers, 'title'>
            )> }
          )> }
        )>>> }
      ) }
    )>> }
  ) }
);

export type CreateNoteMutationVariables = Exact<{
  note: CreateNoteInput;
}>;


export type CreateNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNote'>
);

export type UpdateNoteMutationVariables = Exact<{
  note: UpdateNoteInput;
}>;


export type UpdateNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateNote: (
    { __typename?: 'Note' }
    & Pick<Note, 'id'>
  ) }
);

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNote'>
);

export type NotesQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
}>;


export type NotesQuery = (
  { __typename?: 'Query' }
  & { notes?: Maybe<(
    { __typename?: 'NoteQueryResult' }
    & { total: (
      { __typename?: 'TotalCount' }
      & Pick<TotalCount, 'count' | 'countRelation'>
    ), notes: Array<Maybe<(
      { __typename?: 'NoteEdge' }
      & Pick<NoteEdge, 'cursor'>
      & { node: (
        { __typename?: 'Note' }
        & Pick<Note, 'id' | 'creationDate' | 'modificationDate' | 'visibility'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        )>, text?: Maybe<(
          { __typename?: 'MultilingualString' }
          & Pick<MultilingualString, 'ar' | 'da' | 'de' | 'el' | 'en' | 'es' | 'fr' | 'fi' | 'hu' | 'it' | 'ja' | 'nb' | 'nl' | 'pt' | 'ro' | 'ru' | 'sv' | 'tr'>
        )>, content?: Maybe<(
          { __typename?: 'ContentMap' }
          & Pick<ContentMap, 'contentId'>
        ) | (
          { __typename?: 'Topic' }
          & Pick<Topic, 'contentId'>
        )>, attachments?: Maybe<Array<Maybe<(
          { __typename?: 'BlobRef' }
          & Pick<BlobRef, 'path'>
          & { blob?: Maybe<(
            { __typename?: 'Blob' }
            & Pick<Blob, 'size' | 'url' | 'id' | 'mimeType'>
          )> }
        )>>> }
      ) }
    )>> }
  )> }
);

export type NotificationsTopBarQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type NotificationsTopBarQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<(
    { __typename?: 'NotificationQueryResult' }
    & { total: (
      { __typename?: 'TotalCount' }
      & Pick<TotalCount, 'count'>
    ) }
  )> }
);

export type MarkNotificationsAsReadMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type MarkNotificationsAsReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markNotificationsAsRead'>
);

export type NotificationsQueryVariables = Exact<{
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<(
    { __typename?: 'NotificationQueryResult' }
    & { total: (
      { __typename?: 'TotalCount' }
      & Pick<TotalCount, 'count' | 'countRelation'>
    ), notifications: Array<Maybe<(
      { __typename?: 'NotificationEdge' }
      & Pick<NotificationEdge, 'cursor'>
      & { node: (
        { __typename?: 'Notification' }
        & Pick<Notification, 'id' | 'objectId' | 'objectType' | 'notificationType' | 'creationDate'>
        & { teasers?: Maybe<(
          { __typename?: 'NotificationTeasers' }
          & Pick<NotificationTeasers, 'text'>
        )>, users?: Maybe<Array<Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email'>
        )>>> }
      ) }
    )>> }
  )> }
);

export type GetContentByNoteIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContentByNoteIdQuery = (
  { __typename?: 'Query' }
  & { note?: Maybe<(
    { __typename?: 'Note' }
    & { content?: Maybe<(
      { __typename?: 'ContentMap' }
      & Pick<ContentMap, 'id' | 'contentId'>
    ) | (
      { __typename?: 'Topic' }
      & Pick<Topic, 'id' | 'contentId'>
    )> }
  )> }
);

export type GetStaticPageQueryVariables = Exact<{
  pageType: Scalars['String'];
  languages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
}>;


export type GetStaticPageQuery = (
  { __typename?: 'Query' }
  & { staticPage?: Maybe<(
    { __typename?: 'StaticPage' }
    & Pick<StaticPage, 'id' | 'title'>
    & { contents?: Maybe<Array<Maybe<(
      { __typename?: 'BlobRef' }
      & Pick<BlobRef, 'url'>
    )>>> }
  )> }
);

export type ContentAutocompleteQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<ContentFilter>;
}>;


export type ContentAutocompleteQuery = (
  { __typename?: 'Query' }
  & { contentAutocomplete: (
    { __typename?: 'ContentAutocompleteResult' }
    & Pick<ContentAutocompleteResult, 'query'>
    & { hits?: Maybe<Array<(
      { __typename?: 'ContentAutocompleteHits' }
      & Pick<ContentAutocompleteHits, 'suggest'>
    )>> }
  ) }
);

export type FacetsCollectionQueryVariables = Exact<{
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
  id: Scalars['ID'];
}>;


export type FacetsCollectionQuery = (
  { __typename?: 'Query' }
  & { facetsCollection?: Maybe<(
    { __typename?: 'FacetsCollection' }
    & { facets?: Maybe<Array<Maybe<(
      { __typename?: 'CustomDatafieldFacet' }
      & Pick<CustomDatafieldFacet, 'kind' | 'referencedId'>
      & { display: (
        { __typename?: 'FacetDisplay' }
        & Pick<FacetDisplay, 'type' | 'options'>
      ), teasers?: Maybe<(
        { __typename?: 'FacetTeasers' }
        & Pick<FacetTeasers, 'title'>
      )> }
    ) | (
      { __typename?: 'FieldFacet' }
      & Pick<FieldFacet, 'kind' | 'referencedId'>
      & { display: (
        { __typename?: 'FacetDisplay' }
        & Pick<FacetDisplay, 'type' | 'options'>
      ), teasers?: Maybe<(
        { __typename?: 'FacetTeasers' }
        & Pick<FacetTeasers, 'title'>
      )> }
    )>>> }
  )> }
);

export type AutoCompleteQueryVariables = Exact<{
  filter: DatafieldAutocompleteFilter;
  first?: Maybe<Scalars['NonNegativeInt']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
}>;


export type AutoCompleteQuery = (
  { __typename?: 'Query' }
  & { datafieldAutocomplete: (
    { __typename?: 'DatafieldAutocompleteResult' }
    & Pick<DatafieldAutocompleteResult, 'query'>
    & { hits: Array<(
      { __typename?: 'DatafieldAutocompleteEdge' }
      & Pick<DatafieldAutocompleteEdge, 'datafieldDefinitionId' | 'value' | 'displayName' | 'language'>
      & { datafieldDefinition?: Maybe<(
        { __typename?: 'DateTimeDatafieldDefinition' }
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'EnumDatafieldDefinition' }
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'KeywordDatafieldDefinition' }
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'NumberDatafieldDefinition' }
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'TaxonomyDatafieldDefinition' }
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'TextDatafieldDefinition' }
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      )> }
    )> }
  ) }
);

export type AvailableFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableFeaturesQuery = (
  { __typename?: 'Query' }
  & { availableFeatures?: Maybe<Array<Maybe<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'id'>
  )>>> }
);

export type MetadataDefinitionByIdQueryVariables = Exact<{
  id: Scalars['DatafieldDefinitionID'];
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
}>;


export type MetadataDefinitionByIdQuery = (
  { __typename?: 'Query' }
  & { datafieldDefinition?: Maybe<(
    { __typename?: 'DateTimeDatafieldDefinition' }
    & Pick<DateTimeDatafieldDefinition, 'id' | 'fieldType'>
    & { teasers?: Maybe<(
      { __typename?: 'DatafieldDefinitionTeasers' }
      & Pick<DatafieldDefinitionTeasers, 'title'>
    )> }
  ) | (
    { __typename?: 'EnumDatafieldDefinition' }
    & Pick<EnumDatafieldDefinition, 'id' | 'mappedNames' | 'fieldType'>
    & { values?: Maybe<Array<(
      { __typename?: 'EnumValue' }
      & Pick<EnumValue, 'key'>
      & { teasers?: Maybe<(
        { __typename?: 'DatafieldValueTeasers' }
        & Pick<DatafieldValueTeasers, 'title'>
      )> }
    )>>, teasers?: Maybe<(
      { __typename?: 'DatafieldDefinitionTeasers' }
      & Pick<DatafieldDefinitionTeasers, 'title'>
    )> }
  ) | (
    { __typename?: 'KeywordDatafieldDefinition' }
    & Pick<KeywordDatafieldDefinition, 'id' | 'fieldType'>
    & { teasers?: Maybe<(
      { __typename?: 'DatafieldDefinitionTeasers' }
      & Pick<DatafieldDefinitionTeasers, 'title'>
    )> }
  ) | (
    { __typename?: 'NumberDatafieldDefinition' }
    & Pick<NumberDatafieldDefinition, 'id' | 'fieldType'>
    & { teasers?: Maybe<(
      { __typename?: 'DatafieldDefinitionTeasers' }
      & Pick<DatafieldDefinitionTeasers, 'title'>
    )> }
  ) | (
    { __typename?: 'TaxonomyDatafieldDefinition' }
    & Pick<TaxonomyDatafieldDefinition, 'id' | 'mappedNames' | 'fieldType'>
    & { values?: Maybe<Array<(
      { __typename?: 'TaxonomyValue' }
      & Pick<TaxonomyValue, 'key' | 'pathKeys'>
      & { teasers?: Maybe<(
        { __typename?: 'DatafieldValueTeasers' }
        & Pick<DatafieldValueTeasers, 'title'>
      )> }
    )>>, teasers?: Maybe<(
      { __typename?: 'DatafieldDefinitionTeasers' }
      & Pick<DatafieldDefinitionTeasers, 'title'>
    )> }
  ) | (
    { __typename?: 'TextDatafieldDefinition' }
    & Pick<TextDatafieldDefinition, 'id' | 'fieldType'>
    & { teasers?: Maybe<(
      { __typename?: 'DatafieldDefinitionTeasers' }
      & Pick<DatafieldDefinitionTeasers, 'title'>
    )> }
  )> }
);

export type FacetSelectionQueryVariables = Exact<{
  referencedIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
  id: Scalars['ID'];
}>;


export type FacetSelectionQuery = (
  { __typename?: 'Query' }
  & { facetsCollection?: Maybe<(
    { __typename?: 'FacetsCollection' }
    & { facets?: Maybe<Array<Maybe<(
      { __typename?: 'CustomDatafieldFacet' }
      & Pick<CustomDatafieldFacet, 'id' | 'referencedId' | 'type'>
      & { teasers?: Maybe<(
        { __typename?: 'FacetTeasers' }
        & Pick<FacetTeasers, 'title'>
      )>, definition?: Maybe<(
        { __typename?: 'EnumFacetDefinition' }
        & Pick<EnumFacetDefinition, 'fieldType'>
        & { values?: Maybe<Array<(
          { __typename?: 'EnumValue' }
          & Pick<EnumValue, 'key'>
          & { teasers?: Maybe<(
            { __typename?: 'DatafieldValueTeasers' }
            & Pick<DatafieldValueTeasers, 'title'>
          )> }
        )>> }
      ) | (
        { __typename?: 'KeywordFacetDefinition' }
        & Pick<KeywordFacetDefinition, 'fieldType'>
      ) | (
        { __typename?: 'TaxonomyFacetDefinition' }
        & Pick<TaxonomyFacetDefinition, 'fieldType'>
        & { values?: Maybe<Array<(
          { __typename?: 'TaxonomyValue' }
          & Pick<TaxonomyValue, 'key' | 'pathKeys'>
          & { teasers?: Maybe<(
            { __typename?: 'DatafieldValueTeasers' }
            & Pick<DatafieldValueTeasers, 'title'>
          )> }
        )>> }
      )> }
    ) | (
      { __typename?: 'FieldFacet' }
      & Pick<FieldFacet, 'id' | 'referencedId' | 'type'>
      & { teasers?: Maybe<(
        { __typename?: 'FacetTeasers' }
        & Pick<FacetTeasers, 'title'>
      )>, definition?: Maybe<(
        { __typename?: 'EnumFacetDefinition' }
        & Pick<EnumFacetDefinition, 'fieldType'>
        & { values?: Maybe<Array<(
          { __typename?: 'EnumValue' }
          & Pick<EnumValue, 'key'>
          & { teasers?: Maybe<(
            { __typename?: 'DatafieldValueTeasers' }
            & Pick<DatafieldValueTeasers, 'title'>
          )> }
        )>> }
      ) | (
        { __typename?: 'KeywordFacetDefinition' }
        & Pick<KeywordFacetDefinition, 'fieldType'>
      ) | (
        { __typename?: 'TaxonomyFacetDefinition' }
        & Pick<TaxonomyFacetDefinition, 'fieldType'>
        & { values?: Maybe<Array<(
          { __typename?: 'TaxonomyValue' }
          & Pick<TaxonomyValue, 'key' | 'pathKeys'>
          & { teasers?: Maybe<(
            { __typename?: 'DatafieldValueTeasers' }
            & Pick<DatafieldValueTeasers, 'title'>
          )> }
        )>> }
      )> }
    )>>> }
  )> }
);

export type FieldDefinitionsQueryVariables = Exact<{
  ids?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
}>;


export type FieldDefinitionsQuery = (
  { __typename?: 'Query' }
  & { fieldDefinitions: (
    { __typename?: 'DatafieldDefinitionQueryResult' }
    & { fieldDefinitions: Array<Maybe<(
      { __typename?: 'DatafieldDefinitionEdge' }
      & Pick<DatafieldDefinitionEdge, 'cursor'>
      & { node: (
        { __typename?: 'DateTimeDatafieldDefinition' }
        & Pick<DateTimeDatafieldDefinition, 'id' | 'fieldType'>
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'EnumDatafieldDefinition' }
        & Pick<EnumDatafieldDefinition, 'id' | 'fieldType'>
        & { values?: Maybe<Array<(
          { __typename?: 'EnumValue' }
          & Pick<EnumValue, 'key'>
          & { teasers?: Maybe<(
            { __typename?: 'DatafieldValueTeasers' }
            & Pick<DatafieldValueTeasers, 'title'>
          )> }
        )>>, teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'KeywordDatafieldDefinition' }
        & Pick<KeywordDatafieldDefinition, 'id' | 'fieldType'>
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'NumberDatafieldDefinition' }
        & Pick<NumberDatafieldDefinition, 'id' | 'fieldType'>
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'TaxonomyDatafieldDefinition' }
        & Pick<TaxonomyDatafieldDefinition, 'id' | 'fieldType'>
        & { values?: Maybe<Array<(
          { __typename?: 'TaxonomyValue' }
          & Pick<TaxonomyValue, 'key' | 'pathKeys'>
          & { teasers?: Maybe<(
            { __typename?: 'DatafieldValueTeasers' }
            & Pick<DatafieldValueTeasers, 'title'>
          )> }
        )>>, teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) | (
        { __typename?: 'TextDatafieldDefinition' }
        & Pick<TextDatafieldDefinition, 'id' | 'fieldType'>
        & { teasers?: Maybe<(
          { __typename?: 'DatafieldDefinitionTeasers' }
          & Pick<DatafieldDefinitionTeasers, 'title'>
        )> }
      ) }
    )>> }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'roles' | 'displayName' | 'effectiveRoles' | 'endSessionUrl'>
  ) }
);

export type AccessControlSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessControlSettingsQuery = (
  { __typename?: 'Query' }
  & { accessControlSettings: (
    { __typename?: 'AccessControlSettings' }
    & Pick<AccessControlSettings, 'id' | 'rolesConfig'>
  ) }
);

export type ThemeQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemeQueryQuery = (
  { __typename?: 'Query' }
  & { theme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'theming' | 'showTitle'>
    & { logo?: Maybe<(
      { __typename?: 'BlobRef' }
      & Pick<BlobRef, 'blobId' | 'url' | 'path'>
    )>, startPageLogo?: Maybe<(
      { __typename?: 'BlobRef' }
      & Pick<BlobRef, 'blobId' | 'url' | 'path'>
    )> }
  )> }
);

export type GetUploadConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUploadConfigQuery = (
  { __typename?: 'Query' }
  & { tenant: (
    { __typename?: 'Tenant' }
    & { languages?: Maybe<(
      { __typename?: 'LanguagesQueryResult' }
      & Pick<LanguagesQueryResult, 'available' | 'default'>
    )>, uploadConfig?: Maybe<(
      { __typename?: 'TenantUploadConfig' }
      & { notes?: Maybe<(
        { __typename?: 'NotesUploadConfig' }
        & { attachments?: Maybe<(
          { __typename?: 'UploadMultipleConfig' }
          & Pick<UploadMultipleConfig, 'allowedMimeTypes'>
          & { limits: (
            { __typename?: 'UploadMultipleLimits' }
            & Pick<UploadMultipleLimits, 'maxFileSize' | 'maxFilesPerUpload'>
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type DeleteNoteAttachmentsMutationVariables = Exact<{
  id: Scalars['ID'];
  attachmentIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type DeleteNoteAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNoteAttachments'>
);

export type ExploreQueryVariables = Exact<{
  id: Scalars['ID'];
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
}>;


export type ExploreQuery = (
  { __typename?: 'Query' }
  & { facetsCollection?: Maybe<(
    { __typename?: 'FacetsCollection' }
    & { facets?: Maybe<Array<Maybe<(
      { __typename?: 'CustomDatafieldFacet' }
      & Pick<CustomDatafieldFacet, 'kind' | 'referencedId'>
      & { display: (
        { __typename?: 'FacetDisplay' }
        & Pick<FacetDisplay, 'type' | 'options'>
      ), teasers?: Maybe<(
        { __typename?: 'FacetTeasers' }
        & Pick<FacetTeasers, 'title'>
      )>, definition?: Maybe<(
        { __typename?: 'EnumFacetDefinition' }
        & Pick<EnumFacetDefinition, 'fieldType'>
      ) | (
        { __typename?: 'KeywordFacetDefinition' }
        & Pick<KeywordFacetDefinition, 'fieldType'>
      ) | (
        { __typename?: 'TaxonomyFacetDefinition' }
        & Pick<TaxonomyFacetDefinition, 'fieldType'>
      )> }
    ) | (
      { __typename?: 'FieldFacet' }
      & Pick<FieldFacet, 'kind' | 'referencedId'>
      & { display: (
        { __typename?: 'FacetDisplay' }
        & Pick<FacetDisplay, 'type' | 'options'>
      ), teasers?: Maybe<(
        { __typename?: 'FacetTeasers' }
        & Pick<FacetTeasers, 'title'>
      )>, definition?: Maybe<(
        { __typename?: 'EnumFacetDefinition' }
        & Pick<EnumFacetDefinition, 'fieldType'>
      ) | (
        { __typename?: 'KeywordFacetDefinition' }
        & Pick<KeywordFacetDefinition, 'fieldType'>
      ) | (
        { __typename?: 'TaxonomyFacetDefinition' }
        & Pick<TaxonomyFacetDefinition, 'fieldType'>
      )> }
    )>>> }
  )>, searchConfig?: Maybe<(
    { __typename?: 'SearchConfig' }
    & { entries?: Maybe<Array<(
      { __typename?: 'SearchConfigDatafieldEntry' }
      & Pick<SearchConfigDatafieldEntry, 'type' | 'fieldType' | 'referencedId'>
      & { teasers?: Maybe<(
        { __typename?: 'SearchConfigEntryTeasers' }
        & Pick<SearchConfigEntryTeasers, 'title'>
      )> }
    ) | (
      { __typename?: 'SearchConfigFieldEntry' }
      & Pick<SearchConfigFieldEntry, 'type' | 'fieldType' | 'referencedId'>
      & { teasers?: Maybe<(
        { __typename?: 'SearchConfigEntryTeasers' }
        & Pick<SearchConfigEntryTeasers, 'title'>
      )> }
    )>> }
  )>, dataDisplayConfig?: Maybe<(
    { __typename?: 'DataDisplayConfig' }
    & Pick<DataDisplayConfig, 'separator'>
    & { entries?: Maybe<Array<(
      { __typename?: 'DataDisplayConfigDatafieldEntry' }
      & Pick<DataDisplayConfigDatafieldEntry, 'type' | 'fieldType' | 'referencedId' | 'showTitlePrefix'>
      & { teasers?: Maybe<(
        { __typename?: 'DataDisplayConfigEntryTeasers' }
        & Pick<DataDisplayConfigEntryTeasers, 'title'>
      )> }
    ) | (
      { __typename?: 'DataDisplayConfigFieldEntry' }
      & Pick<DataDisplayConfigFieldEntry, 'nodeTypes' | 'type' | 'fieldType' | 'referencedId' | 'showTitlePrefix'>
      & { teasers?: Maybe<(
        { __typename?: 'DataDisplayConfigEntryTeasers' }
        & Pick<DataDisplayConfigEntryTeasers, 'title'>
      )> }
    )>> }
  )> }
);

export type DataDisplayConfigQueryVariables = Exact<{
  id: Scalars['ID'];
  acceptedLanguages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
}>;


export type DataDisplayConfigQuery = (
  { __typename?: 'Query' }
  & { dataDisplayConfig?: Maybe<(
    { __typename?: 'DataDisplayConfig' }
    & Pick<DataDisplayConfig, 'separator'>
    & { entries?: Maybe<Array<(
      { __typename?: 'DataDisplayConfigDatafieldEntry' }
      & Pick<DataDisplayConfigDatafieldEntry, 'type' | 'fieldType' | 'referencedId' | 'showTitlePrefix'>
      & { datafieldDefinition?: Maybe<{ __typename?: 'DateTimeDatafieldDefinition' } | { __typename?: 'EnumDatafieldDefinition' } | { __typename?: 'KeywordDatafieldDefinition' } | { __typename?: 'NumberDatafieldDefinition' } | (
        { __typename?: 'TaxonomyDatafieldDefinition' }
        & Pick<TaxonomyDatafieldDefinition, 'id'>
        & { values?: Maybe<Array<(
          { __typename?: 'TaxonomyValue' }
          & Pick<TaxonomyValue, 'key'>
          & { teasers?: Maybe<(
            { __typename?: 'DatafieldValueTeasers' }
            & Pick<DatafieldValueTeasers, 'title'>
          )> }
        )>> }
      ) | { __typename?: 'TextDatafieldDefinition' }>, teasers?: Maybe<(
        { __typename?: 'DataDisplayConfigEntryTeasers' }
        & Pick<DataDisplayConfigEntryTeasers, 'title'>
      )> }
    ) | (
      { __typename?: 'DataDisplayConfigFieldEntry' }
      & Pick<DataDisplayConfigFieldEntry, 'type' | 'fieldType' | 'referencedId' | 'showTitlePrefix'>
      & { teasers?: Maybe<(
        { __typename?: 'DataDisplayConfigEntryTeasers' }
        & Pick<DataDisplayConfigEntryTeasers, 'title'>
      )> }
    )>> }
  )> }
);

export type AdminContentMapsQueryVariables = Exact<{
  first?: Maybe<Scalars['NonNegativeInt']>;
  after?: Maybe<Scalars['String']>;
  sortId: ContentMapSortFields;
  sortOrder?: Maybe<SortOrder>;
  filter?: Maybe<ContentMapFilter>;
  trackLimit?: Maybe<Scalars['NonNegativeInt']>;
}>;


export type AdminContentMapsQuery = (
  { __typename?: 'Query' }
  & { contentMaps: (
    { __typename?: 'ContentMapQueryResult' }
    & { total: (
      { __typename?: 'TotalCount' }
      & Pick<TotalCount, 'count' | 'countRelation'>
    ), contentMaps: Array<Maybe<(
      { __typename?: 'ContentMapEdge' }
      & Pick<ContentMapEdge, 'cursor'>
      & { node: (
        { __typename?: 'ContentMap' }
        & Pick<ContentMap, 'id' | 'contentId' | 'locale' | 'modificationDate'>
        & { teasers?: Maybe<(
          { __typename?: 'ContentMapTeasers' }
          & Pick<ContentMapTeasers, 'title'>
        )> }
      ) }
    )>> }
  ) }
);

export type StaticPageMenuQueryVariables = Exact<{
  languages?: Maybe<Array<LanguageWithWildcard> | LanguageWithWildcard>;
}>;


export type StaticPageMenuQuery = (
  { __typename?: 'Query' }
  & { staticPageMenu?: Maybe<(
    { __typename?: 'StaticPageMenu' }
    & Pick<StaticPageMenu, 'id'>
    & { pages?: Maybe<Array<Maybe<(
      { __typename?: 'StaticPageMenuEntry' }
      & Pick<StaticPageMenuEntry, 'pageType'>
      & { teasers?: Maybe<(
        { __typename?: 'StaticPageMenuEntryTeasers' }
        & Pick<StaticPageMenuEntryTeasers, 'title'>
      )>, staticPages?: Maybe<Array<Maybe<(
        { __typename?: 'StaticPage' }
        & Pick<StaticPage, 'id'>
      )>>> }
    )>>> }
  )> }
);

export type CreateCollectionMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  visibility: CollectionVisibilityTypes;
}>;


export type CreateCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCollection'>
);


export const EditableCollectionsDocument = gql`
    query editableCollections($first: NonNegativeInt, $after: String, $sortId: CollectionSortFields!, $sortOrder: SortOrder) {
  editableCollections {
    total {
      count
      countRelation
    }
    collections(
      after: $after
      first: $first
      sort: {field: $sortId, order: $sortOrder}
    ) {
      cursor
      node {
        id
        displayName
        description
        visibility
      }
    }
  }
}
    `;

/**
 * __useEditableCollectionsQuery__
 *
 * To run a query within a React component, call `useEditableCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditableCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditableCollectionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortId: // value for 'sortId'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useEditableCollectionsQuery(baseOptions: Apollo.QueryHookOptions<EditableCollectionsQuery, EditableCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditableCollectionsQuery, EditableCollectionsQueryVariables>(EditableCollectionsDocument, options);
      }
export function useEditableCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditableCollectionsQuery, EditableCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditableCollectionsQuery, EditableCollectionsQueryVariables>(EditableCollectionsDocument, options);
        }
export type EditableCollectionsQueryHookResult = ReturnType<typeof useEditableCollectionsQuery>;
export type EditableCollectionsLazyQueryHookResult = ReturnType<typeof useEditableCollectionsLazyQuery>;
export type EditableCollectionsQueryResult = Apollo.QueryResult<EditableCollectionsQuery, EditableCollectionsQueryVariables>;
export function refetchEditableCollectionsQuery(variables?: EditableCollectionsQueryVariables) {
      return { query: EditableCollectionsDocument, variables: variables }
    }
export const AddContentsToCollectionDocument = gql`
    mutation addContentsToCollection($collectionId: ID!, $idsOfContents: [ID!]!) {
  addContentsToCollection(
    collectionId: $collectionId
    idsOfContents: $idsOfContents
  )
}
    `;
export type AddContentsToCollectionMutationFn = Apollo.MutationFunction<AddContentsToCollectionMutation, AddContentsToCollectionMutationVariables>;

/**
 * __useAddContentsToCollectionMutation__
 *
 * To run a mutation, you first call `useAddContentsToCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContentsToCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentsToCollectionMutation, { data, loading, error }] = useAddContentsToCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      idsOfContents: // value for 'idsOfContents'
 *   },
 * });
 */
export function useAddContentsToCollectionMutation(baseOptions?: Apollo.MutationHookOptions<AddContentsToCollectionMutation, AddContentsToCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContentsToCollectionMutation, AddContentsToCollectionMutationVariables>(AddContentsToCollectionDocument, options);
      }
export type AddContentsToCollectionMutationHookResult = ReturnType<typeof useAddContentsToCollectionMutation>;
export type AddContentsToCollectionMutationResult = Apollo.MutationResult<AddContentsToCollectionMutation>;
export type AddContentsToCollectionMutationOptions = Apollo.BaseMutationOptions<AddContentsToCollectionMutation, AddContentsToCollectionMutationVariables>;
export const DeleteCollectionDocument = gql`
    mutation deleteCollection($id: ID!) {
  deleteCollection(id: $id)
}
    `;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<DeleteCollectionMutation, DeleteCollectionMutationVariables>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCollectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(DeleteCollectionDocument, options);
      }
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const CollectionsDocument = gql`
    query collections($first: NonNegativeInt, $after: String, $sortId: CollectionSortFields!, $sortOrder: SortOrder) {
  collections {
    total {
      count
      countRelation
    }
    collections(
      after: $after
      first: $first
      sort: {field: $sortId, order: $sortOrder}
    ) {
      cursor
      node {
        access
        user {
          id
        }
        id
        description
        displayName
        visibility
      }
    }
  }
}
    `;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortId: // value for 'sortId'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions: Apollo.QueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, options);
      }
export function useCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, options);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = Apollo.QueryResult<CollectionsQuery, CollectionsQueryVariables>;
export function refetchCollectionsQuery(variables?: CollectionsQueryVariables) {
      return { query: CollectionsDocument, variables: variables }
    }
export const RemoveContentsFromCollectionDocument = gql`
    mutation removeContentsFromCollection($collectionId: ID!, $idsOfContents: [ID!]!) {
  removeContentsFromCollection(
    collectionId: $collectionId
    idsOfContents: $idsOfContents
  )
}
    `;
export type RemoveContentsFromCollectionMutationFn = Apollo.MutationFunction<RemoveContentsFromCollectionMutation, RemoveContentsFromCollectionMutationVariables>;

/**
 * __useRemoveContentsFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveContentsFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContentsFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContentsFromCollectionMutation, { data, loading, error }] = useRemoveContentsFromCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      idsOfContents: // value for 'idsOfContents'
 *   },
 * });
 */
export function useRemoveContentsFromCollectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContentsFromCollectionMutation, RemoveContentsFromCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveContentsFromCollectionMutation, RemoveContentsFromCollectionMutationVariables>(RemoveContentsFromCollectionDocument, options);
      }
export type RemoveContentsFromCollectionMutationHookResult = ReturnType<typeof useRemoveContentsFromCollectionMutation>;
export type RemoveContentsFromCollectionMutationResult = Apollo.MutationResult<RemoveContentsFromCollectionMutation>;
export type RemoveContentsFromCollectionMutationOptions = Apollo.BaseMutationOptions<RemoveContentsFromCollectionMutation, RemoveContentsFromCollectionMutationVariables>;
export const GetContentsOfCollectionDocument = gql`
    query getContentsOfCollection($id: ID!, $first: NonNegativeInt, $after: String, $sortId: ContentSortFields!, $sortOrder: SortOrder) {
  collection(id: $id) {
    displayName
    user {
      id
    }
    visibility
    contents {
      total {
        count
        countRelation
      }
      contents(
        after: $after
        first: $first
        sort: {field: $sortId, order: $sortOrder}
      ) {
        cursor
        node {
          id
          contentId
          teasers {
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetContentsOfCollectionQuery__
 *
 * To run a query within a React component, call `useGetContentsOfCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentsOfCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentsOfCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortId: // value for 'sortId'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetContentsOfCollectionQuery(baseOptions: Apollo.QueryHookOptions<GetContentsOfCollectionQuery, GetContentsOfCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentsOfCollectionQuery, GetContentsOfCollectionQueryVariables>(GetContentsOfCollectionDocument, options);
      }
export function useGetContentsOfCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentsOfCollectionQuery, GetContentsOfCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentsOfCollectionQuery, GetContentsOfCollectionQueryVariables>(GetContentsOfCollectionDocument, options);
        }
export type GetContentsOfCollectionQueryHookResult = ReturnType<typeof useGetContentsOfCollectionQuery>;
export type GetContentsOfCollectionLazyQueryHookResult = ReturnType<typeof useGetContentsOfCollectionLazyQuery>;
export type GetContentsOfCollectionQueryResult = Apollo.QueryResult<GetContentsOfCollectionQuery, GetContentsOfCollectionQueryVariables>;
export function refetchGetContentsOfCollectionQuery(variables?: GetContentsOfCollectionQueryVariables) {
      return { query: GetContentsOfCollectionDocument, variables: variables }
    }
export const GetCollectionByIdDocument = gql`
    query getCollectionById($id: ID!) {
  collection(id: $id) {
    id
    description
    displayName
    visibility
  }
}
    `;

/**
 * __useGetCollectionByIdQuery__
 *
 * To run a query within a React component, call `useGetCollectionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>(GetCollectionByIdDocument, options);
      }
export function useGetCollectionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>(GetCollectionByIdDocument, options);
        }
export type GetCollectionByIdQueryHookResult = ReturnType<typeof useGetCollectionByIdQuery>;
export type GetCollectionByIdLazyQueryHookResult = ReturnType<typeof useGetCollectionByIdLazyQuery>;
export type GetCollectionByIdQueryResult = Apollo.QueryResult<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>;
export function refetchGetCollectionByIdQuery(variables?: GetCollectionByIdQueryVariables) {
      return { query: GetCollectionByIdDocument, variables: variables }
    }
export const UpdateCollectionDocument = gql`
    mutation updateCollection($description: String, $displayName: String, $id: ID!) {
  updateCollection(
    diff: {displayName: $displayName, description: $description, id: $id}
  ) {
    id
  }
}
    `;
export type UpdateCollectionMutationFn = Apollo.MutationFunction<UpdateCollectionMutation, UpdateCollectionMutationVariables>;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      description: // value for 'description'
 *      displayName: // value for 'displayName'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(UpdateCollectionDocument, options);
      }
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
export type UpdateCollectionMutationResult = Apollo.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>;
export const TopicMetadataDetailsDocument = gql`
    query topicMetadataDetails($ids: [ID]) {
  topics(filter: {ids: $ids}) {
    topics {
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useTopicMetadataDetailsQuery__
 *
 * To run a query within a React component, call `useTopicMetadataDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicMetadataDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicMetadataDetailsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTopicMetadataDetailsQuery(baseOptions?: Apollo.QueryHookOptions<TopicMetadataDetailsQuery, TopicMetadataDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicMetadataDetailsQuery, TopicMetadataDetailsQueryVariables>(TopicMetadataDetailsDocument, options);
      }
export function useTopicMetadataDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicMetadataDetailsQuery, TopicMetadataDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicMetadataDetailsQuery, TopicMetadataDetailsQueryVariables>(TopicMetadataDetailsDocument, options);
        }
export type TopicMetadataDetailsQueryHookResult = ReturnType<typeof useTopicMetadataDetailsQuery>;
export type TopicMetadataDetailsLazyQueryHookResult = ReturnType<typeof useTopicMetadataDetailsLazyQuery>;
export type TopicMetadataDetailsQueryResult = Apollo.QueryResult<TopicMetadataDetailsQuery, TopicMetadataDetailsQueryVariables>;
export function refetchTopicMetadataDetailsQuery(variables?: TopicMetadataDetailsQueryVariables) {
      return { query: TopicMetadataDetailsDocument, variables: variables }
    }
export const RawClaimsDocument = gql`
    query rawClaims {
  me {
    id
    rawClaims
  }
}
    `;

/**
 * __useRawClaimsQuery__
 *
 * To run a query within a React component, call `useRawClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRawClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRawClaimsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRawClaimsQuery(baseOptions?: Apollo.QueryHookOptions<RawClaimsQuery, RawClaimsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RawClaimsQuery, RawClaimsQueryVariables>(RawClaimsDocument, options);
      }
export function useRawClaimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RawClaimsQuery, RawClaimsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RawClaimsQuery, RawClaimsQueryVariables>(RawClaimsDocument, options);
        }
export type RawClaimsQueryHookResult = ReturnType<typeof useRawClaimsQuery>;
export type RawClaimsLazyQueryHookResult = ReturnType<typeof useRawClaimsLazyQuery>;
export type RawClaimsQueryResult = Apollo.QueryResult<RawClaimsQuery, RawClaimsQueryVariables>;
export function refetchRawClaimsQuery(variables?: RawClaimsQueryVariables) {
      return { query: RawClaimsDocument, variables: variables }
    }
export const ContentMapsDocument = gql`
    query contentMaps($contentMapId: [ID]) {
  contentMaps(filter: {contentIds: $contentMapId}) {
    contentMaps {
      node {
        teasers {
          title(length: 255)
        }
        tocMap {
          path
          node {
            ... on Topic {
              id
              contentId
              mimeType
              url
              teasers {
                title(length: 255)
              }
            }
          }
        }
        id
        contentId
        creationDate
        locale
      }
    }
  }
}
    `;

/**
 * __useContentMapsQuery__
 *
 * To run a query within a React component, call `useContentMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentMapsQuery({
 *   variables: {
 *      contentMapId: // value for 'contentMapId'
 *   },
 * });
 */
export function useContentMapsQuery(baseOptions?: Apollo.QueryHookOptions<ContentMapsQuery, ContentMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentMapsQuery, ContentMapsQueryVariables>(ContentMapsDocument, options);
      }
export function useContentMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentMapsQuery, ContentMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentMapsQuery, ContentMapsQueryVariables>(ContentMapsDocument, options);
        }
export type ContentMapsQueryHookResult = ReturnType<typeof useContentMapsQuery>;
export type ContentMapsLazyQueryHookResult = ReturnType<typeof useContentMapsLazyQuery>;
export type ContentMapsQueryResult = Apollo.QueryResult<ContentMapsQuery, ContentMapsQueryVariables>;
export function refetchContentMapsQuery(variables?: ContentMapsQueryVariables) {
      return { query: ContentMapsDocument, variables: variables }
    }
export const CreateNoteDocument = gql`
    mutation createNote($note: CreateNoteInput!) {
  createNote(noteSpec: $note)
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const UpdateNoteDocument = gql`
    mutation updateNote($note: UpdateNoteInput!) {
  updateNote(partialNote: $note) {
    id
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: ID!) {
  deleteNote(id: $id)
}
    `;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const NotesDocument = gql`
    query notes($id: ID, $first: NonNegativeInt, $after: String) {
  notes(filter: {equals: {objectId: $id}}) {
    total {
      count
      countRelation
    }
    notes(first: $first, sort: {field: creationDate, order: desc}, after: $after) {
      cursor
      node {
        id
        creationDate
        modificationDate
        user {
          id
          displayName
        }
        visibility
        text {
          ar
          da
          de
          el
          en
          es
          fr
          fi
          hu
          it
          ja
          nb
          nl
          pt
          ro
          ru
          sv
          tr
        }
        content {
          contentId
        }
        attachments {
          path
          blob {
            size
            url
            id
            mimeType
            url
          }
        }
      }
    }
  }
}
    `;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNotesQuery(baseOptions?: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
      }
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
        }
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export function refetchNotesQuery(variables?: NotesQueryVariables) {
      return { query: NotesDocument, variables: variables }
    }
export const NotificationsTopBarDocument = gql`
    query notificationsTopBar($userId: ID) {
  notifications(
    filter: {andGroup: [{equals: {userId: $userId}}, {not: {exists: readDate}}]}
  ) {
    total {
      count
    }
  }
}
    `;

/**
 * __useNotificationsTopBarQuery__
 *
 * To run a query within a React component, call `useNotificationsTopBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsTopBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsTopBarQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNotificationsTopBarQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsTopBarQuery, NotificationsTopBarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsTopBarQuery, NotificationsTopBarQueryVariables>(NotificationsTopBarDocument, options);
      }
export function useNotificationsTopBarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsTopBarQuery, NotificationsTopBarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsTopBarQuery, NotificationsTopBarQueryVariables>(NotificationsTopBarDocument, options);
        }
export type NotificationsTopBarQueryHookResult = ReturnType<typeof useNotificationsTopBarQuery>;
export type NotificationsTopBarLazyQueryHookResult = ReturnType<typeof useNotificationsTopBarLazyQuery>;
export type NotificationsTopBarQueryResult = Apollo.QueryResult<NotificationsTopBarQuery, NotificationsTopBarQueryVariables>;
export function refetchNotificationsTopBarQuery(variables?: NotificationsTopBarQueryVariables) {
      return { query: NotificationsTopBarDocument, variables: variables }
    }
export const MarkNotificationsAsReadDocument = gql`
    mutation markNotificationsAsRead($ids: [ID]!) {
  markNotificationsAsRead(ids: $ids)
}
    `;
export type MarkNotificationsAsReadMutationFn = Apollo.MutationFunction<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>;

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>(MarkNotificationsAsReadDocument, options);
      }
export type MarkNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationResult = Apollo.MutationResult<MarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationsAsReadMutation, MarkNotificationsAsReadMutationVariables>;
export const NotificationsDocument = gql`
    query notifications($first: NonNegativeInt, $after: String, $userId: ID, $acceptedLanguages: [LanguageWithWildcard!]) {
  notifications(
    filter: {andGroup: [{equals: {userId: $userId}}, {not: {exists: readDate}}]}
  ) {
    total {
      count
      countRelation
    }
    notifications(
      first: $first
      sort: {field: creationDate, order: desc}
      after: $after
    ) {
      cursor
      node {
        id
        teasers {
          text(acceptedLanguages: $acceptedLanguages)
        }
        objectId
        objectType
        notificationType
        creationDate
        users {
          id
          email
        }
      }
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      userId: // value for 'userId'
 *      acceptedLanguages: // value for 'acceptedLanguages'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export function refetchNotificationsQuery(variables?: NotificationsQueryVariables) {
      return { query: NotificationsDocument, variables: variables }
    }
export const GetContentByNoteIdDocument = gql`
    query getContentByNoteId($id: ID!) {
  note(id: $id) {
    content {
      id
      contentId
    }
  }
}
    `;

/**
 * __useGetContentByNoteIdQuery__
 *
 * To run a query within a React component, call `useGetContentByNoteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByNoteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByNoteIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentByNoteIdQuery(baseOptions: Apollo.QueryHookOptions<GetContentByNoteIdQuery, GetContentByNoteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentByNoteIdQuery, GetContentByNoteIdQueryVariables>(GetContentByNoteIdDocument, options);
      }
export function useGetContentByNoteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentByNoteIdQuery, GetContentByNoteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentByNoteIdQuery, GetContentByNoteIdQueryVariables>(GetContentByNoteIdDocument, options);
        }
export type GetContentByNoteIdQueryHookResult = ReturnType<typeof useGetContentByNoteIdQuery>;
export type GetContentByNoteIdLazyQueryHookResult = ReturnType<typeof useGetContentByNoteIdLazyQuery>;
export type GetContentByNoteIdQueryResult = Apollo.QueryResult<GetContentByNoteIdQuery, GetContentByNoteIdQueryVariables>;
export function refetchGetContentByNoteIdQuery(variables?: GetContentByNoteIdQueryVariables) {
      return { query: GetContentByNoteIdDocument, variables: variables }
    }
export const GetStaticPageDocument = gql`
    query getStaticPage($pageType: String!, $languages: [LanguageWithWildcard!]) {
  staticPage(pageType: $pageType, acceptedLanguages: $languages) {
    id
    title
    contents {
      url
    }
  }
}
    `;

/**
 * __useGetStaticPageQuery__
 *
 * To run a query within a React component, call `useGetStaticPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticPageQuery({
 *   variables: {
 *      pageType: // value for 'pageType'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useGetStaticPageQuery(baseOptions: Apollo.QueryHookOptions<GetStaticPageQuery, GetStaticPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaticPageQuery, GetStaticPageQueryVariables>(GetStaticPageDocument, options);
      }
export function useGetStaticPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaticPageQuery, GetStaticPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaticPageQuery, GetStaticPageQueryVariables>(GetStaticPageDocument, options);
        }
export type GetStaticPageQueryHookResult = ReturnType<typeof useGetStaticPageQuery>;
export type GetStaticPageLazyQueryHookResult = ReturnType<typeof useGetStaticPageLazyQuery>;
export type GetStaticPageQueryResult = Apollo.QueryResult<GetStaticPageQuery, GetStaticPageQueryVariables>;
export function refetchGetStaticPageQuery(variables?: GetStaticPageQueryVariables) {
      return { query: GetStaticPageDocument, variables: variables }
    }
export const ContentAutocompleteDocument = gql`
    query contentAutocomplete($query: String, $filter: ContentFilter) {
  contentAutocomplete(query: $query, filter: $filter) {
    query
    hits {
      suggest
    }
  }
}
    `;

/**
 * __useContentAutocompleteQuery__
 *
 * To run a query within a React component, call `useContentAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useContentAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<ContentAutocompleteQuery, ContentAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentAutocompleteQuery, ContentAutocompleteQueryVariables>(ContentAutocompleteDocument, options);
      }
export function useContentAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentAutocompleteQuery, ContentAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentAutocompleteQuery, ContentAutocompleteQueryVariables>(ContentAutocompleteDocument, options);
        }
export type ContentAutocompleteQueryHookResult = ReturnType<typeof useContentAutocompleteQuery>;
export type ContentAutocompleteLazyQueryHookResult = ReturnType<typeof useContentAutocompleteLazyQuery>;
export type ContentAutocompleteQueryResult = Apollo.QueryResult<ContentAutocompleteQuery, ContentAutocompleteQueryVariables>;
export function refetchContentAutocompleteQuery(variables?: ContentAutocompleteQueryVariables) {
      return { query: ContentAutocompleteDocument, variables: variables }
    }
export const FacetsCollectionDocument = gql`
    query facetsCollection($acceptedLanguages: [LanguageWithWildcard!], $id: ID!) {
  facetsCollection(id: $id) {
    facets {
      kind
      display {
        type
        options
      }
      referencedId
      teasers {
        title(acceptedLanguages: $acceptedLanguages)
      }
    }
  }
}
    `;

/**
 * __useFacetsCollectionQuery__
 *
 * To run a query within a React component, call `useFacetsCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacetsCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacetsCollectionQuery({
 *   variables: {
 *      acceptedLanguages: // value for 'acceptedLanguages'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacetsCollectionQuery(baseOptions: Apollo.QueryHookOptions<FacetsCollectionQuery, FacetsCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacetsCollectionQuery, FacetsCollectionQueryVariables>(FacetsCollectionDocument, options);
      }
export function useFacetsCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacetsCollectionQuery, FacetsCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacetsCollectionQuery, FacetsCollectionQueryVariables>(FacetsCollectionDocument, options);
        }
export type FacetsCollectionQueryHookResult = ReturnType<typeof useFacetsCollectionQuery>;
export type FacetsCollectionLazyQueryHookResult = ReturnType<typeof useFacetsCollectionLazyQuery>;
export type FacetsCollectionQueryResult = Apollo.QueryResult<FacetsCollectionQuery, FacetsCollectionQueryVariables>;
export function refetchFacetsCollectionQuery(variables?: FacetsCollectionQueryVariables) {
      return { query: FacetsCollectionDocument, variables: variables }
    }
export const AutoCompleteDocument = gql`
    query autoComplete($filter: DatafieldAutocompleteFilter!, $first: NonNegativeInt, $acceptedLanguages: [LanguageWithWildcard!]) {
  datafieldAutocomplete(first: $first, filter: $filter) {
    query
    hits {
      datafieldDefinitionId
      value
      displayName
      language
      datafieldDefinition {
        teasers {
          title(acceptedLanguages: $acceptedLanguages)
        }
      }
    }
  }
}
    `;

/**
 * __useAutoCompleteQuery__
 *
 * To run a query within a React component, call `useAutoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoCompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      acceptedLanguages: // value for 'acceptedLanguages'
 *   },
 * });
 */
export function useAutoCompleteQuery(baseOptions: Apollo.QueryHookOptions<AutoCompleteQuery, AutoCompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutoCompleteQuery, AutoCompleteQueryVariables>(AutoCompleteDocument, options);
      }
export function useAutoCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutoCompleteQuery, AutoCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutoCompleteQuery, AutoCompleteQueryVariables>(AutoCompleteDocument, options);
        }
export type AutoCompleteQueryHookResult = ReturnType<typeof useAutoCompleteQuery>;
export type AutoCompleteLazyQueryHookResult = ReturnType<typeof useAutoCompleteLazyQuery>;
export type AutoCompleteQueryResult = Apollo.QueryResult<AutoCompleteQuery, AutoCompleteQueryVariables>;
export function refetchAutoCompleteQuery(variables?: AutoCompleteQueryVariables) {
      return { query: AutoCompleteDocument, variables: variables }
    }
export const AvailableFeaturesDocument = gql`
    query availableFeatures {
  availableFeatures {
    id
  }
}
    `;

/**
 * __useAvailableFeaturesQuery__
 *
 * To run a query within a React component, call `useAvailableFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableFeaturesQuery, AvailableFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableFeaturesQuery, AvailableFeaturesQueryVariables>(AvailableFeaturesDocument, options);
      }
export function useAvailableFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableFeaturesQuery, AvailableFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableFeaturesQuery, AvailableFeaturesQueryVariables>(AvailableFeaturesDocument, options);
        }
export type AvailableFeaturesQueryHookResult = ReturnType<typeof useAvailableFeaturesQuery>;
export type AvailableFeaturesLazyQueryHookResult = ReturnType<typeof useAvailableFeaturesLazyQuery>;
export type AvailableFeaturesQueryResult = Apollo.QueryResult<AvailableFeaturesQuery, AvailableFeaturesQueryVariables>;
export function refetchAvailableFeaturesQuery(variables?: AvailableFeaturesQueryVariables) {
      return { query: AvailableFeaturesDocument, variables: variables }
    }
export const MetadataDefinitionByIdDocument = gql`
    query metadataDefinitionById($id: DatafieldDefinitionID!, $acceptedLanguages: [LanguageWithWildcard!]) {
  datafieldDefinition(id: $id) {
    id
    fieldType
    teasers {
      title(acceptedLanguages: $acceptedLanguages)
    }
    ... on EnumDatafieldDefinition {
      id
      mappedNames
      fieldType
      values {
        key
        teasers {
          title(acceptedLanguages: $acceptedLanguages)
        }
      }
    }
    ... on TaxonomyDatafieldDefinition {
      id
      mappedNames
      fieldType
      values {
        key
        pathKeys
        teasers {
          title(acceptedLanguages: $acceptedLanguages)
        }
      }
    }
  }
}
    `;

/**
 * __useMetadataDefinitionByIdQuery__
 *
 * To run a query within a React component, call `useMetadataDefinitionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetadataDefinitionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetadataDefinitionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      acceptedLanguages: // value for 'acceptedLanguages'
 *   },
 * });
 */
export function useMetadataDefinitionByIdQuery(baseOptions: Apollo.QueryHookOptions<MetadataDefinitionByIdQuery, MetadataDefinitionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetadataDefinitionByIdQuery, MetadataDefinitionByIdQueryVariables>(MetadataDefinitionByIdDocument, options);
      }
export function useMetadataDefinitionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetadataDefinitionByIdQuery, MetadataDefinitionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetadataDefinitionByIdQuery, MetadataDefinitionByIdQueryVariables>(MetadataDefinitionByIdDocument, options);
        }
export type MetadataDefinitionByIdQueryHookResult = ReturnType<typeof useMetadataDefinitionByIdQuery>;
export type MetadataDefinitionByIdLazyQueryHookResult = ReturnType<typeof useMetadataDefinitionByIdLazyQuery>;
export type MetadataDefinitionByIdQueryResult = Apollo.QueryResult<MetadataDefinitionByIdQuery, MetadataDefinitionByIdQueryVariables>;
export function refetchMetadataDefinitionByIdQuery(variables?: MetadataDefinitionByIdQueryVariables) {
      return { query: MetadataDefinitionByIdDocument, variables: variables }
    }
export const FacetSelectionDocument = gql`
    query facetSelection($referencedIds: [String!], $acceptedLanguages: [LanguageWithWildcard!], $id: ID!) {
  facetsCollection(id: $id) {
    facets(referencedIds: $referencedIds) {
      id
      referencedId
      type
      teasers {
        title(acceptedLanguages: $acceptedLanguages)
      }
      definition {
        fieldType
        ... on EnumFacetDefinition {
          values {
            key
            teasers {
              title(acceptedLanguages: $acceptedLanguages)
            }
          }
        }
        ... on TaxonomyFacetDefinition {
          values {
            key
            pathKeys
            teasers {
              title(acceptedLanguages: $acceptedLanguages)
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFacetSelectionQuery__
 *
 * To run a query within a React component, call `useFacetSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacetSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacetSelectionQuery({
 *   variables: {
 *      referencedIds: // value for 'referencedIds'
 *      acceptedLanguages: // value for 'acceptedLanguages'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacetSelectionQuery(baseOptions: Apollo.QueryHookOptions<FacetSelectionQuery, FacetSelectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacetSelectionQuery, FacetSelectionQueryVariables>(FacetSelectionDocument, options);
      }
export function useFacetSelectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacetSelectionQuery, FacetSelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacetSelectionQuery, FacetSelectionQueryVariables>(FacetSelectionDocument, options);
        }
export type FacetSelectionQueryHookResult = ReturnType<typeof useFacetSelectionQuery>;
export type FacetSelectionLazyQueryHookResult = ReturnType<typeof useFacetSelectionLazyQuery>;
export type FacetSelectionQueryResult = Apollo.QueryResult<FacetSelectionQuery, FacetSelectionQueryVariables>;
export function refetchFacetSelectionQuery(variables?: FacetSelectionQueryVariables) {
      return { query: FacetSelectionDocument, variables: variables }
    }
export const FieldDefinitionsDocument = gql`
    query fieldDefinitions($ids: [ID], $acceptedLanguages: [LanguageWithWildcard!]) {
  fieldDefinitions(
    filter: {ids: $ids, orGroup: [{equals: {fieldType: taxonomy}}, {equals: {fieldType: enum}}]}
  ) {
    fieldDefinitions(first: 1000) {
      cursor
      node {
        id
        fieldType
        teasers {
          title(acceptedLanguages: $acceptedLanguages)
        }
        ... on TaxonomyDatafieldDefinition {
          values {
            key
            teasers {
              title(acceptedLanguages: $acceptedLanguages)
            }
            pathKeys
          }
        }
        ... on EnumDatafieldDefinition {
          values {
            key
            teasers {
              title(acceptedLanguages: $acceptedLanguages)
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFieldDefinitionsQuery__
 *
 * To run a query within a React component, call `useFieldDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldDefinitionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      acceptedLanguages: // value for 'acceptedLanguages'
 *   },
 * });
 */
export function useFieldDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>(FieldDefinitionsDocument, options);
      }
export function useFieldDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>(FieldDefinitionsDocument, options);
        }
export type FieldDefinitionsQueryHookResult = ReturnType<typeof useFieldDefinitionsQuery>;
export type FieldDefinitionsLazyQueryHookResult = ReturnType<typeof useFieldDefinitionsLazyQuery>;
export type FieldDefinitionsQueryResult = Apollo.QueryResult<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>;
export function refetchFieldDefinitionsQuery(variables?: FieldDefinitionsQueryVariables) {
      return { query: FieldDefinitionsDocument, variables: variables }
    }
export const MeDocument = gql`
    query me {
  me {
    id
    email
    roles
    displayName
    effectiveRoles
    endSessionUrl
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export function refetchMeQuery(variables?: MeQueryVariables) {
      return { query: MeDocument, variables: variables }
    }
export const AccessControlSettingsDocument = gql`
    query accessControlSettings {
  accessControlSettings {
    id
    rolesConfig
  }
}
    `;

/**
 * __useAccessControlSettingsQuery__
 *
 * To run a query within a React component, call `useAccessControlSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessControlSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessControlSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessControlSettingsQuery(baseOptions?: Apollo.QueryHookOptions<AccessControlSettingsQuery, AccessControlSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccessControlSettingsQuery, AccessControlSettingsQueryVariables>(AccessControlSettingsDocument, options);
      }
export function useAccessControlSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessControlSettingsQuery, AccessControlSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccessControlSettingsQuery, AccessControlSettingsQueryVariables>(AccessControlSettingsDocument, options);
        }
export type AccessControlSettingsQueryHookResult = ReturnType<typeof useAccessControlSettingsQuery>;
export type AccessControlSettingsLazyQueryHookResult = ReturnType<typeof useAccessControlSettingsLazyQuery>;
export type AccessControlSettingsQueryResult = Apollo.QueryResult<AccessControlSettingsQuery, AccessControlSettingsQueryVariables>;
export function refetchAccessControlSettingsQuery(variables?: AccessControlSettingsQueryVariables) {
      return { query: AccessControlSettingsDocument, variables: variables }
    }
export const ThemeQueryDocument = gql`
    query themeQuery {
  theme {
    theming
    logo {
      blobId
      url
      path
    }
    startPageLogo {
      blobId
      url
      path
    }
    showTitle
  }
}
    `;

/**
 * __useThemeQueryQuery__
 *
 * To run a query within a React component, call `useThemeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemeQueryQuery(baseOptions?: Apollo.QueryHookOptions<ThemeQueryQuery, ThemeQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeQueryQuery, ThemeQueryQueryVariables>(ThemeQueryDocument, options);
      }
export function useThemeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeQueryQuery, ThemeQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeQueryQuery, ThemeQueryQueryVariables>(ThemeQueryDocument, options);
        }
export type ThemeQueryQueryHookResult = ReturnType<typeof useThemeQueryQuery>;
export type ThemeQueryLazyQueryHookResult = ReturnType<typeof useThemeQueryLazyQuery>;
export type ThemeQueryQueryResult = Apollo.QueryResult<ThemeQueryQuery, ThemeQueryQueryVariables>;
export function refetchThemeQueryQuery(variables?: ThemeQueryQueryVariables) {
      return { query: ThemeQueryDocument, variables: variables }
    }
export const GetUploadConfigDocument = gql`
    query getUploadConfig {
  tenant {
    languages {
      available
      default
    }
    uploadConfig {
      notes {
        attachments {
          allowedMimeTypes
          limits {
            maxFileSize
            maxFilesPerUpload
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUploadConfigQuery__
 *
 * To run a query within a React component, call `useGetUploadConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUploadConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetUploadConfigQuery, GetUploadConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadConfigQuery, GetUploadConfigQueryVariables>(GetUploadConfigDocument, options);
      }
export function useGetUploadConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadConfigQuery, GetUploadConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadConfigQuery, GetUploadConfigQueryVariables>(GetUploadConfigDocument, options);
        }
export type GetUploadConfigQueryHookResult = ReturnType<typeof useGetUploadConfigQuery>;
export type GetUploadConfigLazyQueryHookResult = ReturnType<typeof useGetUploadConfigLazyQuery>;
export type GetUploadConfigQueryResult = Apollo.QueryResult<GetUploadConfigQuery, GetUploadConfigQueryVariables>;
export function refetchGetUploadConfigQuery(variables?: GetUploadConfigQueryVariables) {
      return { query: GetUploadConfigDocument, variables: variables }
    }
export const DeleteNoteAttachmentsDocument = gql`
    mutation deleteNoteAttachments($id: ID!, $attachmentIds: [ID]!) {
  deleteNoteAttachments(noteId: $id, attachmentIds: $attachmentIds)
}
    `;
export type DeleteNoteAttachmentsMutationFn = Apollo.MutationFunction<DeleteNoteAttachmentsMutation, DeleteNoteAttachmentsMutationVariables>;

/**
 * __useDeleteNoteAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteNoteAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteAttachmentsMutation, { data, loading, error }] = useDeleteNoteAttachmentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useDeleteNoteAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteAttachmentsMutation, DeleteNoteAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteAttachmentsMutation, DeleteNoteAttachmentsMutationVariables>(DeleteNoteAttachmentsDocument, options);
      }
export type DeleteNoteAttachmentsMutationHookResult = ReturnType<typeof useDeleteNoteAttachmentsMutation>;
export type DeleteNoteAttachmentsMutationResult = Apollo.MutationResult<DeleteNoteAttachmentsMutation>;
export type DeleteNoteAttachmentsMutationOptions = Apollo.BaseMutationOptions<DeleteNoteAttachmentsMutation, DeleteNoteAttachmentsMutationVariables>;
export const ExploreDocument = gql`
    query explore($id: ID!, $acceptedLanguages: [LanguageWithWildcard!]) {
  facetsCollection(id: $id) {
    facets {
      kind
      display {
        type
        options
      }
      referencedId
      teasers {
        title(acceptedLanguages: $acceptedLanguages)
      }
      definition {
        fieldType
      }
    }
  }
  searchConfig(id: $id) {
    entries {
      type
      fieldType
      referencedId
      teasers {
        title(length: 50, acceptedLanguages: $acceptedLanguages)
      }
    }
  }
  dataDisplayConfig(id: $id) {
    separator
    entries {
      type
      fieldType
      referencedId
      showTitlePrefix
      teasers {
        title(length: 50, acceptedLanguages: $acceptedLanguages)
      }
      ... on DataDisplayConfigFieldEntry {
        nodeTypes
      }
    }
  }
}
    `;

/**
 * __useExploreQuery__
 *
 * To run a query within a React component, call `useExploreQuery` and pass it any options that fit your needs.
 * When your component renders, `useExploreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreQuery({
 *   variables: {
 *      id: // value for 'id'
 *      acceptedLanguages: // value for 'acceptedLanguages'
 *   },
 * });
 */
export function useExploreQuery(baseOptions: Apollo.QueryHookOptions<ExploreQuery, ExploreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExploreQuery, ExploreQueryVariables>(ExploreDocument, options);
      }
export function useExploreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExploreQuery, ExploreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExploreQuery, ExploreQueryVariables>(ExploreDocument, options);
        }
export type ExploreQueryHookResult = ReturnType<typeof useExploreQuery>;
export type ExploreLazyQueryHookResult = ReturnType<typeof useExploreLazyQuery>;
export type ExploreQueryResult = Apollo.QueryResult<ExploreQuery, ExploreQueryVariables>;
export function refetchExploreQuery(variables?: ExploreQueryVariables) {
      return { query: ExploreDocument, variables: variables }
    }
export const DataDisplayConfigDocument = gql`
    query dataDisplayConfig($id: ID!, $acceptedLanguages: [LanguageWithWildcard!]) {
  dataDisplayConfig(id: $id) {
    separator
    entries {
      type
      fieldType
      referencedId
      showTitlePrefix
      ... on DataDisplayConfigDatafieldEntry {
        datafieldDefinition {
          ... on TaxonomyDatafieldDefinition {
            id
            values {
              key
              teasers {
                title(length: 50, acceptedLanguages: $acceptedLanguages)
              }
            }
          }
        }
      }
      teasers {
        title(length: 50, acceptedLanguages: $acceptedLanguages)
      }
    }
  }
}
    `;

/**
 * __useDataDisplayConfigQuery__
 *
 * To run a query within a React component, call `useDataDisplayConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataDisplayConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataDisplayConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *      acceptedLanguages: // value for 'acceptedLanguages'
 *   },
 * });
 */
export function useDataDisplayConfigQuery(baseOptions: Apollo.QueryHookOptions<DataDisplayConfigQuery, DataDisplayConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataDisplayConfigQuery, DataDisplayConfigQueryVariables>(DataDisplayConfigDocument, options);
      }
export function useDataDisplayConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataDisplayConfigQuery, DataDisplayConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataDisplayConfigQuery, DataDisplayConfigQueryVariables>(DataDisplayConfigDocument, options);
        }
export type DataDisplayConfigQueryHookResult = ReturnType<typeof useDataDisplayConfigQuery>;
export type DataDisplayConfigLazyQueryHookResult = ReturnType<typeof useDataDisplayConfigLazyQuery>;
export type DataDisplayConfigQueryResult = Apollo.QueryResult<DataDisplayConfigQuery, DataDisplayConfigQueryVariables>;
export function refetchDataDisplayConfigQuery(variables?: DataDisplayConfigQueryVariables) {
      return { query: DataDisplayConfigDocument, variables: variables }
    }
export const AdminContentMapsDocument = gql`
    query adminContentMaps($first: NonNegativeInt, $after: String, $sortId: ContentMapSortFields!, $sortOrder: SortOrder, $filter: ContentMapFilter, $trackLimit: NonNegativeInt) {
  contentMaps(filter: $filter) {
    total(trackLimit: $trackLimit) {
      count
      countRelation
    }
    contentMaps(
      sort: {field: $sortId, order: $sortOrder}
      first: $first
      after: $after
    ) {
      cursor
      node {
        id
        contentId
        locale
        modificationDate
        teasers {
          title
        }
      }
    }
  }
}
    `;

/**
 * __useAdminContentMapsQuery__
 *
 * To run a query within a React component, call `useAdminContentMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminContentMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminContentMapsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortId: // value for 'sortId'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *      trackLimit: // value for 'trackLimit'
 *   },
 * });
 */
export function useAdminContentMapsQuery(baseOptions: Apollo.QueryHookOptions<AdminContentMapsQuery, AdminContentMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminContentMapsQuery, AdminContentMapsQueryVariables>(AdminContentMapsDocument, options);
      }
export function useAdminContentMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminContentMapsQuery, AdminContentMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminContentMapsQuery, AdminContentMapsQueryVariables>(AdminContentMapsDocument, options);
        }
export type AdminContentMapsQueryHookResult = ReturnType<typeof useAdminContentMapsQuery>;
export type AdminContentMapsLazyQueryHookResult = ReturnType<typeof useAdminContentMapsLazyQuery>;
export type AdminContentMapsQueryResult = Apollo.QueryResult<AdminContentMapsQuery, AdminContentMapsQueryVariables>;
export function refetchAdminContentMapsQuery(variables?: AdminContentMapsQueryVariables) {
      return { query: AdminContentMapsDocument, variables: variables }
    }
export const StaticPageMenuDocument = gql`
    query staticPageMenu($languages: [LanguageWithWildcard!]) {
  staticPageMenu {
    id
    pages {
      teasers {
        title(acceptedLanguages: $languages)
      }
      staticPages {
        id
      }
      pageType
    }
  }
}
    `;

/**
 * __useStaticPageMenuQuery__
 *
 * To run a query within a React component, call `useStaticPageMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticPageMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticPageMenuQuery({
 *   variables: {
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useStaticPageMenuQuery(baseOptions?: Apollo.QueryHookOptions<StaticPageMenuQuery, StaticPageMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaticPageMenuQuery, StaticPageMenuQueryVariables>(StaticPageMenuDocument, options);
      }
export function useStaticPageMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaticPageMenuQuery, StaticPageMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaticPageMenuQuery, StaticPageMenuQueryVariables>(StaticPageMenuDocument, options);
        }
export type StaticPageMenuQueryHookResult = ReturnType<typeof useStaticPageMenuQuery>;
export type StaticPageMenuLazyQueryHookResult = ReturnType<typeof useStaticPageMenuLazyQuery>;
export type StaticPageMenuQueryResult = Apollo.QueryResult<StaticPageMenuQuery, StaticPageMenuQueryVariables>;
export function refetchStaticPageMenuQuery(variables?: StaticPageMenuQueryVariables) {
      return { query: StaticPageMenuDocument, variables: variables }
    }
export const CreateCollectionDocument = gql`
    mutation createCollection($description: String, $displayName: String, $visibility: CollectionVisibilityTypes!) {
  createCollection(
    spec: {visibility: $visibility, displayName: $displayName, description: $description}
  )
}
    `;
export type CreateCollectionMutationFn = Apollo.MutationFunction<CreateCollectionMutation, CreateCollectionMutationVariables>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      description: // value for 'description'
 *      displayName: // value for 'displayName'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useCreateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCollectionMutation, CreateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(CreateCollectionDocument, options);
      }
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<CreateCollectionMutation, CreateCollectionMutationVariables>;