import React, { FC } from "react";
import { IconButton, Badge, Tooltip } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import { useNotificationsTopBarQuery } from "../../generated/consumer-graph-types";
import { gql, useQuery } from "@apollo/client";
import { USER_QUERY, UserData } from "../../shared-queries";
import { useNotificationsButtonTopBarStyles } from "./notifications-button-top-bar-portal-styles";
import { useTranslation } from "react-i18next";

const NOTIFICATION_POLL_INTERVAL = 60000;

gql`
    query notificationsTopBar($userId: ID) {
        notifications(filter: { andGroup: [{ equals: { userId: $userId } }, { not: { exists: readDate } }] }) {
            total {
                count
            }
        }
    }
`;

interface NotificationsButtonTopBarPortalProps {
    onNotificationsButtonClick: () => void;
}

export const NotificationsButtonTopBarPortal: FC<NotificationsButtonTopBarPortalProps> = ({
    onNotificationsButtonClick,
}) => {
    const { t } = useTranslation();
    const classes = useNotificationsButtonTopBarStyles();
    const { data: userData } = useQuery<UserData>(USER_QUERY);

    const { data: notificationsData } = useNotificationsTopBarQuery({
        skip: !userData,
        variables: { userId: userData?.me.id },
        pollInterval: NOTIFICATION_POLL_INTERVAL,
    });

    return notificationsData ? (
        <Tooltip arrow title={<>{t("Notifications")}</>}>
            <IconButton className={classes.menuBtn} color="inherit" onClick={onNotificationsButtonClick} size="large">
                <Badge badgeContent={notificationsData?.notifications?.total?.count} variant="dot" color="error">
                    <Notifications />
                </Badge>
            </IconButton>
        </Tooltip>
    ) : null;
};
