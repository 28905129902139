import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import React, { FC, useState, useEffect } from "react";
import { TocNode } from "./content/content";
import { Tooltip, Typography } from "@mui/material";

interface TocProps {
    selected: string;
    expanded: string[];
    tocNode: TocNode;
    navigate: Function;
    classes?: { root: string };
}

export const Toc: FC<TocProps> = ({ selected, expanded, tocNode, navigate, classes }) => {
    const [nodeExpanded, setNodeExpanded] = useState<string[]>([]);
    const [nodeSelected, setNodeSelected] = useState<string[]>([]);

    useEffect(() => {
        setNodeSelected([selected]);
        setNodeExpanded((val) => expanded.concat(val).filter((val, index, self) => self.indexOf(val) === index));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, expanded]);

    const handleToggle = (event: any, nodeIds: string[]) => {
        setNodeExpanded(nodeIds);
    };

    const renderTree = (toc: TocNode) => {
        const label = toc.title || toc.id;
        return (
            <TreeItem
                key={toc.id}
                nodeId={toc.id}
                label={
                    <Tooltip followCursor title={label} placement={"right"}>
                        <Typography
                            variant={"body2"}
                            sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                        >
                            {label}
                        </Typography>
                    </Tooltip>
                }
                onClick={(event) => {
                    navigate(toc.id);
                    event.stopPropagation();
                }}
                sx={{ mb: 0.5 }}
            >
                {toc.children ? toc.children.map((node) => renderTree(node)) : null}
            </TreeItem>
        );
    };

    return (
        <TreeView
            expanded={nodeExpanded}
            selected={nodeSelected}
            className={classes?.root}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            onNodeToggle={handleToggle}
        >
            {renderTree(tocNode)}
        </TreeView>
    );
};
