import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useLanguageSelectorStyle = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            flexGrow: 1,
            justifyContent: "flex-start",
            paddingLeft: theme.spacing(2),
            fontSize: 16,
            fontWeight: 400,
            textTransform: "none",
        },
        list: {
            padding: 0,
        },
        listItem: {
            paddingRight: theme.spacing(8),
        },
        label: {
            backgroundColor: theme.palette.background.paper,
        },
    })
);
