import { useEffect, useState } from "react";
import { Dictionary } from "ts-essentials";
import { FacetKinds, ApplicationScope } from "../enums";
import {
    ExploreQuery,
    Facet,
    DataDisplayConfig,
    LanguageWithWildcard,
    DatafieldDefinition,
    FieldDefinitionTypes,
    useExploreQuery,
    useFieldDefinitionsQuery,
} from "../generated/consumer-graph-types";
import { getCurrentLng } from "../providers/ui-language-provider";

export type ExploreData = {
    facetsFilter: Facet[];
    facetsNavigation: Facet[];
    availableKeywordFields: string[];
    availableTextFields: string[];
    availableNumberFields: string[];
    hitListConfiguration: DataDisplayConfig | undefined;
    hitListMetadata: Dictionary<any>;
    navigationMetadataDefinitions: DatafieldDefinition[];
};

export type ExploreDataResult = {
    error: any;
    loading: boolean;
    data: ExploreData | undefined;
};

export const useExploreData = (hitlist: boolean = true): ExploreDataResult => {
    const currentLng = getCurrentLng();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>();
    const [data, setData] = useState<ExploreData | undefined>();

    const { refetch } = useFieldDefinitionsQuery({
        skip: true,
        fetchPolicy: "cache-first",
        variables: {
            acceptedLanguages: [currentLng as LanguageWithWildcard, LanguageWithWildcard.en],
        },
    });

    const { refetch: refetchExploreQuery } = useExploreQuery({
        skip: true,
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        //fetch initial data for explore facet collection, search config and hit list config
        refetchExploreQuery({
            id: ApplicationScope.documentSelection,
            acceptedLanguages: [currentLng as LanguageWithWildcard],
        }).then((result) => {
            if (result.error) {
                setError(error);
                setLoading(false);
            } else {
                onCompleteExploreQuery(result.data);
            }
        });
        // run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCompleteExploreQuery = async (data: ExploreQuery) => {
        let facetsFilter: Facet[] = [];
        let facetsNavigation: Facet[] = [];
        let availableKeywordFields: string[] = [];
        let availableTextFields: string[] = [];
        let availableNumberFields: string[] = [];
        let hitListConfiguration: DataDisplayConfig | undefined = undefined;
        let navigationMetadataDefinitions: DatafieldDefinition[] = [];
        let hitListMetadata: Dictionary<any> = {};

        //search config
        if (data.searchConfig?.entries?.length) {
            const textFields = data.searchConfig.entries.filter((x) => {
                return x?.fieldType === FieldDefinitionTypes.text;
            });

            const keyWordFields = data.searchConfig.entries.filter((x) => {
                return x?.fieldType === FieldDefinitionTypes.keyword;
            });

            const numberFields = data.searchConfig.entries.filter((x) => {
                return x?.fieldType === FieldDefinitionTypes.number;
            });

            const textFieldsValues = textFields.map((x) => {
                return x.referencedId;
            });

            const keyWordFieldsValues = keyWordFields.map((x) => {
                return x.referencedId;
            });

            const numberFieldsValues = numberFields.map((x) => {
                return x.referencedId;
            });

            if (textFieldsValues?.length) availableTextFields = textFieldsValues;
            if (keyWordFieldsValues?.length) availableKeywordFields = keyWordFieldsValues;
            if (numberFieldsValues?.length) availableNumberFields = numberFieldsValues;
        }

        let metadataIdsToFetch: string[] = [];
        let navigationReferencedId: string | undefined;

        //facet collection
        if (data.facetsCollection?.facets?.length) {
            facetsFilter = data.facetsCollection.facets.filter((val: any) => {
                return val?.kind === FacetKinds.filter;
            }) as Facet[];

            facetsNavigation = data.facetsCollection.facets.filter((val: any) => {
                return val?.kind === FacetKinds.navigation;
            }) as Facet[];

            navigationReferencedId = facetsNavigation[0] ? (facetsNavigation[0].referencedId as string) : undefined;
            if (navigationReferencedId) metadataIdsToFetch.push(navigationReferencedId);
        }

        //hit list config
        if (hitlist && data.dataDisplayConfig) {
            const hitListMetadataIds: string[] | undefined = data.dataDisplayConfig?.entries?.map((val: any) => {
                return val.referencedId as string;
            });
            if (hitListMetadataIds) metadataIdsToFetch = metadataIdsToFetch.concat(hitListMetadataIds);
            hitListConfiguration = data.dataDisplayConfig as DataDisplayConfig;
        }

        let fetchedMetadataDefinitions: DatafieldDefinition[] = [];

        if (metadataIdsToFetch.length) {
            const { error, data } = await refetch({ ids: metadataIdsToFetch });
            if (error) {
                setError(error);
                setLoading(false);
                return;
            }
            fetchedMetadataDefinitions = (data?.fieldDefinitions?.fieldDefinitions.map((val) => {
                return val?.node;
            }) || []) as DatafieldDefinition[];

            hitListMetadata = Object.assign(
                {},
                ...fetchedMetadataDefinitions.map((x: any) => ({
                    [x.id]: Object.assign(
                        {},
                        { id: x.id },
                        x.teasers,
                        ...x.values.map((y: any) => ({ [y.key]: { teasers: y.teasers, key: y.key } }))
                    ),
                }))
            );
        }

        if (navigationReferencedId && fetchedMetadataDefinitions.length) {
            const metaData = fetchedMetadataDefinitions.find((val: DatafieldDefinition) => {
                return val.id === navigationReferencedId;
            });
            const newMetadata = metaData ? [metaData] : [];
            navigationMetadataDefinitions = newMetadata;
        }

        setData(() => {
            return {
                facetsFilter,
                facetsNavigation,
                availableKeywordFields,
                availableTextFields,
                availableNumberFields,
                hitListMetadata,
                hitListConfiguration,
                navigationMetadataDefinitions,
            };
        });
        setLoading(false);
    };

    return { error, loading, data };
};
