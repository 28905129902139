import React, { FC, Fragment, useRef, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { GraphLoader } from "../../components/graph-loader";
import { RenderIfHasAccess } from "../../components/render-if-has-access";
import { SideSheet } from "../../components/side-sheet";
import { useNotesSideSheetStyle } from "./notes-side-sheet-style";
import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, Close } from "@mui/icons-material";
import { CountRelation, Note, NoteQueryResult } from "../../generated/consumer-graph-types";
import { NoteCard } from "./note-card";
import { useSnackbar } from "notistack";
import { USER_QUERY, UserData } from "../../shared-queries";
import { Action, Resource } from "../../enums";
import { InfoCubeList } from "../../components/info-cube-list";
import { ListRowProps } from "react-virtualized";
import { NoteCreateDialog } from "./note-create-dialog";
import { NoteEditDialog } from "./note-edit-dialog";
import { DeleteConfirmationDialog } from "../../components/delete-confirmation-dialog";

export const DELETE_NOTE_MUTATION = gql`
    mutation deleteNote($id: ID!) {
        deleteNote(id: $id)
    }
`;

export const NOTES_QUERY = gql`
    query notes($id: ID, $first: NonNegativeInt, $after: String) {
        notes(filter: { equals: { objectId: $id } }) {
            total {
                count
                countRelation
            }
            notes(first: $first, sort: { field: creationDate, order: desc }, after: $after) {
                cursor
                node {
                    id
                    creationDate
                    modificationDate
                    user {
                        id
                        displayName
                    }
                    visibility
                    text {
                        ar
                        da
                        de
                        el
                        en
                        es
                        fr
                        fi
                        hu
                        it
                        ja
                        nb
                        nl
                        pt
                        ro
                        ru
                        sv
                        tr
                    }
                    content {
                        contentId
                    }
                    attachments {
                        path
                        blob {
                            size
                            url
                            id
                            mimeType
                            url
                        }
                    }
                }
            }
        }
    }
`;

interface NotesQueryData {
    notes: NoteQueryResult;
}

type NotesSideSheetProps = {
    //Unique identifier of the content in its use context
    uniqueId: string;
    onClose: () => void;
    open: boolean;
};

export const NotesSideSheet: FC<NotesSideSheetProps> = ({ uniqueId, onClose, open }) => {
    const rowsPerPage = 5;
    const classes = useNotesSideSheetStyle();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const selectedNote = useRef({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isNoteEditOpen, setIsNoteEditOpen] = useState<boolean>(false);
    const [isNoteCreateOpen, setIsNoteCreateOpen] = useState<boolean>(false);
    const [isNoteCreateDlgOpen, setIsNoteCreateDlgOpen] = useState<boolean>(false);
    const [currentNote, setCurrentNote] = useState<Note>();
    const [currentUserId, setCurrentUserId] = useState<string>("");

    const { loading: userLoading } = useQuery<UserData>(USER_QUERY, {
        onCompleted: (data) => {
            setCurrentUserId(data.me.id);
        },
    });

    const variables = { id: uniqueId, first: rowsPerPage };
    const { data, error, loading, refetch, fetchMore } = useQuery<NotesQueryData>(NOTES_QUERY, {
        variables,
        fetchPolicy: "network-only",
        skip: !open,
    });
    const [deleteNoteMutation] = useMutation(DELETE_NOTE_MUTATION);

    const onMenuClick = (event: React.MouseEvent<HTMLElement>, note: Note) => {
        setAnchorEl(event.currentTarget);
        selectedNote.current = note;
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onCreateNote = async () => {
        await refetch({ id: uniqueId, first: rowsPerPage });
        setIsNoteCreateOpen(false);
    };

    const onEditNote = async () => {
        await refetch({ id: uniqueId, first: rowsPerPage });
        setIsNoteEditOpen(false);
    };

    const deleteNote = async () => {
        const { errors } = await deleteNoteMutation({
            variables: { id: currentNote?.id },
            refetchQueries: [{ query: NOTES_QUERY, variables }],
            awaitRefetchQueries: true,
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(t("Note couldn't be deleted"), {
                variant: "error",
                autoHideDuration: null,
            });
        }

        enqueueSnackbar(t("Note successfully deleted"), {
            variant: "success",
        });
    };

    const loadMore = async ({ startIndex }: { startIndex: number }) => {
        if (data?.notes?.total.countRelation === CountRelation.eq && data?.notes?.total.count <= startIndex) {
            return;
        }

        let after = "";
        if (data?.notes?.notes) {
            let note = data.notes.notes[data.notes.notes.length - 1];
            if (note) after = note.cursor;
        }

        await fetchMore({
            variables: {
                first: rowsPerPage,
                after,
            },
        });
    };

    const rowRender = (props: ListRowProps) => {
        const { index, key, style } = props;
        const note = data?.notes?.notes[index]?.node;
        if (!note) return;

        return (
            <Box style={style} key={key}>
                <NoteCard note={note} onMenuClick={note.user?.id === currentUserId ? onMenuClick : undefined} />
            </Box>
        );
    };

    const render = (data: any) => {
        return (
            <>
                <InfoCubeList
                    classes={{ list: classes.noteList }}
                    data={data?.notes?.notes ?? []}
                    rowRenderer={rowRender}
                    loadMore={loadMore}
                />
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    classes={{ list: classes.menuList }}
                >
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            setCurrentNote(selectedNote.current as Note);
                            setIsNoteEditOpen(true);
                        }}
                    >
                        {t("Edit")}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            setCurrentNote(selectedNote.current as Note);
                            setIsNoteCreateDlgOpen(true);
                        }}
                    >
                        {t("Delete")}
                    </MenuItem>
                </Menu>
                <DeleteConfirmationDialog
                    open={isNoteCreateDlgOpen}
                    title={t("Delete note")}
                    content={
                        <Box textAlign="center" component="p">
                            {t("Do you really want to delete the note?")}
                        </Box>
                    }
                    onDelete={async () => {
                        setIsNoteCreateDlgOpen(false);
                        await deleteNote();
                    }}
                    onCancel={() => {
                        setIsNoteCreateDlgOpen(false);
                    }}
                />
            </>
        );
    };

    return (
        <SideSheet onClose={onClose} open={open} anchor={"right"} classes={{ drawerPaper: classes.drawerPaper }}>
            <Box margin={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {t("Notes")}
                </Typography>
                <IconButton aria-label="close" onClick={onClose} size={"small"}>
                    <Close />
                </IconButton>
            </Box>
            <RenderIfHasAccess action={Action.write} resource={Resource.notes_comments}>
                <Box display={"flex"} justifyContent={"flex-end"} m={1}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => setIsNoteCreateOpen(true)}
                        startIcon={<Add />}
                        fullWidth={true}
                    >
                        {t("Create Note")}
                    </Button>
                </Box>
                <NoteCreateDialog
                    open={isNoteCreateOpen}
                    onClose={() => setIsNoteCreateOpen(false)}
                    objectId={uniqueId}
                    onCreate={onCreateNote}
                />
                {currentNote && (
                    <NoteEditDialog
                        open={isNoteEditOpen}
                        onClose={() => setIsNoteEditOpen(false)}
                        note={currentNote as Note}
                        onEditNote={onEditNote}
                    />
                )}
            </RenderIfHasAccess>
            <GraphLoader
                classes={{ progress: classes.progress }}
                loading={loading || userLoading}
                error={error}
                data={data}
                render={render}
            />
        </SideSheet>
    );
};
