import {
    ExploreOutlined,
    FolderOpen,
    HomeOutlined,
    InsertDriveFileOutlined,
    LaunchOutlined,
    SupervisorAccountOutlined,
} from "@mui/icons-material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { SideSheetCollapsable, SideSheetCollapsableItem } from "../components/side-sheet-collapsable";
import { Action, Resource } from "../enums";
import {
    ROUTE_ADMIN,
    ROUTE_COLLECTIONS,
    ROUTE_CONTENT,
    ROUTE_CONTENT_WITH_TOPIC,
    ROUTE_EXPLORE,
    ROUTE_HOME,
} from "./routes";
import { useSideSheetPortalStyle } from "./side-sheet-portal-styles";
import { isAuthenticated } from "../providers/authentication-provider";

export type SideSheetPortalProps = {
    open: boolean;
    collapsed: boolean;
    handleDrawerClose: () => void;
};

export const SideSheetPortal: FC<SideSheetPortalProps> = ({ open, collapsed, handleDrawerClose }) => {
    const classes = useSideSheetPortalStyle();
    const history = useHistory();
    const location = useLocation();
    const [contentPath, setContentPath] = useState<string>("");
    const [sideSheetCollapsableItems, setSideSheetCollapsableItems] = useState<SideSheetCollapsableItem[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        let contentRoute = !!(
            matchPath(location.pathname, ROUTE_CONTENT_WITH_TOPIC) || matchPath(location.pathname, ROUTE_CONTENT)
        );

        if (contentRoute && contentPath !== location.pathname) setContentPath(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (!isAuthenticated()) {
            setSideSheetCollapsableItems([]);
            return;
        }

        const items: SideSheetCollapsableItem[] = [
            {
                id: "home",
                contentTitle: t("Home"),
                startIcon: <HomeOutlined />,
                onClick: () => {
                    history.push(ROUTE_HOME);
                },
            },
            {
                id: "explore",
                startIcon: <ExploreOutlined />,
                contentTitle: t("Explore"),
                onClick: () => {
                    history.push({ pathname: ROUTE_EXPLORE, search: window.location.search });
                },
                access: {
                    action: Action.query,
                    resource: Resource.facet_collections,
                },
            },
            {
                id: "content",
                startIcon: <InsertDriveFileOutlined />,
                contentTitle: t("Content"),
                onClick: () => {
                    history.push(contentPath);
                },
                disabled: !contentPath,
                access: {
                    action: Action.query,
                    resource: Resource.contents,
                },
            },
            {
                id: "collections",
                startIcon: <FolderOpen />,
                contentTitle: t("Collections"),
                onClick: () => {
                    history.push(ROUTE_COLLECTIONS);
                },
                access: {
                    action: Action.query,
                    resource: Resource.collections,
                },
            },
            {
                id: "admin",
                startIcon: <SupervisorAccountOutlined />,
                endIcon: <LaunchOutlined />,
                contentTitle: t("Administration"),
                onClick: () => {
                    window.open(`${window.location.origin}${ROUTE_ADMIN}`, "_blank");
                },
                access: {
                    action: Action.access,
                    resource: Resource.admin_area,
                },
            },
        ];
        setSideSheetCollapsableItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPath]);

    return (
        <SideSheetCollapsable
            items={sideSheetCollapsableItems}
            onClose={handleDrawerClose}
            open={open}
            collapsed={collapsed}
            classes={{
                drawerCollapsed: classes.drawerCollapsed,
                drawerOpen: classes.drawerOpen,
            }}
        />
    );
};
