import { gql } from "@apollo/client";
import { ArrowBack, MoreVert } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory } from "react-router-dom";
import { RowMouseEventHandlerParams } from "react-virtualized";
import { GraphLoader } from "../../components/graph-loader";
import { ColumnData, InfoCubeTable } from "../../components/info-cube-table";
import { MoreMenu } from "../../components/more-menu";
import { RenderIfHasAccess } from "../../components/render-if-has-access";
import {
    ContentSortFields,
    GetContentsOfCollectionQuery,
    SortOrder,
    useGetContentsOfCollectionQuery,
    useRemoveContentsFromCollectionMutation,
} from "../../generated/consumer-graph-types";
import { CountRelation } from "../../generated/public-graph-types";
import { getContentPath } from "../../utils/index";
import { ROUTE_COLLECTIONS } from "../routes";
import { AddToCollectionDialog } from "./add-to-collection-dialog";
import { useCollectionStyle } from "./collection-style";

interface ContentsOfCollectionTableProps {
    collectionId: string;
}

gql`
    mutation removeContentsFromCollection($collectionId: ID!, $idsOfContents: [ID!]!) {
        removeContentsFromCollection(collectionId: $collectionId, idsOfContents: $idsOfContents)
    }
`;

gql`
    query getContentsOfCollection(
        $id: ID!
        $first: NonNegativeInt
        $after: String
        $sortId: ContentSortFields!
        $sortOrder: SortOrder
    ) {
        collection(id: $id) {
            displayName
            user {
                id
            }
            visibility
            contents {
                total {
                    count
                    countRelation
                }
                contents(after: $after, first: $first, sort: { field: $sortId, order: $sortOrder }) {
                    cursor
                    node {
                        id
                        contentId
                        teasers {
                            title
                        }
                    }
                }
            }
        }
    }
`;

export const ContentsOfCollectionTable: FC<ContentsOfCollectionTableProps> = ({ collectionId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const rowsPerPage = 20;
    const [sortByField, setSortByField] = useState<ContentSortFields>(ContentSortFields.title);
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.asc);
    const [anchorEl, setAnchorEl] = useState<any>();
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);
    const [isAddToCollectionDlg, setIsAddToCollectionDlg] = useState<boolean>(false);
    const [contentIdForMoreMenu, setContentIdForMoreMenu] = useState<string>("");
    const [titleCollectionDlg, setTitleCollectionDlg] = useState<string>("");
    const [isEllipsisActive, setIsEllipsisActive] = useState<boolean>(false);
    const labelRef = useRef<any>(null);
    const classes = useCollectionStyle();

    const { data: collectionData, loading, error, refetch, fetchMore } = useGetContentsOfCollectionQuery({
        variables: { id: collectionId, first: rowsPerPage, sortId: sortByField, sortOrder: sortOrder },
        fetchPolicy: "network-only",
        skip: !collectionId,
    });

    const [removeContentsFromCollectionMutation] = useRemoveContentsFromCollectionMutation();

    const deleteContentFromCollection = async (idOfContent: string) => {
        const { data, errors } = await removeContentsFromCollectionMutation({
            variables: {
                idsOfContents: [idOfContent],
                collectionId,
            },
            refetchQueries: ["getContentsOfCollection"],
            awaitRefetchQueries: true,
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(
                t("Could not delete content {{idOfContent}} from collection {{collectionId}}.", {
                    idOfContent,
                    collectionId,
                }),
                {
                    key: idOfContent,
                    variant: "error",
                    autoHideDuration: null,
                }
            );
        }

        if (data) {
            enqueueSnackbar(t("Content deleted from collection"), {
                variant: "success",
            });
        }
    };

    const columns: ColumnData[] = [
        {
            dataKey: "title",
            label: t("Title"),
            render: (data) => {
                return data.node?.teasers?.title;
            },
            widthRatio: 0.9,
        },
        {
            dataKey: "more-menu",
            label: "",
            align: "right",
            render: (data: any) => {
                return (
                    <RenderIfHasAccess action={"write"} resource={"collections"} context={collectionData?.collection}>
                        <Box display={"flex"} justifyContent={"flex-end"} flexGrow={1}>
                            <IconButton
                                data-testid={`more-menu-${data.node.id}`}
                                aria-label="more-menu"
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    setAnchorEl(event.target);
                                    setContentIdForMoreMenu(data.node.id);
                                    setTitleCollectionDlg(data.node?.teasers?.title);
                                    setIsMoreMenuOpen(true);
                                }}
                                size="large"
                            >
                                <MoreVert />
                            </IconButton>
                        </Box>
                    </RenderIfHasAccess>
                );
            },
            widthRatio: 0.1,
            disableSort: true,
        },
    ];

    const sort = async ({ sortBy, sortOrder }: { sortBy: string; sortOrder: SortOrder }) => {
        setSortByField(sortBy as ContentSortFields);
        setSortOrder(sortOrder);

        await refetch({
            id: collectionId,
            first: rowsPerPage,
            sortId: sortBy as ContentSortFields,
            sortOrder: sortOrder,
        });
    };

    const onRowClick = (params: RowMouseEventHandlerParams) => {
        const content = params.rowData.data;
        if (content?.node) {
            const contentPath = getContentPath(content?.node);
            history.push(contentPath);
        }
    };

    const loadMore = async ({ startIndex }: { startIndex: number }) => {
        if (
            collectionData?.collection?.contents?.total.countRelation === CountRelation.eq &&
            collectionData?.collection?.contents?.total?.count <= startIndex
        ) {
            return;
        }

        let after = "";
        if (collectionData?.collection?.contents?.contents) {
            let editableCollection =
                collectionData.collection?.contents.contents[collectionData.collection?.contents.contents.length - 1];
            if (editableCollection) after = editableCollection.cursor;
        }

        await fetchMore({
            variables: {
                first: rowsPerPage,
                after: after,
                sortId: sortByField,
                sortOrder: sortOrder,
            },
        });
    };

    const onScroll = () => {
        if (isMoreMenuOpen) handleClickAway();
    };

    const handleClickAway = (event?: any) => {
        if (!isMoreMenuOpen || event?.target?.nodeName === "path" || event?.target?.nodeName === "svg") return;
        setIsMoreMenuOpen(false);
        setAnchorEl(null);
    };

    const resizeHandler = () => {
        if (labelRef?.current?.offsetWidth < labelRef?.current?.scrollWidth && !isEllipsisActive) {
            setIsEllipsisActive(true);
        }
    };

    useEffect(() => {
        // Timeout to wait for the view to be rendered
        setTimeout(() => resizeHandler(), 300);
        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const render = (data: GetContentsOfCollectionQuery) => {
        if (!data.collection) {
            enqueueSnackbar(
                t("Collection {{collectionId}} not found.", {
                    collectionId,
                }),
                {
                    key: collectionId,
                    variant: "error",
                    autoHideDuration: null,
                }
            );
            return (
                <Redirect
                    to={{
                        pathname: "/collections",
                    }}
                />
            );
        }

        if (!data.collection.contents.contents.length) {
            return (
                <Box display="flex" flexGrow={1} flexDirection="column">
                    <Box display={"flex"} alignItems={"center"} mb={2}>
                        <IconButton aria-label="back" component={Link} to={ROUTE_COLLECTIONS} size="large">
                            <ArrowBack />
                        </IconButton>
                        <Box>
                            <Tooltip
                                arrow
                                placement="bottom"
                                title={
                                    isEllipsisActive ? (
                                        <Box style={{ textTransform: "uppercase" }}>
                                            {t("Collection {{displayName}}", {
                                                displayName: data.collection.displayName,
                                            })}
                                        </Box>
                                    ) : (
                                        ""
                                    )
                                }
                            >
                                <Typography ref={labelRef} variant="h5" className={classes.title}>
                                    {t("Collection {{displayName}}", { displayName: data.collection.displayName })}
                                </Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box ml={2}>{t("There is no content available in this collection.")}</Box>
                </Box>
            );
        }

        return (
            <>
                <Box display={"flex"} alignItems={"center"} sx={{ mb: { lg: 1, xl: 2 }, mt: { lg: 1, xl: 2 } }}>
                    <IconButton aria-label="back" component={Link} to={ROUTE_COLLECTIONS} size="large">
                        <ArrowBack />
                    </IconButton>
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={
                            isEllipsisActive ? (
                                <Box style={{ textTransform: "uppercase" }}>
                                    {t("Collection {{displayName}}", { displayName: data.collection.displayName })}
                                </Box>
                            ) : (
                                ""
                            )
                        }
                    >
                        <Typography ref={labelRef} variant="h5" className={classes.title}>
                            {t("Collection {{displayName}}", { displayName: data.collection.displayName })}
                        </Typography>
                    </Tooltip>
                </Box>
                <InfoCubeTable
                    windowScroll={true}
                    rowHeight={60}
                    data={data.collection.contents?.contents}
                    loadMore={loadMore}
                    sort={sort}
                    sortBy={sortByField}
                    sortOrder={sortOrder}
                    onRowClick={onRowClick}
                    columns={columns}
                    onScroll={onScroll}
                />
                <MoreMenu
                    handleClickAway={handleClickAway}
                    isMoreMenuOpen={isMoreMenuOpen}
                    anchorElement={anchorEl}
                    moreMenuItems={[
                        {
                            label: t("Add to collection"),
                            onClick: async () => {
                                setIsAddToCollectionDlg(true);
                                handleClickAway();
                            },
                        },
                        {
                            label: t("Delete"),
                            onClick: async () => {
                                await deleteContentFromCollection(contentIdForMoreMenu);
                                handleClickAway();
                            },
                        },
                    ]}
                />
                <AddToCollectionDialog
                    onClose={async () => setIsAddToCollectionDlg(false)}
                    open={isAddToCollectionDlg}
                    contentId={contentIdForMoreMenu}
                    contentTitle={titleCollectionDlg}
                    currentCollectionId={collectionId}
                />
            </>
        );
    };

    return <GraphLoader render={render} data={collectionData} loading={loading} error={error}></GraphLoader>;
};
