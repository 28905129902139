import { createStyles, makeStyles } from "@mui/styles";

export const useInfoCubeListStyles = makeStyles(() =>
    createStyles({
        list: {
            "& div": {
                outline: "none",
            },
        },
    })
);
