import { InMemoryCache } from "@apollo/client";

function offsetFromCursor(items: any, cursor: string, readField: any) {
    // Search from the back of the list because the cursor we're
    // looking for is typically the ID of the last item.
    for (let i = items.length - 1; i >= 0; --i) {
        const item = items[i];
        // Using readField works for both non-normalized objects
        // (returning item.id) and normalized references (returning
        // the id field from the referenced entity object), so it's
        // a good idea to use readField when you're not sure what
        // kind of elements you're dealing with.
        if (item.cursor === cursor) {
            // Add one because the cursor identifies the item just
            // before the first item in the page we care about.
            return i + 1;
        }
    }
    // Report that the cursor could not be found.
    return -1;
}

function merge(existing: any, incoming: any, args: any) {
    const merged = existing?.length ? existing.slice(0) : [];
    let offset = offsetFromCursor(merged, args?.args?.after, args.readField);
    // If we couldn't find the cursor, we check for after variable
    // if exists appending incoming data to the end of the list
    // else return incoming data
    if (offset < 0) {
        if (!args?.variables?.after) return incoming;
        else offset = merged.length;
    }

    for (let i = 0; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
    }
    return merged;
}

function mergeWithoutCursor(existing: any, incoming: any, args: any) {
    return { ...existing, ...incoming };
}

export const clientCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                jobChains: {
                    keyArgs: false,
                    merge,
                },
                theme: {
                    keyArgs: false,
                    merge,
                },
                contents: {
                    keyArgs: false,
                    merge: mergeWithoutCursor,
                },
                facetsCollection: {
                    keyArgs: false,
                    merge: mergeWithoutCursor,
                },
            },
        },
        AccessFilterQueryResult: {
            fields: {
                accessFilters: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        CollectionQueryResult: {
            fields: {
                editableCollections: {
                    keyArgs: false,
                    merge,
                },
                collections: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        ContentMapQueryResult: {
            fields: {
                contentMaps: {
                    keyArgs: false,
                    merge,
                },
                matchedFacets: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        NoteQueryResult: {
            fields: {
                notes: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        NotificationQueryResult: {
            fields: {
                notifications: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        UserQueryResult: {
            fields: {
                users: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        WebhookConfigQueryResult: {
            fields: {
                webhookConfigs: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        TenantQueryResult: {
            fields: {
                tenants: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        ContentQueryResult: {
            fields: {
                contents: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        DatafieldDefinitionQueryResult: {
            fields: {
                fieldDefinitions: {
                    keyArgs: false,
                    merge,
                },
            },
        },
        StaticPageQueryResult: {
            fields: {
                staticPages: {
                    keyArgs: false,
                    merge,
                },
            },
        },
    },
});
