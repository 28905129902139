import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { SubTitle } from "./sub-title";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    box: {
        wordBreak: "break-all",
    },
});

type DetailBoxEntryProps = {
    title: string;
    titleColor?: "primary" | "secondary";
    row?: boolean;
    p?: number;
};

export const DetailBoxEntry: FC<DetailBoxEntryProps> = ({ title, titleColor = "secondary", row, p, children }) => {
    const classes = useStyles();

    if (row)
        return (
            <Box display={"flex"} flexDirection={"row"} flexBasis={0} flexGrow={1} p={p ?? 1} className={classes.box}>
                <Box className={"label"} width={80}>
                    <SubTitle color={titleColor}>{title}</SubTitle>
                </Box>
                <Typography variant={"body2"} component={"div"}>
                    {children}
                </Typography>
            </Box>
        );

    return (
        <Box display={"flex"} flexDirection={"column"} flexBasis={0} flexGrow={1} p={1} mb={1} className={classes.box}>
            <SubTitle color={titleColor}>{title}</SubTitle>
            <Box display={"flex"} flexDirection={"column"} flexBasis={0} flexGrow={1}>
                <Typography variant={"body2"} component={"div"}>
                    {children}
                </Typography>
            </Box>
        </Box>
    );
};
