import React, { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { SearchField } from "../../components/search-field";
import { getComponent } from "../../customizing-service";
import { ROUTE_EXPLORE } from "../routes";
import { useHistory } from "react-router-dom";

export const SearchComponent: FC<any> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    // @ts-ignore
    const component = getComponent("SearchComponent");
    if (component) return <Fragment>{component()}</Fragment>;

    const onSelectFacetValue = (rootId: string, referencedId: string) => {
        const urlParams = new URLSearchParams();
        urlParams.set(rootId, referencedId);
        history.push(`${ROUTE_EXPLORE}?${urlParams.toString()}`);
    };

    return (
        <SearchField
            metadata={true}
            color="primary"
            variant="outlined"
            placeholder={t("Search content")}
            clearTriggersSearch={false}
            onSelectFacetValue={onSelectFacetValue}
            autoFocus={true}
        />
    );
};
