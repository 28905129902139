export const MuiTableRow = {
    styleOverrides: {
        root: {
            "&$selected": {
                backgroundColor: "#eeeeee", //colors.grey[200]
            },
            "&$hover": {
                "&:hover": {
                    backgroundColor: "#eeeeee", //colors.grey[200]
                },
            },
        },
    },
};
