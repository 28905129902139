import { Box } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import { GraphLoader } from "../../components/graph-loader";
import { Note, useGetUploadConfigQuery } from "../../generated/consumer-graph-types";
import { NoteForm, NoteFormData } from "./note-form";

type NoteDialogProps = {
    open: boolean;
    title: string;
    dlgBtn: ReactNode;
    attachmentsRenderer: ReactNode;
    onChangeAttachmentInput: (files: File[]) => void;
    onChange: (data: NoteFormData) => void;
    onCancel: () => void;
    note?: Note;
};

export const NoteDialog: FC<NoteDialogProps> = ({
    open,
    title,
    dlgBtn,
    attachmentsRenderer,
    onChangeAttachmentInput,
    onChange,
    onCancel,
    note,
}) => {
    const { loading, error, data } = useGetUploadConfigQuery();

    const render = (data: any) => {
        return (
            <Box mt={1}>
                <NoteForm
                    onChange={onChange}
                    note={note}
                    allowedMimeTypes={data?.tenant.uploadConfig?.notes?.attachments?.allowedMimeTypes}
                    maxFileSize={data?.tenant.uploadConfig?.notes?.attachments?.limits.maxFileSize}
                    maxFilesPerUpload={data?.tenant.uploadConfig?.notes?.attachments?.limits.maxFilesPerUpload}
                    attachmentsRenderer={attachmentsRenderer}
                    onChangeAttachmentInput={onChangeAttachmentInput}
                />
            </Box>
        );
    };

    return (
        <ConfirmationDialog
            fullWidth
            open={open}
            maxWidth={"sm"}
            title={title}
            onCancel={onCancel}
            content={<GraphLoader loading={loading} error={error} data={data} render={render} />}
            dlgBtn={dlgBtn}
        />
    );
};
