import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ContentMap, RawClaimsDocument, RawClaimsQuery } from "../../generated/consumer-graph-types";
import { useSnackbar } from "notistack";
import { gql } from "@apollo/client";
import { consumerClient } from "../../apollo-clients/consumer-client";

interface ContentIframeProps {
    contentURL: string;
    contentMap: ContentMap;
}

gql`
    query rawClaims {
        me {
            id
            rawClaims
        }
    }
`;

export const ContentIframe: FC<ContentIframeProps> = ({ contentURL, contentMap }) => {
    const { t } = useTranslation();
    const iframeRef = useRef(null);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const sendRawClaims = async () => {
        const iframe = iframeRef.current as unknown as HTMLIFrameElement;
        const rawClaimsResult = await consumerClient().query<RawClaimsQuery>({
            query: RawClaimsDocument,
            fetchPolicy: "cache-first",
        });
        const rawClaims = rawClaimsResult.data?.me?.rawClaims;
        const result = { operation: "rawClaims", data: rawClaims };
        iframe?.contentWindow?.postMessage(result);
    };

    const handleMessageEvent = (event: any) => {
        if (
            event.origin !== window.location.origin ||
            !event.data ||
            Object.prototype.toString.call(event.data) === "[object String]" ||
            !("operation" in event.data)
        )
            return;

        const data: { operation: string } = event.data;

        switch (data.operation) {
            case "getRawClaims": {
                sendRawClaims();
                break;
            }

            default: {
                break;
            }
        }
    };

    useEffect(() => {
        if (iframeRef.current) {
            const iframe = iframeRef.current as unknown as HTMLIFrameElement;
            iframe?.contentWindow?.location.replace(contentURL);
        }
        window.addEventListener("message", handleMessageEvent);
        return () => {
            window.removeEventListener("message", handleMessageEvent);
        };
    }, [contentURL]);

    const onload = () => {
        const iframe = iframeRef.current as unknown as HTMLIFrameElement;
        try {
            const nodeListOf = iframe?.contentWindow?.document.querySelectorAll("a");
            nodeListOf?.forEach((node) => {
                let url;
                try {
                    url = new URL(node.href);
                } catch {
                    enqueueSnackbar(t("Link is not accessible"), { variant: "info" });
                    return;
                }

                if (url.protocol === "ms-xhelp:") {
                    const route = `/content/${contentMap.contentId}/t-${node.href.replace("ms-xhelp:///?Id=", "")}-${
                        contentMap.locale
                    }`;
                    node.href = route;
                }

                node.onclick = (ev: MouseEvent) => {
                    // For open in New Tap or New Window this is needed
                    if (ev.ctrlKey || ev.shiftKey) return;

                    const anchorElement = ev.target as HTMLAnchorElement;
                    const sameOrigin = anchorElement.origin === window.location.origin;
                    const route = `${anchorElement.pathname}${anchorElement.hash}`;
                    if (sameOrigin) {
                        // Open normal links via an anchor
                        if (route.includes("/content/")) {
                            history.push(route);
                        } else {
                            // Open content (e. g. pdfs) in infocube with hash
                            if (iframe.contentWindow && node.hash) iframe.contentWindow.location.hash = node.hash;
                            // Open content (e. g. pdfs) in infocube
                            else return;
                        }
                    }
                    // Open external content outside infocube
                    else window.open(anchorElement.href.toString());

                    ev.preventDefault();
                };
            });
        } catch (ignore) {}
    };
    return (
        <iframe
            title={t("content-iframe")}
            ref={iframeRef}
            frameBorder={0}
            scrolling={"yes"}
            width={"100%"}
            height={"100%"}
            onLoad={onload}
        />
    );
};
