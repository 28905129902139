import { useEffect, useState } from "react";
import { Dictionary } from "ts-essentials";

export type ExploreState = {
    selectedFacetsByNavigation: Dictionary<string[]>;
    selectedFacetsByFilter: Dictionary<string[]>;
};

//custom hook persists explore state in session storage
export const useExploreState = () => {
    const key = "Explore";
    const defaultValue: ExploreState = { selectedFacetsByNavigation: {}, selectedFacetsByFilter: {} };

    const [value, setValue] = useState(() => {
        const sessionStorageItem = sessionStorage.getItem(key);
        if (sessionStorageItem === null) return defaultValue;

        try {
            return JSON.parse(sessionStorageItem);
        } catch (e) {
            console.error(e);
            return defaultValue;
        }
    });

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return [value, setValue];
};
