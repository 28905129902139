//@ts-ignore
import * as parser from "../utils/lucene-query-parser/parser";
import { gql } from "@apollo/client";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { FilterList, History, Search } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import React, { FC, ReactNode, useEffect, useState } from "react";

import {
    Language,
    LanguageWithWildcard,
    useAutoCompleteQuery,
    useContentAutocompleteQuery,
    useFacetsCollectionQuery,
} from "../generated/consumer-graph-types";
import { getCurrentLng } from "../providers/ui-language-provider";
import { ContentsFilterProps, getContentFilter } from "../shared-queries/do-not-parse";
import { useInfoCubeAutocompleteStyles } from "./info-cube-autocomplete-style";
import { useTranslation } from "react-i18next";
import { ApplicationScope } from "../enums";

gql`
    query contentAutocomplete($query: String, $filter: ContentFilter) {
        contentAutocomplete(query: $query, filter: $filter) {
            query
            hits {
                suggest
            }
        }
    }
`;

gql`
    query facetsCollection($acceptedLanguages: [LanguageWithWildcard!], $id: ID!) {
        facetsCollection(id: $id) {
            facets {
                kind
                display {
                    type
                    options
                }
                referencedId
                teasers {
                    title(acceptedLanguages: $acceptedLanguages)
                }
            }
        }
    }
`;

gql`
    query autoComplete(
        $filter: DatafieldAutocompleteFilter!
        $first: NonNegativeInt
        $acceptedLanguages: [LanguageWithWildcard!]
    ) {
        datafieldAutocomplete(first: $first, filter: $filter) {
            query
            hits {
                datafieldDefinitionId
                value
                displayName
                language
                datafieldDefinition {
                    teasers {
                        title(acceptedLanguages: $acceptedLanguages)
                    }
                }
            }
        }
    }
`;

type AutoCompleteOption = {
    id?: string;
    root?: string;
    type: "History" | "Metadata" | "Facet" | "Content";
    value: any;
};

type InfoCubeAutocompleteProps = {
    value: string;
    onSearchValueChange: (searchValue: string) => void;
    onInputValueChange: (inputValue: string) => void;
    onEnter: (searchValue: string) => void;
    color?: "primary" | "secondary";
    variant?: "filled" | "outlined" | "standard";
    autoFocus?: boolean;
    label?: string;
    placeholder?: string;
    onBlur?: (searchValue: string) => void;
    classes?: any;
    inputRef?: any;
    onSelectFacetValue?: (rootId: string, referencedId: string) => void;
    onClear?: () => void;
    contentFilterProps?: ContentsFilterProps;
    endAdornment?: ReactNode;
};

export const InfoCubeAutocomplete: FC<InfoCubeAutocompleteProps> = ({
    color,
    variant,
    value,
    onSearchValueChange,
    onInputValueChange,
    onEnter,
    label,
    placeholder,
    onBlur,
    classes,
    inputRef,
    onSelectFacetValue,
    onClear,
    contentFilterProps,
    endAdornment,
    autoFocus,
}) => {
    const SEARCH_VALUES_KEY = "SearchValues";
    const currentLanguage = getCurrentLng();
    const autocompleteClasses = useInfoCubeAutocompleteStyles();
    const [autocompleteOptions, setAutoCompleteOptions] = useState<AutoCompleteOption[]>([]);
    const [historyOptions, setHistoryOptions] = useState<AutoCompleteOption[]>([]);
    const [forceClose, setForceClose] = useState<boolean>(false);
    const [searchValue] = useState<string>(value);
    const [inputValue, setInputValue] = useState<string>(value);
    const [facets, setFacets] = useState<string[]>([]);
    const [metadataAutocompleteOptions, setMetadataAutoCompleteOption] = useState<AutoCompleteOption[]>([]);
    const [contentAutocompleteOptions, setContentAutoCompleteOption] = useState<AutoCompleteOption[]>([]);
    const [parsingError, setParsingError] = useState<string>("");
    const { t } = useTranslation();

    useEffect(() => {
        const searchValuesStorage = localStorage.getItem(SEARCH_VALUES_KEY);
        if (searchValuesStorage) {
            const searchHistory = JSON.parse(searchValuesStorage);
            const options = searchHistory.map((val: any) => {
                const option: AutoCompleteOption = {
                    type: "History",
                    value: val,
                };
                return option;
            });
            setHistoryOptions(options);
        } else setHistoryOptions([]);
    }, []);

    useEffect(() => {
        setInputValue(value);
        setForceClose(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useFacetsCollectionQuery({
        variables: {
            acceptedLanguages: [currentLanguage as LanguageWithWildcard, LanguageWithWildcard.en],
            id: ApplicationScope.documentSelection,
        },
        nextFetchPolicy: "cache-only",
        onCompleted: (data) => {
            const _facets = data?.facetsCollection?.facets?.map((facet) => {
                return facet?.referencedId;
            });
            if (_facets?.length) setFacets(_facets as string[]);
        },
    });
    const skipQuery = !onSelectFacetValue || !(facets?.length && inputValue.length > 2);
    const { data } = useAutoCompleteQuery({
        variables: {
            filter: {
                query: inputValue,
                datafieldDefinitionIds: facets,
                languages: [getCurrentLng() as Language],
            },
            acceptedLanguages: [getCurrentLng() as LanguageWithWildcard, LanguageWithWildcard.en],
        },
        skip: skipQuery,
        onCompleted: (data) => {
            if (data.datafieldAutocomplete.query !== inputValue) {
                return;
            }

            const options =
                data.datafieldAutocomplete.hits.map((val) => {
                    return {
                        id: val.value,
                        type: "Metadata",
                        value: val.displayName,
                        root: val.datafieldDefinition?.teasers?.title || "",
                    } as AutoCompleteOption;
                }) || [];

            setMetadataAutoCompleteOption(options);
        },
    });

    //ignore searchphrase
    const contentFilter = contentFilterProps ? getContentFilter(contentFilterProps, true) : {};
    //TODO one query better?
    useContentAutocompleteQuery({
        variables: {
            query: inputValue,
            filter: contentFilter,
        },
        skip: inputValue?.length < 3,
        onCompleted: (data) => {
            if (data.contentAutocomplete.query !== inputValue) {
                return;
            }

            const options =
                data.contentAutocomplete?.hits?.map((val) => {
                    return {
                        id: val.suggest,
                        type: "Content",
                        value: val.suggest,
                    } as AutoCompleteOption;
                }) || [];

            setContentAutoCompleteOption(options);
        },
    });

    useEffect(() => {
        setAutoCompleteOptions([...metadataAutocompleteOptions, ...contentAutocompleteOptions, ...historyOptions]);
    }, [historyOptions, contentAutocompleteOptions, metadataAutocompleteOptions]);

    const handleOnBlur = () => {
        if (onBlur) onBlur(inputValue);
        setForceClose(true);
    };

    const updateStorage = (val: string) => {
        const historyValues: string[] = historyOptions.map((val) => val.value);
        const alreadyExists = historyValues.includes(val);
        if (alreadyExists) return;

        const maxStoredValues = 100;
        let newHistoryValues = [...historyValues];
        if (newHistoryValues.length === maxStoredValues) newHistoryValues.shift();

        const option: AutoCompleteOption = {
            type: "History",
            value: val,
        };
        setHistoryOptions([...historyOptions, option]);
        newHistoryValues = [...newHistoryValues, val];
        localStorage.setItem(SEARCH_VALUES_KEY, JSON.stringify(newHistoryValues));
    };

    const handleOnEnter = () => {
        if (!validateSearch(inputValue)) return;
        onEnter(inputValue);
        updateStorage(inputValue);
        setForceClose(true);
    };

    const validateSearch = (search: string) => {
        try {
            parser.parse(search);
            setParsingError("");
            return true;
        } catch (e: any) {
            const expected = t("Expected");
            const endOfInput = t("end of input");
            const found = t("found");
            const whitespace = t("whitespace");
            const or = t("or");
            const but = t("but");
            setParsingError(
                `⚠️ ${e.message
                    ?.replace("Expected", expected)
                    ?.replace("end of input", endOfInput)
                    ?.replace("found", found)
                    ?.replace("whitespace", whitespace)
                    ?.replace("or", or)
                    ?.replace("but", but)}`
            );
            return false;
        }
    };

    const onChange = (event: React.ChangeEvent<{}>, option: any) => {
        if (!option) {
            onSearchValueChange("");
            return;
        }
        if (typeof option === "string") {
            onSearchValueChange(option);
            return;
        }

        switch (option.type) {
            case "Content":
            case "History":
                onSearchValueChange(option.value || "");
                setForceClose(true);
                break;
            case "Metadata":
                const found = data?.datafieldAutocomplete.hits.find((val) => val.value === option.id);
                setInputValue("");

                if (found && onSelectFacetValue) onSelectFacetValue(found.datafieldDefinitionId, option.id as string);
                setForceClose(true);
                break;
            default:
                break;
        }
    };

    const open = !forceClose && inputValue.length > 2;

    const renderParts = (parts: any[]) => {
        return parts.map((part: any, index: number) => {
            return (
                <span
                    key={`${part.text}_${index}`}
                    style={{ fontWeight: part.highlight ? 700 : 400, whiteSpace: "pre-wrap" }}
                >
                    {part.text}
                </span>
            );
        });
    };

    const renderContentAutoCompleteOption = (option: AutoCompleteOption, parts: any[]) => {
        return (
            <Box display={"flex"} alignItems={"center"}>
                <Search style={{ marginRight: 4 }} />
                {parts.map((part: any, index: number) => (
                    <span key={`${part.text}_${index}`} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                    </span>
                ))}
            </Box>
        );
    };

    const renderHistoryOption = (option: AutoCompleteOption, parts: any[]) => {
        return (
            <Box display={"flex"} alignItems={"center"}>
                <History style={{ marginRight: 4 }} />
                {renderParts(parts)}
            </Box>
        );
    };

    const renderMetadataOption = (option: AutoCompleteOption, parts: any[]) => {
        return (
            <Box display={"flex"} alignItems={"center"}>
                <Box display={"flex"} alignItems={"center"}>
                    <FilterList style={{ marginRight: 4 }} />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography component={"div"} variant={"caption"}>
                            {option.root}
                        </Typography>
                        <Box>{renderParts(parts)}</Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <Autocomplete
            classes={{
                root: clsx(
                    autocompleteClasses.root,
                    classes?.root,
                    color === "secondary" ? autocompleteClasses.rootSecondary : null
                ),
            }}
            fullWidth={true}
            freeSolo={true}
            clearOnEscape={true}
            open={open}
            options={autocompleteOptions}
            filterOptions={(options, state) => {
                const optionTypes = ["Content", "Metadata", "Facet", "History"];
                let filteredOption: AutoCompleteOption[] = [];
                optionTypes.forEach((val) => {
                    filteredOption = filteredOption.concat(
                        options
                            .filter((option: AutoCompleteOption) => {
                                return (
                                    option.type === val &&
                                    option.value?.toLowerCase().includes(state?.inputValue?.toLowerCase())
                                );
                            })
                            .slice(0, 4) //max.5 options per type
                    );
                });
                return filteredOption;
            }}
            getOptionLabel={(option) => {
                if (typeof option === "string") return option;

                return option.value;
            }}
            onChange={onChange}
            onBlur={handleOnBlur}
            value={searchValue}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) => {
                setInputValue(newInputValue);
                onInputValueChange(newInputValue);
                setForceClose(false);
                if (reason === "clear") {
                    if (onClear) onClear();
                }
            }}
            renderInput={(params) => {
                params.InputProps.startAdornment = (
                    <IconButton onClick={handleOnEnter} size="small">
                        <Search color={color} data-icon={"search"} />
                    </IconButton>
                );
                if (endAdornment)
                    params.InputProps.endAdornment = (
                        <>
                            {params.InputProps.endAdornment}
                            {endAdornment}
                        </>
                    );
                return (
                    <TextField
                        {...params}
                        autoFocus={autoFocus}
                        variant={variant}
                        error={!!parsingError}
                        color={color}
                        className={clsx(autocompleteClasses.textField, classes?.textField)}
                        label={label}
                        onBlur={handleOnBlur}
                        onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                                handleOnEnter();
                            }
                        }}
                        helperText={parsingError}
                        size="small"
                        ref={inputRef}
                        placeholder={placeholder}
                    />
                );
            }}
            renderOption={(props, option: any, state: any) => {
                const matches = match(option.value, state.inputValue);
                const parts = parse(option.value, matches);

                return (
                    <li {...props} key={option.id || props.id}>
                        {(option.type === "History" && renderHistoryOption(option, parts)) ||
                            (option.type === "Metadata" && renderMetadataOption(option, parts)) ||
                            (option.type === "Content" && renderContentAutoCompleteOption(option, parts))}
                    </li>
                );
            }}
        />
    );
};
