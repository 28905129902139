import { Menu, SearchOutlined } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useInfoCubeMediaQuery } from "../../components/info-cube-media-query";
import { RenderIfHasAccess } from "../../components/render-if-has-access";
import { SearchField } from "../../components/search-field";
import { UserAvatar } from "../../components/user-avatar";
import { Action, Resource } from "../../enums";
import { NotificationsButtonTopBarPortal } from "../notifications/notifications-button-top-bar-portal";
import { HelpTopBar } from "./help-top-bar";
import { useTopBarStyles } from "./top-bar-portal-styles";
import { isAuthenticated } from "../../providers/authentication-provider";

interface TopBarProps {
    onMenuClick: () => void;
    onNotificationsButtonClick: () => void;
    logoId?: string;
    title?: string;
}

export const TopBarPortal: FC<TopBarProps> = ({ onMenuClick, onNotificationsButtonClick, logoId, title }) => {
    const classes = useTopBarStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const searchInputRef = useRef();
    const [searchInputVisible, setSearchInputVisible] = useState<boolean>(false);
    const isMobile = useInfoCubeMediaQuery();
    const goToRoot = () => {
        history.push("/");
    };

    function getCompanyLabel() {
        //hide company label if search is visible on mobile devices
        if (isMobile && searchInputVisible) return null;

        return (
            <>
                {logoId && (
                    <img
                        className={`${classes.logo} clickable`}
                        alt="Logo"
                        src={`/downloads/blobs/${logoId}`}
                        onClick={goToRoot}
                    />
                )}
                {title && (
                    <Typography
                        variant="h5"
                        color="inherit"
                        noWrap
                        onClick={logoId ? () => {} : goToRoot}
                        className={`${logoId ? "" : "clickable"}`}
                    >
                        {title}
                    </Typography>
                )}
            </>
        );
    }

    function getSearch() {
        return (
            <>
                <Box className={classes.searchBox} sx={{ visibility: searchInputVisible ? "visible" : "hidden" }}>
                    <SearchField
                        inputRef={searchInputRef}
                        color={"primary"}
                        searchAction={"Icon"}
                        onBlur={(searchValue) => {
                            if (!searchValue) setSearchInputVisible(false);
                        }}
                        clearTriggersSearch={false}
                    />
                </Box>
                <Tooltip arrow title={<>{t("Search")}</>}>
                    <IconButton
                        color={"inherit"}
                        onClick={() => {
                            setSearchInputVisible(true);
                            setTimeout(() => {
                                // @ts-ignore
                                searchInputRef?.current?.click();
                            }, 100);
                        }}
                        style={{ display: searchInputVisible ? "none" : "flex" }}
                        size="large"
                    >
                        <SearchOutlined />
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    return (
        <>
            <AppBar position="fixed" className={classes.root}>
                <Toolbar disableGutters={true}>
                    <IconButton
                        aria-label={"toggle-menu"}
                        className={classes.menuBtn}
                        color="inherit"
                        onClick={onMenuClick}
                        size="large"
                    >
                        <Menu />
                    </IconButton>
                    {getCompanyLabel()}
                    <Box flexGrow={1} />
                    {isAuthenticated() && !isMobile && getSearch()}
                    <RenderIfHasAccess action={Action.query} resource={Resource.notifications} ignoreConditions={true}>
                        <NotificationsButtonTopBarPortal onNotificationsButtonClick={onNotificationsButtonClick} />
                    </RenderIfHasAccess>
                    <HelpTopBar />
                    <UserAvatar title={title} />
                </Toolbar>
            </AppBar>
        </>
    );
};
