export interface ParseNode {
    operator: string;
    term: string;
    left: ParseNode;
    right: ParseNode;
    parenthesized: boolean;
}

export class ParseError extends Error {
    constructor(msg: string) {
        super(msg);

        Object.setPrototypeOf(this, ParseError.prototype);
    }
}
