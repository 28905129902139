import React, { FC, Fragment } from "react";
import { useHasAccess } from "./use-has-access";

export interface RenderIfHasAccessProps {
    action?: string;
    resource?: string;
    context?: any;
    ignoreConditions?: boolean;
    renderForbiddenMessage?: boolean;
    featureID?: string;
}

export const RenderIfHasAccess: FC<RenderIfHasAccessProps> = (props) => {
    const { show, loading } = useHasAccess(props);

    if (loading) return null;

    return show ? <Fragment>{props.children}</Fragment> : props.renderForbiddenMessage ? <div>Forbidden</div> : null;
};
