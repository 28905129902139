import React, { Fragment, FC, ReactNode } from "react";
import { Progress } from "./progress";
import { ApolloError } from "@apollo/client";

interface GraphLoaderProps {
    loading: boolean;
    error: ApolloError | undefined;
    data: any | undefined;
    render: (data: any) => ReactNode;
    classes?: { progress: string };
}

export const GraphLoader: FC<GraphLoaderProps> = ({ loading, error, data, render, classes }) => {
    if (loading) return <Progress classes={{ root: classes?.progress }} />;

    if (error) return <p>{error.message}</p>;

    if (data) return <Fragment>{render(data)}</Fragment>;

    return <Progress classes={{ root: classes?.progress }} />;
};
