import React, { FC } from "react";
import { ExploreData, useExploreData } from "../../components/explore-data";
import { GraphLoader } from "../../components/graph-loader";
import { Go } from "./go";

export const GoLoader: FC = () => {
    const { loading, error, data } = useExploreData(false);

    const render = (data: ExploreData) => {
        return <Go data={data} />;
    };

    return <GraphLoader loading={loading} error={error} data={data} render={render} />;
};
