import React, { FC } from "react";
import { SideSheet } from "../../components/side-sheet";
import { useContentDetailSideSheetStyle } from "./content-detail-side-sheet-style";
import { Box, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useContentDetailsData } from "./content-details-data";
import { GraphLoader } from "../../components/graph-loader";
import { DetailBoxEntry } from "../../components/detail-box-entry";

type ContentDetailSideSheetProps = {
    topicId: string;
    onClose: () => void;
    open: boolean;
};

export const ContentDetailSideSheet: FC<ContentDetailSideSheetProps> = ({ onClose, topicId, open }) => {
    const classes = useContentDetailSideSheetStyle();
    const { t } = useTranslation();
    const { loading, error, metaDataWithValues } = useContentDetailsData(topicId, !open);

    const renderMetaData = (data: Array<{ title: string; values: string[] }>) => {
        return (
            <Box m={1}>
                {data.map((metaDataWithValue, index) => {
                    return (
                        <DetailBoxEntry key={index} title={`${metaDataWithValue.title}: `} titleColor="primary">
                            {metaDataWithValue?.values?.join(", ")}
                        </DetailBoxEntry>
                    );
                })}
            </Box>
        );
    };

    return (
        <SideSheet onClose={onClose} open={open} anchor={"right"} classes={{ drawerPaper: classes.drawerPaper }}>
            <Box>
                <Box margin={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {t("Information")}
                    </Typography>
                    <IconButton aria-label="close" onClick={onClose} size={"small"}>
                        <Close />
                    </IconButton>
                </Box>
            </Box>
            <GraphLoader render={renderMetaData} loading={loading} data={metaDataWithValues} error={error} />
        </SideSheet>
    );
};
