import { palette } from "../palette";

export const MuiButton = {
    styleOverrides: {
        root: {
            "&.MuiButton-outlinedInherit": {
                backgroundColor: palette.background.paper,
                border: "1px solid #bdbdbd",
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
            },
        },
    },
};
