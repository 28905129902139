import { makeStyles } from "@mui/styles";

export const usePortalAppStyle = makeStyles(() => ({
    root: {
        height: "100vh",
        display: "flex",
        "& .ReactVirtualized__Grid": {
            overflow: "overlay !important",
        },
    },
    logo: {
        height: 40,
        paddingRight: 20,
    },
    appBar: {
        zIndex: 1200,
    },
    main: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        height: "100%",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "16px",
        ///PaddingTop matches to app bar height
        paddingTop: "64px",
        "@media (max-width: 899.95px)": {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
        },
        "@media (max-width: 599.95px)": {
            paddingTop: "56px",
        },
    },
}));
