import {
    InsertDriveFileOutlined,
    IntegrationInstructionsOutlined,
    PhotoLibraryOutlined,
    PictureAsPdfOutlined,
    VideoLibraryOutlined,
} from "@mui/icons-material";
import React, { FC } from "react";
import { ReactComponent as WordIcon } from "../img/word.svg";

type MimeTypeIconProps = {
    mimeType: string;
};

export const MimeTypeIcon: FC<MimeTypeIconProps> = ({ mimeType }) => {
    if (mimeType.endsWith("/pdf")) return <PictureAsPdfOutlined />;
    if (mimeType.endsWith("/video")) return <VideoLibraryOutlined />;
    if (mimeType.endsWith("/image")) return <PhotoLibraryOutlined />;
    if (mimeType === "text/html" || mimeType.endsWith("xml")) return <IntegrationInstructionsOutlined />;
    if (mimeType.includes("msword") || mimeType.includes("wordprocessingml.document")) return <WordIcon />;

    return <InsertDriveFileOutlined />;
};
