type ScrollbarWidth = "auto" | "none" | "thin";

export const MuiCssBaseline = {
    styleOverrides: {
        body: {
            height: "100%",
            /* Chrome */
            "& *::-webkit-scrollbar": {
                width: "14px",
                background: "#bdbdbd",
                backgroundColor: "transparent",
            },
            "& *::-webkit-scrollbar-thumb": {
                backgroundColor: "#bdbdbd",
                backgroundClip: "content-box",
                border: "5px solid transparent",
                borderRadius: "7px",
            },
            "& *::-webkit-scrollbar-corner": {
                backgroundColor: "transparent",
            },
            "& *::-webkit-scrollbar-thumb:hover": {
                background: "#bdbdbd",
            },
        },
        /* Firefox */
        div: {
            scrollbarWidth: "thin" as ScrollbarWidth,
            scrollbarColor: "#bdbdbd #ffffff",
        },
    },
};
