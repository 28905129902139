export enum Action {
    access = "access",
    query = "query",
    write = "write",
    delete = "delete",
    administrate = "administrate",
}

export enum Resource {
    admin_area = "admin-area",
    contents = "contents",
    collections = "collections",
    contents_ingest_ms_help_2 = "contents.ingest-ms-help-2",
    contents_ingest_arbitrary_file = "contents.ingest-arbitrary-file",
    facet_collections = "facet-collections",
    notifications = "notifications",
    datafield_definitions = "datafield-definitions",
    datafield_definitions_ingest_customfield_model = "datafield-definitions.ingest-customfield-model",
    static_pages = "static-pages",
    static_pages_menu = "static-pages.menu",
    notes = "notes",
    notes_comments = "notes.comments",
    theme = "themes",
    users = "users",
    access_filters = "access-filters",
    tenant = "tenant",
    webhook_configs = "webhook-configs",
    jobs = "jobs",
    data_display_configs = "data-display-configs",
    search_configs = "search-configs",
    users_api_keys = "users.api-keys",
}

export enum FacetKinds {
    filter = "filter",
    navigation = "navigation",
}

export enum FacetDisplayType {
    flat = "flat",
    tree = "tree",
}

export enum FacetSelectionType {
    singleSelect = "singleSelect",
    multiSelect = "multiSelect",
}

export enum MetadataType {
    dateTime = "dateTime",
    enum = "enum",
    keyword = "keyword",
    number = "number",
    taxonomy = "taxonomy",
    text = "text",
}

export enum Feature {
    API = "api",
    QIC_INFO = "qic-info",
    QIT_INFO = "qit-info",
    SPARE_PARTS = "spare_parts",
    STARTPAGE = "startpage",
}

export enum ApplicationScope {
    assetSelection = "asset-selection",
    asset = "asset",
    productSelection = "product-selection",
    product = "product",
    parts = "parts",
    partsDetails = "part-details",
    article = "article",
    articleSelection = "article-selection",
    documentSelection = "document-selection",
    documentDetails = "document-details",
}
