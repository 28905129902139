import { ArrowBack, Search, Sort } from "@mui/icons-material";
import {
    Badge,
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Paper,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import equal from "fast-deep-equal";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentSortDrawer } from "../../components/content-sort-drawer";
import { FacetsFilter } from "../../components/facets-filter";
import { SearchField } from "../../components/search-field";
import { TotalCountFormatter } from "../../utils/formatter";
import { ExploreSearchProps } from "./explore-search";
import { ExploreFacetNavigationMobile } from "./explore-facet-navigation-mobile";
import { useExploreStyles } from "./explore-style";

export const ExploreSearchMobile: FC<ExploreSearchProps> = ({
    onSelectFacetValue,
    contentFilterProps,
    facets,
    contentsTotal,
    selectedFacets,
    onChange,
    onResetAll,
    contentsAggregationsProps,
    selectedSort,
    onSelectSort,
    fieldDefinitions,
    selectedNavigationFacets,
    facetsCollection,
    contentsData,
    contentsQueryPropsGetter,
    onSelectNavigation,
}) => {
    const { t } = useTranslation();
    const classes = useExploreStyles();
    const [isSearchDlgOpen, setIsSearchDlgOpen] = useState<boolean>(false);
    const [isSortingOpen, setIsSortingOpen] = useState<boolean>(false);
    const [facetsCount, setFacetsCount] = useState<number>(0);

    useEffect(() => {
        let count = 0;
        Object.keys(selectedFacets).forEach((val) => {
            count += selectedFacets[val].length;
        });
        setFacetsCount(count);
    }, [selectedFacets]);

    return (
        <Box display="flex">
            <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1200 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    sx={{ justifyContent: ["center", "space-around", "space-around", "center", "center"] }}
                >
                    <BottomNavigationAction
                        onClick={() => setIsSearchDlgOpen(true)}
                        label={t("Search")}
                        icon={
                            <Badge
                                badgeContent={facetsCount}
                                color="primary"
                                overlap="circular"
                                classes={{ badge: classes.badge }}
                            >
                                <Search color="primary" />
                            </Badge>
                        }
                        classes={{ label: classes.bottomNavigationLabel }}
                    />
                    <BottomNavigationAction
                        onClick={() => setIsSortingOpen(true)}
                        label={t("Sort")}
                        icon={<Sort color="primary" />}
                        classes={{ label: classes.bottomNavigationLabel }}
                    />
                </BottomNavigation>
            </Paper>
            <ContentSortDrawer
                open={isSortingOpen}
                onClose={() => setIsSortingOpen(false)}
                selected={selectedSort}
                onSelect={onSelectSort}
            />
            <Dialog fullScreen open={isSearchDlgOpen} onClose={() => setIsSearchDlgOpen(false)} scroll="paper">
                <Box display={"flex"} alignItems={"center"} justifyContent="space-between">
                    <Box display="flex" alignItems={"center"}>
                        <Box mr={1}>
                            <IconButton aria-label="back" onClick={() => setIsSearchDlgOpen(false)} size="large">
                                <ArrowBack sx={{ fontSize: "32px" }} />
                            </IconButton>
                        </Box>
                        <Typography variant="h5">{t("Filter")}</Typography>
                    </Box>
                    <Box>
                        {!equal(selectedFacets, {}) && (
                            <Button variant="text" onClick={onResetAll}>
                                {t("Reset")}
                            </Button>
                        )}
                    </Box>
                </Box>
                <DialogContent sx={{ pl: 2, pr: 2, pt: 1 }} dividers>
                    <Box display={"flex"} flexGrow={1} justifyContent={"center"} alignItems={"center"}>
                        <SearchField
                            onSelectFacetValue={onSelectFacetValue}
                            metadata={true}
                            color="primary"
                            variant="outlined"
                            contentFilterProps={contentFilterProps}
                            placeholder={t("Search content")}
                            autoFocus={true}
                        />
                    </Box>
                    <ExploreFacetNavigationMobile
                        fieldDefinitions={fieldDefinitions}
                        onSelect={onSelectNavigation}
                        selectedNavigationFacets={selectedNavigationFacets}
                        facetsCollection={facetsCollection}
                        contentsQueryPropsGetter={contentsQueryPropsGetter}
                        contentsData={contentsData}
                    />
                    <FacetsFilter
                        facets={facets}
                        contentsTotal={contentsTotal}
                        onResetAll={onResetAll}
                        onChange={onChange}
                        selectedFacets={selectedFacets}
                        selectedSort={selectedSort}
                        onSelectSort={onSelectSort}
                        contentsAggregationsProps={contentsAggregationsProps}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: 0 }}>
                    {contentsTotal && (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setIsSearchDlgOpen(false)}
                            sx={{ borderRadius: 0 }}
                            size="large"
                        >
                            {TotalCountFormatter(contentsTotal, t("{{count}} Results", { count: contentsTotal.count }))}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};
