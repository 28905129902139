import React, { FC, Fragment } from "react";
import { TopBarPortal } from "./top-bar-portal";
import { getComponent } from "../../customizing-service";

interface TopBarProps {
    onMenuClick: () => void;
    onNotificationsButtonClick: () => void;
    logoId?: string;
    title?: string;
}

export const TopBar: FC<TopBarProps> = ({ onMenuClick, onNotificationsButtonClick, logoId, title }) => {
    // @ts-ignore
    const component = getComponent["TopBarPortal"];
    if (component) return <Fragment>{component(logoId)}</Fragment>;

    return (
        <TopBarPortal
            title={title}
            logoId={logoId}
            onMenuClick={onMenuClick}
            onNotificationsButtonClick={onNotificationsButtonClick}
        />
    );
};
