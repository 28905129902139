//@ts-ignore
import * as parser from "../utils/lucene-query-parser/parser";

import { gql } from "@apollo/client";
import {
    ContentFilter,
    ContentQueryResult,
    FullTextSearchType,
    DatafieldDefinition,
    DataDisplayConfigEntry,
    DatafieldType,
    Facet,
    FieldDefinitionTypes,
    ContentFullTextFields,
} from "../generated/consumer-graph-types";
import { Dictionary } from "ts-essentials";
import { ContentSort } from "../generated/admin-graph-types";
import { ParseError, ParseNode } from "../utils/lucene-query-parser/parser-types";

export const CONTENTS_QUERY = (entries: DataDisplayConfigEntry[]) => {
    const { topicFields, contentMapFields, metadata } = generateHitListQueryParts(entries);

    return gql`
        query searchContents($first: NonNegativeInt, $after: String, $contentFilter: ContentFilter, $sort: [ContentSort]) {
            contents(filter: $contentFilter) {
                total(trackLimit: 1000) {
                    count
                    countRelation
                }
                contents(first: $first, after: $after, sort: $sort) {
                    cursor
                    highlights {
                        title
                        text
                    }
                    node {
                        id
                        contentId
                        locale
                        ${metadata}
                        useContext {
                            rootContentMap {
                                teasers {
                                    title
                                }
                            }
                        }
                        ... on Topic {
                            ${topicFields}
                            contents {
                                mimeType
                            }
                        }
                        ... on ContentMap {
                            id ${/*id needed because empty "... on" is not valid*/ ""}
                            ${contentMapFields}
                        }
                    }
                }
            }
        }
    `;
};

export interface ContentsData {
    contents: ContentQueryResult;
}

export const createBooleanFullTextQuery = (
    search: string,
    fullTextSearchType: FullTextSearchType,
    contentFullTextFields: ContentFullTextFields
) => {
    try {
        const parseTree = parser.parse(search);

        //single term
        if (parseTree.left && !parseTree.right) {
            let term = parseTree.left.term;
            let leftTree = parseTree.left;
            while (!term && leftTree.left) {
                term = leftTree.left.term;
                leftTree = leftTree.left;
            }
            if (term === undefined) term = "";
            return {
                fullText: {
                    query: term,
                    searchType: fullTextSearchType,
                    fields: contentFullTextFields,
                },
            };
        }

        let currentTerms: ParseNode[] = [];

        //pre-order
        const buildFilterFromParseTree = (node: ParseNode) => {
            if (!node) return;

            currentTerms.push(node);

            buildFilterFromParseTree(node.left);
            buildFilterFromParseTree(node.right);
        };

        buildFilterFromParseTree(parseTree);

        currentTerms.reverse();
        const queryParts: any = [];
        let stackOfTerms: string[] = [];
        for (const part of currentTerms) {
            if (part.operator) {
                const terms: any[] = [];

                let i = 0;
                let term;
                while (i < 2 && (term = stackOfTerms.pop())) {
                    terms.push({
                        fullText: {
                            query: term,
                            searchType: fullTextSearchType,
                            fields: contentFullTextFields,
                        },
                    });
                    i++;
                }

                let queryPart;
                while ((queryPart = queryParts.pop())) {
                    terms.push(queryPart);
                }

                let groupPart;
                if (part.operator === "AND" || part.operator === "<implicit>") {
                    groupPart = { andGroup: terms };
                } else if (part.operator === "OR") {
                    groupPart = { orGroup: terms };
                } else if (part.operator.endsWith("NOT")) {
                    /*  parse tree is wrong
                     *
                     *  query: a NOT b
                     *
                     *  is:   NOT      should be:     a
                     *       /   \                     \
                     *      a     b                    NOT
                     *                                   \
                     *                                    b
                     */

                    //pop term and put in NOT clause
                    const NOT_Term = terms.pop();
                    const NOT_Clause = {
                        not: NOT_Term,
                    };

                    terms.push(NOT_Clause);

                    //Push rest in AND or OR clause
                    if (part.operator === "NOT" || part.operator === "AND NOT") {
                        groupPart = { andGroup: terms };
                    } else {
                        groupPart = { orGroup: terms };
                    }
                }

                queryParts.push(groupPart);
            } else if (part.term) stackOfTerms.push(part.term);
        }
        return queryParts[0];
    } catch (e: any) {
        throw new ParseError(e.message);
    }
};

export const generateDynamicSearchContentsOnlyAggregationsQuery = (metadata: any[]) => {
    const datafieldAggregations = metadata
        .filter((m) => {
            return m.type === "metadata";
        })
        .map((m) => m.id);

    const fieldAggregations = metadata
        .filter((m) => {
            return m.type === "field";
        })
        .map((m) => m.id);

    let aggregations = "";

    if (datafieldAggregations.length || fieldAggregations.length) {
        const more = `(after: "", first: 10000){
            count
            value
        }`;

        let fieldQuery = "";
        if (fieldAggregations.length) {
            for (const fieldAggregation of fieldAggregations) {
                fieldQuery += fieldAggregation;
                fieldQuery += more;
            }
        }
        let metaDataQuery = "";

        if (datafieldAggregations.length) {
            metaDataQuery = "useContext {  composite {    ";
            for (const metaDataAggregation of datafieldAggregations) {
                metaDataQuery += metaDataAggregation;
                metaDataQuery += more;
            }
            metaDataQuery += "} }";
        }

        aggregations = `{
        aggregations {  ${fieldQuery}
            ${metaDataQuery}
        }}`;
    }

    return gql`
        query searchContentsOnlyAggregations($contentFilter: ContentFilter) {
            contents(filter: $contentFilter) 
                ${aggregations}
            
        }
    `;
};

export interface ContentsFilterProps {
    searchPhrase: string;
    selectedFacetsByAll: Dictionary<string[]>;
    navigationMetadataDefinitions: DatafieldDefinition[];
    availableTextFields: string[];
    availableKeywordFields: string[];
    availableNumberFields: string[];
    selectedSearchFields: string[];
    allFacetFilters: Facet[];
    ignoreNavigationFacets?: boolean;
}

export interface ContentsAggregationsProps extends ContentsFilterProps {
    rowsPerPage: number;
    searchPhrase: string;
    sort: ContentSort;
}

const filterAndTranslateFacetByType = (
    facetsFilter: Facet[],
    selectedFacets: Dictionary<string[]>,
    type: FieldDefinitionTypes
) => {
    const filtered: Dictionary<string[]> = {};
    const onlyFacetsOfType = facetsFilter.filter((facet: Facet) => facet.definition?.fieldType === type);

    Object.keys(selectedFacets).forEach((key) => {
        if (
            onlyFacetsOfType?.some((navigationMetadataDefinition) => {
                return navigationMetadataDefinition.referencedId === key;
            })
        ) {
            const facet = facetsFilter.find((_facet: Facet) => _facet.referencedId === key);

            if (facet) {
                const referencedId = facet.referencedId;
                if (referencedId) filtered[referencedId] = selectedFacets[key];
            }
        }
    });
    return filtered;
};

export const getContentFilter = (props: ContentsFilterProps, ignoreSearch?: boolean) => {
    const selectedFacets = props.selectedFacetsByAll;

    let onlySelectedTaxonomies = filterAndTranslateFacetByType(
        props.allFacetFilters,
        selectedFacets,
        FieldDefinitionTypes.taxonomy
    );

    let onlySelectedEnums = filterAndTranslateFacetByType(
        props.allFacetFilters,
        selectedFacets,
        FieldDefinitionTypes.enum
    );

    if (props.ignoreNavigationFacets) {
        const facetsWithOutNavigationFacets = onlySelectedTaxonomies;
        Object.keys(onlySelectedTaxonomies).forEach((key) => {
            const navigationFacet = props.navigationMetadataDefinitions?.find((navigationMetadataDefinition) => {
                return navigationMetadataDefinition.id === key;
            });
            if (navigationFacet) {
                delete facetsWithOutNavigationFacets[navigationFacet.id];
            }
        });

        onlySelectedTaxonomies = facetsWithOutNavigationFacets;
    }

    const enumAndGroup: any[] = [];

    Object.keys(onlySelectedEnums).forEach((key) => {
        const enumValuesWithOr = [];
        const values = onlySelectedEnums[key];

        for (const value of values) {
            const equalClause = {};
            //@ts-ignore
            equalClause[key] = value;
            enumValuesWithOr.push({ equals: equalClause });
        }
        enumAndGroup.push({
            orGroup: enumValuesWithOr,
        });
    });

    if (ignoreSearch || !props.searchPhrase) {
        const contentFilter: ContentFilter = {
            taxonomy: onlySelectedTaxonomies,
            andGroup: enumAndGroup,
        };

        return contentFilter;
    }

    const searchFieldsExists = !!(
        props.availableTextFields.length ||
        props.availableKeywordFields.length ||
        props.availableNumberFields.length
    );
    const selectedTextFields = props.availableTextFields.filter(
        (x) => props.selectedSearchFields.length === 0 || props.selectedSearchFields.some((f) => x === f)
    );
    const selectedKeywordFields = props.availableKeywordFields.filter(
        (x) => props.selectedSearchFields.length === 0 || props.selectedSearchFields.some((f) => x === f)
    );
    const selectedNumberFields = props.availableNumberFields.filter(
        (x) => props.selectedSearchFields.length === 0 || props.selectedSearchFields.some((f) => x === f)
    );
    const textFilterField: any = {};

    for (const selectedTextField of selectedTextFields) {
        textFilterField[selectedTextField] = 1;
    }

    const orGroup: any = [];

    for (const selectedKeywordField of selectedKeywordFields) {
        const equalsClause: any = {};
        equalsClause[selectedKeywordField] = props.searchPhrase;
        orGroup.push({ equals: equalsClause });
    }

    if (!isNaN(+props.searchPhrase)) {
        for (const selectedNumberField of selectedNumberFields) {
            const equalsClause: any = {};
            equalsClause[selectedNumberField] = Number(props.searchPhrase);
            orGroup.push({ equals: equalsClause });
        }
    }

    if (selectedTextFields.length) {
        orGroup.push(
            createBooleanFullTextQuery(
                props.searchPhrase,
                FullTextSearchType.prefix,
                searchFieldsExists ? textFilterField : { any: 1 }
            )
        );
    }

    const contentFilter: ContentFilter = {
        taxonomy: onlySelectedTaxonomies,
        orGroup: orGroup,
        andGroup: enumAndGroup,
    };

    return contentFilter;
};

export const getContentsAggregationsQueryVariables = (props: ContentsAggregationsProps) => {
    const contentFilter = getContentFilter(props);

    return {
        after: "",
        first: props.rowsPerPage,
        contentFilter,
        sort: props.sort,
    };
};

export const DELETE_JOB_CHAIN_QUERY = gql`
    mutation deleteJobChain($chainType: JobChainTypes!, $id: ID!) {
        deleteJobChain(chainType: $chainType, id: $id)
    }
`;

export const RETRY_JOB_CHAIN_QUERY = gql`
    mutation retryJobChain($chainType: JobChainTypes!, $id: ID!) {
        retryJobChain(chainType: $chainType, id: $id) {
            id
        }
    }
`;
export const generateHitListQueryParts = (entries: DataDisplayConfigEntry[]) => {
    let metadata = "";
    let topicFields = "";
    let contentMapFields = "";
    const hitListFields = entries?.filter((val) => val.type === DatafieldType.field) || [];
    const dataDisplayConfig = entries?.filter((val) => val.type === DatafieldType.metadata) || [];

    const hitListTopicFields = hitListFields?.filter((x: any) => {
        return x.nodeTypes?.includes("topic");
    });

    const hitListContentMapFields = hitListFields?.filter((x: any) => {
        return x.nodeTypes?.includes("contentMap");
    });

    if (hitListFields?.length) {
        for (const field of hitListTopicFields) {
            if (field.referencedId === "mimeType") continue;
            topicFields += `${field.referencedId}\n`;
        }

        for (const field of hitListContentMapFields) {
            if (field.referencedId === "mimeType") continue;
            contentMapFields += `${field.referencedId}\n`;
        }
    }

    if (dataDisplayConfig?.length) {
        const textMetaData: string[] = [];
        for (const meta of dataDisplayConfig) {
            if (meta.fieldType === FieldDefinitionTypes.text) {
                textMetaData.push(`${meta.referencedId} (acceptedLanguages: any)\n`);
            } else {
                metadata += `${meta.referencedId}\n`;
            }
        }
        metadata += `teasers{title ${textMetaData.join(" ")}}`;
    } else {
        metadata += `teasers{title}`;
    }
    return { topicFields, contentMapFields, metadata };
};
