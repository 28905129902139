import React, { FC, useEffect, useState } from "react";
import { Box, Button, Divider, FormControlLabel, MenuItem, Radio, RadioGroup, Typography } from "@mui/material";
import { Facet, TotalCount } from "../generated/consumer-graph-types";
import { useFacetPopoverStyles } from "./facet-popover-style";
import { useTranslation } from "react-i18next";
import { deepCopy, TotalCountFormatter } from "../utils";
import { Dictionary } from "ts-essentials";

type FacetSingleSelectProps = {
    facet: Facet;
    facetValues: any[];
    selected: Dictionary<string[]>;
    onSelect: (selected: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
    contentsTotal?: TotalCount;
    aggregations?: Dictionary<number>;
};

export const FacetSingleSelect: FC<FacetSingleSelectProps> = ({
    facet,
    facetValues,
    selected,
    onSelect,
    contentsTotal,
    aggregations,
}) => {
    const classes = useFacetPopoverStyles();
    const { t } = useTranslation();
    const [singleFacetValue, setSingleFacetValue] = useState<string>("");
    const [showReset, setShowReset] = useState<boolean>(false);

    useEffect(() => {
        let resetVisible = false;
        let facetValue = "";
        if (selected[facet.referencedId] && selected[facet.referencedId][0]) {
            resetVisible = true;
            facetValue = selected[facet.referencedId][0];
        }

        setSingleFacetValue(facetValue);
        setShowReset(resetVisible);
    }, [facetValues, facet.referencedId, selected]);

    const onSingleSelect = (facet: Facet, value: any) => {
        let newSelected = deepCopy(selected);
        const isSelected = newSelected[facet.referencedId] && newSelected[facet.referencedId].includes(value.key);

        if (isSelected) {
            //deselect facet
            delete newSelected[facet.referencedId];
            setSingleFacetValue("");
            setShowReset(false);
        } else {
            //select facet
            newSelected[facet.referencedId] = [value.key];
            setSingleFacetValue(value.key);
            setShowReset(true);
        }

        onSelect(newSelected, isSelected ? [facet.referencedId] : []);
    };

    const reset = () => {
        let newSelected = deepCopy(selected);
        delete newSelected[facet.referencedId];
        onSelect(newSelected, [facet.referencedId as string]);
        setSingleFacetValue("");
        setShowReset(false);
    };

    const getLabel = (val: any) => {
        let label = val.teasers?.title;
        if (aggregations) {
            label = `${label} (${aggregations[val.key] ?? 0})`;
        }

        return label;
    };

    return (
        <>
            {showReset && (
                <Box display={"flex"} alignItems={"center"} m={1}>
                    <Button size={"small"} onClick={reset}>
                        {t("Reset")}
                    </Button>
                </Box>
            )}
            <Divider />
            <Box overflow={"auto"}>
                <RadioGroup value={singleFacetValue}>
                    {facetValues
                        .filter((val: any) => {
                            const isDisabled = aggregations
                                ? !!(aggregations[val.key] === undefined || aggregations[val.key] === 0)
                                : false;

                            return !isDisabled;
                        })
                        .map((val: any, index: number) => {
                            return (
                                <MenuItem key={index} className={classes.menuItem}>
                                    <FormControlLabel
                                        classes={{ root: classes.formControlLabel }}
                                        value={val.key}
                                        control={<Radio color={"primary"} onClick={() => onSingleSelect(facet, val)} />}
                                        label={getLabel(val)}
                                    />
                                </MenuItem>
                            );
                        })}
                </RadioGroup>
            </Box>
            {contentsTotal && (
                <>
                    <Divider />
                    <Box m={1}>
                        <Typography variant={"caption"}>
                            {TotalCountFormatter(contentsTotal, t("{{count}} Results", { count: contentsTotal.count }))}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    );
};
