import React, { FC, useEffect, useState } from "react";
import { Facet, DatafieldDefinition, TotalCount } from "../generated/consumer-graph-types";
import { Dictionary } from "ts-essentials";
import { FacetsNavigation } from "./facets-navigation";
import { Box, Divider, Typography } from "@mui/material";
import { TotalCountFormatter } from "../utils";
import { useTranslation } from "react-i18next";

type FacetTreeProps = {
    facet: Facet;
    fieldDefinitions: DatafieldDefinition[];
    selected: Dictionary<string[]>;
    onSelect: (selected: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
    contentsTotal?: TotalCount;
    aggregations?: Dictionary<number>;
};

export const FacetTree: FC<FacetTreeProps> = ({
    facet,
    fieldDefinitions,
    aggregations,
    selected,
    onSelect,
    contentsTotal,
}) => {
    const [selectedFacets, setSelectedFacets] = useState<Dictionary<string[]>>({});
    const { t } = useTranslation();

    useEffect(() => {
        setSelectedFacets(selected);
    }, [selected, facet]);

    return (
        <>
            <Box>
                <FacetsNavigation
                    onSelect={onSelect}
                    selectedFacets={selectedFacets}
                    facetsCollection={[facet]}
                    fieldDefinitions={fieldDefinitions}
                    aggregations={aggregations}
                    hideRootLabel={true}
                />
            </Box>
            {contentsTotal && (
                <>
                    <Divider />
                    <Box m={1}>
                        <Typography variant={"caption"}>
                            {TotalCountFormatter(contentsTotal, t("{{count}} Results", { count: contentsTotal.count }))}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    );
};
