import { alpha, Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useHitListStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchPaper: {
            display: "flex",
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.down("md")]: {
                paddingBottom: theme.spacing(7), //app bar height
            },
        },
        list: {
            padding: 0,
        },
        popper: {
            zIndex: 100000,
            minWidth: 180,
        },
        item: {
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 16,
        },
        searchResultList: {
            "&:focus": {
                outline: "none",
            },
        },
        searchResultRow: {
            cursor: "pointer",
            borderRadius: "5px",
            border: "1px solid " + theme.palette.grey[200],
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),
            paddingTop: theme.spacing(1.5),
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down("md")]: {
                marginBottom: 0,
            },
            "&:hover": {
                backgroundColor: theme.palette.grey[200] + " !important",
            },
            "& em": {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        searchResultMeta: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            marginLeft: 0,
            paddingTop: theme.spacing(1),
            color: theme.palette.grey[400],
        },
        highlightText: {
            marginTop: theme.spacing(1),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        progress: {
            height: 700,
        },
        searchList: {
            display: "flex",
            flexGrow: 1,
        },
        facetValue: {
            margin: "1px",
            cursor: "pointer",
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            "&:hover": {
                textDecoration: "underline",
                textDecorationColor: theme.palette.primary.main,
            },
        },
        title: {
            cursor: "pointer",
            color: theme.palette.text.primary,
            display: "flex",
            alignItems: "end",
        },
        icon: {
            marginRight: theme.spacing(1),
            cursor: "pointer",
            "& svg": {
                fontSize: "35px",
                [theme.breakpoints.down("sm")]: {
                    fontSize: "2rem",
                },
            },
        },
    })
);
