import { Box, Button, CircularProgress } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useButtonWithProgressStyles } from "./button-with-progress-style";

type ButtonWithProgressProps = {
    title: string;
    loading: boolean;
    onClick: () => void;
    variant?: "text" | "outlined" | "contained";
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    disabled?: boolean;
    size?: "small" | "medium" | "large";
};

export const ButtonWithProgress: FC<ButtonWithProgressProps> = ({
    title,
    loading,
    onClick,
    variant,
    color,
    startIcon,
    endIcon,
    disabled,
    size,
}) => {
    const classes = useButtonWithProgressStyles();

    return (
        <Box className={classes.wrapper}>
            <Button
                variant={variant || "contained"}
                color={color || "primary"}
                disabled={disabled || loading}
                onClick={onClick}
                startIcon={startIcon}
                endIcon={endIcon}
                size={size}
            >
                {title}
            </Button>
            {loading && <CircularProgress size={24} className={classes.progress} />}
        </Box>
    );
};
