import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useUserAvatarStyle = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 16,
        },
        icon: {
            fontSize: 64,
        },
        popper: {
            zIndex: 100000,
            minWidth: 180,
        },
        button: {
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightMedium,
            justifyContent: "flex-start",
            letterSpacing: 0,
            padding: "10px 8px",
            textTransform: "none",
            width: "100%",
        },
        longText: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    })
);
