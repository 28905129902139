import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useExploreStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            flexDirection: "column",
            flexGrow: 1,
            [theme.breakpoints.down("lg")]: {
                width: "70%",
            },
            [theme.breakpoints.down("xl")]: {
                width: "60%",
            },
        },
        progress: {
            height: 700,
        },
        searchField: {
            "& .MuiFormControl-root": {
                margin: 0,
            },
        },
        container: {
            display: "flex",
            height: "100%",
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down("md")]: {
                paddingTop: 0,
            },
        },
        bottomNavigationLabel: {
            color: theme.palette.primary.main,
        },
        badge: {
            minWidth: theme.spacing(2),
            width: theme.spacing(2),
            height: theme.spacing(2),
            fontSize: "0.65rem",
            top: "5px",
        },
    })
);
