import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithProgress } from "../../components/button-with-progress";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import { CollectionVisibilityTypes, useCreateCollectionMutation } from "../../generated/consumer-graph-types";
import { CollectionForm, CollectionFormData } from "./collection-form";

type AddToNewCollectionDialogProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    afterCreate: (collectionId: string) => void;
};

export const AddToNewCollectionDialog: FC<AddToNewCollectionDialogProps> = ({ open, onClose, title, afterCreate }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formData, setFormData] = useState<CollectionFormData | undefined>();
    const [createCollectionMutation] = useCreateCollectionMutation();

    const save = async () => {
        const { data, errors } = await createCollectionMutation({
            variables: {
                displayName: formData?.displayName,
                description: formData?.description,
                visibility: formData?.isPublic ? CollectionVisibilityTypes.public : CollectionVisibilityTypes.personal,
            },
            refetchQueries: ["editableCollections", "collections"],
            awaitRefetchQueries: true,
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(t("{{name}} couldn't be created", { name: formData?.displayName }), {
                key: formData?.displayName,
                variant: "error",
                autoHideDuration: null,
            });
        }

        if (data?.createCollection) {
            enqueueSnackbar(t("{{name}} successfully created", { name: formData?.displayName }), {
                variant: "success",
            });
            if (afterCreate) afterCreate(data?.createCollection);
        }
    };

    return (
        <ConfirmationDialog
            maxWidth="md"
            fullWidth
            open={open}
            title={title}
            onCancel={onClose}
            content={<CollectionForm onChangeData={setFormData} />}
            dlgBtn={
                <Box ml={1}>
                    <ButtonWithProgress
                        disabled={!formData?.displayName}
                        loading={isSaving}
                        aria-label={"save"}
                        variant="contained"
                        onClick={async () => {
                            setIsSaving(true);
                            await save();
                            setIsSaving(false);
                        }}
                        title={t("Save")}
                    />
                </Box>
            }
        />
    );
};
