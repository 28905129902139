import { Box, Drawer, Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ContentToc } from "./content-toc";

interface ContentTocDrawerProps {
    open: boolean;
    onClose: () => void;
    contentUniqueId: string;
}

export const ContentTocDrawer: FC<ContentTocDrawerProps> = ({ open, onClose, contentUniqueId }) => {
    const { t } = useTranslation();

    return (
        <Drawer anchor={"bottom"} open={open} onClose={onClose} PaperProps={{ sx: { maxHeight: "70%" } }}>
            <Box m={3} mb={1} mt={2}>
                <Typography variant="h6">{t("Table Of Contents")}</Typography>
            </Box>
            <Box mt={2}>
                <ContentToc uniqueId={contentUniqueId} />
            </Box>
        </Drawer>
    );
};
