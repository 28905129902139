import i18n from "i18next";
import { Language } from "../generated/consumer-graph-types";

let availableLngs: string[] = [];
let currentLang = "en";
export const AVAILABLE_LANGUAGES: Language[] = [Language.de, Language.en, Language.es, Language.fr, Language.ja];

export const initLanguage = (defaultLng: string, available: string[]) => {
    let locale = i18n.language.substring(0, 2);
    availableLngs = available;
    currentLang = available.includes(locale) || !defaultLng ? locale : defaultLng;
};

export const getCurrentLng = () => {
    return currentLang;
};

export const setCurrentLng = (lng: string) => {
    if (availableLngs.includes(lng)) currentLang = lng;
};
