import { ApolloProvider } from "@apollo/client";
import { Close } from "@mui/icons-material";
import { Box, CssBaseline, IconButton, StyledEngineProvider, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React, { FC, RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { init } from "../apm-client";
import { consumerClient, GraphError } from "../apollo-clients/consumer-client";
import { ErrorPage } from "../components/error-page";
import { Progress } from "../components/progress";
import { RenderIfHasAccess } from "../components/render-if-has-access";
import { Action, Resource } from "../enums";
import ErrorBoundary from "../error-boundary";
import { Theme } from "../generated/consumer-graph-types";
import { TenantAuthConfig } from "../generated/public-graph-types";
import { isAuthenticated, initAuthentication } from "../providers/authentication-provider";
import { getDisplayName, setTenant } from "../providers/tenant-provider";
import { initLanguage } from "../providers/ui-language-provider";
import { standardTheme } from "../theming/standard-theme";
import { usePortalAppStyle } from "./app-style";
import { NotificationsSideSheet } from "./notifications/notifications-side-sheet";
import { Routes } from "./routes";
import { applyTheme } from "./server-theme";
import { SideSheetPortal } from "./side-sheet-portal";
import { TopBar } from "./top-bar/top-bar";
declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export const App: FC<{ scriptError: string; statusCode: number }> = ({ scriptError, statusCode }) => {
    init();

    const classes = usePortalAppStyle();
    const isDesktop = useMediaQuery(standardTheme.breakpoints.up("lg"), {
        defaultMatches: true,
    });
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [theme, setTheme] = useState<Theme>(Object.assign({}));
    const [themeHasLoaded, setThemeHasLoaded] = useState<boolean>(false);
    const [logoId, setLogoId] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [showTitle, setShowTitle] = useState<boolean>(false);
    const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
    const [graphError, setGraphError] = useState<GraphError | undefined>();

    const notistackRef: RefObject<any> = React.createRef();
    const onClickDismiss = (key: any) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    useEffect(() => {
        if (!scriptError) {
            setTenant()
                .then(async (tenant) => {
                    //set ui language
                    initLanguage(tenant.languages?.default as string, (tenant.languages?.available as string[]) || []);

                    await applyTheme(
                        setTheme,
                        setThemeHasLoaded,
                        setLogoId,
                        setTitle,
                        setShowTitle,
                        tenant.displayName
                    ).catch();

                    if (isAuthenticated()) return;

                    const authenticated = await initAuthentication(tenant.auth as TenantAuthConfig);
                    setIsLoggedIn(authenticated);
                })
                .catch((e) => {
                    console.error(e.message);
                    setError(t("An error occurred during login"));
                });
        } else {
            setError(scriptError);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const changeTheme = (theme: Theme) => {
            if (!theme?.theming) return;

            theme.theming = createTheme(theme.theming);
            setTheme(theme);
            setLogoId(theme?.logo?.blobId || "");
            setShowTitle(theme?.showTitle || false);
            setTitle(theme?.teasers?.title || getDisplayName());
        };
        const receiveMessage = (event: { data: any; origin: string }) => {
            const _theme: Theme = event.data;
            if (event.origin !== window.location.origin) {
                return;
            }
            changeTheme(_theme);
        };

        window.addEventListener("message", receiveMessage, false);
        return () => {
            window.removeEventListener("message", receiveMessage);
        };
    }, [theme]);

    if (error) return <ErrorPage errorMessage={error} />;

    if (isLoggedIn === undefined || !themeHasLoaded || !theme)
        return (
            <Box display={"flex"} height={700} justifyContent={"center"} alignItems={"center"}>
                <Progress />
            </Box>
        );

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleDrawerToggle = () => {
        setOpen(true);
        setCollapsed(!collapsed);
    };

    const handleNotificationsDrawerToggle = () => {
        setNotificationsOpen(!notificationsOpen);
    };

    const shouldOpenSideSheet = isDesktop ? true : open;

    const getErrorMessage = (errorCode: string | undefined) => {
        switch (errorCode) {
            case "403":
                return t("Unfortunately you do not have the required permission.");
            default:
                return;
        }
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme.theming}>
                <SnackbarProvider
                    ref={notistackRef}
                    maxSnack={3}
                    autoHideDuration={3000}
                    preventDuplicate
                    action={(key) => (
                        <IconButton onClick={onClickDismiss(key)} color="inherit" size="small">
                            <Close />
                        </IconButton>
                    )}
                >
                    <ErrorBoundary>
                        <ApolloProvider client={consumerClient(setGraphError)}>
                            {!graphError ? (
                                <Router>
                                    <div className={classes.root}>
                                        <CssBaseline />
                                        <TopBar
                                            onMenuClick={isDesktop ? handleDrawerToggle : handleDrawerOpen}
                                            onNotificationsButtonClick={handleNotificationsDrawerToggle}
                                            logoId={logoId}
                                            title={showTitle ? title : ""}
                                        />
                                        <SideSheetPortal
                                            open={shouldOpenSideSheet}
                                            collapsed={collapsed}
                                            handleDrawerClose={handleDrawerClose}
                                        />
                                        <Box component="main" className={classes.main} id="main">
                                            <Routes
                                                notificationsOpen={notificationsOpen}
                                                setNotificationsOpen={setNotificationsOpen}
                                            />
                                        </Box>
                                        <RenderIfHasAccess
                                            action={Action.query}
                                            resource={Resource.notifications}
                                            ignoreConditions={true}
                                        >
                                            <NotificationsSideSheet
                                                onClose={() => setNotificationsOpen(false)}
                                                open={notificationsOpen}
                                            />
                                        </RenderIfHasAccess>
                                    </div>
                                </Router>
                            ) : (
                                <Box
                                    display="flex"
                                    height="90vh"
                                    justifyContent="center"
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    <ErrorPage
                                        requestId={graphError.requestId}
                                        errorMessage={getErrorMessage(graphError.errorCode)}
                                        errorCode={graphError.errorCode}
                                    />
                                </Box>
                            )}
                        </ApolloProvider>
                    </ErrorBoundary>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
