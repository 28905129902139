import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useSideSheetStyles = makeStyles((theme: Theme) => ({
    paper: {
        overflowX: "hidden",
    },
    open: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    close: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
}));
