import i18next from "i18next";
import { CountRelation, TotalCount } from "../generated/consumer-graph-types";

export const DateFormatter = (dateTimeString: string) => {
    return new Intl.DateTimeFormat(i18next.language).format(new Date(dateTimeString));
};

export const TimeFormatter = (dateTimeString: string) => {
    // @ts-ignore
    return new Intl.DateTimeFormat(i18next.language, { timeStyle: "short" }).format(new Date(dateTimeString));
};

export const DateTimeFormatter = (dateTimeString: string) => {
    // @ts-ignore
    return new Intl.DateTimeFormat(i18next.language, { dateStyle: "short", timeStyle: "short" }).format(
        new Date(dateTimeString)
    );
};

export function BytesFormatter(bytes: number, decimals: number = 2) {
    if (bytes === 0) return "0";

    const k = 1000,
        dm = decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(i > 1 ? dm : 0))}${sizes[i]}`;
}

export const TotalCountFormatter = (totalCount: TotalCount | undefined, text: string) => {
    if (!totalCount) return text;

    switch (totalCount.countRelation) {
        case CountRelation.eq:
            return text;
        case CountRelation.lte:
            return `<${text}`;
        case CountRelation.gte:
            return `>${text}`;
    }

    return text;
};

export const isValidISODate = (text: string) => {
    //Date.parse behaves differently in chrome
    //https://bugs.chromium.org/p/chromium/issues/detail?id=126448
    return text.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/g);
};
