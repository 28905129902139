import { Box, Fab } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useInfoCubeMediaQuery } from "./info-cube-media-query";

interface FloatingActionProps {
    onClick: () => void;
    title: string;
    icon: ReactNode;
}

export const FloatingAction: FC<FloatingActionProps> = ({ onClick, title, icon }) => {
    const isMobile = useInfoCubeMediaQuery();

    return (
        <Fab
            color="primary"
            sx={{ position: "absolute", bottom: isMobile ? 16 : 32, right: isMobile ? 48 : 64, zIndex: 1200 }}
            variant={isMobile ? "circular" : "extended"}
            size={isMobile ? "medium" : "large"}
            onClick={onClick}
        >
            {icon}
            {isMobile ? "" : <Box ml={1}>{title}</Box>}
        </Fab>
    );
};
