import React, { FC, useEffect, useState } from "react";
import { SearchComponent } from "./search/search-component";
import { Box } from "@mui/material";
import { getServerTheme } from "./server-theme";
import { Theme } from "../generated/public-graph-types";
import { useStartPagePortalStyle } from "./start-page-portal-style";

export const StartPagePortal: FC = () => {
    const classes = useStartPagePortalStyle();
    const [startPageLogoURL, setStartPageLogoURL] = useState<string>("");

    useEffect(() => {
        getServerTheme().then((theme: Theme) => {
            setStartPageLogoURL(theme?.startPageLogo?.url);
        });
    }, []);

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            flexGrow={1}
            width={"100%"}
            sx={{ marginTop: [8, 8, 8, 0, 0], paddingTop: [2, 4, 4, 10, 10] }}
        >
            {startPageLogoURL && (
                <Box
                    className={classes.imageContainer}
                    display="flex"
                    flexDirection={"column"}
                    justifyContent={"center"}
                >
                    <img src={startPageLogoURL} alt={"start page logo"} width={"100%"} />
                </Box>
            )}
            <Box className={classes.searchContainer}>
                <SearchComponent />
            </Box>
        </Box>
    );
};
