import React, { FC } from "react";
import { FacetMultiSelect } from "./facet-multi-select";
import { FacetSingleSelect } from "./facet-single-select";
import { FacetTree } from "./facet-tree";
import { FacetSelectionProps } from "./facet-selection";
import { DatafieldDefinition } from "../generated/consumer-graph-types";
import { FacetDisplayType, FacetSelectionType } from "../enums";
import { TotalCount } from "../generated/public-graph-types";
import { Dictionary } from "ts-essentials";

interface FacetSelectorProps extends FacetSelectionProps {
    filteredFacetValues: any[];
    toggleSelectAll: (selectAllChecked: boolean) => void;
    filterValue: string;
    metaDataDefinition: DatafieldDefinition;
    aggregations?: Dictionary<number>;
    contentsTotal?: TotalCount;
}

export const FacetSelector: FC<FacetSelectorProps> = ({
    facet,
    filteredFacetValues,
    onSelect,
    selected,
    toggleSelectAll,
    filterValue,
    metaDataDefinition,
    aggregations,
    contentsTotal,
}) => {
    const isTree = facet.display.options?.visualization === FacetDisplayType.tree;
    const isMultiSelect = facet.display.type === FacetSelectionType.multiSelect;

    const getElement = () => {
        if (isMultiSelect && (filterValue.length > 0 || !isTree))
            return (
                <FacetMultiSelect
                    facet={facet}
                    facetValues={filteredFacetValues}
                    aggregations={aggregations}
                    onSelect={onSelect}
                    selected={selected}
                    filterValue={filterValue}
                    toggleSelectAll={toggleSelectAll}
                    contentsTotal={contentsTotal}
                />
            );

        if (!isMultiSelect && (filterValue.length > 0 || !isTree))
            return (
                <FacetSingleSelect
                    facet={facet}
                    facetValues={filteredFacetValues}
                    aggregations={aggregations}
                    selected={selected}
                    onSelect={onSelect}
                    contentsTotal={contentsTotal}
                />
            );

        if (isTree)
            return (
                <FacetTree
                    facet={facet}
                    fieldDefinitions={metaDataDefinition ? [metaDataDefinition] : []}
                    aggregations={aggregations}
                    onSelect={onSelect}
                    selected={selected}
                    contentsTotal={contentsTotal}
                />
            );
    };

    return <>{getElement()}</>;
};
