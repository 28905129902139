import { init as initApm } from "@elastic/apm-rum";

const isLocal = () => {
    return (window as any).__DEV__;
};

export const init = () => {
    initApm({
        serviceName: "info-cube",
        serverUrl: process.env.REACT_APP_APM_SERVER,
        environment: isLocal() ? "local" : process.env.NODE_ENV === "development" ? "dev" : process.env.NODE_ENV,
    });
};
