import { TaxonomyValue } from "../generated/consumer-graph-types";
import { Dictionary } from "ts-essentials";

export interface TreeNode {
    id: string;
    root: string;
    children: TreeNode[];
    teaser: string;
    count: number;
}

export const convertToTree = (
    taxonomyMetadataDefinitions: TaxonomyValue[],
    root: string,
    aggregations: Dictionary<number>
): TreeNode[] => {
    const findWhere = (nodes: TreeNode[], value: string) => {
        return nodes.find((node) => node.id === value);
    };
    const tree: TreeNode[] = [];

    taxonomyMetadataDefinitions.sort((x, y) => {
        const a = JSON.stringify(x.pathKeys);
        const b = JSON.stringify(y.pathKeys);

        return (x?.pathKeys?.length || 0) - (y?.pathKeys?.length || 0) || a.localeCompare(b);
    });

    for (const taxonomyMetadataDefinition of taxonomyMetadataDefinitions) {
        const keys = taxonomyMetadataDefinition?.pathKeys;
        if (!keys) continue;

        let currentLevel = tree;
        for (let j = 0; j < keys.length; j++) {
            const part = keys[j];
            const existingPath = findWhere(currentLevel, part);

            if (existingPath) {
                currentLevel = existingPath.children;
            } else {
                const count = aggregations[taxonomyMetadataDefinition.key] || 0;

                const newNode: TreeNode = {
                    id: taxonomyMetadataDefinition.key,
                    root,
                    teaser: taxonomyMetadataDefinition.teasers?.title || "",
                    children: [],
                    count,
                };

                currentLevel.push(newNode);
                currentLevel = newNode.children;
            }
        }
    }
    return tree;
};
