import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Dictionary } from "ts-essentials";
import { FacetSelectionType } from "../enums";
import { Facet, DatafieldDefinition } from "../generated/consumer-graph-types";
import { convertToTree, TreeNode } from "../portal/parseTree";
import { getCurrentLng } from "../providers/ui-language-provider";
import { deepCopy } from "../utils";
import { InfoCubeTreeItem } from "./info-cube-tree-item";
import { useFacetsNavigationStyles } from "./facets-navigation-style";
import { Progress } from "./progress";

interface FacetsNavigationProps {
    onSelect: (selectedFacets: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
    selectedFacets: Dictionary<string[]>;
    fieldDefinitions: DatafieldDefinition[];
    aggregations?: Dictionary<number>;
    facetsCollection?: Facet[];
    ignoreMatches?: boolean;
    hideRootLabel?: boolean;
    ignoreSelectionType?: boolean;
}

export const FacetsNavigation: FC<FacetsNavigationProps> = ({
    onSelect,
    selectedFacets,
    fieldDefinitions,
    aggregations,
    facetsCollection,
    ignoreMatches,
    hideRootLabel,
    ignoreSelectionType,
}) => {
    const classes = useFacetsNavigationStyles();
    const currentLng = getCurrentLng();
    const [copyOfFacets, setCopyOfFacets] = useState<any[]>([]);
    useEffect(() => {
        setCopyOfFacets(deepCopy(fieldDefinitions));
    }, [fieldDefinitions]);

    if (!fieldDefinitions || !facetsCollection) return <Progress />;

    const multiSelectFacets = facetsCollection.filter((facet) => {
        return facet.display.type === FacetSelectionType.multiSelect;
    });

    const referencedIdsOfMultiSelectedFacets = multiSelectFacets.map((multiSelectFacet) => {
        return multiSelectFacet.referencedId;
    });

    const toggleFacet = (facetId: string, root: string) => {
        const clonedSelectedFacets: Dictionary<string[]> = deepCopy(selectedFacets);

        if (clonedSelectedFacets[root] && clonedSelectedFacets[root].indexOf(facetId) !== -1) {
            clonedSelectedFacets[root] = clonedSelectedFacets[root].filter((f) => f !== facetId);
            if (!clonedSelectedFacets[root]?.length) delete clonedSelectedFacets[root];
        } else {
            if (clonedSelectedFacets[root]) {
                const isSingleSelect = !referencedIdsOfMultiSelectedFacets.some(
                    (referencedIdsOfMultiSelectedFacet) => referencedIdsOfMultiSelectedFacet === root
                );
                if (isSingleSelect && !ignoreSelectionType) {
                    delete clonedSelectedFacets[root];
                }
            }
            if (!clonedSelectedFacets[root]) clonedSelectedFacets[root] = [];
            clonedSelectedFacets[root].push(facetId);
        }

        onSelect(clonedSelectedFacets, [root]);
    };

    const renderTaxonomy = (taxonomy: DatafieldDefinition, treeNodes: TreeNode[]) => {
        if (!treeNodes.length) return null;

        const id = taxonomy.id;
        //@ts-ignore
        const title = taxonomy?.title ? taxonomy?.title[currentLng] : taxonomy?.teasers?.title || id;

        return (
            <InfoCubeTreeItem
                key={id}
                nodeId={id}
                label={
                    !hideRootLabel ? (
                        <Typography variant={"subtitle1"} sx={{ fontWeight: "bold" }} key={id}>
                            {title}
                        </Typography>
                    ) : null
                }
                sx={{ cursor: "default" }}
                icon={<></>}
            >
                {treeNodes.map((node: TreeNode) => {
                    return <Fragment key={node.id}>{renderTree(node)}</Fragment>;
                })}
            </InfoCubeTreeItem>
        );
    };

    const renderTree = (node: TreeNode) => {
        const count = node.count;
        let title = node.teaser;
        if (!ignoreMatches) {
            title += ` (${count}) `;
        }
        const isSelected = !!(selectedFacets[node.root] && selectedFacets[node.root].indexOf(node.id) !== -1);
        const disabled = count < 1 && !ignoreMatches && !isSelected;
        if (disabled) return;

        return (
            <InfoCubeTreeItem
                nodeId={node.id}
                label={
                    <FormControlLabel
                        control={<Checkbox size="small" color="primary" checked={isSelected} disabled={disabled} />}
                        label={title}
                    />
                }
                classes={{
                    root: node.children.length ? "" : classes.rootWithoutChildren,
                    content: classes.itemContent,
                    label: disabled ? classes.disabledLabel : "",
                }}
                expandIcon={
                    node.children.length ? <ChevronRight data-testid={`expand-icon-${node.id}`} /> : <Fragment />
                }
                collapseIcon={
                    node.children.length ? <ExpandMore data-testid={`collapse-icon-${node.id}`} /> : <Fragment />
                }
                onLabelClick={() => {
                    if (!disabled) toggleFacet(node.id, node.root);
                }}
            >
                {node.children.map((node: TreeNode) => {
                    return <Fragment key={node.id}>{renderTree(node)}</Fragment>;
                })}
            </InfoCubeTreeItem>
        );
    };

    const matches = !ignoreMatches && aggregations ? aggregations : {};
    let selectedExpanded: string[] = [];
    Object.keys(selectedFacets).forEach((key: string) => {
        selectedExpanded = selectedExpanded.concat([key], selectedFacets[key]);
    });

    return (
        <>
            {!!matches &&
                copyOfFacets.map((taxonomy: any) => {
                    const treeNodes = convertToTree(taxonomy.values, taxonomy.id, matches);
                    if (!treeNodes.length) {
                        return null;
                    }

                    let nodeTreeExpanded = treeNodes.map((val) => val.id);
                    let defaultExpanded = selectedExpanded.concat([taxonomy.id], nodeTreeExpanded);

                    return (
                        <TreeView
                            key={taxonomy.id}
                            defaultExpanded={defaultExpanded}
                            className={classes.root}
                            defaultCollapseIcon={<ExpandMore />}
                            defaultExpandIcon={<ChevronRight />}
                            disabledItemsFocusable={true}
                        >
                            {renderTaxonomy(taxonomy, treeNodes)}
                        </TreeView>
                    );
                })}
        </>
    );
};
