import React, { FC } from "react";
import { FormControl, InputLabel, Select } from "@mui/material";
import { LanguageCode } from "./language-code";
import { useLanguageSelectorStyle } from "./language-selector-style";
import { AVAILABLE_LANGUAGES } from "../providers/ui-language-provider";

interface LanguageSelectorProps {
    selected: string;
    onChange: (event: any) => void;
    label?: string;
    size?: "small" | "medium";
    fullWidth?: boolean;
    variant?: "standard" | "outlined" | "filled";
    startAdornment?: React.ReactNode;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({
    selected,
    onChange,
    label,
    size,
    fullWidth,
    variant,
    startAdornment,
}) => {
    const classes = useLanguageSelectorStyle();

    return (
        <FormControl size={size} fullWidth={fullWidth} variant={variant} sx={{ margin: 0 }}>
            {label && (
                <InputLabel shrink htmlFor="select-language" classes={{ root: classes.label }}>
                    {label}
                </InputLabel>
            )}
            <Select
                startAdornment={startAdornment}
                autoWidth={true}
                native
                value={selected}
                onChange={onChange}
                data-testid={"select-language"}
                name={"select-language"}
            >
                {AVAILABLE_LANGUAGES.map((lng) => {
                    return <LanguageCode key={lng} languageCode={lng} asSelectOption={true} />;
                })}
            </Select>
        </FormControl>
    );
};
