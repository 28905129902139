import { Box, Link } from "@mui/material";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { GraphLoader } from "../../components/graph-loader";
import {
    LanguageWithWildcard,
    StaticPageMenuEntry,
    useStaticPageMenuQuery,
} from "../../generated/consumer-graph-types";
import { getCurrentLng } from "../../providers/ui-language-provider";

export const StaticPagesFooter: FC<any> = () => {
    const currentLanguage = getCurrentLng();
    const { data, error, loading } = useStaticPageMenuQuery({
        variables: {
            languages: [currentLanguage as LanguageWithWildcard, LanguageWithWildcard.en],
        },
    });

    const render = (data: any) => {
        return (
            <Box display={"flex"} justifyContent={"center"} pb={2} pt={4}>
                {data.staticPageMenu?.pages?.map((page: StaticPageMenuEntry, index: number) => {
                    return (
                        <Box mr={4} key={index}>
                            <Link component={RouterLink} underline="none" to={`/static-pages/${page?.pageType}`}>
                                {page?.teasers?.title}
                            </Link>
                        </Box>
                    );
                })}
            </Box>
        );
    };

    return <GraphLoader data={data} error={error} loading={loading} render={render} />;
};
