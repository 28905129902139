import { getAuthHeader } from "../providers/authentication-provider";

export const getContent = async (url: string) => {
    const response = await fetch(url, {
        headers: { Authorization: getAuthHeader() },
    });

    if (!response.ok) throw new Error(response.statusText);

    return await response.text();
};
