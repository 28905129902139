import { Box } from "@mui/system";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FacetsFilter } from "../../components/facets-filter";
import { SearchField } from "../../components/search-field";
import { useExploreStyles } from "./explore-style";
import { ExploreSearchProps } from "./explore-search";

export const ExploreSearchDesktop: FC<ExploreSearchProps> = ({
    onSelectFacetValue,
    contentFilterProps,
    facets,
    contentsTotal,
    selectedFacets,
    onChange,
    onResetAll,
    contentsAggregationsProps,
    selectedSort,
    onSelectSort,
}) => {
    const classes = useExploreStyles();
    const { t } = useTranslation();

    return (
        <>
            <Box mb={1}>
                <SearchField
                    onSelectFacetValue={onSelectFacetValue}
                    metadata={true}
                    color="primary"
                    variant="outlined"
                    contentFilterProps={contentFilterProps}
                    placeholder={t("Search content")}
                    classes={{ autocomplete: classes.searchField }}
                    autoFocus={true}
                />
            </Box>
            <FacetsFilter
                facets={facets}
                contentsTotal={contentsTotal}
                onResetAll={onResetAll}
                onChange={onChange}
                selectedFacets={selectedFacets}
                selectedSort={selectedSort}
                onSelectSort={onSelectSort}
                contentsAggregationsProps={contentsAggregationsProps}
            />
        </>
    );
};
