import { useQuery } from "@apollo/client";
import {
    AccessControlData,
    ACCESS_CONTROL_SETTINGS_QUERY,
    AvailableFeaturesData,
    AVAILABLE_FEATURES,
    UserData,
    USER_QUERY,
} from "../shared-queries";
import { RenderIfHasAccessProps } from "./render-if-has-access";
const AccessControl = require("role-acl");

export const useHasAccess = ({
    action,
    resource,
    context: externalContext,
    ignoreConditions,
    featureID,
}: RenderIfHasAccessProps) => {
    const { data: userData, error: userError, loading: userLoading } = useQuery<UserData>(USER_QUERY, {
        fetchPolicy: "cache-first",
    });

    const getRelevantRoles = (rolesConfig: any, userRoles: string[] | undefined) => {
        if (!userRoles || !rolesConfig) {
            return [];
        }
        const configRoles = Object.keys(rolesConfig);
        return configRoles.filter((role) => userRoles.includes(role));
    };

    const {
        data: accessCtrlSettingsData,
        error: accessCtrlSettingsError,
        loading: accessCtrlSettingsLoading,
    } = useQuery<AccessControlData>(ACCESS_CONTROL_SETTINGS_QUERY, { fetchPolicy: "cache-first" });

    const {
        data: availableFeatures,
        error: availableFeaturesError,
        loading: availableFeaturesLoading,
    } = useQuery<AvailableFeaturesData>(AVAILABLE_FEATURES, { skip: !featureID, fetchPolicy: "cache-first" });

    const features =
        availableFeatures?.availableFeatures?.map((x) => {
            return x?.id;
        }) || [];
    const error = userError || accessCtrlSettingsError || availableFeaturesError;
    if (error) {
        //@ts-ignore
        if (error.networkError?.result?.statusCode !== 401) console.error(error);

        return { show: false, error: error, loading: false };
    }

    if (userLoading || accessCtrlSettingsLoading || (featureID && availableFeaturesLoading))
        return { show: false, error: null, loading: true };

    if (featureID) {
        const hasFeature = features.includes(featureID);
        if (!hasFeature) return { show: hasFeature, error: null, loading: false };
        if (!action || !resource) return { show: hasFeature, error: null, loading: false };
    }

    let show = false;
    const effectiveRoles = userData?.me.effectiveRoles?.length ? userData?.me.effectiveRoles : [];

    if (accessCtrlSettingsData?.accessControlSettings?.rolesConfig) {
        const relevantRoles = getRelevantRoles(
            accessCtrlSettingsData?.accessControlSettings?.rolesConfig,
            effectiveRoles as string[]
        );

        const control = new AccessControl(accessCtrlSettingsData?.accessControlSettings?.rolesConfig);
        if (externalContext) {
            const enrichedContext = {
                user: userData?.me,
                context: { ...externalContext, userId: externalContext.user?.id },
            };

            const permission = control.can(relevantRoles).context(enrichedContext).execute(action).sync().on(resource);
            show = permission.granted;
        } else {
            const permission = control.can(relevantRoles).execute(action).sync().on(resource, !!ignoreConditions);
            show = permission.granted;
        }
    }

    return { show, error: null, loading: false };
};
