import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useTopBarStyles = makeStyles((theme: Theme) => ({
    root: {
        boxShadow: "none",
        "& .clickable": {
            cursor: "pointer",
        },
    },
    menuBtn: {
        marginRight: theme.spacing(1),
    },
    logo: {
        height: 28,
        paddingRight: 20,
    },
    searchBox: {
        display: "flex",
        alignItems: "center",
        maxHeight: 48,
    },
}));
