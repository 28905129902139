import { palette } from "../palette";

export const MuiTooltip = {
    styleOverrides: {
        tooltip: {
            backgroundColor: palette.background.paper,
            color: "#546e7a",
            boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
            fontSize: "0.75rem",
        },
        arrow: {
            "&::before": {
                backgroundColor: palette.background.paper,
                boxShadow:
                    "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
            },
        },
    },
};
