import { Content } from "../generated/consumer-graph-types";
export * from "./formatter";
export default function removeNullProperties(obj: any) {
    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        let hasProperties = value && Object.keys(value).length > 0;
        if (value === null) {
            delete obj[key];
        } else if (typeof value !== "string" && hasProperties) {
            removeNullProperties(value);
        }
    });
}

export const deepCopy = function <T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
};

export const getContentPath = (content: Content) => {
    // @ts-ignore
    if (content.__typename === "Topic") {
        const splitId = content.id.split("@");
        return `/content/${splitId[1]}/${splitId[0]}`;
        // @ts-ignore
    } else if (content.__typename === "ContentMap") {
        return `/content/${content.contentId}`;
    }

    return "";
};
