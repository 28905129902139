import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useInfoCubeBreadcrumbsStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            flexWrap: "nowrap",
            overflow: "hidden",
        },
        item: {
            fontSize: "12px",
            whiteSpace: "nowrap",
        },
        separator: {
            margin: 0,
        },
    })
);
