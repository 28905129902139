import React, { FC } from "react";
import { Button, List, ListItem, ListItemText, Popover } from "@mui/material";
import { useLanguageSelectorStyle } from "./language-selector-style";
import { ChevronLeft, ExpandMore } from "@mui/icons-material";
import { LanguageCode } from "./language-code";

interface LanguagePopoverProps {
    languages: string[] | null | undefined;
    selected: string;
    onChange: (selectedLng: string) => void;
}

export const LanguagePopover: FC<LanguagePopoverProps> = ({ languages, selected, onChange }) => {
    const classes = useLanguageSelectorStyle();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null | undefined>();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <ListItem disableGutters={true} className={classes.list}>
            <Button
                aria-describedby="language-popover"
                onClick={handleClick}
                className={classes.btn}
                disabled={!languages?.length}
                color="inherit"
            >
                <LanguageCode testid={"current-lng"} languageCode={selected} />
                {open ? <ChevronLeft /> : <ExpandMore />}
            </Button>
            {languages?.length && (
                <Popover
                    id="language-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    style={{ zIndex: 100001 }}
                >
                    <List className={classes.list}>
                        {languages.map((lng: string) => {
                            return (
                                <ListItem
                                    key={lng}
                                    selected={lng === selected}
                                    className={classes.listItem}
                                    button
                                    onClick={() => {
                                        onChange(lng as string);
                                        handleClose();
                                    }}
                                >
                                    <ListItemText primary={<LanguageCode languageCode={lng} />} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Popover>
            )}
        </ListItem>
    );
};
