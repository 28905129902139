import React, { FC } from "react";
import { Dictionary } from "ts-essentials";
import { useInfoCubeMediaQuery } from "../../components/info-cube-media-query";
import { ContentSort, Facet, TotalCount } from "../../generated/consumer-graph-types";
import { ContentsAggregationsProps, ContentsFilterProps } from "../../shared-queries/do-not-parse";
import { ExploreSearchDesktop } from "./explore-search-desktop";
import { ExploreSearchMobile } from "./explore-search-mobile";

export interface ExploreSearchProps {
    onSelectFacetValue: (rootId: string, referencedId: string) => void;
    contentFilterProps: ContentsFilterProps;
    facets: Facet[];
    contentsTotal?: TotalCount;
    selectedFacets: Dictionary<string[]>;
    onChange: (selected: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
    onResetAll: () => void;
    contentsAggregationsProps?: ContentsAggregationsProps;
    selectedSort: ContentSort;
    onSelectSort: (sort: ContentSort) => void;
    //TODO
    fieldDefinitions: any;
    selectedNavigationFacets: Dictionary<string[]>;
    facetsCollection: Facet[];
    contentsData: any;
    contentsQueryPropsGetter: () => any;
    onSelectNavigation: (selectedFacets: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
}

export const ExploreSearch: FC<ExploreSearchProps> = (props) => {
    const isMobile = useInfoCubeMediaQuery();

    return isMobile ? <ExploreSearchMobile {...props} /> : <ExploreSearchDesktop {...props} />;
};
