import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useInfoCubeAutocompleteStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiOutlinedInput-root": {
                backgroundColor: theme.palette.background.paper,
            },
        },
        rootSecondary: {
            "& svg": {
                color: theme.palette.secondary.contrastText,
            },
            "& .MuiInputBase-input": {
                color: theme.palette.secondary.contrastText,
            },
            "& .MuiInput-underline:before": {
                borderColor: theme.palette.secondary.contrastText,
            },
            "& .MuiInput-underline:hover:before": {
                borderColor: theme.palette.secondary.contrastText,
            },
            "& .MuiInput-underline:after": {
                borderColor: theme.palette.secondary.contrastText,
            },
        },
        popper: {
            width: "fit-content !important",
            minWidth: 200,
            maxWidth: 400,
        },
        textField: {
            minWidth: 200,
        },
    })
);
