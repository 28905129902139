import { Delete } from "@mui/icons-material";
import { Box, Chip, FormHelperText, IconButton, Tooltip, Typography, Divider } from "@mui/material";
import { alpha, Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import equal from "fast-deep-equal";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dictionary } from "ts-essentials";
import { ApplicationScope } from "../enums";
import { ContentSort, Facet, TotalCount, useFacetSelectionQuery } from "../generated/consumer-graph-types";
import { LanguageWithWildcard } from "../generated/public-graph-types";
import { getCurrentLng } from "../providers/ui-language-provider";
import { ContentsAggregationsProps } from "../shared-queries/do-not-parse";
import { deepCopy, TotalCountFormatter } from "../utils";
import { ContentSortPopper } from "./content-sort-popper";
import { FacetSelection } from "./facet-selection";
import { useInfoCubeMediaQuery } from "./info-cube-media-query";

export type SelectedFilterFacet = {
    rootId: string;
    rootTitle: string;
    referencedId: string;
    title: string;
};

type FacetsFilterProps = {
    facets: Facet[];
    contentsTotal?: TotalCount;
    selectedFacets: Dictionary<string[]>;
    onChange: (selected: Dictionary<string[]>, deselectedRootIds?: string[]) => void;
    onResetAll: () => void;
    contentsAggregationsProps?: ContentsAggregationsProps;
    selectedSort: ContentSort;
    onSelectSort: (sort: ContentSort) => void;
};

const useFacetsFilterStyles = makeStyles((theme: Theme) =>
    createStyles({
        deleteIcon: {
            color: theme.palette.primary.main + " !important",
            "&:hover": {
                color: theme.palette.primary.light + " !important",
            },
        },
        chip: {
            margin: "1px",
            marginBottom: "2px",
            backgroundColor: alpha(theme.palette.primary.main, 0.3),
        },
    })
);

export const FacetsFilter: FC<FacetsFilterProps> = ({
    facets,
    contentsTotal,
    onChange,
    onResetAll,
    selectedFacets,
    selectedSort,
    contentsAggregationsProps,
    onSelectSort,
}) => {
    const isMobile = useInfoCubeMediaQuery();
    const classes = useFacetsFilterStyles();
    const { t } = useTranslation();
    const currentLng = getCurrentLng();
    const [selectedFilterFacet, setSelectedFilterFacet] = useState<SelectedFilterFacet[]>([]);
    const { refetch: refetchFacetSelection } = useFacetSelectionQuery({
        variables: {
            acceptedLanguages: [currentLng as LanguageWithWildcard, LanguageWithWildcard.en],
            id: ApplicationScope.documentSelection,
        },
        skip: true,
    });

    useEffect(() => {
        if (selectedFacets) {
            const ids: string[] = [];
            for (let k in selectedFacets) {
                ids.push(k);
            }

            if (ids.length) {
                evaluateSelectedFacets(ids);
            } else {
                setSelectedFilterFacet([]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacets]);

    const evaluateSelectedFacets = async (ids: string[]) => {
        let newSelected: SelectedFilterFacet[] = [];
        const data = await refetchFacetSelection({ referencedIds: ids });

        if (data.data.facetsCollection?.facets?.length) {
            for (let k in selectedFacets) {
                const foundFacet: any = data.data.facetsCollection.facets.find((val) => val?.referencedId === k);
                if (foundFacet) {
                    const validValues = selectedFacets[k].map((val: string) =>
                        foundFacet.definition.values.find((x: any) => val === x.key)
                    );

                    const selected: SelectedFilterFacet[] = validValues
                        .filter((val: any) => val)
                        .map((val: any) => {
                            return {
                                rootId: foundFacet.referencedId,
                                rootTitle: foundFacet.teasers?.title ?? foundFacet.referencedId,
                                referencedId: val.key,
                                title: val.teasers.title ?? val.key,
                            };
                        });

                    newSelected = newSelected.concat(selected);
                }
            }
        }

        setSelectedFilterFacet(newSelected);
    };

    const deselectFacetFilter = (value: SelectedFilterFacet) => {
        const newSelected = deepCopy(selectedFacets);
        const newFilter = newSelected[value.rootId]?.filter((x: string) => x !== value.referencedId) ?? [];
        if (newFilter.length) {
            newSelected[value.rootId] = newFilter;
        } else {
            delete newSelected[value.rootId];
        }
        onChange(newSelected, [value.rootId]);
    };

    const renderActiveFilter = () => {
        return (
            <>
                <Box mt={1} mb={1}>
                    <Divider />
                </Box>
                <Box flexDirection={"column"} sx={{ display: { md: "flex", sm: "none", xs: "none" } }}>
                    {!equal(selectedFacets, {}) && (
                        <Box display={"flex"}>
                            <Box whiteSpace="nowrap" pt={1.25}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                    {t("Active Filter")}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexWrap={"wrap"} flexGrow={1} ml={1} pt={1.5}>
                                {selectedFilterFacet.map((val: SelectedFilterFacet) => {
                                    return (
                                        <Chip
                                            classes={{ deleteIcon: classes.deleteIcon, root: classes.chip }}
                                            size="small"
                                            key={val.referencedId}
                                            label={
                                                <Box
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                    alignItems={"center"}
                                                    color={"primary.main"}
                                                >
                                                    <Box fontSize={12} fontWeight={"fontWeightBold"}>
                                                        {val.title}
                                                    </Box>
                                                    <Box fontSize={12} ml={0.5}>{`(${val.rootTitle})`}</Box>
                                                </Box>
                                            }
                                            onDelete={() => deselectFacetFilter(val)}
                                        />
                                    );
                                })}
                            </Box>
                            <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="start">
                                <Tooltip arrow title={<>{t("Reset active filters")}</>}>
                                    <IconButton onClick={onResetAll} size="large">
                                        <Delete data-icon={"reset-filters-icon"} color="error" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    )}
                    <Box display={"flex"} flexGrow={1} alignItems={"flex-end"}>
                        {contentsTotal && (
                            <FormHelperText>
                                {TotalCountFormatter(
                                    contentsTotal,
                                    t("{{count}} Results", { count: contentsTotal.count })
                                )}
                            </FormHelperText>
                        )}
                        <Box
                            display={"flex"}
                            flexGrow={1}
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                            paddingBottom={2}
                        >
                            <ContentSortPopper selected={selectedSort} onSelect={onSelectSort} />
                        </Box>
                    </Box>
                </Box>
            </>
        );
    };

    return (
        <>
            <Box display="flex" flexWrap={"wrap"} flexDirection={isMobile ? "column" : "row"}>
                {facets?.map((val: Facet) => {
                    return (
                        <FacetSelection
                            facet={val}
                            key={val.referencedId}
                            onSelect={onChange}
                            selected={selectedFacets}
                            contentsAggregationsProps={contentsAggregationsProps}
                        />
                    );
                })}
            </Box>
            {renderActiveFilter()}
        </>
    );
};
