import { ArrowDropDown, Check, Sort } from "@mui/icons-material";
import { Box, Button, ClickAwayListener, Fade, MenuItem, MenuList, Paper, Popper, Tooltip } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentSort, ContentSortFields, SortOrder } from "../generated/consumer-graph-types";

type ContentSortPopperProps = {
    selected: ContentSort;
    onSelect: (sort: ContentSort) => void;
};

export const ContentSortPopper: FC<ContentSortPopperProps> = ({ selected, onSelect }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedFieldTitle, setSelectedFieldTitle] = useState<string>();

    const entries = [
        {
            title: t("Ranking"),
            fieldTitle: t("Ranking"),
            value: {
                field: ContentSortFields.score,
                order: SortOrder.desc,
            },
        },
        {
            title: t("Title ascending"),
            fieldTitle: t("Title"),
            value: {
                field: ContentSortFields.title,
                order: SortOrder.asc,
            },
        },
        {
            title: t("Title descending"),
            field: t("Title"),
            value: {
                field: ContentSortFields.title,
                order: SortOrder.desc,
            },
        },
        {
            title: t("Language ascending"),
            fieldTitle: t("Language"),
            value: {
                field: ContentSortFields.locale,
                order: SortOrder.asc,
            },
        },
        {
            title: t("Language descending"),
            fieldTitle: t("Language"),
            value: {
                field: ContentSortFields.locale,
                order: SortOrder.desc,
            },
        },
        {
            title: t("Upload date ascending"),
            fieldTitle: t("Upload date"),
            value: {
                field: ContentSortFields.uploadDate,
                order: SortOrder.asc,
            },
        },
        {
            title: t("Upload date descending"),
            fieldTitle: t("Upload date"),
            value: {
                field: ContentSortFields.uploadDate,
                order: SortOrder.desc,
            },
        },
        {
            title: t("Modification date ascending"),
            fieldTitle: t("Modification date"),
            value: {
                field: ContentSortFields.modificationDate,
                order: SortOrder.asc,
            },
        },
        {
            title: t("Modification date descending"),
            fieldTitle: t("Modification date"),
            value: {
                field: ContentSortFields.modificationDate,
                order: SortOrder.desc,
            },
        },
    ];

    useEffect(() => {
        const found = entries.find((x) => x.value.field === selected.field);
        if (found) setSelectedFieldTitle(found.fieldTitle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const toggleOpen = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    return (
        <>
            <Tooltip arrow title={<>{t("Sort")}</>}>
                <Button
                    sx={{ textTransform: "none" }}
                    onClick={toggleOpen}
                    data-icon-button={"sort"}
                    size="small"
                    startIcon={<Sort color="primary" />}
                    endIcon={<ArrowDropDown />}
                    color="inherit"
                    variant="outlined"
                >
                    {selectedFieldTitle}
                </Button>
            </Tooltip>
            <Popper open={Boolean(anchorEl)} placement={"bottom"} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper elevation={3}>
                                <MenuList>
                                    {entries.map((entry, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                onClick={() => {
                                                    onSelect(entry.value);
                                                    handleClickAway();
                                                }}
                                            >
                                                <Box component={"span"} minWidth={32}>
                                                    {JSON.stringify(selected) === JSON.stringify(entry.value) && (
                                                        <Box display={"flex"}>
                                                            <Check />
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Box component={"span"}>{entry.title}</Box>
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};
