import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useNoteFormStyle = makeStyles((theme: Theme) => ({
    root: {
        "& .MuiInputBase-root": {
            fontSize: 12,
        },
        "& .MuiFormControl-root": {
            flexGrow: 0,
        },
    },
    addNotePaper: {
        padding: theme.spacing(2),
    },
    menu: {
        "& .MuiMenu-list": {
            padding: 0,
        },
    },
    dropzone: {
        height: 60,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderWidth: "2px",
        borderRadius: "2px",
        borderColor: theme.palette.divider,
        borderStyle: "dashed",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        outline: "none",
        transition: "border 0.24s ease-in-out",
        fontSize: 11,
        cursor: "pointer",
        "&:hover, &:focus": {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    },
    mimeTypeIconBox: {
        marginTop: "6px",
    },
}));
