import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { FC } from "react";

interface InfoCubeDialogProps extends DialogProps {
    title: string;
    onClose: () => void;
    contentClasses?: { root: string };
}

export const InfoCubeDialog: FC<InfoCubeDialogProps> = (props) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true,
    });
    const { title, onClose, contentClasses, open, maxWidth, ...other } = props;

    return (
        <Dialog
            fullScreen={!isDesktop}
            fullWidth
            open={open}
            maxWidth={props.maxWidth}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    onClose();
                }
            }}
            {...other}
        >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <DialogTitle sx={{ paddingRight: 0 }}>{title}</DialogTitle>
                <Box pr={2}>
                    <IconButton aria-label="close" onClick={onClose} size={"small"}>
                        <Close data-icon={"close"} />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent classes={{ root: contentClasses?.root }} dividers>
                {props.children}
            </DialogContent>
        </Dialog>
    );
};
