import { ArrowUpward } from "@mui/icons-material";
import { Box, Fab, Zoom } from "@mui/material";
import clsx from "clsx";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    IndexRange,
    InfiniteLoader,
    List,
    ScrollParams,
    WindowScroller,
} from "react-virtualized";
import { ListRowProps } from "react-virtualized/dist/es/List";
import { useInfoCubeListStyles } from "./info-cube-list-style";
import { useInfoCubeMediaQuery } from "./info-cube-media-query";
import { useTheme } from "@mui/styles";
import debounce from "lodash.debounce";

type InfoCubeListClasses = {
    list: string;
};

type InfoCubeListProps = {
    data: any[];
    rowRenderer: (props: ListRowProps) => ReactNode;
    loadMore: (params: IndexRange) => Promise<any>;
    width?: number;
    classes?: InfoCubeListClasses;
    onScroll?: (params: ScrollParams) => any;
    bottom?: number;
    windowScroll?: boolean;
};

const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 50,
});

export const InfoCubeList: FC<InfoCubeListProps> = ({
    data,
    rowRenderer,
    loadMore,
    width,
    classes,
    onScroll,
    windowScroll,
}) => {
    const listClasses = useInfoCubeListStyles();
    const isMobile = useInfoCubeMediaQuery();
    const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false);
    let listRef = useRef<any>();
    const theme = useTheme();
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const scrollHandler = () => {
        if (window.pageYOffset > 150) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const resetListRowsHeight = () => {
        cache.clearAll();
        //@ts-ignore
        if (listRef.current) listRef.current.recomputeRowHeights();
    };
    const debouncedResetListRowsHeight = debounce(resetListRowsHeight, 200);

    const resizeHandler = () => {
        debouncedResetListRowsHeight();
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        if (windowScroll) window.addEventListener("scroll", scrollHandler);
        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
            window.removeEventListener("resize", resizeHandler);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        cache.clearAll();
        //@ts-ignore
        if (listRef.current) listRef.current.recomputeRowHeights();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const defaultRowRenderer = (props: ListRowProps) => {
        const { index, key, parent } = props;
        return (
            <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                {rowRenderer(props)}
            </CellMeasurer>
        );
    };

    const windowScrollList = (
        <>
            <Box className={clsx(classes?.list, listClasses.list)}>
                <InfiniteLoader
                    isRowLoaded={({ index }) => (data ? !!data[index] : true)}
                    loadMoreRows={loadMore}
                    rowCount={1000000}
                >
                    {({ onRowsRendered, registerChild }) => (
                        <WindowScroller>
                            {({ height, isScrolling, scrollTop }) => (
                                <AutoSizer disableHeight>
                                    {({ width: autoSizerWidth }) => (
                                        <List
                                            autoHeight
                                            height={height || 100}
                                            onRowsRendered={onRowsRendered}
                                            ref={listRef}
                                            rowCount={data.length}
                                            rowHeight={cache.rowHeight}
                                            rowRenderer={defaultRowRenderer}
                                            width={width ?? (autoSizerWidth || 100)}
                                            deferredMeasurementCache={cache}
                                            overscanRowCount={15}
                                            onScroll={onScroll}
                                            isScrolling={isScrolling}
                                            scrollTop={scrollTop}
                                        />
                                    )}
                                </AutoSizer>
                            )}
                        </WindowScroller>
                    )}
                </InfiniteLoader>
            </Box>
            <Zoom in={showScrollToTop} timeout={transitionDuration}>
                <Fab
                    color="primary"
                    sx={{ position: "fixed", bottom: isMobile ? 64 : 32, right: isMobile ? 48 : 64, zIndex: 1200 }}
                    variant={"circular"}
                    size={isMobile ? "medium" : "large"}
                    onClick={scrollToTop}
                >
                    <ArrowUpward />
                </Fab>
            </Zoom>
        </>
    );

    const virtualizedList = (
        <Box className={clsx(classes?.list, listClasses.list)}>
            <InfiniteLoader
                isRowLoaded={({ index }) => (data ? !!data[index] : true)}
                loadMoreRows={loadMore}
                rowCount={1000000}
            >
                {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                        {({ height, width: autoSizerWidth }) => (
                            <List
                                height={height || 100}
                                onRowsRendered={onRowsRendered}
                                ref={listRef}
                                rowCount={data.length}
                                rowHeight={cache.rowHeight}
                                rowRenderer={defaultRowRenderer}
                                width={width ?? (autoSizerWidth || 100)}
                                deferredMeasurementCache={cache}
                                overscanRowCount={15}
                                onScroll={onScroll}
                            />
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </Box>
    );

    if (windowScroll === true) return windowScrollList;

    return virtualizedList;
};
