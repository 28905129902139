import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        supportedLngs: ["en", "de", "es", "fr", "ja"],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        fallbackLng: "en",
        react: {
            useSuspense: true,
        },
        nsSeparator: false,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
