import React, { FC, Fragment } from "react";
import { Notification } from "../../generated/consumer-graph-types";
import { Box, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import { useNotificationCardStyle } from "./notification-card-style";
import { DateFormatter } from "../../utils";
import { Done, OpenInBrowser } from "@mui/icons-material";

type NotificationCardProps = {
    notification: Notification;
    onMarkAsRead?: (notification: Notification) => void;
    onGotoObject?: (notification: Notification) => void;
};

export const NotificationCard: FC<NotificationCardProps> = ({ notification, onMarkAsRead, onGotoObject }) => {
    const classes = useNotificationCardStyle();

    const clickHandler = (
        e: React.MouseEvent<HTMLElement>,
        notification: Notification,
        functionToCall: ((notification: Notification) => void) | undefined
    ) => {
        e.stopPropagation();
        if (functionToCall) {
            functionToCall(notification);
        }
    };

    return (
        <Card
            className={classes.card}
            onClick={(e) => clickHandler(e, notification, onGotoObject ? onGotoObject : onMarkAsRead)}
        >
            <CardHeader
                className={classes.cardHeader}
                titleTypographyProps={{ variant: "caption" }}
                title={
                    <Box display={"flex"} alignItems={"center"}>
                        <Box display={"flex"} flexDirection={"column"} marginLeft={0.5}>
                            <Box>{DateFormatter(notification.creationDate)}</Box>
                        </Box>
                    </Box>
                }
                action={
                    <Fragment>
                        {onMarkAsRead && (
                            <IconButton
                                aria-label="mark-as-read"
                                onClick={(e) => clickHandler(e, notification, onMarkAsRead)}
                                size="large"
                            >
                                <Done />
                            </IconButton>
                        )}
                        {onGotoObject && (
                            <IconButton
                                aria-label="go-to-object"
                                onClick={(e) => clickHandler(e, notification, onGotoObject)}
                                size="large"
                            >
                                <OpenInBrowser />
                            </IconButton>
                        )}
                    </Fragment>
                }
            />
            <CardContent className={classes.cardContent}>{notification.teasers?.text}</CardContent>
        </Card>
    );
};
