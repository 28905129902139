import { gql } from "@apollo/client";
import { publicClient } from "../apollo-clients/public-client";
import { Tenant } from "../generated/public-graph-types";

const TENANT_QUERY = gql`
    query {
        tenant {
            domain
            displayName
            languages {
                available
                default
            }
            auth {
                accessMode
                authenticators {
                    authenticators {
                        node {
                            url
                            default
                            strategyName
                            displayName
                        }
                    }
                }
            }
        }
    }
`;

let tenant: Tenant;

export const setTenant = async () => {
    const result = await publicClient.query({
        query: TENANT_QUERY,
    });

    tenant = result.data.tenant;
    return result.data.tenant;
};

export const getLanguageConfig = () => {
    return tenant?.languages;
};

export const getDisplayName = () => {
    return tenant?.displayName;
};
