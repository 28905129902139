import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useNotificationCardStyle = makeStyles((theme: Theme) => ({
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: "pointer",
    },
    cardHeader: {
        paddingBottom: 0,
    },
    cardContent: {
        paddingTop: 0,
    },
}));
