import {
    ClickAwayListener,
    ClickAwayListenerProps,
    Drawer,
    Fade,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    PopperProps,
} from "@mui/material";
import React, { FC, MouseEventHandler } from "react";
import { useMoreMenuStyle } from "./more-menu-style";
import { useInfoCubeMediaQuery } from "./info-cube-media-query";

type MoreMenuItem = {
    onClick: MouseEventHandler;
    label: string;
};

interface MoreMenuProps {
    isMoreMenuOpen: boolean;
    anchorElement: PopperProps["anchorEl"];
    handleClickAway: ClickAwayListenerProps["onClickAway"];
    moreMenuItems: MoreMenuItem[];
}

export const MoreMenu: FC<MoreMenuProps> = ({ isMoreMenuOpen, anchorElement, handleClickAway, moreMenuItems }) => {
    const isMobile = useInfoCubeMediaQuery();
    const classes = useMoreMenuStyle();

    if (isMobile) {
        return (
            <Drawer anchor={"bottom"} open={isMoreMenuOpen} onClose={handleClickAway}>
                <MenuList>
                    {moreMenuItems.map((moreMenuItem) => {
                        return (
                            <MenuItem key={moreMenuItem.label} className={classes.item} onClick={moreMenuItem.onClick}>
                                {moreMenuItem.label}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Drawer>
        );
    }

    return (
        <Popper
            open={isMoreMenuOpen}
            placement={"bottom-end"}
            anchorEl={anchorElement}
            transition
            className={classes.popper}
        >
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper elevation={3}>
                            <MenuList>
                                {moreMenuItems.map((moreMenuItem) => {
                                    return (
                                        <MenuItem
                                            key={moreMenuItem.label}
                                            className={classes.item}
                                            onClick={moreMenuItem.onClick}
                                        >
                                            {moreMenuItem.label}
                                        </MenuItem>
                                    );
                                })}
                            </MenuList>
                        </Paper>
                    </Fade>
                </ClickAwayListener>
            )}
        </Popper>
    );
};
