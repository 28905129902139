import { Box, FormControlLabel, Switch, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RenderIfHasAccess } from "../../components/render-if-has-access";
import { Action, Resource } from "../../enums";
import { CollectionVisibilityTypes } from "../../generated/consumer-graph-types";

export type CollectionFormData = {
    displayName: string;
    description: string;
    isPublic: boolean;
};

type CollectionFormProps = {
    onChangeData: (data: CollectionFormData) => void;
    data?: CollectionFormData;
};

export const CollectionForm: FC<CollectionFormProps> = ({ data, onChangeData }) => {
    const [displayNameTextFieldValue, setDisplayNameTextFieldValue] = useState<string>(data?.displayName ?? "");
    const [descriptionTextFieldValue, setDescriptionTextFieldValue] = useState<string>(data?.description ?? "");
    const [isPublicCheckBoxValue, setIsPublicCheckBoxValue] = useState<boolean>(data?.isPublic ?? false);
    const { t } = useTranslation();

    useEffect(() => {
        const formData: CollectionFormData = {
            displayName: displayNameTextFieldValue,
            description: descriptionTextFieldValue,
            isPublic: isPublicCheckBoxValue,
        };
        onChangeData(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayNameTextFieldValue, descriptionTextFieldValue, isPublicCheckBoxValue]);

    return (
        <Box flexDirection={"column"} display="flex">
            <TextField
                size={"small"}
                variant={"outlined"}
                required={true}
                id={"displayname"}
                label={t("Name")}
                value={displayNameTextFieldValue}
                onChange={(e) => setDisplayNameTextFieldValue(e.target.value)}
                margin={"normal"}
            />
            <TextField
                fullWidth={true}
                size={"small"}
                variant={"outlined"}
                id={"description"}
                label={t("Description")}
                value={descriptionTextFieldValue}
                onChange={(e) => setDescriptionTextFieldValue(e.target.value)}
                margin={"normal"}
            />
            <RenderIfHasAccess
                action={Action.write}
                context={{ visibility: CollectionVisibilityTypes.public }}
                resource={Resource.collections}
            >
                <Box>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={isPublicCheckBoxValue}
                                onChange={() => setIsPublicCheckBoxValue(!isPublicCheckBoxValue)}
                            />
                        }
                        disabled={Boolean(data)}
                        label={t("Public")}
                        labelPlacement="end"
                        style={{ maxWidth: "300px" }}
                    />
                </Box>
            </RenderIfHasAccess>
        </Box>
    );
};
