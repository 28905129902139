import "@fontsource/inconsolata";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Progress } from "../components/progress";
import "../i18n";
import * as serviceWorker from "../serviceWorker";
// @ts-ignore TS6133
import { App } from "./app";
require("dotenv").config();

const start = (statusCode: number, scriptError: string) => {
    ReactDOM.render(
        <React.StrictMode>
            <Suspense fallback={<Progress />}>
                <App scriptError={scriptError} statusCode={statusCode} />
            </Suspense>
        </React.StrictMode>,
        document.getElementById("root")
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
};

const serviceWorkerConfig = {
    onUpdate: () => {
        console.log("reload because of new content");
        window.location.reload();
    },
};

serviceWorker.register(serviceWorkerConfig);
// @ts-ignore
window.infocube.start = start;
