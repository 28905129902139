import { gql } from "@apollo/client";
import { Delete } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonWithProgress } from "../../components/button-with-progress";
import { MimeTypeIcon } from "../../components/mime-type-icon";
import {
    BlobRef,
    MultilingualString,
    Note,
    NoteVisibilityTypesInput,
    UpdateNoteInput,
    useDeleteNoteAttachmentsMutation,
    useUpdateNoteMutation,
} from "../../generated/consumer-graph-types";
import { getAuthHeader } from "../../providers/authentication-provider";
import { useNoteFormStyle } from "./notes-form-style";
import { NoteFormData } from "./note-form";
import { NoteDialog } from "./note-dialog";

gql`
    mutation updateNote($note: UpdateNoteInput!) {
        updateNote(partialNote: $note) {
            id
        }
    }
`;

type NoteEditModalProps = {
    note: Note;
    open: boolean;
    onClose: () => void;
    onEditNote: () => void;
};

export const NoteEditDialog: FC<NoteEditModalProps> = ({ open, onClose, onEditNote, note }) => {
    const classes = useNoteFormStyle();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [deleteAttachmentsMutation] = useDeleteNoteAttachmentsMutation();
    const [updateNoteMutation] = useUpdateNoteMutation();
    const [attachments, setAttachments] = useState<BlobRef[]>(note.attachments as BlobRef[]);
    const [attachmentsToRemove, setAttachmentsToRemove] = useState<BlobRef[]>([]);
    const [attachmentsToUpload, setAttachmentsToUpload] = useState<File[]>([]);
    const [formData, setFormData] = useState<NoteFormData | undefined>();

    useEffect(() => {
        setAttachments(note.attachments as BlobRef[]);
        setAttachmentsToUpload([]);
        setAttachmentsToRemove([]);
    }, [note]);

    const onEdit = async (
        language: string,
        comment: string,
        visibilityType: NoteVisibilityTypesInput
    ): Promise<any> => {
        const text: MultilingualString = {};
        // @ts-ignore
        text[language] = comment;

        const noteInput: UpdateNoteInput = {
            id: note.id,
            text,
            visibility: visibilityType,
        };

        const { errors } = await updateNoteMutation({
            variables: { note: noteInput },
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(t("Note couldn't be changed"), {
                variant: "error",
                autoHideDuration: null,
            });
            onEditNote();
            return;
        }

        if (attachmentsToUpload.length) {
            await uploadAttachments(note.id, attachmentsToUpload);
        }

        if (attachmentsToRemove.length) {
            await remove(
                note.id,
                attachmentsToRemove.map((val) => val.blob?.id as string)
            );
        }

        enqueueSnackbar(t("Note successfully changed"), {
            variant: "success",
        });

        onEditNote();
    };

    const remove = async (noteId: string, attachmentIds: string[]) => {
        const { data, errors } = await deleteAttachmentsMutation({
            variables: { id: noteId, attachmentIds },
        });

        if (errors) {
            console.error(errors);
            enqueueSnackbar(errors[0].message, { variant: "error", autoHideDuration: null });
            enqueueSnackbar(t("Note attachment couldn't be deleted"), {
                variant: "error",
                autoHideDuration: null,
            });
        }
        if (data) {
            return true;
        }
    };

    const uploadAttachments = async (noteId: String, attachments: File[]) => {
        try {
            await new Promise((resolve, reject) => {
                const data = new FormData();
                attachments.forEach((val) => {
                    data.append("files", val);
                });

                const uploadRequest = new XMLHttpRequest();
                uploadRequest.addEventListener("load", (event) => {
                    //@ts-ignore
                    if (event.target.status === 201) {
                        //@ts-ignore
                        resolve(event.target.response);
                    }
                    reject(event);
                });
                uploadRequest.addEventListener("error", (event) => {
                    reject(event);
                });

                uploadRequest.open("POST", `/api/upload/notes/${noteId}/attachments`, true);
                uploadRequest.setRequestHeader("Authorization", getAuthHeader());
                uploadRequest.send(data);
            });
        } catch (e) {
            enqueueSnackbar(t("Attachment upload failed."), {
                variant: "warning",
                autoHideDuration: null,
            });
            return false;
        }
    };

    const removeAttachment = (index: any) => {
        let newAttachments = [...attachments];
        setAttachmentsToRemove([...attachmentsToRemove, attachments[index]]);

        newAttachments.splice(index, 1);
        setAttachments(newAttachments);
    };

    const removeAttachmentToUpload = (index: any) => {
        let newAttachments = [...attachmentsToUpload];
        newAttachments.splice(index, 1);
        setAttachmentsToUpload(newAttachments);
    };

    const onChangeAttachmentInput = (files: File[]) => {
        const addedAttachments: File[] = [];
        files.forEach((val: File) => {
            if (
                !attachmentsToUpload.find((x: File) => {
                    return x.name === val.name;
                })
            )
                addedAttachments.push(val);
        });

        if (addedAttachments.length) setAttachmentsToUpload([...attachmentsToUpload, ...addedAttachments]);
    };

    const close = () => {
        setAttachmentsToUpload([]);
        setAttachmentsToRemove([]);
        setAttachments(note.attachments as BlobRef[]);
        onClose();
    };

    const attachmentsRenderer = (
        <Box mt={1} mb={1}>
            {attachments.map((val: BlobRef, index: number) => {
                return (
                    <Chip
                        style={{ maxWidth: 270 }}
                        color={"default"}
                        key={val.path}
                        size={"small"}
                        label={val.path?.substring(1, val.path?.length)}
                        deleteIcon={<Delete />}
                        icon={
                            <Box className={classes.mimeTypeIconBox}>
                                <MimeTypeIcon mimeType={val.blob?.mimeType as string} />
                            </Box>
                        }
                        onDelete={() => removeAttachment(index)}
                    />
                );
            })}
            {attachmentsToUpload.map((val: File, index: number) => {
                return (
                    <Chip
                        style={{ maxWidth: 270 }}
                        color={"default"}
                        key={val.name}
                        size={"small"}
                        label={val.name}
                        icon={
                            <Box className={classes.mimeTypeIconBox}>
                                <MimeTypeIcon mimeType={val.type} />
                            </Box>
                        }
                        onDelete={() => removeAttachmentToUpload(index)}
                    />
                );
            })}
        </Box>
    );

    return (
        <NoteDialog
            open={open}
            title={t("Edit Note")}
            note={note}
            onChange={setFormData}
            onCancel={close}
            attachmentsRenderer={attachmentsRenderer}
            onChangeAttachmentInput={onChangeAttachmentInput}
            dlgBtn={
                <Box ml={1}>
                    <ButtonWithProgress
                        title={t("Save")}
                        loading={inProgress}
                        color={"primary"}
                        disabled={!formData?.comment}
                        variant={"contained"}
                        onClick={async () => {
                            setInProgress(true);
                            const succeeded = await onEdit(
                                formData?.language as string,
                                formData?.comment as string,
                                formData?.visibilityType as NoteVisibilityTypesInput
                            );

                            if (succeeded) {
                                //setComment("");
                            }

                            setInProgress(false);
                        }}
                    />
                </Box>
            }
        />
    );
};
