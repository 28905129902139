import React, { FC } from "react";
import { NoteVisibilityTypes } from "../generated/consumer-graph-types";
import { PublicOutlined, VpnLockOutlined } from "@mui/icons-material";
import { ReactComponent as PublicOffIcon } from "../img/public_off.svg";
import { useTranslation } from "react-i18next";

type VisibilityIconProps = {
    visibility: NoteVisibilityTypes;
};

export const NotesVisibilityIcon: FC<VisibilityIconProps> = ({ visibility }) => {
    const { t } = useTranslation();

    switch (visibility) {
        case NoteVisibilityTypes.pendingApproval:
            return <VpnLockOutlined titleAccess={t("Pending approval")} />;
        case NoteVisibilityTypes.private:
            return <PublicOffIcon title={t("Private")} />;
        case NoteVisibilityTypes.public:
            return <PublicOutlined titleAccess={t("Public")} />;
        default:
            return null;
    }
};
