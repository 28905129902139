import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useDeleteConfirmationDialogStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            [theme.breakpoints.up("lg")]: {
                width: "24rem",
            },
        },
        icon: {
            color: theme.palette.error.contrastText,
            height: 32,
            width: 32,
        },
    })
);
