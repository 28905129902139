import React, { FC } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";

export interface PageTitleProp {
    title: string;
    gutterBottom?: boolean;
    backRoute?: string;
}

export const PageTitle: FC<PageTitleProp> = ({ title, backRoute, gutterBottom = true }) => {
    return (
        <Box sx={{ ml: { xs: 1.5, sm: 1.5, lg: 0 } }} mb={gutterBottom ? 2 : 0} display={"flex"} alignItems={"center"}>
            {backRoute && (
                <Box mr={1}>
                    <IconButton aria-label="back" component={Link} to={backRoute} size="small">
                        <ArrowBack />
                    </IconButton>
                </Box>
            )}
            <Typography variant="h6" gutterBottom={!backRoute && gutterBottom}>
                {title}
            </Typography>
        </Box>
    );
};
