//@ts-ignore
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import equal from "fast-deep-equal";
import merge from "lodash.merge";
import { useSnackbar } from "notistack";
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { IndexRange } from "react-virtualized";
import { Dictionary } from "ts-essentials";
import { GraphLoader } from "../../components/graph-loader";
import { useInfoCubeMediaQuery } from "../../components/info-cube-media-query";
import { FacetSelectionType } from "../../enums";
import {
    ContentEdge,
    ContentSort,
    ContentSortFields,
    CountRelation,
    Facet,
    DataDisplayConfig,
    DatafieldDefinition,
    SortOrder,
} from "../../generated/consumer-graph-types";
import {
    ContentsAggregationsProps,
    ContentsData,
    CONTENTS_QUERY,
    getContentsAggregationsQueryVariables,
} from "../../shared-queries/do-not-parse";
import { deepCopy } from "../../utils";
import * as parser from "../../utils/lucene-query-parser/parser";
import { ROUTE_EXPLORE } from "../routes";
import { URL_PARAM_SEARCH } from "../url-params";
import { ExploreFacetNavigationDesktop } from "./explore-facet-navigation-desktop";
import { ExploreSearch } from "./explore-search";
import { useExploreStyles } from "./explore-style";
import { HitList } from "./hit-list";
import { ExploreState, useExploreState } from "./use-explore-state";

type ExploreProps = {
    facetsFilter: Facet[];
    facetsNavigation: Facet[];
    dataDisplayConfiguration?: DataDisplayConfig;
    availableKeywordFields: string[];
    availableTextFields: string[];
    availableNumberFields: string[];
    hitlistMetadataDefinitions: any;
    navigationMetadataDefinitions?: DatafieldDefinition[];
};

type SearchState = {
    selectedFacetsByAll: Dictionary<string[]>;
    searchPhrase: string;
    searchFields: string[];
    hitsSort: ContentSort;
};

export const Explore: FC<ExploreProps> = ({
    facetsFilter,
    facetsNavigation,
    dataDisplayConfiguration,
    availableKeywordFields,
    availableTextFields,
    availableNumberFields,
    hitlistMetadataDefinitions,
    navigationMetadataDefinitions,
}) => {
    const DEFAULT_HITS_SORT = { field: ContentSortFields.score, order: SortOrder.desc };
    const MAX_SELECTED_FILTER_COUNT = 30;
    const classes = useExploreStyles();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isMobile = useInfoCubeMediaQuery();
    const [selectedFacetsByNavigation, setSelectedFacetsByNavigation] = useState<Dictionary<string[]>>({});
    const [selectedFacetsByFilter, setSelectedFacetsByFilter] = useState<Dictionary<string[]>>({});
    const [allFacets] = useState<Facet[]>(facetsFilter.concat(facetsNavigation));
    const [loading, setLoading] = useState<boolean>(true);
    const [hitsLoading, setHitsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [contentsData, setContentsData] = useState<ContentsData | undefined>();
    const contents = useRef<ContentsData | undefined>();
    const [searchState, setSearchState] = useState<SearchState | undefined>();
    const [facetNavWidth, setFacetNavWidth] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [exploreState, setExploreState] = useExploreState();

    useEffect(() => {
        setExploreState((val: ExploreState) => Object.assign({}, val, { selectedFacetsByNavigation }));
    }, [selectedFacetsByNavigation, setExploreState]);

    useEffect(() => {
        setExploreState((val: ExploreState) => Object.assign({}, val, { selectedFacetsByFilter }));
    }, [selectedFacetsByFilter, setExploreState]);

    const getContentsQueryProps = () => {
        return {
            rowsPerPage: 25,
            searchPhrase: searchState?.searchPhrase as string,
            selectedFacetsByAll: searchState?.selectedFacetsByAll ?? {},
            selectedSearchFields: searchState?.searchFields ?? [],
            sort: searchState?.hitsSort ?? { field: ContentSortFields.score, order: SortOrder.desc },
            navigationMetadataDefinitions: navigationMetadataDefinitions as DatafieldDefinition[],
            availableTextFields,
            availableKeywordFields,
            availableNumberFields,
            allFacetFilters: facetsFilter.concat(facetsNavigation),
        };
    };

    const refetchContents = () => {
        setHitsLoading(true);
        let queryProps = Object.assign({}, getContentsQueryProps(), {
            ignoreNavigationFacets: false,
        });

        refetchContentsData(getContentsAggregationsQueryVariables(queryProps)).then((result) => {
            if (result.error) {
                setError(result.error);
            } else {
                contents.current = result.data;
                setContentsData(result.data);
            }
            setLoading(false);
            setHitsLoading(false);
        });
    };

    const validateSearch = (search: string) => {
        try {
            parser.parse(search);
            return true;
        } catch (e: any) {
            return false;
        }
    };

    useEffect(() => {
        if (searchState) {
            refetchContents();
        }

        //listen to search variables to refetch contents
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchState]);

    const { fetchMore, refetch: refetchContentsData } = useQuery<ContentsData>(
        CONTENTS_QUERY(dataDisplayConfiguration?.entries || []),
        {
            skip: true,
            fetchPolicy: "no-cache",
        }
    );

    function handleUrlParams() {
        //check url params for search value and search fields
        const urlParams = new URLSearchParams(location.search);
        const searchParam = urlParams.has(URL_PARAM_SEARCH) ? urlParams.get(URL_PARAM_SEARCH) || "" : "";
        const fields: string[] = [];
        const filterParams: Dictionary<string[]> = {};
        const navigationParams: Dictionary<string[]> = {};

        urlParams.forEach((value, key) => {
            if (key === URL_PARAM_SEARCH) return;

            if (value === "1") {
                //TODO handle if facet value is 1
                //search fields
                fields.push(key);
            } else if (
                value !== "1" &&
                allFacets.some((val: Facet) => {
                    return val.referencedId === key;
                })
            ) {
                //add available facet filter
                if (facetsNavigation.some((val) => val.referencedId === key)) navigationParams[key] = value.split(",");
                if (facetsFilter.some((val) => val.referencedId === key)) filterParams[key] = value.split(",");
            }
        });

        if (!equal(selectedFacetsByNavigation, navigationParams)) setSelectedFacetsByNavigation(navigationParams);
        if (!equal(selectedFacetsByFilter, filterParams)) setSelectedFacetsByFilter(filterParams);

        const allFilterParams = Object.assign({}, navigationParams, filterParams);

        let isChanged =
            !equal(searchParam, searchState?.searchPhrase) ||
            !equal(fields, searchState?.searchFields) ||
            !equal(allFilterParams, searchState?.selectedFacetsByAll);

        if (isChanged) {
            if (!validateSearch(searchParam)) {
                //search phrase is invalid -> remove search phrase and reload page
                enqueueSnackbar(t("Error in search phrase in deeplink."), { variant: "error" });
                const urlParams = new URLSearchParams(location.search);
                urlParams.delete(URL_PARAM_SEARCH);
                const route = `${ROUTE_EXPLORE}?${urlParams.toString()}`;
                history.push(route);
                return;
            }

            setSearchState((prevState: SearchState | undefined) => {
                let newState: SearchState | undefined;

                if (prevState)
                    newState = {
                        ...prevState,
                        searchPhrase: searchParam,
                        searchFields: fields,
                        selectedFacetsByAll: allFilterParams,
                    };
                else
                    newState = {
                        searchPhrase: searchParam,
                        searchFields: fields,
                        selectedFacetsByAll: allFilterParams,
                        hitsSort: DEFAULT_HITS_SORT,
                    };

                return newState;
            });
        }
    }

    //listen if search value changes
    useEffect(() => {
        handleUrlParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    /**
     * Sets url with all selected facets if the maximum of selected filter  is not exceeded.
     * Selected facets will be set and deselected facets will be deleted from url params.
     *
     * @param {string[]} selectedFacets The selected facets .
     * @param {string[]} removeRootIds The deselected facets. Necessary to remove them from the url params.
     */
    const triggerSearch = (selectedFacets: Dictionary<string[]>, removeRootIds?: string[]): boolean => {
        const urlParams = new URLSearchParams(window.location.search);
        //remove selected facet from url params if removeRootId is set
        if (removeRootIds)
            removeRootIds.forEach((removeRootId) => {
                urlParams.delete(removeRootId);
            });

        let selectedFilterCount = 0;
        for (const rootId in selectedFacets) {
            selectedFilterCount += selectedFacets[rootId].length;
            if (selectedFacets[rootId].length) urlParams.set(rootId, selectedFacets[rootId].join(","));
            else urlParams.delete(rootId);
        }

        if (selectedFilterCount > MAX_SELECTED_FILTER_COUNT) {
            enqueueSnackbar(
                t("A maximum of {{max}} filter criteria can be selected.", {
                    max: MAX_SELECTED_FILTER_COUNT,
                }),
                { variant: "warning" }
            );
            return false;
        }

        const route = `${ROUTE_EXPLORE}?${urlParams.toString()}`;
        if (`${location.pathname}${location.search}` !== route) {
            history.push(route);
        }

        return true;
    };

    const onChangeFilter = (selectedFacets: Dictionary<string[]>, deselectedRootIds?: string[]) => {
        const allSelectedFacets = merge({}, selectedFacetsByNavigation, selectedFacets);
        if (triggerSearch(allSelectedFacets, deselectedRootIds)) {
            setSelectedFacetsByFilter(selectedFacets);
        }
    };

    const onChangeNavigation = (selectedNavigationFacets: Dictionary<string[]>, deselectedRootIds?: string[]) => {
        const allSelectedFacets = merge({}, selectedFacetsByFilter, selectedNavigationFacets);
        if (triggerSearch(allSelectedFacets, deselectedRootIds)) {
            setSelectedFacetsByNavigation(selectedNavigationFacets);
        }
    };

    const onResetAllFilter = () => {
        const deselectedRootIds: string[] = [];
        for (let k in selectedFacetsByFilter) {
            deselectedRootIds.push(k);
        }

        const allSelectedFacets = merge({}, selectedFacetsByNavigation);
        triggerSearch(allSelectedFacets, deselectedRootIds);
    };

    const onSelectFacetValue = (rootId: string, referencedId: string) => {
        const isNavigationFacet = facetsNavigation.some((val) => val.referencedId === rootId);
        let clonedSelectedFacets: Dictionary<string[]>;
        if (isNavigationFacet) {
            //check if navigation facet is already set if true then return
            if (selectedFacetsByNavigation[rootId] && selectedFacetsByNavigation[rootId].indexOf(referencedId) !== -1)
                return;

            clonedSelectedFacets = deepCopy(selectedFacetsByNavigation);
            //deselect previous value in facet for single select facets
            if (!clonedSelectedFacets[rootId] || facetsNavigation[0].display.type === FacetSelectionType.singleSelect) {
                clonedSelectedFacets[rootId] = [];
            }

            clonedSelectedFacets[rootId].push(referencedId);
            onChangeNavigation(clonedSelectedFacets);
        } else {
            //check if filter facet is already set if true then return
            if (selectedFacetsByFilter[rootId] && selectedFacetsByFilter[rootId].includes(referencedId)) return;

            clonedSelectedFacets = deepCopy(selectedFacetsByFilter);
            const facet = facetsFilter?.find((val) => val.referencedId === rootId);
            //deselect previous value in facet for single select facets
            if (facet?.display.type === FacetSelectionType.singleSelect) {
                delete clonedSelectedFacets[rootId];
            }

            if (clonedSelectedFacets[rootId]) clonedSelectedFacets[rootId].push(referencedId);
            else clonedSelectedFacets[rootId] = [referencedId];

            onChangeFilter(clonedSelectedFacets);
        }
    };

    const onSelectSort = (sort: ContentSort) => {
        if (!equal(sort, searchState?.hitsSort)) {
            setSearchState((prevState: SearchState | undefined) => {
                return !prevState ? prevState : { ...prevState, hitsSort: sort };
            });
        }
    };

    const loadMore = async ({ startIndex }: IndexRange) => {
        if (
            contents.current?.contents?.total.countRelation === CountRelation.eq &&
            contents.current?.contents?.total.count <= startIndex
        ) {
            return;
        }

        const contentsQueryProps = Object.assign({}, getContentsQueryProps(), {
            ignoreNavigationFacets: false,
        });
        let variables = getContentsAggregationsQueryVariables(contentsQueryProps);
        if (contents.current?.contents?.contents) {
            let content = contents.current.contents.contents[contents.current.contents.contents.length - 1];
            if (content) variables.after = content.cursor;
        }

        const result = await fetchMore({
            variables: variables,
        });

        const newContents = contents.current?.contents.contents.concat(result.data.contents.contents) as ContentEdge[];
        const newTotal = { ...contents.current?.contents.total, ...result.data.contents.total };
        contents.current = Object.assign(
            {},
            { contents: { contents: newContents, total: newTotal, aggregations: {} } }
        );

        setContentsData(contents.current);
    };

    const setFacetNavWidthCb = async (width: number) => {
        setFacetNavWidth((prev) => {
            if (prev === width) return prev;

            return width;
        });
    };

    const renderFacetNavigation = () => {
        if (isMobile || !navigationMetadataDefinitions?.length) {
            if (facetNavWidth !== 0) setFacetNavWidth(0);
            return null;
        }

        return (
            <ExploreFacetNavigationDesktop
                fieldDefinitions={navigationMetadataDefinitions}
                onSelect={onChangeNavigation}
                selectedNavigationFacets={selectedFacetsByNavigation}
                facetsCollection={facetsNavigation}
                contentsQueryPropsGetter={getContentsQueryProps}
                contentsData={contentsData}
                onWidthChange={setFacetNavWidthCb}
                top={80}
            />
        );
    };

    const render = (data: any) => {
        let queryProps = Object.assign({}, getContentsQueryProps(), {
            ignoreNavigationFacets: false,
        });

        let contentAggregationsForFacetFilterProps: ContentsAggregationsProps | undefined = queryProps;

        return (
            <Box className={classes.container}>
                {renderFacetNavigation()}
                <Box
                    className={classes.main}
                    sx={{ pl: isMobile ? 0 : `${facetNavWidth}px`, transitionProperty: "padding-left" }}
                >
                    <ExploreSearch
                        onSelectFacetValue={onSelectFacetValue}
                        contentFilterProps={queryProps}
                        facets={facetsFilter ?? []}
                        contentsTotal={contents.current?.contents.total}
                        onResetAll={onResetAllFilter}
                        onChange={onChangeFilter}
                        selectedFacets={selectedFacetsByFilter}
                        selectedSort={searchState?.hitsSort ?? DEFAULT_HITS_SORT}
                        onSelectSort={onSelectSort}
                        contentsAggregationsProps={contentAggregationsForFacetFilterProps}
                        fieldDefinitions={navigationMetadataDefinitions}
                        onSelectNavigation={onChangeNavigation}
                        selectedNavigationFacets={selectedFacetsByNavigation}
                        facetsCollection={facetsNavigation}
                        contentsQueryPropsGetter={getContentsQueryProps}
                        contentsData={contentsData}
                    />
                    <HitList
                        data={data}
                        loading={hitsLoading}
                        loadMore={loadMore}
                        fieldDefinitions={hitlistMetadataDefinitions}
                        facets={allFacets}
                        metadataSeparator={dataDisplayConfiguration?.separator}
                        dataDisplayConfigEntries={dataDisplayConfiguration?.entries || []}
                        onSelectFacet={onSelectFacetValue}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <GraphLoader
            classes={{ progress: classes.progress }}
            loading={loading}
            error={error}
            data={contentsData}
            render={render}
        />
    );
};
