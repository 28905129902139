import { Check } from "@mui/icons-material";
import { Box, Drawer, MenuItem, MenuList, Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ContentSort, ContentSortFields, SortOrder } from "../generated/consumer-graph-types";

type ContentSortDrawerProps = {
    open: boolean;
    onClose: () => void;
    selected: ContentSort;
    onSelect: (sort: ContentSort) => void;
};

export const ContentSortDrawer: FC<ContentSortDrawerProps> = ({ open, onClose, selected, onSelect }) => {
    const { t } = useTranslation();

    const entries = [
        {
            title: t("Ranking"),
            value: {
                field: ContentSortFields.score,
                order: SortOrder.desc,
            },
        },
        {
            title: t("Title ascending"),
            value: {
                field: ContentSortFields.title,
                order: SortOrder.asc,
            },
        },
        {
            title: t("Title descending"),
            value: {
                field: ContentSortFields.title,
                order: SortOrder.desc,
            },
        },
        {
            title: t("Language ascending"),
            value: {
                field: ContentSortFields.locale,
                order: SortOrder.asc,
            },
        },
        {
            title: t("Language descending"),
            value: {
                field: ContentSortFields.locale,
                order: SortOrder.desc,
            },
        },
        {
            title: t("Upload date ascending"),
            value: {
                field: ContentSortFields.uploadDate,
                order: SortOrder.asc,
            },
        },
        {
            title: t("Upload date descending"),
            value: {
                field: ContentSortFields.uploadDate,
                order: SortOrder.desc,
            },
        },
        {
            title: t("Modification date ascending"),
            value: {
                field: ContentSortFields.modificationDate,
                order: SortOrder.asc,
            },
        },
        {
            title: t("Modification date descending"),
            value: {
                field: ContentSortFields.modificationDate,
                order: SortOrder.desc,
            },
        },
    ];

    return (
        <Drawer anchor={"bottom"} open={open} onClose={onClose}>
            <Box m={3} mb={1} mt={2}>
                <Typography variant="h6">{t("Sort")}</Typography>
            </Box>
            <MenuList>
                {entries.map((entry, index) => {
                    const isSelected = JSON.stringify(selected) === JSON.stringify(entry.value);

                    return (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                onSelect(entry.value);
                                onClose();
                            }}
                        >
                            <Box component={"span"} minWidth={32}>
                                {isSelected && (
                                    <Box display={"flex"}>
                                        <Check />
                                    </Box>
                                )}
                            </Box>
                            <Box component={"span"} fontWeight={isSelected ? "fontWeightBold" : "fontWeightRegular"}>
                                {entry.title}
                            </Box>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Drawer>
    );
};
