import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStartPagePortalStyle = makeStyles((theme: Theme) => ({
    searchContainer: {
        display: "flex",
        width: "50%",
        justifyContent: "center",
        [theme.breakpoints.down("lg")]: {
            "@media (orientation: portrait)": {
                width: "70%",
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    imageContainer: {
        maxWidth: "35%",
        [theme.breakpoints.up("lg")]: {
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.down("lg")]: {
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.down("sm")]: {
            maxWidth: "65%",
        },
    },
}));
